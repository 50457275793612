export const QuoteBlock =
{
    uuid: "c85c6d86-7207-42da-b5d6-626fc61249a3",
    name: "quote",
    code: "quote-box",
    type: "Text",
    type_title: "Text",
    configuration: {
        owner_picture: {
            type: 'imageField',
            label: 'Owner Picture',
            isRequired: false,
            frame: "1:1"
        },
        alternative_text: {
            type: "",
            supportMultiLanguages: ["de", "en"]
        },
        image_title: {
            type: "",
            supportMultiLanguages: ["de", "en"]
        },
        owner_name: {
            type: 'textField',
            label: 'Owner Name',
            isRequired: false,
            maxLength: 100,
        },
        owner_position: {
            type: 'textFieldCustom',
            label: 'Owner Position',
            isRequired: false,
            supportMultiLanguages: ["de", "en"],
            
        },
        quote: {
            type: 'richTextEditor',
            label: 'Quote',
            isRequired: true,
            supportMultiLanguages: ["de", "en"],
            validationType: "string",
            validations: [
                {
                    type: "required",
                    params: ["Quote is required"],
                },
                {
                  "type": "nullable",
                  "params": []
                }
            ]
        },
        xssConfiguration: {
            owner_name: 'textField',
            owner_position: 'textFieldCustom',
            quote: 'richTextEditor'
        }
    },
    parameters: {},
}


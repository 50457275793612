import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./media-info.scss";
import _ from "lodash";
import axios from "axios";
import copy from "copy-to-clipboard";
import { MediaV2ManagerAPI } from "../../../../../services";
import { formatDateTimeV2, generateLinkDownLoad } from "../../../../../utils/logic";
import {
  formatFileSize,
  formatImageFileURL,
  getFileExtension,
  getOriginalFileName,
  IconConfig,
} from "../../../../../utils/mediaUtils";
import { env } from "../../../../../environment";
import { useTranslation } from "react-i18next";
import { ReactComponent as ReplaceSvg } from '../../../../../assets/images/icons/replace.svg';
import { ReactComponent as TrashSvg } from '../../../../../assets/images/icons/trash.svg';
import { ReactComponent as DownloadSvg } from '../../../../../assets/images/icons/download.svg';
import { ReactComponent as AdjustSvg } from '../../../../../assets/images/icons/adjust.svg';
import { ReactComponent as CopySvg } from '../../../../../assets/images/icons/copy.svg';
import CircleInfoSvg from '../../../../../assets/images/icons/info-circle.svg';
import { Button } from "primereact/button";
import { Formik } from "formik";
import { InputText } from "primereact/inputtext";
import { useLayoutV2 } from "../../../../../context/LayoutProvider";
import useAuth from "../../../../../context/useAuth";
import { PERMISSIONS_V2 } from "../../../../../components_v2/utils/utils";

const MediaInfo = (props: any) => {
  const { auth } = useAuth();
  const permissions = {
    canUpdateMedia: auth?.permissions?.includes(PERMISSIONS_V2.MEDIA_UPDATE),
  }
  const { onHide, data, refresh, editPhoto, handleFileChange, deleteMedia } = props;
  const inputFileRef = useRef<any>(null);
  const formikRef = useRef(null);
  const { setLoadingProgress, setSuccessProgress, setErrorProgress, getLanguage } = useLayoutV2();
  const [copyText, setCopyText] = useState("");
  const { t } = useTranslation('language', { keyPrefix: 'media_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

  const fileUrl = data.selectedMedia && data.selectedMedia.fullPath
    ? `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(data.selectedMedia.fullPath)}` : "";

  const previewFileURL = data.selectedMedia && data.selectedMedia.type === "Image"
    ? `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(data.selectedMedia.fullPath)}`
    : IconConfig[data.selectedMedia?.file_extension];

  const replaceImage = () => {
    inputFileRef.current.click();
  };

  const copyToClipboard = () => {
    copy(copyText);
    setSuccessProgress(t('txt_copy_media_url'));
  };

  const downLoadMedia = async () => {
    if (data?.selectedMedia) {
      setLoadingProgress(errorTrans('txt_loading'));
      const fileUrl = encodeURI(`${env.PUBLIC_UPLOAD_URL}/${data?.selectedMedia.fullPath}`);
      axios.get(fileUrl, { responseType: "blob" })
        .then((response: any) => {
          if (response && response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const fileName = getOriginalFileName(data?.selectedMedia.name) || "media";
            generateLinkDownLoad(fileName, url);
            setSuccessProgress(t('txt_download_media_success'));
          } else {
            setErrorProgress(errorTrans('txt_download_media_fail'));
          }
        });
    }
  };

  const onSubmitDataForm = async (values: any) => {
    try {
      setLoadingProgress(errorTrans('txt_loading'));
      let requestData = { ...data.selectedMedia, ...values };
      const response = await updateMedia(requestData);
      const method = response.config.method;

      if (response && response.status === 200 && method === "put") {
        setSuccessProgress(t('txt_update_media_success'));
        refresh();
      } else {
        throw new Error('txt_update_media_fail');
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error?.message || 'txt_update_media_fail'));
    }
  };

  const updateMedia = async (parameters: any) => {
    delete parameters["file_size"];
    delete parameters["file"];
    delete parameters[`${parameters["file"]}_extension`];

    let updateData = {
      uuid: parameters.uuid,
      folderId: parameters.folder_id,
      fullPath: parameters.fullPath,
      name: parameters.name,
      size: parameters.size,
      type: parameters.type,
      alternativeText: JSON.stringify(parameters.alternativeText),
      description: JSON.stringify(parameters.description),
      metadata: JSON.stringify(parameters.metadata),
      title: JSON.stringify(parameters.title)
    }

    return await MediaV2ManagerAPI.updateFile(updateData, false);
  };

  useEffect(() => {
    if (data.selectedMedia) {
      setCopyText(encodeURI(fileUrl));
    }
  }, [data.selectedMedia, fileUrl]);

  return (
    <div className="media-details">
      <div className="file-preview">
        <img alt="Media Preview"
          src={`${data.selectedMedia?.type === "Image"
            ? `${encodeURI(previewFileURL)}?v=${moment().seconds()}`
            : `${encodeURI(previewFileURL)}`}`}
          className={`fixed-frame-image image-w-${data.selectedMedia?.type === "Image" ? "90" : "25"} image-bg-${data.selectedMedia?.file_extension}`}
        />
      </div>
      <div className="button-panel d-flex flex-row">
        <Button
          className="wi-danger-button-icon-v2"
          type={"button"}
          hidden={!permissions.canUpdateMedia}
          onClick={deleteMedia}>
          <TrashSvg className="icon-svg"></TrashSvg>
        </Button>
        <Button
          className="wi-primary-button-icon-v2"
          type={"button"}
          hidden={!permissions.canUpdateMedia}
          onClick={replaceImage}>
          <ReplaceSvg className="icon-svg"></ReplaceSvg>
        </Button>
        <Button
          className="wi-primary-button-icon-v2"
          type={"button"}
          onClick={downLoadMedia}>
          <DownloadSvg className="icon-svg"></DownloadSvg>
        </Button>
        <Button
          className="wi-primary-button-icon-v2"
          type={"button"}
          onClick={copyToClipboard}>
          <CopySvg className="icon-svg"></CopySvg>
        </Button>
        {data.selectedMedia &&
          data.selectedMedia.type === "Image" &&
          getFileExtension(data.selectedMedia.name).toLowerCase() !== "svg" && (
            <Button
              className="wi-primary-button-icon-v2"
              type={"button"}
              hidden={!permissions.canUpdateMedia}
              onClick={editPhoto}
            >
              <AdjustSvg className="icon-svg"></AdjustSvg>
            </Button>
          )}
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputFileRef}
          onChange={handleFileChange}
          accept=".jg, .jpeg, .png, .svg, .doc, .docx, .xls, .xlsx, .pdf, .txt, .zip, .rar, .7z, .avi, .mov, .mp4"
        />
      </div>
      <div className="section-summary pr-24 pl-24 pt-16 pb-16">
        <div className="">{getOriginalFileName(data.selectedMedia?.name)}</div>
        <div className="txt-gray" dangerouslySetInnerHTML={{
          __html: t('txt_upload_media_title', { user: data.selectedMedia?.createdUser, time: formatDateTimeV2(data.selectedMedia?.createdAt, getLanguage()) }),
        }}>
        </div>
        {
          data.selectedMedia?.updatedAt
            ? <div className="txt-gray" dangerouslySetInnerHTML={{
              __html: t('txt_update_media_title', { user: data.selectedMedia?.updatedUser, time: formatDateTimeV2(data.selectedMedia?.updatedAt, getLanguage()) }),
            }}></div> : <></>
        }
      </div>
      <div className="section-media-properties">
        <div className="media-properties">
          <div className="media-main-properties d-flex flex-row">
            {
              data.selectedMedia?.metadata?.shoot_date
                ? <><div><span className="txt-gray">{t('txt_shoot_on')}</span> {data.selectedMedia.metadata?.shoot_date}</div><div className="small-circle"></div></> : ''
            }
            <div>{formatFileSize(data.selectedMedia?.size)}</div>
            {
              data.selectedMedia?.type === "Image" && data.selectedMedia?.metadata?.dimensions
                ? <><div className="small-circle"></div><div>{`${data.selectedMedia.metadata.dimensions.width} x ${data.selectedMedia.metadata.dimensions.height}`}</div></> : ''
            }
            {
              data.selectedMedia?.metadata?.xResolution
                ? <><div className="small-circle"></div><div>{data.selectedMedia.metadata.xResolution} DPI</div></> : ''
            }
          </div>
          <div className="media-extension">{getFileExtension(data.selectedMedia?.name)}</div>
        </div>
        {
          data.selectedMedia?.type === "Image"
            ? <div className="media-camera-info">
              {
                data.selectedMedia?.metadata?.camera_model || data.selectedMedia.metadata.originator
                  ? <>{data.selectedMedia.metadata.camera_model || ''} {data.selectedMedia.metadata.originator ? <><span className="txt-gray">{t('txt_by')}</span> {data.selectedMedia.metadata.originator}</> : ''}</>
                  : <>{t('txt_no_camera_info')}</>
              }
            </div> : ''
        }
      </div>
      <div className="media- pl-24 pr-24 pb-24 pt-12">
        <Formik
          innerRef={formikRef}
          initialValues={data.selectedMedia && data.selectedMedia.metadata
            ? _.cloneDeep(data.selectedMedia)
            : MediaBlock?.parameters}
          // validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            //console.log("values >>>", values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form mb-24">
                      <label className="label mb-8">
                        {t('txt_media_title')}
                        <div className="icon-info ml-8">
                          <img src={CircleInfoSvg} alt="icon info" loading="lazy" className="m-0" />
                        </div>
                      </label>
                      <InputText
                        type="text"
                        name="title.de"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title?.de || ''}
                        maxLength={500}
                        disabled={!permissions.canUpdateMedia}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="element-form mb-24">
                      <label className="label mb-8">
                        {t('txt_media_alternative_text')}
                        <div className="icon-info ml-8">
                          <img src={CircleInfoSvg} alt="icon info" loading="lazy" className="m-0" />
                        </div>
                      </label>
                      <InputText
                        type="text"
                        name="alternativeText.de"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.alternativeText?.de || ''}
                        maxLength={500}
                        disabled={!permissions.canUpdateMedia}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-form mb-24">
                      <label className="label mb-8">
                        {t('txt_media_description')}
                        <div className="icon-info ml-8">
                          <img src={CircleInfoSvg} alt="icon info" loading="lazy" className="m-0" />
                        </div>
                      </label>
                      <InputText
                        type="text"
                        name="description.de"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description?.de || ''}
                        maxLength={500}
                        disabled={!permissions.canUpdateMedia}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="element-form mb-24">
                      <label className="label mb-8">
                        {t('txt_media_copyright')}
                        <div className="icon-info ml-8">
                          <img src={CircleInfoSvg} alt="icon info" loading="lazy" className="m-0" />
                        </div>
                      </label>
                      <InputText
                        type="text"
                        name="metadata.copyright"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.metadata?.copyright || ''}
                        maxLength={500}
                        disabled={!permissions.canUpdateMedia}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-12">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between gap-24">
                      <Button
                        className="wi-danger-button-v2 h48 flex-1"
                        type="submit"
                        label={`${t('txt_cancel')}`}
                        hidden={!permissions.canUpdateMedia}
                        onClick={() => onHide()}
                      />
                      <Button
                        className="wi-primary-button-v2 h48 flex-1"
                        type="submit"
                        label={`${t('txt_edit')}`}
                        hidden={!permissions.canUpdateMedia}
                        onClick={() => onSubmitDataForm(values)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MediaInfo;

export const MediaBlock = {
  configuration: {
    alternativeText: {
      type: "textField",
      label: "Alternative Text",
      supportMultiLanguages: ["de", "en"],
      maxLength: 500,
    },
    title: {
      type: "textField",
      label: "Title",
      supportMultiLanguages: ["de", "en"],
      maxLength: 200,
    },
    description: {
      type: "textField",
      label: "Description",
      supportMultiLanguages: ["de", "en"],
      maxLength: 500,
    },
    metadata: {
      type: "object",
      isGlobalContentRef: false,
      children: {
        // originator: {
        //   type: "textField",
        //   label: "Creator",
        // },
        copyright: {
          type: "textField",
          label: "Copyright",
        },
      },
    },
    xssConfiguration: {
      description: "textFieldCustom",
    },
  },
  parameters: {
    alternativeText: {
      de: "",
      en: "",
    },
    title: {
      de: "",
      en: "",
    },
    description: {
      de: "",
      en: "",
    },
    metadata: {
      originator: "",
      copyright: "",
    },
  },
};

import { AxiosClient, cacheAge } from './httpClient'

export default class ReceiptManagerAPI {
  static getReceipts(data: any) {
    return AxiosClient.post(`/receipts/search`,  data);
  }

  static generateReceipt(data: any) {
    return AxiosClient.post(`/receipts`,  data);
  }

  static updateReceipt(uuid: string, data: any) {
    return AxiosClient.put(`/receipts/${uuid}`,  data);
  }

  static deleteReceipts(ids: any) {
    return AxiosClient.post(`/receipts/delete`, { ids });
  }

  static exportReceipt(uuid: any, lang: any) {
    return AxiosClient.get(`/receipts/${uuid}/export?lang=${lang}`);
  }
}

import * as React from "react";
import { useEffect } from "react";

const InputInline = (props: any) => {
    const { children, showEditLabel, setShowEditLabel, childRef, handleSubmitChangeLabel } = props;
  
    const handleSaveEdit = () => {
        setShowEditLabel(false);
        handleSubmitChangeLabel();
    }

    const handleKeyDownEditInput = (e: any) => {
        switch (e.key.toLowerCase()) {
            case "escape":
                setShowEditLabel(false);
                break;
            
            case "enter": 
                handleSaveEdit();
                break;
                
            default:
                break;
        }
    }

    useEffect(() => {
        if (childRef && childRef.current && showEditLabel) {
          childRef.current.focus();
        }
      }, [showEditLabel, childRef]);

    return (
        <div 
            onKeyDown={(e) => handleKeyDownEditInput(e)}
            onBlur={handleSaveEdit}
        >
            {children}
        </div>
    );
  };

  export default InputInline
import { useField } from "formik";
import * as React from "react";
import { useState } from "react";
import "./wi-upload-field.scss";
import "./wi-upload-multiple-field.scss";
import {
  formatFileSize,
  getBase64,
  getCompressedImage,
  getCompressedThumbnail,
  getFileExtension,
  getImageDimensions,
  IconConfig,
  IMAGE_EXTENSIONS,
  isFileOverSize,
  isFileTypeNotAllowed,
  parseImageMetadata,
} from "../../../utils/mediaUtils";
import _ from "lodash";

const MAX_COUNT = 10;

const WIUploadMultipleField = (props: any) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched } = helpers;
  const { error, touched } = meta;
  const { value } = field;
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [dropzoneActive, setDropzoneActive] = useState(false);

  const onSelectedFiles = (e: any) => {
    e.preventDefault();
    setTouched(true);
    const chosenFiles = Array.prototype.slice.call(e.target.files);

    if (areValidatedFiles(chosenFiles)) {
      handleUploadFiles(chosenFiles);
    } else {
      e.target.value = null;
    }
  };

  const areValidatedFiles = (chosenFiles: any) => {
    const foundFileOverSizeLimit = chosenFiles.some(isFileOverSize);
    if (foundFileOverSizeLimit) {
      window.alert("File size has exceeded it max limit of 25MB");
      return false;
    }

    const hasFileIsNotAllowed = chosenFiles.some(isFileTypeNotAllowed);

    if (hasFileIsNotAllowed) {
      window.alert(
        "The system accepts jpg, jpeg, png, svg, doc, docx, xls, xlsx, pdf, txt, 7z, rar, zip, avi, mov, and mp4 file types"
      );
      return false;
    }

    return true;
  };

  const handleUploadFiles = async (files: any) => {
    const imagesArray = Array.from(files).map(async (uFile: any) => {
      let f = uFile;
      const fileExtension = getFileExtension(f.name).toLowerCase();
      const isImage = IMAGE_EXTENSIONS.some((item) => item === fileExtension) && (fileExtension !== 'svg' && fileExtension !== 'png');
      let thumbnailBase64 = null;
      let fileMetaInfo;
      if (IMAGE_EXTENSIONS.some((item) => item === fileExtension)) {
        fileMetaInfo = await parseImageMetadata(f);
      }
      if (isImage) {
        f = await getCompressedImage(uFile);
        const thumbnailFile = await getCompressedThumbnail(f);
        thumbnailBase64 = await getBase64(thumbnailFile);
      }

      let fileBase64: any = await getBase64(f);
      if (IMAGE_EXTENSIONS.some((item) => item === fileExtension)) {
        // const description = { de: "", en: "" };
        const dimensions = await getImageDimensions(fileBase64);
        fileMetaInfo = {...fileMetaInfo, dimensions };
      }
      const fileIcon = IconConfig[fileExtension] || "";

      return {
        url: fileBase64,
        icon: fileIcon,
        name: f.name,
        size: f.size,
        type: f.type,
        extension: fileExtension,
        metadata: fileMetaInfo || {},
        thumbnailBase64: thumbnailBase64
      };
    });

    await Promise.all(imagesArray).then((results: any) => {
      const updatedList = [...selectedFiles, ...results];
      setSelectedFiles(updatedList);
      setValue(updatedList, true);
    });
  };

  const deleteFileHandler = (file: any) => {
    const updatedList = selectedFiles.filter((e: any) => e !== file);
    setSelectedFiles(updatedList);
    setValue(updatedList, true);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setTouched(true)
    const chosenFiles = Array.prototype.slice.call(e.dataTransfer.files);

    setDropzoneActive(false);
    if (areValidatedFiles(chosenFiles)) {
      handleUploadFiles(chosenFiles);
    } else {
      e.dataTransfer.value = null;
    }
  }
  return (
    <>
      {!props.hideLabel ? (
        <label className="label">
          {props.label}{" "}
          {props.required && !props.fieldName ? (
            <span className="required-label">*</span>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}

      <div
        onDragOver={(e) => {
          setDropzoneActive(true);
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          setDropzoneActive(false);
          e.preventDefault();
        }}
        onDrop={(e) => handleDrop(e)}
        className={`dropzone ${props.classField || ""} ${props.className || ""
          }  
        ${dropzoneActive ? "active" : ""}
        ${error &&
            touched &&
            (selectedFiles?.length === 0 || selectedFiles?.length > MAX_COUNT)
            ? "upload-error"
            : ""
          }
        `}
      >
        <label>
          {selectedFiles.length > MAX_COUNT ? (
            <p className="error">
              You can't upload more than {MAX_COUNT} images! <br />
              <span>
                please delete <b> {selectedFiles.length - MAX_COUNT} </b> of
                them{" "}
              </span>
            </p>
          ) : (
            <p>
              {dropzoneActive ? (<i className="pi pi-plus-circle" />) : ("Drop file here or click to upload")}

              {props.fieldName ? ` ${props.fieldName}` : ""}{" "}

              {props.required && props.fieldName ? (
                <span className="required-label">*</span>
              ) : (
                ""
              )}
            </p>
          )}

          <input
            className="input-upload"
            type={props.type}
            name={field.name}
            multiple={true}
            accept=".jpg, .jpeg, .png, .svg, .doc, .docx, .xls, .xlsx, .pdf, .txt, .zip, .rar, .7z, .avi, .mov, .mp4"
            onChange={(e: any) => onSelectedFiles(e)}
          />
        </label>
      </div>
      <div className="preview-file-container">
        {selectedFiles &&
          selectedFiles.map((file: any, i: any) => {
            return (
              <div key={i} className="file-review">
                <div key={file} className="file-review-item">
                  <div
                    className="file-preview-item-del mr-3"
                    onClick={() => deleteFileHandler(file)}
                  >
                    <i className="fa-solid fa-trash" />
                  </div>
                  <img
                    className={`file-review-img file-review-img-w-${file.icon ? "20" : "40"
                      } file-review-img-bg-${file.extension}`}
                    src={file.icon || file.url}
                    alt=""
                  />
                  <div className="file-review-item-info">
                    <p className="file-review-item-info-name">{file.name}</p>
                    <p className="file-review-item-info-size">{formatFileSize(file.size)}</p>
                  </div>

                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default WIUploadMultipleField;

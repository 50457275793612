import { useField } from "formik";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { env } from "../../../environment";
import "./wi-upload-field.scss";
import InsertMediaDialogComponent from "./wi-upload-media-dialog";
import { Toast } from "primereact/toast";
import { showNotification } from "../../../utils/logic";

const LIMIT_FILE_SIZE = 1000000;
const LIMIT_HERO_FILE_SIZE = 10000000;
const LIMIT_IMAGE_TYPES = ["jpg", "jpeg", "png", "svg"];

const WIUploadMediaField = (props: any) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched } = helpers;
  const { error, touched } = meta;
  const { value } = field;
  const [image, setImage] = useState(null);
  const [fileData, setFileData] = useState<any>();
  const [isShowMediaDialog, setIsShowMediaDialog] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    setImageUrl(setImageProps());
  }, [value]);

  const setImageProps = () => {
    if (!!value) {
      setImageAlternativeText(value);
      setImage(value);

      return `${env.PUBLIC_UPLOAD_URL}/${value}`;
    }

    return null;
  };

  const setImageAlternativeText = async (value: any) => {
    if (!!props.onURLChanged) {
      props.onURLChanged(fileData?.alternativeText['de'], fileData?.title['de']);
    }
  }

  const [imageUrl, setImageUrl] = useState(() => setImageProps());

  const handleImageValue = (selectedImage: any, isSetShowDialog?: boolean) => {
    setTouched(true);
    const limitImageTypes = props.imageTypes && props.imageTypes.length > 0 ? props.imageTypes : LIMIT_IMAGE_TYPES;
    if (!selectedImage) {
      return;
    }

    if (
      !limitImageTypes.some((item: string) => item === selectedImage.file_extension.toLowerCase())
    ) {
      const typesImage = props.imageTypes && props.imageTypes.length > 0 ? props.imageTypes.toString() : '.jpeg, .jpg, .png, and .svg';
      showNotification("error", `The system only accepts ${typesImage} file types`, toast);
      return;
    }

    if (!!props.onURLChanged) {
      props.onURLChanged(selectedImage?.alternativeText, selectedImage?.title);
    }

    setFileData(selectedImage);
    setValue(encodeURI(`${selectedImage.fullPath}`), true);
    setImageUrl(encodeURI(`${env.PUBLIC_UPLOAD_URL}/${`${selectedImage.fullPath}`}`));
    if(isSetShowDialog) {
      setIsShowMediaDialog(false);
    }
  };

  const handleRemoveImageValue = (event: any) => {
    event?.preventDefault();
    setImage(null);
    setImageUrl(null);
    setTouched(false);
    setValue("");
    if (!!props.onURLChanged) {
      props.onURLChanged(null);
    }
    return;
  };

  const onOpenMediaManager = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowMediaDialog(true);
  };

  return (
    <>
      {!props.hideLabel ? (
        <label className="label">
          {props.label}{" "}
          {props.required && !props.fieldName ? (
            <span className="required-label">*</span>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}
      {(!props.hideLabel && !props.hideLabelFileName) ? <label className="label">{image}</label> : ""}

      <label
        className={`label-upload-field ${props.classField || ""} ${
          props.className || ""
        } ${error && touched && !image ? "upload-error" : ""}`}
      >
        {
          props.hideHintText ? (
            ""
          )
          : (
          <p style={{ display: imageUrl ? "none" : "block" }}>
            Click to choose{" "}
            {props.fieldName ? ` ${props.fieldName}` : ""}{" "}
            {props.required && props.fieldName ? (
              <span className="required-label">*</span>
            ) : (
              ""
            )}
          </p>
          )
        }
        
        <button
          className="input-upload"
          onChange={handleImageValue}
          disabled={props.disabled}
          type={props.type}
          name={field.name}
          onClick={(e: any) => onOpenMediaManager(e)}
        />
        {imageUrl && (
          <>
            {
             props.isNotCenter ? 
             (
              <div className="orientation-image-container">
                <img
                  src={imageUrl || ""}
                  alt={imageUrl || ""}
                  className={props.classImage + " media-preview"}
                  // sizes="(min-width: 1023px) 780px, (min-width: 675px) 620px, 100vw"
                  // srcSet={`${imageUrl} 320w, ${imageUrl} 6400w, ${imageUrl} 960w, ${imageUrl} 12800w, ${imageUrl} 1920w, ${imageUrl} 2560w`}
                />
              </div>
             ) :
             (
              <img
              src={imageUrl || ""}
              alt={imageUrl || ""}
              className={props.classImage + " media-preview"}
              // sizes="(min-width: 1023px) 780px, (min-width: 675px) 620px, 100vw"
              // srcSet={`${imageUrl} 320w, ${imageUrl} 6400w, ${imageUrl} 960w, ${imageUrl} 12800w, ${imageUrl} 1920w, ${imageUrl} 2560w`}
              />
             )
            }
            {!props.disabledRemove ? !props.disabled ? (
              <span className="remove-icon">
                <i
                  className="fa-solid fa-xmark fa-lg"
                  onClick={handleRemoveImageValue}
                />
              </span>
            ) : '' : ''}
          </>
        )}
      </label>
      <InsertMediaDialogComponent
        visible={isShowMediaDialog}
        fileType="image"
        handleFileValue={(selectedMedia: any) => handleImageValue(selectedMedia, true)}
        setIsShowMediaDialog={setIsShowMediaDialog}
        onHide={() => setIsShowMediaDialog(false)}
      />
      <Toast ref={toast} />
    </>
  );
};

export default WIUploadMediaField;

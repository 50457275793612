import { AxiosClient, cacheAge } from './httpClient'

export default class RegionManagerAPI {
  static getAllRegions() {
    return AxiosClient.get(`/regions`, cacheAge);
  }

  // static getAllForestTypes() {
  //   return AxiosClient.get(`/forest-types`, cacheAge);
  // }

  static getRegions(data: any) {
    return AxiosClient.get(`/regions/pagination`, {...data});
  }

  static getRegion(uuid: string) {
    return AxiosClient.get(`/regions/${uuid}`);
  }

  static generateRegions(data: any) {
    return AxiosClient.post(`/regions`,  data);
  }

  static updateRegions(id: string, data: any) {
    return AxiosClient.put(`/regions/${id}`,  data);
  }

  static deleteRegion(uuid: string) {
    return AxiosClient.delete(`/regions/${uuid}`);
  }
}

import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./pre-add-partner-form.scss";
import { PARTNER_CATEGORY_LIST, PARTNER_TYPE_LIST, PARTNER_TYPES } from "../../../../components_v2/utils/utils";
import { WIFormDropdownV2, WIFormInputTextV2 } from "../../../../components_v2/common/form-fields";
import { createPartner, updatePartner } from "../../../../components/partner-manager/partner-business";
import { PartnerManagerAPI } from "../../../../services";
import { useLayoutV2 } from "../../../../context/LayoutProvider";

const PreAddPartnerForm = (props: any) => {
  const { onHide, partner, refreshData } = props;
  const formikRef = useRef<any>(null);
  const navigate = useNavigate();
  const { t } = useTranslation('language', { keyPrefix: 'partner_details' });
  const { t: globalTrans } = useTranslation('language');
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { turnOffProgress, setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();

  const commonItemTemplate = (option: any) => {
    return <div>{globalTrans(option?.label)} </div>
  };

  const syncPartnerData = async (partnerValues: any) => {
    try {
      let updatedPartner = null;
      if (partner?.uuid) {
        updatedPartner = await updatePartner(partner?.uuid || '', partnerValues);
        setSuccessProgress(errorTrans('txt_update_partner_success'));
      } else { 
        updatedPartner = await createPartner(partnerValues);
        setSuccessProgress(errorTrans('txt_generate_partner_success'));
      }
      refreshData(updatedPartner);
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  };

  const onSubmit = async () => {
    try {
      const { values, isValid } = formikRef?.current;
      formikRef.current?.validateForm();
      if (formikRef && isValid && values.type) {
        setLoadingProgress(errorTrans('txt_loading'));
        const partnersRes = await PartnerManagerAPI.getAllPartners(false);
        const partners = partnersRes?.data?.records || [];
        const isDuplicatedCode = partners.some((p: any) => p.uuid !== partner?.uuid && p.code?.toUpperCase() === values.code?.toUpperCase());
        if (isDuplicatedCode) {
          throw new Error('txt_failed_partner_duplicated_code');
        }

        const categoryParam = values.type === PARTNER_TYPES.PARTNER && values.category ? `&category=${values.category}` : '';
        if (partner?.uuid) {
          if (values.type === PARTNER_TYPES.FUNDRAISER || values.type === PARTNER_TYPES.PARTNER) {
            await syncPartnerData(values);
          }
          onHide();
        } else {
          if (values.type === PARTNER_TYPES.FUNDRAISER) {
            await syncPartnerData(values);
            onHide();
          } else {
            turnOffProgress();
            const codeParam = values.code ? `&code=${values.code}` : '';
            navigate(`/partners/create?type=${values.type}${codeParam}${categoryParam}`);
          }
        }
      }
    } catch (error: any) {
      setErrorProgress(errorTrans(error.message));
    }
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(errorTrans('txt_required')),
    type: Yup.string().nullable().required(errorTrans('txt_required')),
    // category: Yup.string().nullable().when("type", {
    //   is: (type: string) => type === PARTNER_TYPES.PARTNER,
    //   then: Yup.string().nullable().required(errorTrans('txt_required')),
    // }),
    name: Yup.string().nullable().when("type", {
      is: (type: string) => type === PARTNER_TYPES.FUNDRAISER,
      then: Yup.string().nullable().required(errorTrans('txt_required')),
    }),
  });

  const initialFormValues = () => {
    if (partner) {
      return partner;
    }

    let initialData = {
      code: '',
      name: '',
      type: null,
      category: null,
      description: {de: ' ', en: ' '},
      partner_logo: '',
      headline_image: '',
      mobile_headline_image: ''
    };

    return initialData;
  };

  const onKeyPress = (evt: any) => {
    return (
      ["+", "-", ".", "{", "}", "[", "]"].includes(evt.key) &&
      evt.preventDefault()
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={initialFormValues()}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        //console.log("values >>>", values);
      }}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="row-content">
            <div className="row">
              {
                !partner ? <div className="col-md-12 mb-24">
                  <WIFormInputTextV2 
                    title={t('txt_partner_code')}
                    name='code'
                    isRequired={true}
                    maxLength={3}
                    onKeyDown={(e: any) => onKeyPress(e)}
                  />
                </div> : <></>
              }
              {
                !partner ? <div className="col-md-12 mb-24">
                <WIFormDropdownV2
                  name='type'
                  title={t('txt_partner_type')}
                  isRequired={true}
                  optionLabel="label"
                  optionValue="code"
                  appendTo="self"
                  valueTemplate={commonItemTemplate}
                  itemTemplate={commonItemTemplate}
                  options={PARTNER_TYPE_LIST}
                  onChange={(e: any) => {
                    if (e.target.value !== PARTNER_TYPES.PARTNER) {
                      setFieldValue("category", '', true);
                    }
                    handleChange(e);
                  }}
                />
                </div> : <></>
              }
              {/* {
                values.type === PARTNER_TYPES.PARTNER ? <div className="col-md-12 mb-24">
                  <WIFormDropdownV2
                    name='category'
                    title={t('txt_category')}
                    isRequired={true}
                    optionLabel="label"
                    optionValue="code"
                    appendTo="self"
                    valueTemplate={commonItemTemplate}
                    itemTemplate={commonItemTemplate}
                    options={PARTNER_CATEGORY_LIST}
                  />
                </div> : <></>
              } */}
              {
                values.type === PARTNER_TYPES.FUNDRAISER ? <div className="col-md-12 mb-24">
                  <WIFormInputTextV2
                    title={t('txt_partner_name')}
                    name="name"
                    isRequired={true}
                    maxLength={100}
                  />
                </div> : <></>
              }
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between gap-24">
                  <Button
                    className="wi-danger-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t('txt_cancel')}`}
                    onClick={() => onHide()}
                  />
                  <Button
                    className="wi-primary-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t('txt_continue')}`}
                    onClick={() => onSubmit()}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default PreAddPartnerForm;

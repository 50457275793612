import * as React from "react";
import { useState, useRef } from "react";
import { FieldArray, useFormikContext } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import _ from "lodash";
import { Tree } from "primereact/tree";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { v4 as uuidv4 } from "uuid";
import "./header-subitem-list.scss";
import { WiTextEditor, WiTextEditor1Line } from "../../../../../dynamic-form/commons";
import SearchURLGlobal from "../../../../../common/searchURLGlobal/searchURLGlobal";
import { clearHtmlTags } from "../../../../../../utils/xssFilter";

const CMS_HEADER_ICONS = [
  { label: 'News', value: '&#xe93a;' },
  { label: 'Globe', value: '&#xe940;' },
  { label: 'Campaign', value: '&#xe941;' },
  { label: 'About Us', value: '&#xe93c;' },
  { label: 'Info', value: '&#xe939;' },
  { label: 'Project', value: '&#xe93b;' },
  { label: 'German Flag', value: '&#xe93e;' },
  { label: 'Canadian Flag', value: '&#xe93f;' },
  { label: 'Peruvian Flag', value: '&#xe93d;' },
  { label: 'Chevron Right', value: '&#xe903;' },
]

const HeaderSubItemList = (props: any) => {
  const { globalContent, type } = props;
  const { values, handleChange, handleBlur, setFieldValue } = useFormikContext<any>();
  const [selectedNode, setSelectedNode] = useState<any>();
  const dragItem = useRef<any>();
  const dragOverItem = useRef<any>();
  const name = 'items';

  const renderIcons = (option: any) => {
    return option ? <div className="header-item-label"><span className="aw_icon_dropdown"><i className="aw_icon" dangerouslySetInnerHTML={{ __html: option.value }}></i></span> {option.label}</div> : '';
  }

  const formatHeader = (header: string) => {
    let str = _.cloneDeep(header);
    clearHtmlTags(str);
    return str;
  }

  const removeItem = (arrayHelpers: any, e: any, i: number) => {
    e.preventDefault();
    e.stopPropagation();
    arrayHelpers.remove(i);
  };

  const buildHeaderTemplate = (arrayHelpers: any, node: any, i: any) => (options: any) => {
    const valueItems = _.get(values, name);
    const valueItem = valueItems ? _.get(valueItems, i) : null;

    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} pl-1`;
    const header = formatHeader(_.get(node, 'label'));
    const disabledEdit = valueItem?.disabled || false;

    return (
      <div className={className} onClick={!disabledEdit ? options.onTogglerClick : () => { }}>
        <button className={options.togglerClassName} onClick={!disabledEdit ? options.onTogglerClick : () => { }}>
          <span className={toggleIcon}></span>
        </button>
        <div className={titleClassName} dangerouslySetInnerHTML={{ __html: header }}></div>
        {
         !node.disabled ? <i
            className={`item-icon fa-solid fa-trash-can disable-btn`}
            onClick={(e) => removeItem(arrayHelpers, e, findIndex(node))}
          ></i> : <></> 
        }
      </div>
    )
  }

  const dragStart = (e: any, uuid: string) => {
    dragItem.current = e.target.id;
  }

  const dragEnter = (e: any, arrayHelpers: any) => {
    e.preventDefault();
    dragOverItem.current = e.currentTarget.id;
    const dragIndex = values.items?.findIndex((c: any) => c.uuid === dragItem.current);
    const dropIndex = values.items?.findIndex((c: any) => c.uuid === dragOverItem.current);
    arrayHelpers.move(dragIndex, dropIndex);
  }

  // const drop = (arrayHelpers: any) => (e: any) => {
  //   e.preventDefault();
  //   const dragIndex = values.items?.findIndex((c: any) => c.uuid === dragItem.current);
  //   const dropIndex = values.items?.findIndex((c: any) => c.uuid === dragOverItem.current);
  //   arrayHelpers.move(dragIndex, dropIndex);
  // }

  const nodeTemplate = (arrayHelpers: any, node: any, options: any) => {
    const index = findIndex(node);
    return (
      <div
        draggable
        id={node.uuid}
        key={`items.${index}`}
        onDragOver={(e) => e.preventDefault()}
        onDragStart={(e: any) => dragStart(e, node.uuid)}
        onDragEnter={(e: any) => dragEnter(e, arrayHelpers)}
      // onDragEnd={drop(arrayHelpers)}
      >
        <Panel
          key={`items.${index}`}
          className="header-subitem-list-panel"
          headerTemplate={buildHeaderTemplate(arrayHelpers, node, index)}
          collapsed={node.uuid !== selectedNode}
          onToggle={(e) => setSelectedNode(!e.value ? node.uuid : null)}
          toggleable
        >
          <div className="form-element-item" key={`items.${index}`}>
            <div className="row">
              {
                type === 'list-sub-items' ? <div className="col-md-6">
                  <div className="element-form">
                    <label className="label">
                      Icon
                    </label>
                    <Dropdown
                      // className={`header-item-icon ${errors.icon ? "has-error" : ""}`}
                      name={`items.${index}.icon`}
                      value={values.items[index].icon}
                      options={CMS_HEADER_ICONS}
                      itemTemplate={renderIcons}
                      valueTemplate={renderIcons}
                      onChange={handleChange}
                      optionLabel="label"
                      optionValue="value"
                      showClear
                    />
                  </div>
                </div> : <></>
              }
              <div className="col-md-12">
                <div className="element-form">
                  <label className="label">
                    Title {" "}
                    {/* <span className="required-label">*</span> */}
                  </label>
                  <WiTextEditor1Line
                    name={`items.${index}.label`}
                    values={values}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="element-form">
                  <SearchURLGlobal
                    label={"Link"}
                    search_content={globalContent}
                    values={values.items[index].link}
                    searchFields={["name"]}
                    // isRequired={true}
                    onChangeValue={(valueURL: any) => {
                      setFieldValue(`items.${index}.link`, valueURL, true);
                    }}
                  />
                </div>
              </div>
              {
                type === 'list-sub-items-description' ? <div className="col-md-12">
                  <div className="element-form">
                    <label className="label">
                      Description {" "}
                      <span className="required-label">*</span>
                    </label>
                    <WiTextEditor
                      name={`items.${index}.description`}
                      values={values}
                    />
                  </div>
                </div> : <></>
              }
            </div>
          </div>
        </Panel>
      </div>
    )
  }

  const findIndex = (node: any) => values.items?.findIndex((c: any) => c.uuid === node?.uuid);

  const validatedNodes = (arrayHelpers: any) => (value: any) => {
    let dropIndex = value.dropNode ? findIndex(value.dropNode) : value.dropIndex;
    const dragIndex = findIndex(value.dragNode);
    arrayHelpers.move(dragIndex, dropIndex);
  };

  return <FieldArray name='items'
    render={(arrayHelpers:any) => {
      return <>
        {/* <Tree
          key={`items`}
          className="header-subitem-tree"
          contentClassName="form-tree-content"
          dragdropScope="wi-array-field-tree"
          value={values.items}
          onDragDrop={(event: any) => validatedNodes(arrayHelpers)(event)}
          nodeTemplate={nodeTemplate}
        /> */}

        {
          values.items?.map((c: any) => nodeTemplate(arrayHelpers, c, null))
        }
        <Button
          className="btn-submit-list"
          onClick={() => arrayHelpers.push({ uuid: uuidv4(), label: 'New', link: { de: '#' } })}>
          <i className="fa-solid fa-plus"></i>
        </Button>
      </>
    }}
  />;
};

export default HeaderSubItemList;

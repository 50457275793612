import { AxiosClient } from './httpClient'

export default class CouponsManagerAPI {
  static getCoupons(data: any) {
    // return AxiosClient.get(`/coupon-batches`, data);
    return AxiosClient.post(`/coupon-batches/search`, data);
  }

  static getCampaigns() {
    return AxiosClient.get('/campaigns');
  }

  static generateCoupon(data: any) {
    return AxiosClient.post(`/coupon-batches`,  data);
  }

  static exportSCVCoupon(uuid: string) {
    return AxiosClient.get(`/coupon-batches/${uuid}/export`);
  }

}

import { AxiosClient, cacheAge } from '../httpClient'

export default class ContactManagerAPI {
  static deleteQuote(id: string) {
    return AxiosClient.delete(`/quotes/${id}`);
  }

  static createQuote(data: any) {
    return AxiosClient.post(`/quotes`, data);
  }

  static updateQuote(data: any) {
    return AxiosClient.put(`/quotes/${data.uuid}`, data);
  }

  static getAll(enableCache: boolean = true) {
    return enableCache
    ? AxiosClient.get(`/quotes`, cacheAge)
    : AxiosClient.get(`/quotes`);
  }

  static getQuote(uuid: string) {
    return AxiosClient.get(`/quotes/${uuid}`);
  }
}
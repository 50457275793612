import { AxiosClient } from "./httpClient";

export default class SubscriptionManagerAPI{

    static getSubscriptionsBySearch(data: any) {
        return AxiosClient.post(`/subscriptions/search`, data);
    }

    static getSubscriptionsByID(id: any) {
        return AxiosClient.post(`/subscriptions/${id}`, {});
    }
} 
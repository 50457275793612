import * as React from "react";
import { useEffect, useState } from "react";
import "./coupon-detail-filter-component.scss";
import { Button } from "primereact/button";
import CouponDetailFilterField from "./coupon-detail-filter-field";
import { WISearchField } from "../../common";
import { CODE_STATUS_LIST } from "../../../utils/utils";
import CalendarField from "../../donation-manager/components/donation-filter/calendar-field";

const CouponDetailFilterComponent = (props: any) => {
    const { fields, filterValues, lang, onRemove, onChange, onSearchFunc } = props;
    const [status, setStatus] = useState<any[]>(CODE_STATUS_LIST);
    const [values, setValues] = useState<Map<string, string[]>>();
    const [isDirty, setDirty] = useState<boolean>(false);
    const [search, setSearch] = useState<any>(filterValues.search);

    const getFields = () => {
        const resetValues = new Map<string, string[]>();
        fields.forEach((item: string) => {
            resetValues.set(item, []);
        });
        setValues(resetValues);
    };

    useEffect(()=> {
        getFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const filter = new Map<string, string[]>();
        fields.forEach((k: string) => {
            if(k === 'dates' && filterValues.where.from && filterValues.where.to) {
                filter.set(k, [filterValues.where.from, filterValues.where.to]);
            } else {
                filter.set(k, filterValues.where[k]);
            }
        });
        setValues(filter);
        setSearch(filterValues.search);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues]);

    const onRemoveFilters = async () => {
        fields.forEach(async (item: string) => {
            values?.set(item, []);
        });
        setSearch('');
        await onRemove();
    };

    const onFilterChange = async (event: any) => {
        setDirty(true);
        values?.set(event.field, event.value);
        await onChange({field: event.field, value: event.value});  
    };

    return (
        <div className="filter-coupon-detail-component">
            <div className="flex align-items-center flex-wrap justify-content-between filter-panel">
                <div className="filter-container">
                    <WISearchField
                        icon={"pi pi-search"}
                        placeholder="Search for anything"
                        setSearch={(value: any) => setSearch(value.global.value)}
                        enterSearch={() => onSearchFunc(search)}
                        globalValue={search}
                    />
                    <CouponDetailFilterField
                        name="status"
                        label="Status"
                        className="wi-filter-option-coupon"
                        pannelClassName="overlaypanel-filter-coupons"
                        selectedItems={values?.get("status") || []}
                        options={status}
                        optionLabel={"name.de"}
                        optionValue={"code"}
                        visible={values?.has("status") || false}
                        isDirty={isDirty}
                        setDirty={setDirty}
                        onChange={onFilterChange}
                    />
                    <CalendarField
                        name="dates"
                        label="Dates"
                        className="wi-filter-option-coupon"
                        pannelClassName="overlaypanel-filter-coupons"
                        selectedItems={values?.get("dates") || []}
                        visible={values?.has("dates") || false}
                        isDirty={isDirty}
                        setDirty={setDirty}
                        onChange={onFilterChange}
                    />
                </div>
                <div className="remove-filter-button">
                <Button
                    type="button"
                    icon="pi pi-times"
                    label="Clear filter(s)"
                    onClick={onRemoveFilters}
                />
                </div>
            </div>
        </div>
    );
};

export default CouponDetailFilterComponent;

import * as React from "react";
import { useState, useRef } from "react";
import "./import-old-donations-page.scss";
import Layout from "../layout/layout";
import { Formik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { showNotification } from "../../utils/logic";
import { ImportOldDonationsTypeList, TypeOldDataCode } from "../../utils/utils";
import WICSVUploadField from "../common/upload/wi-upload-csv-field";
import { SelectButton } from "primereact/selectbutton";
import ImportOldDonationsAPI from "../../services/importOldDonationsService";

const ImportOldDonationsPage = () => {
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(TypeOldDataCode.donation);

  const formikRef = useRef(null);

  const handleSubmit = async (values: any) => {
    try {
      let res = null;
      if(selectedItem === TypeOldDataCode.donation){
        res = await ImportOldDonationsAPI.importDonations({data: values.dataImport});
      }
      else{
        res = await ImportOldDonationsAPI.importDonors({data: values.dataImport});
      }
      if (res && res?.status === 200) {
        setIsLoading(false);
        showNotification("success", "Import successfully", toast);
      } else {
        setIsLoading(false);
        showNotification("error", "Failed to import", toast);
      }
    
    } catch (error) {
      setIsLoading(false);
      showNotification("error", "Failed to import", toast);
    }
  };

  const initialValues = () => {
    return {
      language: "de",
    }
  };


  return (
    <Layout>
      <div className="import-old-donations-page">
        <Toast ref={toast} />
        <div className="import-header">
        </div>
        <div className="import-container import-container-extra">
          <div className="impoty-btn">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues()}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                handleSubmit(values);
              }}
            >
              {({
                dirty,
                isValid,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-6 col-md-6">
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="input-item">
                        <label className="label">Import For <span className="required-label">*</span></label>
                        <SelectButton
                          className="donation-checkbox"
                          id="is_prepaid"
                          name="is_prepaid"
                          options={ImportOldDonationsTypeList}
                          optionLabel="name"
                          optionValue="code"
                          onChange={(item: any) => {
                            if (item.value) {
                              setSelectedItem(item.value);
                            }
                          }}
                          value={selectedItem}
                        />
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center justify-content-start upload-file">
                      <WICSVUploadField
                        name="dataImport"
                        className="input-upload"
                        labelClassName="label-upload-csv"
                        nameFileClassName="name-file" />
                    </div>
                    <div className="col-12 d-flex flex-column align-items-start justify-content-start note-message">
                      <p>Please make sure that all data are correct. This cannot be undone.</p>
                    </div>
                    <div className="btn-import">
                      <Button
                        loading={isLoading}
                        type="submit"
                        label="Submit"
                        icon="pi pi-chevron-right"
                        iconPos="right"
                        className="btn-submit p-button-rounded">
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImportOldDonationsPage;

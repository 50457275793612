import * as React from "react";
import { memo } from 'react';
import { Dropdown } from "primereact/dropdown";
import { InputText } from 'primereact/inputtext';
import { RegionStatusUtils } from "../../../../utils/utils";
import { WiTextEditor } from "../../../dynamic-form/commons";
import WIUploadMediaField from "../../../common/upload/wi-upload-media-field";
import { isValidateSpecialCharacter } from "../../../../utils/logic";
import useAuth from "../../../../context/useAuth";
import { PERMISSIONS_V2 } from "../../../../components_v2/utils/utils";

const GeneralFormComponent = (props: any) => {
  const { values, errors, touched, handleChange, 
    handleBlur, setFieldValue, countries, lands, region } = props;
  const { auth } = useAuth();
  const permissions = {
    canCreateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_CREATE),
    canUpdateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_UPDATE),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW)
  };

    return (
        <>
          <div className="row row-content">
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Name DE{" "}
                  <span className="required-label">*</span>
                </label>
                <InputText
                  className={`${
                    touched.name?.de && errors.name?.de
                      ? "has-error"
                      : ""
                  }`}
                  type="text"
                  name="name.de"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name?.de}
                  maxLength={50}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
                <div
                  className={`${
                    touched.name?.de && errors.name?.de ? "error" : ""
                  }`}
                >
                  {touched.name?.de && errors.name?.de}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Name EN{" "}
                  <span className="required-label">*</span>
                </label>
                <InputText
                  className={`${
                    touched.name?.en && errors.name?.en
                      ? "has-error"
                      : ""
                  }`}
                  type="text"
                  name="name.en"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name?.en}
                  maxLength={50}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
                <div
                  className={`${
                    touched.name?.en && errors.name?.en ? "error" : ""
                  }`}
                >
                  {touched.name?.en && errors.name?.en}
                </div>
              </div>
            </div>
          </div>
          <div className="row row-content">
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Headline DE{" "}
                  <span className="required-label">*</span>
                </label>
                <InputText
                  className={`${
                    touched.headline?.de && errors.headline?.de
                      ? "has-error"
                      : ""
                  }`}
                  type="text"
                  name="headline.de"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.headline?.de}
                  maxLength={50}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
                <div
                  className={`${
                    touched.headline?.de && errors.headline?.de ? "error" : ""
                  }`}
                >
                  {touched.headline?.de && errors.headline?.de}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Headline EN{" "}
                  <span className="required-label">*</span>
                </label>
                <InputText
                  className={`${
                    touched.headline?.en && errors.headline?.en
                      ? "has-error"
                      : ""
                  }`}
                  type="text"
                  name="headline.en"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.headline?.en}
                  maxLength={50}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
                <div
                  className={`${
                    touched.headline?.en && errors.headline?.en ? "error" : ""
                  }`}
                >
                  {touched.headline?.en && errors.headline?.en}
                </div>
              </div>
            </div>
          </div>
          <div className="row row-content">
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Sub-headline DE{" "}
                </label>
                <InputText
                  type="text"
                  name="additional_description.de"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.additional_description?.de}
                  maxLength={80}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Sub-headline EN{" "}
                </label>
                <InputText
                  type="text"
                  name="additional_description.en"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.additional_description?.en}
                  maxLength={80}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              </div>
            </div>
          </div>
          <div className="row row-content">
            {
              region ?
              <div className="col-md-6">
                <div className="element-form">
                  <label className="label">Active Area <span className="required-label"> *</span></label>
                  <Dropdown
                    id="dropdown_status"
                    name="default_land_id"
                    className={`${
                      touched.default_land_id && errors.default_land_id
                        ? "has-error"
                        : ""
                    } p-dropdown-custom-search`}
                    value={values.default_land_id}
                    options={lands}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                    optionLabel="name"
                    optionValue="uuid"
                    appendTo="self"
                    disabled={!region || !(permissions.canCreateRegion || permissions.canUpdateRegion)}
                    filter
                    filterBy="code"
                    filterPlaceholder={'Search'}
                  />
                  <div
                    className={`${
                      touched.default_land_id && errors.default_land_id ? "error" : ""
                    }`}
                  >
                    {touched.default_land_id && errors.default_land_id}
                  </div>
                </div>
              </div> 
              :
              <div className="col-md-6">
                <div className="element-form">
                  <label className="label">
                    Identifier{" "}
                    <span className="required-label">*</span>
                  </label>
                  <InputText
                    className={`${
                      touched.identifier && errors.identifier
                        ? "has-error"
                        : ""
                    }`}
                    type="text"
                    name="identifier"
                    onChange={(e:any) => {
                      const valueText = e.target.value;
                      if(isValidateSpecialCharacter(valueText)) {
                        handleChange(e);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.identifier}
                    maxLength={20}
                    disabled={region || !(permissions.canCreateRegion || permissions.canUpdateRegion)}
                  />
                  <div
                    className={`${
                      touched.identifier && errors.identifier ? "error" : ""
                    }`}
                  >
                    {touched.identifier && errors.identifier}
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">Status <span className="required-label"> *</span></label>
                <Dropdown
                  className={`${errors.status ? "has-error" : ""}`}
                  id="dropdown_status"
                  name="status"
                  value={values.status}
                  options={RegionStatusUtils}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  optionLabel="name"
                  optionValue="code"
                  appendTo="self"
                  disabled={!region || !(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              </div>
            </div>
          </div>
          <div className="row row-content">
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Landing Page URL{" "}
                </label>
                <InputText
                  type="text"
                  name="landing_page_url"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.landing_page_url}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Country{" "}
                  <span className="required-label">*</span>
                </label>
                <Dropdown
                  id="dropdown_countries"
                  name="country"
                  className="wi-country-dropdown p-dropdown-custom-search"
                  value={values.country}
                  scrollHeight={'150px'}
                  options={countries || []}
                  onChange={(item: any) => {
                    setFieldValue("country", item.value);
                  }}
                  optionLabel="name"
                  optionValue="ISO2"
                  appendTo="self"
                  filter
                  filterBy="name"
                  filterPlaceholder={'Search'}
                  placeholder="Choose a country"
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              </div>
            </div>
          </div>
          <div className="row row-content">
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Forest Type DE{" "}
                  <span className="required-label">*</span>
                </label>
                <InputText
                  className={`${
                    touched.forest_type?.de && errors.forest_type?.de
                      ? "has-error"
                      : ""
                  }`}
                  type="text"
                  name="forest_type.de"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.forest_type?.de}
                  maxLength={40}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
                <div
                  className={`${
                    touched.forest_type?.de && errors.forest_type?.de ? "error" : ""
                  }`}
                >
                  {touched.forest_type?.de && errors.forest_type?.de}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="element-form">
                <label className="label">
                  Forest Type EN{" "}
                  <span className="required-label">*</span>
                </label>
                <InputText
                  className={`${
                    touched.forest_type?.en && errors.forest_type?.en
                      ? "has-error"
                      : ""
                  }`}
                  type="text"
                  name="forest_type.en"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.forest_type?.en}
                  maxLength={40}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
                <div
                  className={`${
                    touched.forest_type?.en && errors.forest_type?.en ? "error" : ""
                  }`}
                >
                  {touched.forest_type?.en && errors.forest_type?.en}
                </div>
              </div>
            </div>
          </div>
          <div className="row row-content">
            <div className="col-md-12">
              <div className="element-form">
                <label className="label">
                  Description
                  <span className="required-label"> *</span>
                </label>
                <WiTextEditor name="description" 
                  values={values} 
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}/>
              </div>
            </div>
          </div>
          <div className="row row-content">
            <div className="col-md-6">
              <div className="element-form">
                <WIUploadMediaField
                  type="file"
                  hideLabelFileName={true}
                  classImage="image-cert"
                  className="img-frame-16-9"
                  name="land_logo"
                  label="Logo"
                  required={true}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              </div>
            </div> 
            <div className="col-md-6">
              <div className="element-form">
                <WIUploadMediaField
                  type="file"
                  hideLabelFileName={true}
                  classImage="image-cert"
                  className="img-frame-16-9"
                  name="headline_image"
                  label="Header Picture"
                  required={true}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              </div>
            </div>
          </div>
          <div className="row row-content">
            <div className="col-md-6">
              <div className="element-form">
                <WIUploadMediaField
                  type="file"
                  hideLabelFileName={true}
                  classImage="image-cert"
                  className="img-frame-16-9"
                  name="mobile_headline_image"
                  label="Mobile Header Picture"
                  required={true}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              </div>
            </div>   
          </div>
        </>
    );
};

export default memo(GeneralFormComponent);

import { ProgressSpinner } from "primereact/progressspinner";
import * as React from "react"
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { CMsPageManagerAPI } from "../../../services";

const CMSPagePreview = () => {
  const { id } = useParams();
  const lang = useSearchParams()[0]?.get('lang') || 'de';
  const [isLoading, setIsLoading] = useState(false);
  const [pagePreview, setPagePreview] = useState("");

  const getCMSPagePreview = async (uuid: string) => {
    setIsLoading(true);
    try {
      const res = await CMsPageManagerAPI.getCmsPagePreview(uuid, lang);
      if (res && res.status === 200) {
        if (res.data && res.data.htmlTemplate) {
          setPagePreview(res.data.htmlTemplate);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getCMSPagePreview(id);
    }
  }, [id])

  return (
    
    <React.Fragment>
      {isLoading ? (
          <div className="loading-component">
            <ProgressSpinner />
          </div>
        ) : (
          <></>
        )}
      {
        <iframe style={{ width: '100%', height: '100vh' }} srcDoc={pagePreview}></iframe>
      }
    </React.Fragment>
  )
}

export default CMSPagePreview

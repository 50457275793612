import * as React from "react";
import "./header-item.scss";
import { env } from "../../../../../environment";

const HeaderItem = (props: any) => {
  const { isActive, onClick, data, onEdit, onDelete, onMoveUp, onMoveDown, onDuplicate } = props;

  const buildListWithSubItemsTemplate = (item: any) => {
    return <>
      <div className="header-item-wrapper">
        <div className="header-item-label">
          {item.icon ? <i className="aw_icon mr8" dangerouslySetInnerHTML={{ __html: item.icon }}></i> : <></>}
          <div className="p-container" style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: item.label }}></div>
        </div>
        {
          item.items?.map((m: any) =>
            <div className="aw_menu_item" key={m.uuid}>
              <a className="header-sub-item-label" role="menuitem" href={m.link?.de} onClick={(e) => e.preventDefault()}>
                <div className="p-container" style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: m.label }}></div>
                <i className="aw_icon ml4" dangerouslySetInnerHTML={{ __html: m.icon }}></i>
              </a>
            </div>
          )
        }
      </div>
    </>;
  }

  const buildListWithDescriptionTemplate = (item: any) => {
    return <>
      <div className="header-item-wrapper header-item-wrapper-description">
        {
          item.items?.map((m: any) =>
            <div className="aw_menu_item" key={m.uuid}>
              <a className="header-item-label" role="menuitem" href={m.link?.de} onClick={(e) => e.preventDefault()}>
                <div className="p-container header-item-label" style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: m.label }}></div>
                <div className="p-container header-item-desscription" dangerouslySetInnerHTML={{ __html: m.description }}></div>
              </a>
            </div>
          )
        }
      </div>
    </>;
  }

  const buildCTAPageTemplate = (item: any) => {
    return <>
      <div className="item-card">
        <a href={item.link?.de} onClick={(e) => e.preventDefault()}>
          <img loading="lazy" alt="Wilderness International" className="item-card-image mb16" title="" src={`${env.PUBLIC_UPLOAD_URL}/${item.image}`} />
        </a>
        <div className="item-card-body pl24 pr24">
          {
            item.label ? <a className="hover-no-underline" href={item.link?.de} onClick={(e) => e.preventDefault()}>
              <div className="h5 prime p-container" dangerouslySetInnerHTML={{ __html: item.label }}></div>
            </a> : <></>
          }
          {item.description ? <div className="grey-60 aw-rich-text mb2 p-container" dangerouslySetInnerHTML={{ __html: item.description }}></div> : <></>}
        </div>
        <div className="item-card-footer">
          <a href={item.link?.de} className="aw_btn text-button p-container" onClick={(e) => e.preventDefault()}> <div className="p-container" style={{ 'display': 'inline-block' }} dangerouslySetInnerHTML={{ __html: item.link_name || 'Mehr lesen' }}></div></a>
        </div>
      </div>
    </>;
  }

  // const buildCTAImageOnlyTemplate = (item: any) => {
  //   return <>
  //     <div className="item-card item-card-image-only">
  //       <a href={item.link} onClick={(e) => e.preventDefault()}>
  //         <img loading="lazy" alt="Wilderness International" className="item-card-image mb16" title="" src={item.image} />
  //       </a>
  //       <a href={item.link} className="aw_btn text-button" onClick={(e) => e.preventDefault()}>Mehr lesen</a>
  //     </div>
  //   </>;
  // }

  // const buildListWithoutSubItemTemplate = (item: any) => {
  //   return <>
  //     <div className="header-item-wrapper header-item-wrapper-bold">
  //       {
  //         item.items?.map((m: any) =>
  //           <div className="aw_menu_item">
  //             <a className="header-item-label" role="menuitem" href={m.link} onClick={(e) => e.preventDefault()}>
  //               <div className="p-container" style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: m.label }}></div>
  //               <i className="aw_icon ml4"></i>
  //             </a>
  //           </div>
  //         )
  //       }
  //     </div>
  //   </>;
  // }

  const buildTemplate = (item: any) => {
    if (item.type === 'list-sub-items') {
      return buildListWithSubItemsTemplate(item);
    } else if (item.type === 'page') {
      return buildCTAPageTemplate(item);
    } else if (item.type === 'list-normal') {
      // return buildListWithoutSubItemTemplate(item);
    } else if (item.type === 'cta-image') {
      // return buildCTAImageOnlyTemplate(item);
    }

    return buildListWithDescriptionTemplate(item);
  }

  return <>
    <div key={data?.uuid} className={`header-item ${isActive ? 'header-item-active' : ''}`} onClick={onClick}>
      {buildTemplate(data)}
      {
        isActive ? <div className="header-menu">
          <div className="header-menu-item" onClick={onDuplicate}><i className="fa-solid fa-copy"></i></div>
          <div className="header-menu-item" onClick={onEdit}><i className="fa-solid fa-pencil"></i></div>
          { !data.disabledDelete ? <div className="header-menu-item" onClick={onDelete}><i className="fa-solid fa-trash"></i></div> : <></> }
          <div className="header-menu-item" onClick={onMoveUp}><i className="fa-solid fa-chevron-up"></i></div>
          <div className="header-menu-item" onClick={onMoveDown}><i className="fa-solid fa-chevron-down"></i></div>
        </div> : <></>
      }
    </div>
  </>;
};

export default HeaderItem;

import { useField } from "formik";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { env } from "../../../environment";
import "./wi-upload-field-v2.scss";
import InsertMediaDialogComponent from "./wi-upload-media-dialog";
import { Toast } from "primereact/toast";
import { showNotification } from "../../../utils/logic";
import { ReactComponent as ImageSvg } from "../../../assets/images/icons/image.svg";
import { ReactComponent as ReplaceSvg } from '../../../assets/images/icons/replace.svg';
import { ReactComponent as TrashSvg } from '../../../assets/images/icons/trash.svg';
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

const LIMIT_FILE_SIZE = 1000000;
const LIMIT_HERO_FILE_SIZE = 10000000;
const LIMIT_IMAGE_TYPES = ["jpg", "jpeg", "png", "svg"];

const WIUploadMediaFieldV2 = (props: any) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched } = helpers;
  const { error, touched } = meta;
  const { value } = field;
  const [image, setImage] = useState(null);
  const [fileData, setFileData] = useState<any>();
  const [isShowMediaDialog, setIsShowMediaDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>();
  const { t } = useTranslation('language');
  
  const toast = useRef(null);

  useEffect(() => {
    setImageUrl(setImageProps());
  }, [value]);

  const setImageProps = () => {
    if (!!value) {
      setImageAlternativeText(value);
      setImage(value);
      return `${env.PUBLIC_UPLOAD_URL}/${value}`;
    }

    return null;
  };

  const setImageAlternativeText = async (value: any) => {
    if (!!props.onURLChanged) {
      props.onURLChanged(fileData?.alternativeText['de'], fileData?.title['de']);
    }
  }

  const handleImageValue = (selectedImage: any, isSetShowDialog?: boolean) => {
    setTouched(true);
    const limitImageTypes = props.imageTypes && props.imageTypes.length > 0 ? props.imageTypes : LIMIT_IMAGE_TYPES;
    if (selectedImage) {
      if (
        !limitImageTypes.some((item: string) => item === selectedImage.file_extension.toLowerCase())
      ) {
        const typesImage = props.imageTypes && props.imageTypes.length > 0 ? props.imageTypes.toString() : '.jpeg, .jpg, .png, and .svg';
        showNotification("error", `The system only accepts ${typesImage} file types`, toast);
        return;
      }

      if (!!props.onURLChanged) {
        props.onURLChanged(selectedImage?.alternativeText, selectedImage?.title);
      }

      setFileData(selectedImage);
      setValue(encodeURI(`${selectedImage.fullPath}`), true);
      setImageUrl(encodeURI(`${env.PUBLIC_UPLOAD_URL}/${`${selectedImage.fullPath}`}`));
      if (isSetShowDialog) {
        setIsShowMediaDialog(false);
      }
    }
  };

  const handleRemoveImageValue = (event: any) => {
    event?.preventDefault();
    setImage(null);
    setImageUrl(null);
    setTouched(false);
    setValue("");
    if (!!props.onURLChanged) {
      props.onURLChanged(null);
    }
    return;
  };

  const onOpenMediaManager = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowMediaDialog(true);
  };

  return (
    <>
      <label
        className={`label-upload-field-v2 ${imageUrl ? 'upload-active' : ''} ${props.classField || ""} ${props.className || ""
          } ${error && touched && !image ? "upload-error" : ""}`}
      >
        { !imageUrl ? <div className="upload-placeholder">
          <div className="d-flex justify-content-center">
            <div className="upload-placeholder-icon">
              <ImageSvg></ImageSvg>
            </div>
          </div>
          {t('txt_choose_from_gallery')}
        </div> : <></> }
        <button
          className="input-upload"
          onChange={handleImageValue}
          disabled={props.disabled || imageUrl}
          type={props.type}
          name={field.name}
          onClick={onOpenMediaManager}
        />
        {imageUrl && (
          <>
            <img
              src={imageUrl || ""}
              className={(props.classImage || '') + " media-preview"} />
            {
              !props.disabledRemove && !props.disabled ? (
                <span className="icon-panel">
                  <Button
                    className="wi-danger-button-icon-v2"
                    type={"button"}
                    onClick={handleRemoveImageValue}
                  >
                    <TrashSvg className="icon-svg"></TrashSvg>
                  </Button>
                  <Button
                    className="wi-primary-button-icon-v2"
                    type={"button"}
                    onClick={onOpenMediaManager}
                  >
                    <ReplaceSvg className="icon-svg"></ReplaceSvg>
                  </Button>
                </span>
              ) : '' 
            }
          </>
        )}
      </label>
      <InsertMediaDialogComponent
        visible={isShowMediaDialog}
        fileType="image"
        handleFileValue={(selectedMedia: any) => handleImageValue(selectedMedia, true)}
        setIsShowMediaDialog={setIsShowMediaDialog}
        onHide={() => setIsShowMediaDialog(false)}
      />
      <Toast ref={toast} />
    </>
  );
};

export default WIUploadMediaFieldV2;

import React from 'react'
import WIUploadMultipleField from '../../../common/upload/wi-upload-multiple-field';
import { WIFormElement } from '../wi-form-element/wi-form-element';

export function WIUploadMedia(props: any) {
  const { name } = props;

  return (
    <WIFormElement {...props}>
      <WIUploadMultipleField
        type="file"
        id={name}
        name={name}
        required={true}
        hideLabel={true}
      />
    </WIFormElement>
  );
}

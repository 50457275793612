import * as React from "react"
import './create-receipt-form.scss';
import { Formik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { CurrencyCoupons, PASSWORD_SENDMAIL } from '../../../../utils/utils';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { Toast } from 'primereact/toast';
import moment from "moment";
import { parseStringToFloat, showNotification } from "../../../../utils/logic";
import _ from "lodash";
import * as Yup from "yup";
import { ReceiptsAPI } from "../../../../services";
import ConfirmPasswordDialog from '../confirm-generate-receipt-dialog/confirm-generate-receipt-dialog';

const BATCH_DEFAULT_VALUE = {
  name: "",
  amount: 0,
  payment_address: "",
  postal_code: "",
  city: "",
  country: "DE",
  donated_date: moment().toDate(),
  receipt_date: moment().toDate(),
  currency_code: "eur",
  company_name: "",
};

const CreateReceiptFormComponent = (props: any) => {

    const { receipt, onHide, fetchReceiptsData, countries } = props;
    
    const formikRef = useRef(null);
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowPasswordDialog, setIsShowPasswordDialog] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [errorPassword, setErrorPassword] = useState(false);

    const initialReceiptFormValues = (receipt: any) => {
        let initialReceipt = BATCH_DEFAULT_VALUE;
        if(!receipt) {
            initialReceipt.name = '';
            initialReceipt.amount = 0;
            initialReceipt.payment_address = "";
            initialReceipt.postal_code = '';
            initialReceipt.city = '';
            initialReceipt.country = 'DE';
            initialReceipt.donated_date = moment().toDate();
            initialReceipt.receipt_date = moment().toDate();
            initialReceipt.currency_code = 'eur';
            initialReceipt.company_name = "";
            
            return initialReceipt;
        }

        initialReceipt.name = receipt.name;
        initialReceipt.amount = receipt.amount;
        initialReceipt.payment_address = receipt.payment_address;
        initialReceipt.postal_code = receipt.postal_code;
        initialReceipt.city = receipt.city;
        initialReceipt.country = receipt && receipt.country ? receipt.country : 'DE';
        initialReceipt.donated_date = receipt && receipt.donated_date ? new Date(receipt.donated_date) : moment().toDate();
        initialReceipt.receipt_date = receipt && receipt.receipt_date ? new Date(receipt.receipt_date) : moment().toDate();
        initialReceipt.currency_code = receipt && receipt.currency_code ? receipt.currency_code : 'eur';
        initialReceipt.company_name = receipt.company_name;
        return initialReceipt;
    };

    const validationSchema = Yup.object().shape({
      name: Yup.string().nullable().required("This field is required."),
      amount: Yup.number().nullable().min(1, "This field must be greater than 1.").required("This field is required."),
      payment_address: Yup.string().nullable().required("This field is required."),
      postal_code: Yup.string().nullable().required("This field is required."),
      city: Yup.string().nullable().required("This field is required."),
      country: Yup.string().nullable().required("This field is required."),
      donated_date: Yup.string().nullable().required("This field is required."),
      receipt_date: Yup.string().nullable().required("This field is required."),
      currency_code: Yup.string().nullable().required("This field is required."),
      company_name: Yup.string().nullable(),
    });

    const insertReceipt = async (dataReceipt: any) => {
        const resGenerateReceipt = await ReceiptsAPI.generateReceipt(dataReceipt);

        if (resGenerateReceipt && resGenerateReceipt.status === 200) {
            setIsLoading(false);
            onHide();
            showNotification("success", "Generate receipt successfully", toast);
            fetchReceiptsData();
        } else {                    
            setIsLoading(false);
            showNotification("error", "Failed to generate receipt", toast);
        }
    }

    const updateReceipt = async (dataReceipt: any) => {
        const resUpdateReceipt = await ReceiptsAPI.updateReceipt(receipt.uuid, dataReceipt);

        if (resUpdateReceipt && resUpdateReceipt.status === 200) {
            setIsLoading(false);
            onHide();
            showNotification("success", "Update receipt successfully", toast);
            fetchReceiptsData();
        } else {                    
            setIsLoading(false);
            showNotification("error", "Failed to update receipt", toast);
        }
    }

    const callGenerateReceipt = () => {
        if(password === PASSWORD_SENDMAIL) {
            setErrorPassword(false);
            setIsLoading(true);
            const dataReceipt = {
                // @ts-ignore: Object is possibly 'null'.
                ...formikRef?.current.values,
                // @ts-ignore: Object is possibly 'null'.
                company_name: formikRef?.current.values.company_name || "",
                // @ts-ignore: Object is possibly 'null'.
                amount: +formikRef?.current.values.amount,
                // @ts-ignore: Object is possibly 'null'.
                donated_date: moment.utc(moment(new Date(formikRef?.current.values.donated_date)).format("MM/DD/YYYY HH:mm:ss")).toISOString(),
                // @ts-ignore: Object is possibly 'null'.
                receipt_date: moment.utc(moment(new Date(formikRef?.current.values.receipt_date)).format("MM/DD/YYYY HH:mm:ss")).toISOString()
            }
    
            if(!receipt) {
                insertReceipt(dataReceipt);
            } else {
                updateReceipt(dataReceipt);
            }

            onHideConfirmDialog();
        } else {
            setErrorPassword(true);
        }
    }

    const generateReceipt = async () => {
        // @ts-ignore: Object is possibly 'null'.
        formikRef.current.validateForm();
        // @ts-ignore: Object is possibly 'null'.
        const { dirty, isValid } = formikRef?.current;
        // @ts-ignore: Object is possibly 'null'.
        if (formikRef && dirty && isValid) {
            try {
                setIsShowPasswordDialog(true);
            } catch (error) {
                setIsLoading(false);
                showNotification("error", "Failed to generate receipt", toast);
            }
        }
    }

    const onHideConfirmDialog = () => {
        setPassword("");
        setIsShowPasswordDialog(false);
        setErrorPassword(false);
      }

    return (
        <div className="receipt-form">
            <div className="receipt-form-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={initialReceiptFormValues(receipt)}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        // console.log("values >>>", values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        handleChange
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="element-form">
                                            <label className="label">AMOUNT <span className="asterik">*</span></label>
                                            <InputNumber
                                                value={values.amount}
                                                name="amount"
                                                onBlur={(e) => {
                                                    const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                                                    setFieldValue('amount', value, false);
                                                    setTimeout(() => {
                                                        handleBlur(e);
                                                    }, 200);
                                                }}
                                                mode="decimal" 
                                                locale="de-DE" 
                                                placeholder="0.00"
                                                minFractionDigits={0}
                                                maxFractionDigits={2}
                                            />
                                            <div className={`${touched.amount && errors.amount ? 'error' : ''}`}>
                                                {touched.amount && errors.amount}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="element-form">
                                        <label className="label">Name <span className="asterik">*</span></label>
                                            <InputText
                                                value={values.name}
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                maxLength={80}
                                            />
                                            <div className={`${touched.name && errors.name ? 'error' : ''}`}>
                                                {touched.name && errors.name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="element-form">
                                            <label className="label">Country <span className="asterik">*</span></label>
                                            <Dropdown
                                                id="dropdown_countries"
                                                name="country"
                                                className="wi-country-dropdown"
                                                value={values.country}
                                                options={countries || []}
                                                onChange={(item: any) => {
                                                    setFieldValue('country', item.value);
                                                }}
                                                optionLabel="name"
                                                optionValue="ISO2"
                                                appendTo="self"
                                                filter
                                                filterBy="name"
                                                placeholder="Choose a country"
                                                filterPlaceholder={'Search'}
                                            />
                                            <div className={`${touched.country && errors.country ? 'error' : ''}`}>
                                                {touched.country && errors.country}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="element-form">
                                        <label className="label">City <span className="asterik">*</span></label>
                                            <InputText
                                                value={values.city}
                                                name="city"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                maxLength={85}
                                            />
                                            <div className={`${touched.city && errors.city ? 'error' : ''}`}>
                                                {touched.city && errors.city}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="element-form">
                                            <label className="label">Street Name and House Number <span className="asterik">*</span></label>
                                            <InputText
                                                value={values.payment_address}
                                                name="payment_address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                maxLength={100}
                                            />
                                            <div className={`${touched.payment_address && errors.payment_address ? 'error' : ''}`}>
                                                {touched.payment_address && errors.payment_address}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="element-form">
                                            <label className="label">Post Code <span className="asterik">*</span></label>
                                            <InputText
                                                value={values.postal_code}
                                                name="postal_code"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                maxLength={10}
                                            />
                                            <div className={`${touched.postal_code && errors.postal_code ? 'error' : ''}`}>
                                                {touched.postal_code && errors.postal_code}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="element-form">
                                        <label className="label">Organization </label>
                                            <InputText
                                                value={values.company_name}
                                                name="company_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                maxLength={80}
                                            />
                                            <div className={`${touched.company_name && errors.company_name ? 'error' : ''}`}>
                                                {touched.company_name && errors.company_name}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-3">
                                        <div className="element-form">
                                            <label className="label">Currency <span className="asterik">*</span></label>
                                            <SelectButton
                                                id="currency_code"
                                                name='currency_code'
                                                className="wi-selectbutton"
                                                value={values.currency_code}
                                                options={CurrencyCoupons}
                                                onChange={(item: any) => {
                                                    if(item.value)
                                                    {
                                                        setFieldValue('currency_code', item.value, false);
                                                    }
                                                }}
                                                optionLabel="name"
                                                optionValue="code"
                                                // optionDisabled="constant"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="element-form">
                                            <label className="label">Donated date <span className="asterik">*</span></label>
                                            <Calendar
                                                id="basic"
                                                name="donated_date"
                                                panelClassName="expire-calendar"
                                                value={values.donated_date}
                                                onBlur={handleBlur}
                                                onChange={(item) => {
                                                    setFieldValue('donated_date', item.value, false);
                                                }}
                                                dateFormat="dd.mm.yy"
                                            />
                                            <div className={`${touched.donated_date && errors.donated_date ? 'error' : ''}`}>
                                                {touched.donated_date && errors.donated_date ? 'This field is invalid' : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="element-form">
                                            <label className="label">Receipt date <span className="asterik">*</span></label>
                                            <Calendar
                                                id="basic"
                                                name="receipt_date"
                                                panelClassName="expire-calendar"
                                                value={values.receipt_date}
                                                onBlur={handleBlur}
                                                onChange={(item) => {
                                                    setFieldValue('receipt_date', item.value, false);
                                                }}
                                                dateFormat="dd.mm.yy"
                                            />
                                            <div className={`${touched.receipt_date && errors.receipt_date ? 'error' : ''}`}>
                                                {touched.receipt_date && errors.receipt_date ? 'This field is invalid' : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-4 mb-2 justify-content-end">
                                    <Button
                                        type="submit" 
                                        loading={isLoading}
                                        label={`${receipt ? 'Update' : 'Generate'}`}
                                        className="wi-primary-button wi-button-medium"
                                        icon="pi pi-chevron-right"
                                        iconPos="right"
                                        onClick={generateReceipt}
                                    />
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            <ConfirmPasswordDialog 
                visible={isShowPasswordDialog}
                onHide={() => onHideConfirmDialog()}
                onConfirm={callGenerateReceipt}
                password={password}
                onSetPassword={(value: any) => setPassword(value)}
                errorPassword={errorPassword}
            />
            <Toast ref={toast} />
        </div>
    )
}

export default CreateReceiptFormComponent;
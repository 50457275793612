import { AxiosClient } from './httpClient';

/**
 * A type alias of "Match Funding Receipt Info"
 */
type MatchFundingReceiptInfo = {
  name: string,
  country: string,
  city: string,
  address: string,
  postal_code: number,
  donation_date: string,
  receipt_date: string
}

export default class MatchFundingManagerAPI {

  static paginate(data: any) {
    return AxiosClient.post(`/match-fundings/search`, data);
  }

  static create(data: any) {
    return AxiosClient.post(`/match-fundings`,  data);
  }

  static update(id: any, data: any) {
    return AxiosClient.put(`/match-fundings/${id}`,  data);
  }

  static activate(id: any) {
    return AxiosClient.put(`/match-fundings/${id}/activate`);
  }
  static deactivate(id: any) {
    return AxiosClient.put(`/match-fundings/${id}/deactivate`);
  }

  /**
   * Download Receipt
   * @param uuid: Match Funding ID 
   * @param receiptInfo: Receipt Info inputted by user
   * @returns A promise of generating Match Funding Receipt
   */
  static downloadReceipt(uuid: string, receiptInfo: MatchFundingReceiptInfo) {
    return AxiosClient.post(`/match-fundings/${uuid}/receipt`, receiptInfo);
  }
}

import _ from 'lodash';
import { env } from '../environment';

export const replaceParameter = (str: string, source: string, target: string) => {
  const regex = new RegExp(source, "g");
  return str?.replace(regex, target);
}

export const buildHeaderV2 = (c: any, lang = 'de') => {
  const header = _.cloneDeep(c);
  const buttonConfig: any = {
    urlName: header.parameters?.urlName?.[lang] || 'Wald schützen',
    buttonUrl: header.parameters?.buttonUrl?.[lang] || '#',
    blank_mode: header.parameters?.blank_mode || false,
    menu: buildHeaderMenu(header) || ''
  };

  let content = c.template;
  ['urlName', 'buttonUrl', 'blank_mode', 'menu'].map((f: string) => {
    content = replaceParameter(content, `{{parameters.${f}}}`, buttonConfig?.[f]);
  });


  return content;
}

const buildMenuItemListWithTitle = (item: any) => {
  return `
      <ul class="aw_mm__item_group aw_mm__group_type__a">
        <li>
          ${item.icon ? `<i class="aw_icon mr8">${item.icon}</i>` : ''}
          <div class="aw_rich_text" style="display: inline-block">${item.label}</div>
        </li>
        ${item.items?.map((d: any) => (`
              <li class="aw_menu_item">
                  <a role="menuitem" href="${d.link?.de}">
                    <div class="aw_rich_text"  style="display: inline-block">${d.label}</div>
                    ${d.icon ? `<i class="aw_icon">${d.icon}</i>` : ''}
                  </a>
              </li>
          `)).join('')
    }
      </ul>
  `;
}

const buildMenuItemListWithDescription = (item: any, isWide = false) => {
  return `
      <ul class="aw_mm__item_group ${isWide ? 'aw_mm__group_type__desc_wide' : 'aw_mm__group_type__desc'}">
        ${item.items?.map((d: any) => (`
              <li class="aw_menu_item">
                <a role="menuitem" href="${d.link?.de}">
                    <div class="aw_rich_text">${d.label}</div>
                    <div class="aw_rich_text hover-no-underline hide_mobile_true"><p>${d.description}</p></div>
                </a>
              </li>
          `)).join('')
    }
      </ul>
  `;
}

const buildMenuItemPage = (item: any) => {
  return `
      <div class="aw_mm__column__cta aw_mm__column__cta__type_post aw_card bg_light no_color_change">
        <a href="${item.link?.de}">
            <img style="aspect-ratio: 5/3" loading="lazy" alt="${item.label || ''}" class="mb16" title="${item.label || ''}" src="${env.PUBLIC_UPLOAD_URL}/${item.image}">
        </a>
        <div class="aw_card_body pl24 pr24">
            ${item.label ? `<a class="hover-no-underline" href="${item.link?.de}"><div class="h5 prime aw_rich_text">${item.label}</div></a>` : ''}
            ${item.description ? `<div class="grey_60 text_overflow_10 aw_rich_text mb2">${item.description}</div>` : ''}
        </div>
        <div class="aw_card_footer">
            <a href="${item.link?.de}" class="aw_btn aw_text_btn aw_rich_text">${item.link_name || 'Mehr lesen'}</a>
        </div>
      </div>
  `;
}

const buildMenuItemTemplate = (item: any, isWide = false) => {
  if (item.type === 'page') {
    return buildMenuItemPage(item);
  } else if (item.type === 'list-sub-items-description') {
    return buildMenuItemListWithDescription(item, isWide);
  }
  return buildMenuItemListWithTitle(item);
}

const buildMenuPanelTemplate = (subItems: any[], tabIndex: number) => {
  return `
    ${
      [1, 2, 3, 4].map((columnNo: any) => {
        const colItems = subItems.filter((s: any) => s.columnNo === columnNo);
        return `
              <div class="aw_mm__column">
                ${colItems.map((item: any) => buildMenuItemTemplate(item, tabIndex === 1)).join('')}
              </div>
            `;
      }).join('')
    }
  `;
}

const buildMenuLV1Template = (item: any, tabIndex: number) => {
  const menuPanelContent = buildMenuPanelTemplate(item.content, tabIndex);
  return `
      <li aria-expanded="false" class="aw_menu_item aw_menu_item_has_children">
        <a href="#" tabindex="0">${item.label} <i class="aw_icon aw_mm_indicator"></i></a>
        <div class="aw_mm">${menuPanelContent}</div>
      </li>
  `;
}

const buildHeaderMenu = (header: any) => {
  return header.parameters.menuItems.map((item: any, i: number) => buildMenuLV1Template(item, i)).join('');
}
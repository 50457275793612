/**
 * API Manager for "Donation Match Funding"
 */

import { AxiosResponse } from 'axios';
import { AxiosClient } from './httpClient'

export default class DonationMatchFundingManagerAPI {

    /**
     * Get match funding applied to a Donation
     * @param donationId: Donation ID
     * @returns a Promise of querying Match Funding of a Donation
     */
    static getMatchFundingByDonationId(donationId: string):Promise<AxiosResponse<any, any>> {
        return AxiosClient.get(`/donation-match-funding/donation/${donationId}`);
    }

}
import { AxiosClient, cacheAge } from './httpClient'

export default class LandManagerAPI {
  static getLands(data: any) {
    return AxiosClient.get(`/lands/pagination`, {...data});
  }

  static getLand(id: string) {
    return AxiosClient.get(`/lands/${id}`);
  } 
  
  static getAllLands() {
    return AxiosClient.get(`/lands`);
  }

  static createLand(data: any) {
    return AxiosClient.post(`/lands`,  data);
  }

  static updateLand(id: string,data: any) {
    return AxiosClient.put(`/lands/${id}`,  data);
  }

  static deleteLand(id: string) {
    return AxiosClient.delete(`/lands/${id}`);
  }
}

import { AxiosClient } from '../httpClient'

export default class DonationExamplesManagerAPI {
  static getAllDonationExamples() {
    return AxiosClient.get(`/donation-examples`);
  }

  static getDonationExamples(data: any) {
    return AxiosClient.get(`/donation-examples/pagination`, data);
  }

  static getDonationExample(uuid: string) {
    return AxiosClient.get(`/donation-examples/${uuid}`);
  }

  static generateDonationExamples(data: any) {
    return AxiosClient.post(`/donation-examples`,  data);
  }

  static updateDonationExamples(data: any) {
    return AxiosClient.put(`/donation-examples/${data.uuid}`, data);
  }

  static deleteDonationExample(uuid: string) {
    return AxiosClient.delete(`/donation-examples/${uuid}`);
  }
}

export default class PreViewFormUtil {
  static getContent(language: any) {
    const key = Object.keys(certificateContent).findIndex(
      (key) => key === language
    );
    return key >= 0 ? Object.values(certificateContent)[key] : undefined;
  }
}

const certificateContent = {
  en: {
    primary_headline: "WILDERNESS PROTECTION",
    secondary_headline: "CERTIFICATE",
    donor_title: "For",
    donor_fullname: "Mother Earth",
    area_amount: "1,000 m<sup>2</sup>",
    area_title: "of temperate Rainforest",
    area_title_second:
      "will forever be protected thanks to this wilderness sponsorship",
    co2_amount: "600,000 kg CO<sup>2</sup>",
    co2_title: "are stored in the biomass of the forest on this area",
    primary_geo_coordinate: "S12°52'13.6236",
    secondary_geo_coordinate: "W69°30'47.4012",
    qr_description: "Here you can always see how big",
    donation_serial: "Donation: #23-121995",
    donation_date: "February 23, 2023",
    director_sign_person_name: "KAI ANDERSCH",
    director_sign_person_position: "Director",
    director_sign_person_location: "Wilderness International",
    president_sign_person_name: "DR. CHRIS KIRKBY",
    president_sign_person_position: "President",
    president_sign_person_location: "Wilderness International Peru",
    board_of_director_sign_person_name: "TOBIAS HÜRTEN",
    board_of_director_sign_person_position: "Director",
    board_of_director_sign_person_location: "Wilderness International Canada",
    board_sign_person_name: "HENRIETTE WESSEL",
    board_sign_person_position: "Board",
    board_sign_person_location: "Wilderness International Peru",
    is: "is:"
  },
  de: {
    primary_headline: "WILDNISSCHUTZ",
    secondary_headline: "URKUNDE",
    donor_title: "Für",
    donor_fullname: "Mother Earth",
    area_amount: "1,000 m<sup>2</sup>",
    area_title: "tropischer Primärregenwald",
    area_title_second:
      "werden mit dieser Wildnispatenschaft langfristig bewahrt",
    co2_amount: "600,000 kg CO<sup>2</sup>",
    co2_title: "bindet der Wald auf dieser Fläche in seiner Biomasse",
    primary_geo_coordinate: "S12°52'13.6236",
    secondary_geo_coordinate: "W69°30'47.4012",
    qr_description: "Hier siehst du immer aktuell, wie groß der",
    donation_serial: "Patenschaft: #23-121995",
    donation_date: "23. February 2023",
    director_sign_person_name: "KAI ANDERSCH",
    director_sign_person_position: "Vorstandsvorsitzender",
    director_sign_person_company: "Wilderness International",
    director_sign_person_location: "",
    president_sign_person_name: "DR. CHRIS KIRKBY",
    president_sign_person_position: "Präsident",
    president_sign_person_company: "Wilderness International",
    president_sign_person_location: "Peru",
    board_of_director_sign_person_name: "TOBIAS HÜRTEN",
    board_of_director_sign_person_position: "Vorstand",
    board_of_director_sign_person_company: "Wilderness International",
    board_of_director_sign_person_location: "Kanada",
    board_sign_person_name: "HENRIETTE WESSEL",
    board_sign_person_position: "Vorstand",
    board_sign_person_company: "Wilderness International",
    board_sign_person_location: "Peru",
    is: "ist:"
  },
};

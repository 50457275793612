import _ from "lodash";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ListBox } from "primereact/listbox";
import { OverlayPanel } from "primereact/overlaypanel";
import * as React from "react";
import { useEffect, useState, useRef } from "react";

const CouponDetailFilterField = (props: any) => {
  const ref = useRef(null);
  const [items, setItems] = useState<any[]>([]);
  const [selectedValues, setSelectedValues] = useState<any[]>();
  const {
    name,
    options,
    label,
    visible,
    optionLabel,
    optionValue,
    selectedItems,
    isDirty,
    setDirty,
    className,
    pannelClassName,
    onChange,
  } = props;

  useEffect(() => {
    const items = options.map((item: any, index: number) => {
      return { ...item, key: index };
    });
    setItems(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    const selected = selectedItems || [];
    setSelectedValues(selected);
    setDirty(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, isDirty]);

  const onPanelToggle = (panel: any, event: any) => {
    panel.current.toggle(event);
  };

  const onHandlerChange = (event: any) => {
    onChange({
      field: name,
      value: event.value,
    });
  };

  const generateHeadline = (): string => {
    if (selectedValues && selectedValues.length > 1) {
      return `${label} | ${selectedValues.length} items`;
    } else if (selectedValues && selectedValues.length > 0) {
      const selectedItem = items.find((p) =>
        selectedValues.includes(_.get(p, optionValue))
      );
      return `${label} | ${_.get(selectedItem, optionLabel)}`;
    }
    return label;
  };

  const fetchFromObject = (obj: any, prop: any): any => {
    if (typeof obj === "undefined") return false;
    const index = prop.indexOf(".");
    if (index > -1) {
      return fetchFromObject(
        obj[prop.substring(0, index)],
        prop.substr(index + 1)
      );
    }
    return obj[prop];
  };

  const itemTemplateFunc = (option: any) => {
    const label = fetchFromObject(option, optionLabel);
    const value = fetchFromObject(option, optionValue);
    return (
      <>
        <Checkbox
          inputId="cb1"
          value={value}
          checked={selectedValues?.includes(value) || false}
        ></Checkbox>
        <label htmlFor="cb1" className="p-checkbox-label">
          {label}
        </label>
      </>
    );
  };

  return visible ? (
    <>
      <Button
        type="button"
        icon="pi pi-plus-circle"
        label={generateHeadline()}
        className={`${className} ${
          selectedValues?.length && selectedValues?.length > 0
            ? "filter-active"
            : ""
        }`}
        aria-haspopup
        aria-controls="overlay_panel"
        onClick={(e) => onPanelToggle(ref, e)}
      />
      <OverlayPanel
        id="overlay_panel"
        ref={ref}
        showCloseIcon={false}
        appendTo={document.body}
        className={pannelClassName}
      >
        <ListBox
          listStyle={{ maxHeight: "180px" }}
          value={selectedValues}
          options={items}
          onChange={onHandlerChange}
          optionLabel={optionLabel}
          optionValue={optionValue}
          multiple={true}
          itemTemplate={itemTemplateFunc}
        />
      </OverlayPanel>
    </>
  ) : (
    <></>
  );
};

export default CouponDetailFilterField;

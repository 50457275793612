import * as React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import "./delete-confirm-dialog.scss";

const WIConfirmDialog = (props: any) => {
  const { icon, visible, onConfirm, onHide, message, hideLabel = 'No, keep it',  confirmLabel = 'Yes, delete it', className, maskClassName } = props;

  const buildFooterTemplate = () => (
    <React.Fragment>
      <Button
        label={hideLabel}
        className={"wi-secondary-button-v2 flex-1"}
        onClick={onHide}
      />
      <Button
        label={confirmLabel}
        className="wi-primary-danger-button-v2 flex-1"
        onClick={onConfirm}
      />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        modal
        header="Warning"
        className={"warning-dialog-v2"  + (className ? ` ${className}` : '')}
        maskClassName={(maskClassName ? ` ${maskClassName}` : '')}
        visible={visible}
        style={{ width: "500px" }}
        footer={buildFooterTemplate}
        onHide={onHide}
      >
        <div className="confirmation-content">
          { icon ? icon : ''}
          { message && (
            <div>
              {message}
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default WIConfirmDialog;

import _ from "lodash";
import { generatePageData } from "../../utils/commonGeneratePageData";
import { LanguageItem } from "../../utils/commonType";
import * as Yup from 'yup';
import { CMS_STATUS_CODE, PARTNER_TYPES, STATUS_CODE } from "../../components_v2/utils/utils";
import { getPageTypeByCode } from "../pages/builder-page.common";
import { PAGE_CODE } from "../pages/utils";
import { CMsPageManagerAPI, PartnerManagerAPI } from "../../services";

export interface PartnerType {
    name: string;
    code: string;
    status: string;
    partner_logo: string;
    headline_image: string;
    mobile_headline_image: string;
    description: LanguageItem;
    fact: LanguageItem;
    slogan: LanguageItem;
    type: string;
    category: string;
    generate_page: boolean;
}

export const buildPartnerSchema = (errorTrans: any) => Yup.object().shape({
    name: Yup.string()
        .required(errorTrans('txt_required')),
    code: Yup.string()
        .required(errorTrans('txt_required')),
    partner_logo: Yup.string().when("type", {
        is: (type: string) => type !== PARTNER_TYPES.FUNDRAISER,
        then: Yup.string().required(errorTrans('txt_required')),
    }),
    headline_image: Yup.string(),
    mobile_headline_image: Yup.string(),
    type: Yup.string()
        .required(errorTrans('txt_required')),
    // category: Yup.string().when("type", {
    //     is: (type: string) => type === PARTNER_TYPES.PARTNER,
    //     then: Yup.string().required(errorTrans('txt_required')),
    // }),
    description: Yup.object().shape({
        de: Yup.string()
        //en: Yup.string().required(errorTrans('txt_required'))
    }),
    fact: Yup.object().shape({
        de: Yup.string()
        //en: Yup.string().required(errorTrans('txt_required'))
    }),
    slogan: Yup.object().shape({
        de: Yup.string()
        //en: Yup.string().required(errorTrans('txt_required'))
    }),
});

export const initializePartnersValues = (partner: any, code = '', type = '', category = ''): PartnerType => {
    return {
        name: partner ? `${partner.name}` : "",
        code: partner ? `${partner.code}` : (code || ""),
        status: partner ? `${partner.status}` : STATUS_CODE.NEW,
        type: partner ? `${partner.type}` : (type || PARTNER_TYPES.PARTNER),
        category: partner ? `${partner.category}` : (category || ''),
        generate_page: partner?.generate_page || false,
        partner_logo: partner ? `${partner.partner_logo ?? ""}` : "",
        headline_image: partner ? `${partner.headline_image ?? ""}` : "",
        mobile_headline_image: partner ? `${partner.mobile_headline_image ?? ""}` : "",
        description: partner && partner.description ? { de: partner.description.de, en: partner.description.en } : { de: '', en: '' },
        fact: partner && partner.fact ? { de: partner.fact.de, en: partner.fact.en } : { de: '', en: '' },
        slogan: partner && partner.slogan ? { de: partner.slogan.de, en: partner.slogan.en } : { de: '', en: '' },
    }
};

export const generatePartnerPageData = async (partnerId: any, formikValues: any, parentPages: any, cmsPage: any, partnerPageType: any, allBlocks: any, footers: any, headers: any, isPublished = false) => {
    let mappings: any = {
        'image-content-header': {
            'headline.de': formikValues.name,
            'subHeadline.de': formikValues.slogan?.de,
            'picture': formikValues.headline_image,
            'mobile_picture': formikValues.mobile_headline_image
        },
        'partner-box': {
            'partner.partner_logo': formikValues.partner_logo,
            'partner.description.de': formikValues.fact?.de,
            'partner.profile_url': formikValues.profile_url || '' // LOGIC: Outdated
        },
        'paragraph-with-title': {
            'headline.de': '',
            'paragraph.de': formikValues.description?.de
        }
    };

    return await generatePageData(formikValues, parentPages, cmsPage, partnerPageType, mappings, allBlocks, footers, headers, partnerId, formikValues.headline_image, isPublished);
}

export const buildPartnerPageData = async (partnerId: any, partner: any, cmsPage: any, masterData: any, isPublished = false) => {
    const partnerPageType = getPageTypeByCode(masterData.cmsPageTypes, PAGE_CODE.Partner);
    return await generatePartnerPageData(partnerId, partner, masterData.cmsMasterPages, cmsPage, partnerPageType, masterData.ctaBlocks, masterData.footers, masterData.headers, isPublished);
}

export const isFundraiser = (type: any) => type === PARTNER_TYPES.FUNDRAISER;

export const syncDataWithPartnerPage = async (partnerData: any, cmsPartnerPageId: string, cmsMasterData: any, forcePublish = false) => {
    if (!isFundraiser(partnerData.type)) {
      const isCMSPageExisted = !!cmsPartnerPageId;
      let cmsPage = null;
      if (cmsPartnerPageId) {
        cmsPage = await getPartnerPage(cmsPartnerPageId);
      }
      let pageData = await buildPartnerPageData(partnerData.uuid, partnerData, { uuid: cmsPartnerPageId }, cmsMasterData, forcePublish || cmsPage?.status === CMS_STATUS_CODE.PUBLISHED);

      if (isCMSPageExisted) {
        // TODO: Published Config is wrong ??? Check again 
        delete pageData["url"]; // Don't allow updating the url of the partner page
        delete pageData["status"]; // Don't allow updating the status of the partner page, let it be
        if (forcePublish) {
          pageData.status = CMS_STATUS_CODE.PUBLISHED;
        }
        await CMsPageManagerAPI.update(cmsPartnerPageId, pageData.content);
        await updatePage(cmsPartnerPageId, pageData); // API will publish page if page status is Published
        return cmsPartnerPageId;
      } else { // Only happen when creating new page
        let newCmsPage = await createDraftPage({...pageData, status: CMS_STATUS_CODE.DRAFT});
        return newCmsPage.uuid;
      }
    }
    return null;
};

export const syncPartnerData = async (id: any, partnerValues: any) => {
    const partnersRes = await PartnerManagerAPI.getAllPartners(false);
    const partners = partnersRes?.data?.records || [];
    const isDuplicatedCode = partners.some((p: any) => p.uuid !== id && p.code?.toUpperCase() === partnerValues.code?.toUpperCase());
    if (isDuplicatedCode) {
      throw new Error('txt_failed_partner_duplicated_code');
    }

    if (id) {
      return await updatePartner(id || '', partnerValues);
    }
    return await createPartner(partnerValues);
};

export const getPartnerPage = async (uuid: string) => {
    const response = await CMsPageManagerAPI.getCMSPageDetail(uuid);

    if (response && response.status === 200) {
        return response.data.record;
    } else {
        throw new Error('txt_failed_update_partner');
    }
}

export const createDraftPage = async (data: any) => {
    const res = await CMsPageManagerAPI.createPage(data);
    if (res && res.status === 200) {
        return res.data.result;
    } else {
        throw new Error('txt_failed_generate_partner');
    }
}

export const updatePartner = async (uuid: string, data: any) => {
    const response = await PartnerManagerAPI.updatePartners(uuid, data);

    if (response && response.status === 200) {
        return response.data.result;
    } else {
        throw new Error('txt_failed_update_partner');
    }
}

export const createPartner = async (data: any) => {
    const response = await PartnerManagerAPI.generatePartners(data);

    if (response && response.status === 200) {
        return response.data.result;
    } else {
        throw new Error('txt_failed_generate_partner');
    }
}

export const createAndPublishPage = async (data: any) => {
    const res = await CMsPageManagerAPI.createAndPublishPage(data);
    if (res && res.status === 200) {
        return res.data.result;
    } else {
        throw new Error('txt_failed_generate_partner');
    }
}

export const updatePage = async (uuid: string, data: any) => {
    const res = await CMsPageManagerAPI.updatePage(uuid, data);

    if (res && res.status === 200) {
        return res.data.result;
    } else {
        throw new Error('txt_failed_update_partner');
    }
};

export const fetchPartnerData = async (uuid: string) => {
    if (uuid) {
        const res = await PartnerManagerAPI.getPartner(uuid);
        if (res && res.status === 200) {
            return res?.data?.record;
        }
        throw new Error('txt_not_found');
    }
    return null;
};

import React from "react";
import _ from "lodash";
import InfoCircle from '../../../assets/images/icons/info-circle.svg';
import { useFormikContext } from "formik";
import WIUploadMediaFieldV2 from "../../../components/common/upload/wi-upload-media-field-v2";

const WIFormImageSelectorV2 = (props: any) => {
  const { name, title, isRequired, disabled } = props;
  const { errors, touched } = useFormikContext();

  return (
    <div className="element-form">
      <label className="label pb-16">
        {title}{" "}
        {isRequired ? <span className="required-label ml-4">*</span> : <></>}
        <div className="icon-info ml-16">
          <img src={InfoCircle} alt="Info" loading="lazy" className="m-0" />
        </div>
      </label>
      <WIUploadMediaFieldV2
        type="file"
        name={name}
        required={isRequired}
        disabled={disabled}
      />
      <div className={`${_.get(errors, name) && _.get(touched, name) ? "error" : ""}`}>
        {_.get(touched, name) && _.get(errors, name)}
      </div>
    </div>
  )
}

export default WIFormImageSelectorV2;
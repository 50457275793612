const SEARCH_FIELDS = [
  "payment_first_name",
  "payment_last_name",
  "payment_email",
  "payment_address",
  "payment_post_code",
  "payment_city",
  "payment_country"
];

export const getObjectWithValues = (where_obj: any) => {
  const tempt_obj: any = {};
  for (const [key, value] of Object.entries(where_obj)) {
    if (key && key !== "joined_date" 
    && key !== "donation" 
    && key !== "amount"
    && key !== "forest"
    && key !== "co2") {
      if (
        (Array.isArray(value) || typeof value === "string") &&
        value.length > 0
      ) {
        tempt_obj[key] = value;
      }
    } else {
      if (Array.isArray(value) && value.length === 2) {
        tempt_obj[key] = value;
        tempt_obj["from_"+key] = value[0];
        tempt_obj["to_"+key] = value[1];
      }
    }
  }
  return tempt_obj;
};

export const getObjectSearchValue = (value: string) => {
  const search_result: any[] = [];
  SEARCH_FIELDS.forEach((key: string) => {
    const obj = {
      [key]: value,
    };
    search_result.push(obj);
  });

  return search_result;
};

export const ERROR_EXISTING_DONOR = 'txt_duplicated_donor_email'; 
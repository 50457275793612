import { useState, useRef, useLayoutEffect, useEffect } from "react";
import React from "react";
import "./teams-details.scss";
import { MenuDot, WIPaginator, WISearchField } from "../common";
import MenuButton from "../common/menu-button/menu-button";
import Layout from "../layout/layout";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import { WildernessRunsManagerAPI } from "../../services";
import { Sidebar } from 'primereact/sidebar';
import { Toast } from "primereact/toast";
import { ProgressSpinner } from 'primereact/progressspinner';
import DownloadTeamsRunnersDialog from "./components/download-teams-runners-dialog/download-teams-runners-dialog";
import moment from "moment";
import UpdateTeamForm from "./components/update-team-form/update-team-form";
import { Link } from "react-router-dom";
import { generateFileDownload, showNotification } from "../../utils/logic";
import ConfirmPasswordDialog from "../common/confirm-password-dialog/confirm-password-dialog";
import { PASSWORD_SENDMAIL } from "../../utils/utils";

const TeamsDetails = (props: any) => {
  const wildernessRunID = window.location.pathname ? window.location.pathname.split("/")[2] : '';
  const TeamID = window.location.pathname ? window.location.pathname.split("/")[4] : '';
  const [teamData, setTeamData] = useState<any>();
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPasswordDialog, setIsShowPasswordDialog] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState(false);
  const [sendMail, setSendMail] = useState({
    runner: false,
  })
  const [runners, setRunners] = useState({
    data: [],
    totalPage: 5,
  });
  const [isShowDownloadTeams, setIsShowDownloadTeams] = useState(false);
  const [paginator, setPaginator] = useState({
    page: 0,
    first: 0,
    rows: 10,
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: 'name',
    sortOrder: -1,
  });
  const [valueSearch, setValueSearch] = useState('');

  const fetchTeamDetails = async () => {
    setIsLoading(true);
    try {
      const res = await WildernessRunsManagerAPI.getTeamDetails(wildernessRunID, TeamID);
      if (res && res.status === 200) {
        setTeamData(res.data.record || null);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const fetchRunnersData = async () => {
    setIsLoading(true);
    const valuesFilter = {
      pageNo: paginator.page,
      range: paginator.rows,
      where: {
        search_name: valueSearch
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === 1 ? "DESC" : "ASC"]
    }
    try {
      const resRunners = await WildernessRunsManagerAPI.getRunners(wildernessRunID, TeamID, valuesFilter);

      if (resRunners && resRunners.status === 200) {
        setRunners({
          data: resRunners.data.records,
          totalPage: resRunners.data.total || 0,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setRunners({
        data: [],
        totalPage: 0,
      });
      setIsLoading(false);
    }
  };

  const downloadRunnerCertificate = async (data: any) => {
    setIsLoading(true);
    try {
      const res = await WildernessRunsManagerAPI.downloadRunnerCertificate(wildernessRunID, TeamID, data.id);
      if (res && res.status === 200) {
        const default_file_name = data.name.trim().toLowerCase().replace(/(^|\s)\S/g, (l: any) => l.toUpperCase()).replace(/ /g, "-");
        generateFileDownload(`Wildnislauf_Laeufer_Urkunde_${default_file_name}.pdf`, res.data.content)
        showNotification("success", "Download certificate successfully.", toast);
      } else {
        showNotification("error", "Download certificate failed.", toast);
      }
      setIsLoading(false);
    } catch (error) {
      showNotification("error", "Download certificate failed.", toast);
      setIsLoading(false);
    }
  }

  const downloadAllRunnerCertificates = async () => {
    setIsLoading(true);
    try {
        const res = await WildernessRunsManagerAPI.downloadAllRunnerCertificate(wildernessRunID, TeamID);
        if (res && res.status === 200) {
          const default_file_name = teamData?.name?.trim().toLowerCase().replace(/(^|\s)\S/g, (l: any) => l.toUpperCase()).replace(/ /g, "-");
          generateFileDownload(`Wildnislauf_Laeufer_Urkunden_${default_file_name}.zip`, res.data.base64Zip)
          showNotification("success", "Download all runner certificates successfully.", toast);
        } else {
          showNotification("error", "Download certificates failed.", toast);
        }
      setIsLoading(false);
    } catch (error) {
        showNotification("error", "Download certificates failed.", toast);
        setIsLoading(false);
    }
  }

  useEffect(() => {
    if (typeof paginator.page === "number") {
      fetchRunnersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator, sortConfig]);

  useLayoutEffect(() => {
    Promise.all([
      fetchTeamDetails(),
      fetchRunnersData()
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendAllRunnerCertificates = async () => {
    if (password === PASSWORD_SENDMAIL) {
      setErrorPassword(false);
      setIsLoading(true);
      try {
        const resSend = await WildernessRunsManagerAPI.sendAllRunnerCertificates(wildernessRunID, TeamID);
        if (resSend && resSend.status === 200) {
          fetchTeamDetails();
          showNotification("success", "Sent mail successfully", toast);
        }
        setIsLoading(false);
      } catch (error) {
        showNotification("error", "Failed to send mail", toast);
        setIsLoading(false);
        return;
      }
      onHideConfirmDialog();
    } else {
      setErrorPassword(true);
    }
  }

  const onHideConfirmDialog = () => {
    setPassword("");
    setIsShowPasswordDialog(false);
    setErrorPassword(false);
    setSendMail({
      runner: false
    })
  }

  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
  };

  const onSearchFunc = () => {
    fetchRunnersData();
  }

  const actionItems: any = [
    {
      label: 'Edit Team',
      icon: 'fa-solid fa-pen',
      description: "Edit Team Details",
      command: () => setIsShowSidebar(true),
    },
    {
      label: 'Download All Runner Certificates',
      icon: 'fa-solid fa-download',
      description: "Download all runner certificates as zipped PDFs",
      command: () => downloadAllRunnerCertificates()
    },
    {
      label: 'Send All Certificates To All Runners',
      icon: 'fa-solid fa-paper-plane',
      description: "Send WIRun, Team and Runner certificates to all runners via emails",
      // disabled: teamData?.runners_certificate_sent_at
      command: () => {
        setIsShowPasswordDialog(true);
        setSendMail({
          runner: true
        })
      }
    }
  ];

  const buildActionMenu = () => {
    return actionItems;
  };

  const formatAmountBodyTemplate = (rowData: any, field: string) => {
    return <div><span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData[field]?.toString())?.toLocaleString("de-DE") : '-'}</span></div>;
  };

  const formatExpectedBodyTemplate = (rowData: any) => {
    return <div><span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat((rowData?.expected_money_per_units * rowData?.archived_units)?.toString())?.toLocaleString("de-DE") : '-'}</span></div>;
  };

  const formatAchievedBodyTemplate = (rowData: any) => {
    if (teamData) {
      return <div><span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(((rowData?.archived_units * teamData?.run?.unit_length) / 1000)?.toString())?.toLocaleString("de-DE") : '-'}</span></div>;
    }
  };

  const formDate = (value: any) => {
    if (value) {
      const date = moment(new Date(value), 'DD.MM.YYYY HH:mm:ss').utc().add(1, 'hour').format("DD.MM.YYYY HH:mm:ss");
      return date.replace("01:00:00", "00:00:00");
    }
    return '-';
  }

  const tableColumns = [
    {
      field: "name",
      header: "Name",
      width: "calc(20% - 50px)",
      align: "left",
      bodyTemPlate: (rowData: any, column: any) => {
        return <Link onClick={(e) => e.stopPropagation()}
          className="table-ref-link-cell"
          to={`/wilderness-runs/${wildernessRunID}/teams/${TeamID}/runners/${rowData.id}`}>{rowData.name}</Link>;
      },
    },
    {
      field: "email",
      header: "Email",
      width: "20%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return <span>{rowData?.email?.toLowerCase() || '-'}</span>
      },
    },
    {
      field: "archived_units",
      header: "Achieved Units",
      width: "11%",
      align: "left",
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'archived_units'),
    },
    {
      field: "",
      header: "Achieved Distance (km)",
      width: "14%",
      align: "left",
      bodyTemPlate: (rowData: any) => formatAchievedBodyTemplate(rowData),
    },
    {
      field: "total_sponsors",
      header: "Donors",
      width: "8%",
      align: "left",
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'total_sponsors'),
    },
    {
      field: "expected_money_per_units",
      header: "Expected Amount (€)",
      width: "13%",
      align: "left",
      bodyTemPlate: (rowData: any) => formatExpectedBodyTemplate(rowData),
    },
    {
      field: "actual_paid_money",
      header: "Actual Paid Amount (€)",
      width: "14%",
      align: "left",
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, 'actual_paid_money'),
    }
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: "Open",
        description: "Open and edit Runner Details",
        icon: "fa-solid fa-arrow-up-right-from-square",
        url: `/wilderness-runs/${wildernessRunID}/teams/${TeamID}/runners/${rowData.id}`
      },
      {
        label: "Download Runner Certificate",
        description: "Download Runner certificates as PDF",
        icon: "fa-solid fa-download",
        command: () => downloadRunnerCertificate(rowData),
      },
      // {
      //   label: "Send All Certificates",
      //   description: "Send WIRun, Team and Runner certificate to the runner via email",
      //   icon: "fa-solid fa-paper-plane",
      //   disabled: (!rowData.actual_paid_money)
      // },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  }

  return (
    <Layout>
      <Toast ref={toast} />
      {
        isLoading && (
          <div className="loading-component">
            <ProgressSpinner />
          </div>
        )
      }
      <div className="teams-detail-page">
        <div className="teams-detail-container">
          <div className="teams-detail-header">
            <div className="text">
              <p className="name" style={{ paddingRight: '1px' }}>
                <Link className="name" style={{ paddingRight: '1px' }} to={`/wilderness-runs/${wildernessRunID}`}>{teamData?.run?.name}</Link>
              </p>
              <p className="name" style={{ fontSize: '16px', padding: '4px 10px' }}> <i className="fa-solid fa-chevron-right"></i></p>
              <p className="name">{teamData?.name}</p>
            </div>
            <div className="actions">
              <MenuButton label="Actions" items={buildActionMenu()}></MenuButton>
            </div>
          </div>
          <div className="partner-info">
            <div className="partner-info-container">
              <div className="headline">
                <label className="label">Team Leader Information</label>
                <div className="partner-content">
                  <div className="info">
                    <p className="name"> {teamData?.leader_name || '-'} </p>
                    <p className="email"> {teamData?.leader_email || '-'} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="teams-info">
            <div className="teams-info-container">
              <div className="headline">
                <label className="label">Team Information</label>
              </div>
              <div className="content">
                <div className="row content-row">
                  <div className="col-md-3">
                    <label className="label">Runners</label>
                  </div>
                  <div className="col-md-9">
                    <p className="text">{teamData?.total_runners ? parseFloat(teamData?.total_runners?.toString())?.toLocaleString("de-DE") : '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                    <label className="label">Sponsors</label>
                  </div>
                  <div className="col-md-9">
                    <p className="text">{teamData?.total_sponsors ? parseFloat(teamData?.total_sponsors?.toString())?.toLocaleString("de-DE") : '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                    <label className="label">Achieved </label>
                  </div>
                  <div className="col-md-9">
                    <p className="text">{teamData?.total_archived_units ? `${parseFloat(teamData?.total_archived_units?.toString())?.toLocaleString("de-DE")} units - ${((teamData?.total_archived_units * teamData?.run?.unit_length) / 1000).toLocaleString("de-DE")} km` : '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                    <label className="label">Expected amount (EA)</label>
                  </div>
                  <div className="col-md-9">
                    <p className="text">{teamData?.expected_paid_money ? `${parseFloat(teamData?.expected_paid_money?.toString())?.toLocaleString("de-DE")} €` : '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                    <label className="label">Actual paid amount (APA)</label>
                  </div>
                  <div className="col-md-9">
                    <p className="text">{teamData?.actual_paid_money ? `${parseFloat(teamData?.actual_paid_money?.toString())?.toLocaleString("de-DE")} €` : '-'} </p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                    <label className="label">Team certificate sent</label>
                  </div>
                  <div className="col-md-9">
                    <p className="text">{teamData?.team_certificate_sent_at ? formDate(teamData?.team_certificate_sent_at) : '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                    <label className="label">All Runner certificates sent</label>
                  </div>
                  <div className="col-md-9">
                    <p className="text"> {teamData?.runners_certificate_sent_at ? formDate(teamData?.runners_certificate_sent_at) : '-'} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="teams-info">
            <div className="teams-info-container">
              <div className="headline">
                <label className="label">Runners</label>
                <div className="teams-header-panel">
                  <WISearchField
                    icon={"pi pi-search"}
                    placeholder="Search for anything"
                    setSearch={(value: any) => setValueSearch(value.global.value)}
                    enterSearch={() => onSearchFunc()}
                    globalValue={valueSearch}
                  />
                </div>
              </div>
              <div className="teams-table">
                <DataTable
                  value={runners.data}
                  scrollable
                  dataKey="id"
                  scrollHeight="calc(100vh - 166px)"
                  scrollDirection="both"
                  lazy
                  onSort={(e: any) => {
                    setSortConfig({
                      sortField: e.sortField,
                      sortOrder: e.sortOrder,
                    });
                  }}
                  sortField={sortConfig.sortField}
                  sortOrder={sortConfig.sortOrder}
                >
                  {dynamicColumns}
                  <Column
                    frozen
                    alignFrozen="right"
                    style={{ width: "50px" }}
                    body={buildMenu}
                  ></Column>
                </DataTable>
                <WIPaginator
                  first={paginator.first}
                  rows={paginator.rows}
                  totalRecords={runners.totalPage}
                  onPageChange={onBasicPageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <DownloadTeamsRunnersDialog
          visible={isShowDownloadTeams}
          onHide={() => {
            setIsShowDownloadTeams(false)
          }}
        />
        <Sidebar
          visible={isShowSidebar}
          position="right"
          className="p-sidebar-md add-donor-sibar-right"
          style={{ width: '30em' }}
          onHide={() => setIsShowSidebar(false)}>
          <div className="add-donor-sidebar-content">
            <div className="headline">
              <h6>Update Team</h6>
            </div>
            <UpdateTeamForm
              fetchCallBack={(() => fetchTeamDetails())}
              toast={toast}
              onHide={() => setIsShowSidebar(false)}
              teamData={teamData}
              wildernessRunID={wildernessRunID}
            />
          </div>
        </Sidebar>
        <ConfirmPasswordDialog 
            visible={isShowPasswordDialog}
            onHide={() => onHideConfirmDialog()}
            onConfirm={() => {
              sendAllRunnerCertificates();
            }}
            password={password}
            onSetPassword={(value: any) => setPassword(value)}
            errorPassword={errorPassword}
        />
      </div>
    </Layout>
  )
};

export default TeamsDetails;

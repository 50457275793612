import { useField } from "formik";
import * as React from "react";
import { useState } from "react";

const WICSVUploadField = (props: any) => {
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched } = helpers;
  const [labelFile, setLabelFile] = useState(null);

  const handleOnChangedValue = (evt: any) => {
    setTouched(true);
    const file = evt.target.files[0];
    if (file && file.type === "text/csv") {
      const label = evt.target.value.split("\\");
      setLabelFile(label[label.length - 1]);
      if (file) {
        const read = new FileReader();
        read.onload = function (e: any) {
          const contents = e.target.result;
          setValue(window.btoa(unescape(encodeURIComponent(contents))), true);
        };
        read.readAsText(file);
      } else {
        alert("Failed to load file");
      }
    } else {
      alert("Only import files csv");
    }
  };

  return (
    <>
      <label className={props.labelClassName || ""} htmlFor="upload">
        Choose CSV
      </label>
      <input
        className={props.className || ""}
        type="file"
        name="upload"
        id="upload"
        accept=".csv"
        onChange={handleOnChangedValue}
      />
      {labelFile ? (
        <label className={props.nameFileClassName || ""}>{labelFile}</label>
      ) : null}
    </>
  );
};

export default WICSVUploadField;

import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "./wi-codeBatchesDropdown.scss";
import * as _ from "lodash";
import CodeManagerAPI from "../../../../services/couponsManager";
import { env } from '../../../../environment';
import { MultiSelect } from "primereact/multiselect";

export function CodeBatchesDropdown(props: any) {
  const { values, disabled, schema } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [sources, setSources] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<any>([]);

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    updateFieldValue(selectedValues);
  }, [selectedValues]);

  const fetchAllData = async () => {
    const [batchData] = await Promise.all([
      CodeManagerAPI.getCoupons({
        "pageNo": 0,
        "range": 2000,
        "where": { "status": ["active", "finished"] },
        "order": [["serial", "DESC"]]
      }),
    ]);

    const batches = batchData?.data?.records || [];
    setSources(batches);
    setSelectedValues(batches.filter((g: any) => (values?.dropdown_id?.split(";") || []).includes(g.id)));
  };

  const calculatePercentProtected = (totalSize: number, availableSize: number) => {
    let protectedSize = totalSize - availableSize;
    return (100 * protectedSize) / totalSize;
  };

  const convertKgToTon = (kg: any) => {
    return (kg / 1000);
  }

  const updateFieldValue = (value: any) => {
    if (value) {
      const items = value.map((c: any) => ({
        no_valid_coupons: (+c.no_valid_coupons || +c.quantity),
        area: +((+c.no_valid_coupons || +c.quantity) * (+c.area)).toFixed(2),
        amount_kg_co2: (+c.no_valid_coupons || +c.quantity) * (+c.area) * (+c.amount_kg_co2),
        no_boosted_donations: +(c.no_boosted_donations || 0),
        boosted_protected_size: +(c.boosted_protected_size || 0),
        boosted_amount_kg_co2: +(c.boosted_amount_kg_co2 || 0),
      }));

      const totalNoCodes= _.sumBy(items, 'no_valid_coupons')?.toLocaleString('de');
      const totalArea = _.sumBy(items, 'area')?.toLocaleString('de');
      const totalCO2 = (+convertKgToTon(_.sumBy(items, 'amount_kg_co2')).toFixed(3))?.toLocaleString('de');
      const totalNoBoostedDonations = _.sumBy(items, 'no_boosted_donations')?.toLocaleString('de');
      const totalBoostedArea = _.sumBy(items, 'boosted_protected_size')?.toLocaleString('de');
      const totalBoostedCO2 = (+convertKgToTon(_.sumBy(items, 'boosted_amount_kg_co2')).toFixed(3))?.toLocaleString('de');
      let fontSize = totalNoCodes.length > 5 || totalArea.length > 5 || totalCO2.length > 5 
        || totalNoBoostedDonations.length > 5 ||totalBoostedArea.length > 5 || totalBoostedCO2.length > 5 ? "3.625rem" : "4.625rem";

      setFieldValue('font_size', fontSize);
      setFieldValue('dropdown_id', _.map(value, 'id').join(';'));

      setFieldValue('facts.0.fact', totalNoCodes);
      setFieldValue('facts.1.fact', totalArea);  
      setFieldValue('facts.2.fact', totalCO2);
      setFieldValue('facts.3.fact', totalNoBoostedDonations);
      setFieldValue('facts.4.fact', totalBoostedArea);
      setFieldValue('facts.5.fact', totalBoostedCO2);

      setFieldValue('facts.0.fact.de', totalNoCodes);
      setFieldValue('facts.1.fact.de', totalArea);
      setFieldValue('facts.2.fact.de', totalCO2);
      setFieldValue('facts.3.fact.de', totalNoBoostedDonations);
      setFieldValue('facts.4.fact.de', totalBoostedArea);
      setFieldValue('facts.5.fact.de', totalBoostedCO2);
    }
  };

  const customItemTemplate = (item: any) => {
    return (
      <div>
        <div className="batch-title">{item?.name} - {item?.no_valid_coupons?.toLocaleString('de')} codes</div>
      </div>
    );
  };

  return (
    <div className="form-element">
      <div className="form-element-title" style={{ marginBottom: "15px" }}>
        {schema.label}
      </div>
      <div style={{ margin: "-10px" }}>
        <div className="apply-btn" style={{ display: "flex" }}>
          <div className="select-group">
            <MultiSelect
              className="wi-multi-select-dropdown"
              itemTemplate={customItemTemplate}
              dropdownIcon={"pi pi-chevron-down"}
              value={selectedValues}
              options={sources}
              optionLabel="name"
              onChange={(item: any) => {
                setSelectedValues(item.value);
              }}
              appendTo="self"
              filter
              filterBy={schema.sourceConfiguration.searchFields?.join(",")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
import * as React from "react";
import "./tag-wir.scss";
import { Tag } from "primereact/tag";
import { useEffect, useState } from "react";
import { WIR_STATUS } from "../../../../utils/utils";

const WITagWIR = (props: any) => {
  const {  value } = props;
  const [valueTag, setValueTag] = useState({
    icon: '',
    value: '',
    status: '',
    color: '',
    backgroundColor: ''
  })

  useEffect(() => {
    if(value) {
      switch(value) {
        case WIR_STATUS.NEW: setValueTag({
          icon: 'pi pi-exclamation-triangle',
          value: "New",
          status: 'warning',
          color: '#ffffff',
          backgroundColor: '#737373'
        });
        break;
        case WIR_STATUS.ACTIVE: setValueTag({
          icon: 'pi pi-check',
          value: "Active",
          status: 'success',
          color: '#ffffff',
          backgroundColor: '#477EC0'
        });
        break;
        case WIR_STATUS.COMPLETED: setValueTag({
          icon: 'pi pi-user',
          value: "Completed",
          status: 'info',
          color: '#ffffff',
          backgroundColor: '#95C24E'
        });
        break;
        default: setValueTag({
          icon: 'pi pi-info-circle',
          value: "Empty",
          status: 'info',
          color: '#ffffff',
          backgroundColor: '#eceef1'
        });
        break;
      }
    }
  }, [value]);

  return (
    <>
    {
        valueTag.value ? 
        <div className="tag-wir-custom">
          <Tag className="mr-2" style={{ color: valueTag.color, backgroundColor: valueTag.backgroundColor }} severity={valueTag.status} value={valueTag.value}></Tag>
        </div>
        : ''
    }
    </>
  );
};

export default WITagWIR;

export const FAQBlock =  
  {
    uuid: "d202f689-23d5-4c2d-841f-430a8f960812",
    name: "FAQ",
    code: "faq",
    type: "General Blocks",
    type_title: "General BlocksCTA", 
    configuration: {
      category: {
        type: 'textFieldCustom',
        label: 'Category',
        isRequired: false,
      },
      question: {
        type: 'textFieldCustom',
        label: 'Question',
        isRequired: true,
        supportMultiLanguages: ["de","en"],
        validationType: "string",
        maxLength: 200,
        validations: [
          {
            type: "required",
            params: ["Question is required"],
          },
          {
            type: "nullable",
            params: [],
          }
        ],
      },
      answer: {
        type: 'richTextEditor',
        label: 'Answer',
        isRequired: true,
        supportMultiLanguages: ["de","en"],
        validationType: "string",
        validations: [
          {
            type: "required",
            params: ["Answer is required"],
          },
          {
            type: "nullable",
            params: [],
          }
        ],
      },
      xssConfiguration: {
        question: 'textFieldCustom',
        answer: 'richTextEditor',
        category: 'textFieldCustom',
      }
    },
    parameters: {},
  }


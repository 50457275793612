import * as React from "react";
import "./tag-sponsor.scss";
import { Tag } from "primereact/tag";
import { useEffect, useState } from "react";
import { SPONSOR_STATUS_LIST } from "../../../../utils/utils";

const WITagSponsor = (props: any) => {
  const {  value } = props;
  const [valueTag, setValueTag] = useState({
    icon: '',
    value: '',
    status: '',
    color: '',
    backgroundColor: ''
  })

  useEffect(() => {
    if(value) {
      switch(value) {
        case SPONSOR_STATUS_LIST.PAID: setValueTag({
          icon: 'pi pi-exclamation-triangle',
          value: "Paid",
          status: 'success',
          color: '#ffffff',
          backgroundColor: '#95C24E'
        });
        break;
        case SPONSOR_STATUS_LIST.UNPAID: setValueTag({
          icon: 'pi pi-check',
          value: "Unpaid",
          status: 'info',
          color: '#ffffff',
          backgroundColor: '#477EC0'
        });
        break;
        default: setValueTag({
          icon: 'pi pi-info-circle',
          value: "Empty",
          status: 'info',
          color: '#ffffff',
          backgroundColor: '#eceef1'
        });
        break;
      }
    }
  }, [value]);

  return (
    <>
    {
        valueTag.value ? 
        <div className="tag-sponsor-custom">
          <Tag className="mr-2" style={{ color: valueTag.color, backgroundColor: valueTag.backgroundColor }} severity={valueTag.status} value={valueTag.value}></Tag>
        </div>
        : ''
    }
    </>
  );
};

export default WITagSponsor;

import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Formik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CMsPageManagerAPI, PartnerManagerAPI } from "../../../services";
import Layout from "../../../components_v2/common/layout/layout";
import { PAGE_CODE } from "../../pages/utils";
import ActionFormComponent from "./action-form-partner/action-form-partner";
import { RichTextEditorV2 } from "./create-partner-form/custom-editor-component";
import { CMS_STATUS_CODE, PARTNER_CATEGORY_LIST, PARTNER_TYPE_LIST, PARTNER_TYPES, PERMISSIONS_V2, STATUS_CODE } from "../../../components_v2/utils/utils";
import WIUploadMediaFieldLogo from "../../common/upload/wi-upload-media-field-logo";
import {
  buildPartnerSchema, fetchPartnerData, getPartnerPage, initializePartnersValues,
  syncDataWithPartnerPage, syncPartnerData, updatePage, updatePartner
} from "../partner-business";
import {
  fetchPageTypes, getPageTypeByCode, fetchCMSParentPages,
  fetchCMSFooters, fetchCMSHeaders, fetchAllBlocks
} from "../../pages/builder-page.common";
import { useLayoutV2 } from "../../../context/LayoutProvider";
import { WIFormImageSelectorV2, WIFormInputTextV2 } from "../../../components_v2/common/form-fields";
import moment from "moment";
import { env } from "../../../environment";
import { Sidebar } from "primereact/sidebar";
import PreAddPartnerForm from "../../../pages/partner-manager/components/pre-add-partner-form";
import useAuth from "../../../context/useAuth";

export const CreatePartnerManager = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { auth } = useAuth();

  const [searchParams] = useSearchParams();
  const partnerCode = searchParams.get('code') || '';
  const partnerType = searchParams.get('type') || '';
  const partnerCategory = partnerType === PARTNER_TYPES.PARTNER ? searchParams.get('category') || '' : '';

  const { t } = useTranslation('language', { keyPrefix: 'partner_details' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: globalTrans } = useTranslation('language');

  const { setLoadingProgress, setErrorProgress, setSuccessProgress, setIsContentLoading, turnOffProgress } = useLayoutV2();
  const [isEdit, setIsEdit] = useState(!id ? true : false);
  const [partner, setPartner] = useState<any>(null);
  const formikRef = useRef<any>(null);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [cmsPartnerPageId, setCmsPartnerPageId] = useState<any>('');
  const [cmsPartnerPageURL, setCmsPartnerPageURL] = useState<any>('');
  const [masterData, setMasterData] = useState<any>({
    headers: [],
    footers: [],
    ctaBlocks: [],
    cmsPageTypes: [],
    cmsMasterPages: []
  });

  const permissions = {
    canUpdatePartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_UPDATE),
    canViewCMSPAGE: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_VIEW),
    canUpdateCMSPAGE: auth?.permissions?.includes(PERMISSIONS_V2.CMS_PAGE_UPDATE),
  };

  const isFundraiser = (type: any) => type === PARTNER_TYPES.FUNDRAISER;

  const commonItemTemplate = (option: any) => {
    return (<div>{option?.label ? globalTrans(option?.label) : ''} </div>);
  };

  const actionBack = () => {
    if (id) {
      setIsEdit(false);
      formikRef?.current?.resetForm();
    } else {
      navigate('/partners');
    }
  }

  const isDisabledFiledForm = (values: any) => {
    return !isEdit; //|| [STATUS_CODE.INACTIVE, STATUS_CODE.ARCHIVED].includes(values.status);
  }

  const getAllData = async () => {
    try {
      setIsContentLoading(true);
      let [footers, headers, cmsPageTypes, ctaBlocks, partner] = await Promise.all([
        fetchCMSFooters(),
        fetchCMSHeaders(),
        fetchPageTypes(),
        fetchAllBlocks(),
        fetchPartnerData(id || '')
      ]);

      if (partner && isFundraiser(partner.type)) {
        navigate('/partners');
        return;
      }

      let partnerPageType = getPageTypeByCode(cmsPageTypes, PAGE_CODE.Partner);
      let masterPageType = getPageTypeByCode(cmsPageTypes, PAGE_CODE.Master);
      let querySearch: any = { conditions: { typeIds: [partnerPageType?.uuid, masterPageType?.uuid] } };
      let res = await CMsPageManagerAPI.searchAllPagesWithPagination(querySearch, { pageNo: 0, range: 10000 });
      let pages = res?.data?.records || [];
      let masterPages = pages.filter((p: any) => p.type_id === masterPageType?.uuid);
      let partnerPage = pages.find((p: any) => p.parent_id === id);
      let cmsMasterPages = fetchCMSParentPages(masterPages);
      let parentPage: any = cmsMasterPages.find((d: any) => partnerPage?.page_id === d.id);

      setCmsPartnerPageId(partnerPage?.uuid);
      setPartner(partner);
      setMasterData({ headers, footers, ctaBlocks, cmsPageTypes, cmsMasterPages });
      if (parentPage && partnerPage) {
        setCmsPartnerPageURL([...parentPage?.nodes?.map((c: any) => c.url), partnerPage.url].join('/'));
      }
    } catch (error: any) {
      setErrorProgress(error.message);
      setPartner(null);
    } finally {
      setIsContentLoading(false);
    }
  }

  useEffect(() => {
    // Create new without enough mandatory fields, redirect to page partners 
    if (!id && (!partnerType || !partnerCode)) {
      navigate('/partners');
      return;
    }
    getAllData();
  }, []);

  const upsertPartner = async () => {
    if (formikRef && formikRef.current) {
      formikRef.current.validateForm();
      ['code', 'name', 'partner_logo', 'type', 'category'].forEach(f => {
        formikRef.current.setFieldTouched(f, true);
      });
    }

    if (formikRef && formikRef.current.isValid) {
      try {
        setLoadingProgress(errorTrans('txt_loading'));

        // @ts-ignore: Object is possibly 'null'.
        const partnerValues = _.cloneDeep(formikRef?.current?.values);
        partnerValues.code = partner ? partner?.code?.toString()?.toUpperCase() : partnerCode;
        const partnerData = await syncPartnerData(id, partnerValues);
        let partnerPageId = await syncDataWithPartnerPage(partnerData, cmsPartnerPageId, masterData);
        await PartnerManagerAPI.updatePartners(partnerData.uuid, {}); // refresh partner master page
        const isCreateFlow = !id;
        if (isCreateFlow) {
          setSuccessProgress(errorTrans('txt_generate_partner_success'));
          turnOffProgress();
          navigate(`/partners/${partnerData?.uuid}`);
          // window.location.reload();
        } else {
          setSuccessProgress(errorTrans('txt_update_partner_success'));
          setPartner(partnerData);
          setCmsPartnerPageId(partnerPageId);
        }

        setIsEdit(false);
      } catch (error: any) {
        let errorTxt = 'txt_failed_generate_partner';
        if (error.message && error.message.includes('txt_')) {
          errorTxt = error.message;
        }
        setErrorProgress(errorTrans(errorTxt));
      } finally {
      }
    }
  };

  const changePartnerStatus = async (partnerValues: any, newStatus: any) => {
    try {
      if (id && partnerValues && newStatus) {
        setLoadingProgress(errorTrans('txt_loading'));
        let partnerPageId = cmsPartnerPageId;
        const partnerData = _.cloneDeep(partnerValues);
        partnerData.status = newStatus;
        partnerData.code = partnerValues?.code?.toString()?.toUpperCase();

        await updatePartner(id || '', partnerData);
        if (partnerValues.status === STATUS_CODE.NEW && newStatus === STATUS_CODE.ACTIVE) {
          const pageData = await getPartnerPage(cmsPartnerPageId);
          pageData.status = CMS_STATUS_CODE.PUBLISHED;
          pageData['custom_config'] = pageData['custom_config'] || {};
          pageData['custom_config']['published_info'] = pageData['custom_config']['published_info'] || [];
          pageData['custom_config']['published_info'].push({ time: moment().toISOString() })
          await updatePage(partnerPageId, pageData); // API will publish page if page status is Published
          await PartnerManagerAPI.updatePartners(id, {}); // refresh partner master page
        }
        
        setPartner(partnerData);
        setCmsPartnerPageId(partnerPageId);
        setIsEdit(false);
        setSuccessProgress(errorTrans('txt_update_partner_success'));
      }
    } catch (error: any) {
      let errorTxt = 'txt_failed_update_partner';
      if (error.message && error.message.includes('txt_')) {
        errorTxt = error.message;
      }
      setErrorProgress(errorTrans(errorTxt));
    } finally {
    }
  };

  const menuItems = [
    {
      label: `${t('txt_edit')}`,
      className: 'item-menu',
      icon: "wi-edit",
      hidden: !permissions.canUpdatePartner || !partner,
      command: () => setIsEdit(true)
    },
    // {
    //   label: t('txt_change_category'),
    //   icon: "wi-list",
    //   hidden: !partner || partner?.type !== PARTNER_TYPES.PARTNER,
    //   command: () => setIsShowSidebar(true)
    // },
    {
      label: t('txt_active'),
      icon: "wi-active",
      hidden: !permissions.canUpdatePartner || !partner || partner?.status === STATUS_CODE.ACTIVE,
      command: () => changePartnerStatus(partner, STATUS_CODE.ACTIVE)
    },
    {
      label: t('txt_inactive'),
      icon: "wi-inactive",
      hidden: !permissions.canUpdatePartner || !partner || partner?.status === STATUS_CODE.INACTIVE,
      command: () => changePartnerStatus(partner, STATUS_CODE.INACTIVE)
    },
    {
      label: t('txt_archive'),
      icon: "wi-archive",
      hidden: !permissions.canUpdatePartner || !partner || partner?.status === STATUS_CODE.ARCHIVED,
      command: () => changePartnerStatus(partner, STATUS_CODE.ARCHIVED)
    },
    {
      label: t('txt_edit_cms_page'),
      icon: "wi-edit-paint",
      hidden: !permissions.canUpdateCMSPAGE || !partner || partner?.type === PARTNER_TYPES.FUNDRAISER,
      url: `/cms-pages/${cmsPartnerPageId}`
    },
    {
      label: t('txt_open_cms_page'),
      icon: "wi-open-doc",
      hidden: !permissions.canViewCMSPAGE || !partner || partner?.type === PARTNER_TYPES.FUNDRAISER,
      externalUrl:  `${env.CMS_URL}${cmsPartnerPageURL}`
    },
    // {
    //   label: `${t('txt_change_log')}`,
    //   className: 'item-menu',
    //   icon: <NoteBookSvg className="icon-svg"></NoteBookSvg>,
    //   command: () => { },
    // },
  ];

  return (
    <Layout title={t('txt_user_detail')} customLayout={true} customCopyRight={true}>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={initializePartnersValues(partner, partnerCode, partnerType, partnerCategory)}
        validationSchema={buildPartnerSchema(errorTrans)}
        onSubmit={(values, { setSubmitting }) => { }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit
        }) => (
          <>
            <ActionFormComponent
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              actionBack={actionBack}
              onSubmit={() => upsertPartner()}
              menuItems={menuItems}
            />
            <div className="partner-form-container">
              <form onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                  {!isFundraiser(values.type) ? <div className="mb-32">
                    <WIUploadMediaFieldLogo
                      className="square-logo"
                      type="file"
                      name="partner_logo"
                      required={true}
                      disabled={!isEdit}>
                    </WIUploadMediaFieldLogo>
                    <div className="element-form d-flex justify-content-center">
                      {errors.partner_logo && touched.partner_logo ? (
                        <small className="error">{errors.partner_logo}</small>
                      ) : null}
                    </div>
                  </div> : <></>}
                  <div className={`col-12 col-12col-lg-12 pb-0 pt-0`}>
                    <div className={`row row-content ${isFundraiser(values.type) ? 'justify-content-center' : ''} `}>
                      <div className="element-form">
                        <div className="element-full pb-16">
                          {values?.code || '-'}
                        </div>
                        <div className="element-full pb-32">
                          {globalTrans(PARTNER_TYPE_LIST.find(s => s.code === values.type)?.label || '') || ''}
                          {/* {values?.type === PARTNER_TYPES.PARTNER && values.category ? ` - ${globalTrans(PARTNER_CATEGORY_LIST.find(s => s.code === values.category)?.label || '')}` : ''} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${!isFundraiser(values.type) ? 'col-12 col-lg-12' : 'col-6 col-lg-6 align-items-center'} pb-0 pt-0`}>
                    <div className="row row-content">
                      <div className={`${isFundraiser(values.type) ? 'col-md-12' : 'col-md-6'} mb-32`}>
                        <WIFormInputTextV2
                          title={t('txt_partner_name')}
                          name="name"
                          isRequired={true}
                          maxLength={100}
                          disabled={isDisabledFiledForm(values)}
                        />
                      </div>
                      {
                        !isFundraiser(values.type) ?
                          <div className="col-md-6">
                            <WIFormInputTextV2
                              title={t('txt_slogan')}
                              name="slogan.de"
                              isRequired={true}
                              maxLength={100}
                              disabled={isDisabledFiledForm(values)}
                            />
                          </div> : <></>
                      }
                      {
                        !isFundraiser(values.type) ?
                          <>
                            <div className={`${isFundraiser(values.type) ? 'col-md-12' : 'col-md-6'}`}>
                              <div className="element-form mb-32">
                                <label className="label mb-16">
                                  {t('txt_description')}
                                </label>
                                <RichTextEditorV2
                                  name="description.de"
                                  isMultipleLine={true}
                                  isRequired={true}
                                  disabled={isDisabledFiledForm(values)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="element-form mb-32">
                                <label className="label mb-16">
                                  {t('txt_fact')}
                                </label>
                                <RichTextEditorV2
                                  disabled={isDisabledFiledForm(values)}
                                  name="fact.de"
                                  isMultipleLine={true}
                                  isRequired={true}
                                />
                              </div>
                            </div>
                          </> : <></>
                      }
                    </div>
                  </div>
                </div>
                {
                  !isFundraiser(values.type) ?
                    <div className="row">
                      <div className="col-md-6">
                        <WIFormImageSelectorV2
                          title={t('txt_header_picture')}
                          name="headline_image"
                          disabled={isDisabledFiledForm(values)}
                        />
                      </div>
                      <div className="col-md-6">
                        <WIFormImageSelectorV2
                          title={t('txt_mobile_header_picture')}
                          name="mobile_headline_image"
                          disabled={isDisabledFiledForm(values)}
                        />
                      </div>
                    </div> : <></>
                }
              </form>
            </div>
          </>
        )}
      </Formik>
      <Sidebar
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        visible={isShowSidebar}
        onHide={() => setIsShowSidebar(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6 className="m-0">{t('txt_change_category')}</h6>
          </div>
          <PreAddPartnerForm
            onHide={() => setIsShowSidebar(false)}
            refreshData={(updatedPartner: any) => setPartner(updatedPartner)}
            partner={partner}
          />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default CreatePartnerManager;

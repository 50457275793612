import moment from "moment";
import { LanguageItem } from "../../../../../utils/commonType";

export type MetaDataType = {
    alternative_text: LanguageItem;
    title: LanguageItem;
    description: LanguageItem;
    xResolution: number;
    yResolution: number;
    camera_model: string;
    shoot_date: string;
    dimensions: {
        height: number;
        width: number;
    };
};

export type MetaDataTypeV2 = {
    xResolution: number;
    yResolution: number;
    camera_model: string;
    shoot_date: string;
    dimensions: {
        height: number;
        width: number;
    };
};

export type Media = {

    file: string;
    file_url: string;
    file_size: number;
    extension: string;
    name: string;
    type: string;
    folder_id: string;
    metadata: MetaDataType;
};

export type MediaV2 = {
    fullPath: string;
    size: number;
    extension: string;
    name: string;
    type: string;
    folderId: string;
    title: LanguageItem;
    alternativeText: LanguageItem;
    description: LanguageItem;
    metadata: MetaDataTypeV2;
};

export const createEmptyMedia = (): Media => {
    return {
        file: "",
        file_url: "",
        file_size: 0,
        extension: "",
        name: "",
        type: "",
        folder_id: "",
        metadata: {
            alternative_text: {
                de: "",
                en: "",
            },
            title: {
                de: "",
                en: "",
            },
            description: {
                de: "",
                en: "",
            },
            xResolution: 0,
            yResolution: 0,
            camera_model: "",
            shoot_date: "",
            dimensions: {
                height: 0,
                width: 0,
            }
        },
    };
};

export const createEmptyMediaV2 = ():  MediaV2 => {
    return {
        fullPath: "",
        size: 0,
        extension: "",
        name: "",
        type: "",
        folderId: "",
        title: {
            de: "",
            en: "",
        },
        alternativeText: {
            de: "",
            en: "",
        },
        description: {
            de: "",
            en: "",
        },
        metadata: {
            xResolution: 0,
            yResolution: 0,
            camera_model: "",
            shoot_date: "",
            dimensions: {
                height: 0,
                width: 0,
            }
        },
    };
};
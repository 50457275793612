import { LanguageItem } from "../../../../utils/commonType";
import { PAGE_LANGUAGE } from "../../utils";

export type SEOConfig = {
  seo_title: LanguageItem;
  seo_description: LanguageItem;
  seo_image: string;
};

export type PageType = {
  name: string;
  title: LanguageItem;
  type_id: string;
  header_id: string;
  page_id: string;
  footer_id: string;
  language: string[];
  url: string;
  status: string;
  og_seo_config: SEOConfig;
  twitter_seo_config: SEOConfig;
  last_published_at: string;
  first_published_at: string;
  special_type?: string;
  custom_config?: any;
  allow_search?: number;
  addtional_js_libs?: string;
  addtional_css_libs?: string;
};

export const createEmptySEOConfig = () => {
  return {
    seo_title: {
      de: "",
      en: ""
    },
    seo_description: {
      de: "Mit deiner Spende schützt du mit je 1€ zum einen 1qm natürlichen Lebensraum als Wildnis-Pate und kompensierst zum anderen 60 kg CO2, die dort als Biomasse gebunden sind.",
      en: "With your donation, you protect 1 square meter of natural habitat as a wilderness sponsor and compensate for 60 kg of CO2 that is bound there as biomass."
    },
    seo_image: "default_seo.jpg"
  }
}

export const createEmptyPage = (): PageType => {
  return {
    name: "",
    title: {
      de: "",
      en: ""
    },
    type_id: "",
    header_id: "",
    footer_id: "",
    page_id: "",
    language: PAGE_LANGUAGE.map(l => l.code),
    url: "",
    status: "draft",
    og_seo_config: createEmptySEOConfig(),
    twitter_seo_config: createEmptySEOConfig(),
    last_published_at: '',
    first_published_at: '',
    special_type: '',
    custom_config: {},
    allow_search: 1,
    addtional_js_libs: '',
    addtional_css_libs: ''
  };
};
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import "./wi-goalDropdown.scss";
import * as _ from "lodash";
import GoalManagerAPI from "../../../../services/goalManager";
import { env } from '../../../../environment';
import { MultiSelect } from "primereact/multiselect";

export function GoalDropdown(props: any) {
  const { values, disabled, schema } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [sources, setSources] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>();

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    updateFieldValue(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    updateFieldValue(values.goals);
    setSelectedValue(values.goals);
  }, [values.goals]);

  const fetchAllData = async () => {
    const [goalData] = await Promise.all([
      GoalManagerAPI.getGoals({
        "pageNo": 0,
        "range": 2000,
        "where": {"status":["active","completed"],"search_name":""},
        "order":["created_at","DESC"]
      }),
    ]);

    const goals = goalData?.data?.records || [];
    setSources(goals);

    let ids = values.goal_ids?.split(";") || [];
    setSelectedValue(goals.filter((g: any) => ids.includes(g.id)));
  };

  const calculatePercentProtected = (totalSize: number, availableSize: number) => {
    let protectedSize = totalSize - availableSize;
    return (100 * protectedSize) / totalSize;
  };

  const convertKgToTon = (kg: any) => {
    return Math.trunc(kg / 1000);
  }

  const updateFieldValue = (value: any) => {
    if (value) {
      setFieldValue('goal_ids', _.map(value, 'id').join(';'));
      setFieldValue('goals', value);
    }
  };

  const customItemTemplate = (item: any) => {
    return (
      <div>
        <div className="goal-title">
          <div>{item?.goal_type === "region" ? item?.region_name : item?.campaign_name}</div>
          <div style={{marginLeft: "10px"}}> {item?.donation_goal.toLocaleString('de')} m²</div>
        </div>
        <div className="goal-name" dangerouslySetInnerHTML={{ __html: `${item?.name}`}}></div>
      </div>
    );
  };

  const customSelectedItemTemplate = (item: any) => {
    return (
      <div>
        <div className="goal-title">
          <div>{item?.goal_type === "land" ? item?.land_name : item?.campaign_name} - {item?.code}</div>
          <div>{item?.donation_goal.toLocaleString('de')} m²</div>
        </div>
      </div>
    );
  };

  return (
    <div className="form-element">
      <div className="form-element-title" style={{ marginBottom: "15px" }}>
        {schema.label}
      </div>
      <div style={{ margin: "-10px" }}>
        <div className="apply-btn" style={{ display: "flex" }}>
          <div className="select-group">
            <MultiSelect
              value={selectedValue}
              itemTemplate={customItemTemplate}
              selectedItemTemplate={customSelectedItemTemplate}
              dropdownIcon={"pi pi-chevron-down"}
              maxSelectedLabels={1}
              options={sources}
              onChange={(item: any) => {
                setSelectedValue(item.value);
              }}
              appendTo="self"
              optionLabel="name"
              showSelectAll={false}
              filter
              filterBy={schema.sourceConfiguration.searchFields?.join(
                ","
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
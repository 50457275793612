import * as React from "react";
import { useEffect, useState } from "react";
import _ from 'lodash';
import "./donation-filter-component.scss";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { TabMenu } from "primereact/tabmenu";
import FilterField from "../../../../components_v2/common/filter-field/filter-field";
import WISearchField from "../../../../components_v2/common/search/wi-search-field";
import useAuth from "../../../../context/useAuth";
import { DONATION_RHYTHM_LIST, DONATION_STATUS_LIST, PERMISSIONS_V2 } from "../../../../components_v2/utils/utils";
import { ReactComponent as FilterSvg } from '../../../../assets/images/icons/filter-cog.svg';
import CalendarFilterField from "../../../../components_v2/common/calendar-filter-field/calendar-filter-field";
import { DONATION_CURRENCY_LIST, DONATION_PAYMENT_METHOD_LIST, DONATION_TYPE_LIST } from "../../../../utils/utils";
import { MenuButton } from "../../../../components_v2/common/menu-dot/menu-dot";
import CampaignCouponManagerAPI from '../../../../services/campaignCouponManager';

const DonationFilterComponent = (props: any) => {
  const { auth } = useAuth();
  const { t } = useTranslation('language', { keyPrefix: 'donation_manager' });
  const { t: globalTrans } = useTranslation('language');
  const permissions = {
    canCreateDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_CREATE),
    canImportDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_IMPORT),
  }

  const { fields, items, lang, selectedItem, isStatusAll, onStatusChange, onSubmit,
    onRemove, onChange, onSearchFunc, filterValues, onAddDonation, onReserveDonation, area, campaigns, regions } = props;
  const [currency, setCurrency] = useState<any[]>(DONATION_CURRENCY_LIST);
  const [status, setStatus] = useState<any[]>(DONATION_STATUS_LIST.filter((c: any) => c.code !== 'reserved'));
  const [payments, setPayments] = useState<any[]>(DONATION_PAYMENT_METHOD_LIST);
  const [donationTypes, setDonationTypes] = useState<any[]>(DONATION_TYPE_LIST);
  const [rhythms, setRhythms] = useState<any[]>(DONATION_RHYTHM_LIST);
  const [values, setValues] = useState<Map<string, string[]>>();
  const [options, setOptions] = useState<any[]>([]);
  const [isDirty, setDirty] = useState<boolean>(false);
  const [search, setSearch] = useState<any>(filterValues.search);
  const [coupons, setCoupons] = useState<any[]>([]);
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);

  const getFields = () => {
    const resetValues = new Map<string, string[]>();
    fields.forEach((item: string) => {
      resetValues.set(item, []);
    });
    setValues(resetValues);
  };

  /**
   * Get a list of coupons (campaign coupons) for Coupons dropdown 
   */
  const fetchCoupons = async () => {
    const requestBody = { "pageNo": 0, "range": 50, "where": {}, "order": [["coupon", "ASC"]]};
    const response = await CampaignCouponManagerAPI.getCoupons(requestBody);
    if (response?.status === 200 && response?.data?.records?.length) {
      setCoupons(response.data.records);
    }
  }

  const ucFirst = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  useEffect(() => {
    getFields();
    fetchCoupons();
  }, []);

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      if (k === 'dates' && filterValues.where.from && filterValues.where.to) {
        filter.set(k, [filterValues.where.from, filterValues.where.to]);
      } else {
        filter.set(k, filterValues.where[k]);
      }
    });
    setValues(filter);
    setSearch(filterValues.search);
  }, [filterValues]);

  useEffect(() => {
    const list = items.map((c: any, i: number) => {
      return {
        key: i,
        // label: ucFirst(c.name[lang]),
        label: globalTrans(DONATION_STATUS_LIST.find((s: any) => s.code === c.code)?.label || 'status.txt_all'),
        code: c.code,
      };
    });
    setOptions(list);
  }, [items]);

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch('');
    await onRemove();
  };

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
    // await onChange({ field: event.field, value: event.value });
  };

  const onActiveIndex = (selectedItem: any): number => {
    return options.findIndex((c) => c.code === selectedItem);
  };

  const buildActionItems = () => {
    return [
      {
        label: t("txt_add_donation"),
        icon: 'wi-plus',
        hidden: !permissions.canCreateDonation,
        command: (e: any) => {
          onAddDonation();
        }
      },
      {
        label: t("txt_import_donations"),
        icon: 'wi-import',
        url: '/import',
        hidden: !permissions.canImportDonation,
      },
      {
        label: t("txt_resevse_donation"),
        icon: 'wi-reserve',
        hidden: !permissions.canCreateDonation,
        command: (e: any) => {
          onReserveDonation();
        }
      },
    ]
  };

  return (
    <>
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={"pi pi-search"}
              placeholder={t('txt_search')}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={() => onSearchFunc(search)}
              globalValue={search}
            />
            <div className="filter-btn">
              <div
                className="filter-btn-content p-8 ml-16"
                onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg ></FilterSvg>
              </div>
            </div>
          </div>
          {
            permissions.canCreateDonation ?
              <div className="actions-donations">
                <MenuButton label={t("txt_actions")} items={buildActionItems()} />
              </div> : <></>
          }
        </div>
      </div>
      <div className="ml-24 mr-24 mt-12 mb-0">
        <div className="page-type-item">
          <TabMenu
            className="wi-tabmenu-v2"
            model={options}
            activeIndex={onActiveIndex(selectedItem)}
            onTabChange={(e: any) => onStatusChange(e.value)}
          />
        </div>
      </div>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_filter')}</h6>
          </div>
          <div className="filter-wrapper-v2">
            <div className="filter-form-container">
              <div className="filter-elements mb-16">
                <FilterField
                  name="region_id"
                  label={t("txt_region")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("region_id") || []}
                  options={regions}
                  optionLabel={"name.de"}
                  optionValue={"uuid"}
                  visible={values?.has("region_id") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                  filter
                  filterBy="name.de,code"
                  filterPlaceholder={t('txt_search')}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="land_id"
                  label={t("txt_area")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("land_id") || []}
                  options={area}
                  optionLabel={"name.de"}
                  optionValue={"uuid"}
                  visible={values?.has("land_id") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="campaign_id"
                  label={t("txt_campaign")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("campaign_id") || []}
                  options={campaigns}
                  optionLabel={"name.de"}
                  optionValue={"uuid"}
                  visible={values?.has("campaign_id") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                  filter
                  filterBy="name.de,code"
                  filterPlaceholder={t('txt_search')}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="payment_currency_code"
                  label={t("txt_currency")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("payment_currency_code") || []}
                  options={currency}
                  optionLabel={"name.de"}
                  optionValue={"code"}
                  visible={values?.has("payment_currency_code") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="status"
                  label={t("txt_status")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("status") || []}
                  options={status.map((s: any) => ({...s, label: globalTrans(s.label)}))}
                  optionLabel={"label"}
                  optionValue={"code"}
                  visible={isStatusAll}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="payment_method"
                  label={t("txt_payment_method")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("payment_method") || []}
                  options={payments}
                  optionLabel={"name.de"}
                  optionValue={"code"}
                  visible={values?.has("payment_method") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                  filter
                  filterBy="name.de,code"
                  filterPlaceholder={t('txt_search')}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="donation_type"
                  label={t("txt_type")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("donation_type") || []}
                  options={donationTypes}
                  optionLabel={"name.de"}
                  optionValue={"code"}
                  visible={values?.has("donation_type") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="rhythm"
                  label={t("txt_rhythm")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("rhythm") || []}
                  options={rhythms.map((s: any) => ({...s, name: globalTrans(s.name)}))}
                  optionLabel={"name"}
                  optionValue={"code"}
                  visible={values?.has("rhythm") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="subsequent"
                  label={t("txt_subsequent")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("subsequent") || []}
                  options={[{ 'code': 1, 'name': 'All' }, { 'code': 0, 'name': 'Subsequent Only' }]}
                  optionLabel={"name"}
                  optionValue={"code"}
                  visible={values?.has("subsequent") || true}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                />
              </div>
              <div className="filter-elements mb-16">
                <CalendarFilterField
                  name="dates"
                  label={t("txt_dates")}
                  pannelClassName="top-mask-dialog"
                  selectedItems={values?.get("dates") || []}
                  visible={values?.has("dates") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                />
              </div>
              <div className="filter-elements mb-16">
                <FilterField
                  name="coupons"
                  label={t("txt_coupon")}
                  className="wi-filter-option-v2"
                  pannelClassName="overlaypanel-filter-v2"
                  selectedItems={values?.get("coupons") || []}
                  options={coupons}
                  optionLabel={"coupon"}
                  optionValue={"uuid"}
                  visible={values?.has("coupons") || false}
                  isDirty={isDirty}
                  setDirty={setDirty}
                  onChange={onFilterChange}
                  filter
                  filterBy="coupon"
                  filterPlaceholder={t('txt_search')}
                />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-start gap-24 mt-8">
                    <Button
                      className="wi-danger-button-v2 h48 flex-1"
                      type="submit"
                      label={`${t('txt_clear_filter')}`}
                      onClick={() => onRemoveFilters()}
                    />
                    <Button
                      className="wi-primary-button-v2 h48 flex-1"
                      type="submit"
                      label={`${t('txt_apply')}`}
                      onClick={() => onSubmit(values, fields)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar >
    </>
  );
};

export default DonationFilterComponent;

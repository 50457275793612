export const DonationExampleBlock =
{
  uuid: "d202f689-23d5-4c2d-841f-430a8f960812",
  name: "Donation Example",
  code: "donation-example",
  type: "General Blocks",
  type_title: "General BlocksCTA",
  configuration: {
    example: {
      type: 'textFieldCustom',
      label: 'Donation Example',
      isRequired: true,
      supportMultiLanguages: ["de", "en"],
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Donation Example is required"],
        },
        {
          type: "nullable",
          params: [],
        }
      ],
    },
    url: {
      type: "searchTextField",
      label: "URL",
      isRequired: true,
      supportMultiLanguages: [
        "de",
        "en"
      ],
      sourceConfiguration: {
        "type": "internal_urls",
        "fieldName": "url",
        "searchFields": [
          "name"
        ],
        "labelField": "name"
      },
      validationType: "string",
      maxLength: 255,
      validations: [
        {
          "type": "required",
          "params": [
            "URL is required"
          ]
        }
      ]
    },
    description: {
      type: 'richTextEditor',
      label: 'Description ',
      isRequired: true,
      supportMultiLanguages: ["de", "en"],
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Description is required"],
        },
        {
          type: "nullable",
          params: [],
        }
      ],
    },
    
    xssConfiguration: {
      example: 'textFieldCustom',
      description: 'richTextEditor',
      url: 'textField'
    }
  },
  parameters: {},
}


import * as React from "react";
import { Dialog } from "primereact/dialog";
import { Button as PButton } from 'primereact/button';
import "./delete-confirm-dialog.scss";

const WIConfirmDialog = (props: any) => {
  const { visible, onConfirm, onHide, message, classIcon, hideLabel = 'No, keep it',  confirmLabel = 'Yes, delete it', className, maskClassName } = props;

  const buildFooterTemplate = () => (
    <React.Fragment>
      <PButton
        label={hideLabel}
        className={"p-button-text button-hide"}
        onClick={onHide}
      />
      <PButton
        data-testid="btnConfirm"
        label={confirmLabel}
        className="p-button-text button-confirm"
        onClick={onConfirm}
      />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        modal
        header="Warning"
        className={"wi-block-confirm-dialog warning-dialog"  + (className ? ` ${className}` : '')}
        maskClassName={(maskClassName ? ` ${maskClassName}` : '')}
        visible={visible}
        style={{ width: "500px" }}
        footer={buildFooterTemplate}
        onHide={onHide}
      >
        <div className="confirmation-content">
          <i className={classIcon}/>
          { message && (
            <span>
              {message}
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default WIConfirmDialog;

import React, { useRef, useState } from "react";
import { Formik, Form as FormikForm, useFormikContext } from "formik";
import { Button } from "primereact/button";
import "./wi-form.scss";
import * as _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { Messages } from 'primereact/messages';
import { xssFilter } from "../../../../utils/xssFilter";
import { debounce } from "lodash";
import moment from "moment";
import { env } from "../../../../environment";
import { formatImageFileURL, getThumbnailFullpath } from "../../../../utils/mediaUtils";

export function SubmitButton(props: any) {
  const { title, onSubmitData, iconPos, icon, xssConfig, ...rest } = props;
  const { isValid, dirty, values } = useFormikContext();

  const submit = () => {
    xssFilter(values, xssConfig);
    onSubmitData(values);
  };

  return (
    <Button
      className="btn-submit"
      type="submit"
      disabled={!(isValid && dirty)}
      onClick={submit}
      iconPos={iconPos || "left"}
      icon={icon || "pi pi-check"}
      label={title}
    ></Button>
  );
}

const AutoSave = React.memo((props: any) => {
  const { debounceMs, onSubmitData } = props;
  const formik = useFormikContext<any>();
  const [lastSaved, setLastSaved] = React.useState<any>(new Date().toISOString());

  const debouncedSubmit = React.useCallback(
    debounce(() => setLastSaved(new Date().toISOString()),
      debounceMs, {
      maxWait: debounceMs
    }
    ),
    [debounceMs]
  );

  React.useEffect(() => {
    onSubmitData();
  }, [lastSaved]);

  React.useEffect(() => {
    debouncedSubmit();
  }, [formik.values]);

  let result = null;
  if (!!formik.isSubmitting) {
    result = "Saving...";
  } else if (Object.keys(formik.errors).length > 0) {
    result = `ERROR: ${formik.errors.error}`;
  } else if (lastSaved !== null) {
    result = `Last Saved: ${lastSaved}`;
  }
  // return <>{result}</>;
  return <></>;
});

const PageItem = (props: any) => {
  const { option } = props;
  return (
    <div className="global-page-item">
      <div className="global-page-picture">
        {option.picture ? 
        <img 
        src={`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.picture))}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src= `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option.picture)}`;
        }} 
        alt={option.page_name} />
        : ''}
      </div>
      <div className="page-details">
        <div className="global-page-name">{option.page_name}</div>
        <div className="global-page-url">{option.link.de.replace('/de', '')}</div>
        <div className="global-page-description">{option.description?.de}</div>
      </div>
    </div>
  );
}

const ContactItem = (props: any) => {
  const { option } = props;
  return (
    <div className="global-page-item">
      <div className="global-page-picture global-page-picture-circle">
        {option.profile_picture ?
        <img 
        src={`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.profile_picture))}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src= `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option.profile_picture)}`;
        }} 
        alt={option.name} /> 
        : ''}
      </div>
      <div className="page-details">
        <div className="global-page-name">{option.name}</div>
        <div className="global-page-position" dangerouslySetInnerHTML={{ __html: option.position?.de }}></div>
        <div className="global-page-url">{option.email}</div>
        <div className="global-page-phone">{option.phone}</div>
      </div>
    </div>
  );
}

const QuoteItem = (props: any) => {
  const { option } = props;
  return (
    <div className="global-page-item">
      <div className="global-page-picture global-page-picture-circle">
        {option.owner_picture ?
        <img 
        src={`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.owner_picture))}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src= `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option.owner_picture)}`;
        }}  
        alt={option.owner_name} /> 
        : ''}
      </div>
      <div className="page-details">
        <div className="global-page-name">{option.owner_name}</div>
        <div className="global-page-position" dangerouslySetInnerHTML={{ __html: option.owner_position?.de }}></div>
        <div className="global-page-description" dangerouslySetInnerHTML={{ __html: option.quote?.de }}></div>
      </div>
    </div>
  );
}

const PartnerItem = (props: any) => {
  const { option } = props;
  return (
    <div className="global-page-item">
      <div className="global-page-picture">
        {option.partner_logo ?
        <img src={`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.partner_logo))}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src= `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option.partner_logo)}`;
        }}
        alt={option.name} /> 
        : ''}
      </div>
      <div className="page-details">
        <div className="global-page-position">{option.name}</div>
        <div className="global-page-description" dangerouslySetInnerHTML={{ __html: option.description?.de }}></div>
      </div>
    </div>
  );
}

const WIForm = (props: any) => {
  const { onSubmitData, globalConfig, globalContent, submitButtonConfig, xssConfig, initialValues, disabled, isAutoSave, ...rest } = props;
  const [globalValue, selectGlobalValue] = useState<any>();
  const [originalValue, setOriginalValue] = useState<any>();

  const message = useRef(null);

  const processGlobal = (globalValues: any) => {
    let globalValue = _.cloneDeep(globalValues);
    if (Array.isArray(globalValue)) {
      globalValue.forEach((c: any) => {
        c.is_global_ref = true;
        c.global_content_id = c.uuid;
      });
    } else {
      globalValue.is_global_ref = true;
      globalValue.global_content_id = globalValue.uuid;
    }

    return globalValue;
  }

  const updateDefaultValue = (default_value: any, newValue: any) => {
    if (!default_value || !newValue) {
      return;
    }

    let newKeys = Object.keys(newValue);
    let defaultKeys = Object.keys(default_value);
    defaultKeys.forEach(defaultKey => {
      if (!newKeys.includes(defaultKey)) {
        newValue[defaultKey] = default_value[defaultKey];
      }
    });
    newValue.default_value = default_value;
  }

  const onClick = (selectedValue: any, { values, setFieldValue, setValues, setFieldTouched, setTouched }: any) => {
    const globalValues = globalContent[globalConfig?.type].filter((c: any) => selectedValue?.includes(c.uuid));
    const globalValue = processGlobal(globalValues);
    const fieldName = globalConfig?.fieldName;

    globalValue.forEach((item: any) => {
      updateDefaultValue(initialValues.default_value, item);
    });

    if (fieldName) {
      const fieldValue = _.get(values, fieldName);
      if (fieldValue && Array.isArray(fieldValue) && globalValue) {
        const newValues = _.isArray(globalValue) ? globalValue : [globalValue];
        setFieldValue(fieldName, _.concat(...fieldValue, newValues));
      } else if (fieldValue) {
        setFieldValue(fieldName, globalValue && globalValue.length > 0 ? _.cloneDeep(globalValue)[0] : globalValue);
      }
      setFieldTouched(fieldName);
    } else {
      const newValue = globalValue && globalValue.length > 0 ? _.cloneDeep(globalValue)[0] : globalValue;
      setValues(newValue, true);
      Object.keys(newValue).forEach(f => setFieldTouched(f, true));
    }
    // @ts-ignore: Object is possibly 'null'.
    message.current?.show({ severity: 'success', detail: "Item has been added successfully", life: 10000 });
  };

  return (
    <Formik {...props}>
      {(formProps: any) => (
        <React.Fragment>
          <div className="apply-btn">
            <div className="select-group">
              { !disabled &&
                globalConfig &&
                globalConfig.type &&
                globalContent[globalConfig?.type] ? (
                <>
                  <Dropdown
                    panelClassName="global-content-dropdown"
                    value={globalValue}
                    options={globalContent[globalConfig?.type]}
                    showClear={globalConfig.selectType !== "multiple"}
                    scrollHeight={'500px'}
                    itemTemplate={(option: any) => {
                      if (['project_pages', 'press_pages', 'overview_pages'].includes(globalConfig?.type)) {
                        return <PageItem option={option}></PageItem>;
                      } else if (['contacts'].includes(globalConfig?.type)) {
                        return <ContactItem option={option}></ContactItem>;
                      } else if (['quotes'].includes(globalConfig?.type)) {
                        return <QuoteItem option={option}></QuoteItem>;
                      } else if (['partners'].includes(globalConfig?.type)) {
                        return <PartnerItem option={option}></PartnerItem>
                      } else {
                        const label = globalConfig.labelField || "uuid";
                        return <div className="global-item-label" dangerouslySetInnerHTML={{ __html: _.get(option, label) }}></div>
                      }
                    }}
                    valueTemplate={(option: any) => {
                      const label = globalConfig.labelField || "uuid";
                      return <div className="global-item-label" dangerouslySetInnerHTML={{ __html: _.get(option, label) }}></div>
                    }}
                    onChange={(item: any) => {
                      if (globalValue === undefined) {
                        setOriginalValue(formProps.values);
                      }

                      if (globalConfig.selectType !== "multiple") {
                        selectGlobalValue(item?.value);
                      }
                      if (item?.value === undefined) {
                        formProps.setValues(originalValue);
                        return;
                      }
                      onClick(item?.value, formProps);
                    }}
                    optionLabel={
                      globalConfig.labelField || "uuid"
                    }
                    optionValue="uuid"
                    filter
                    filterBy={globalConfig.searchFields?.join(",")}
                  />
                </>
              ) : (
                ""
              )}
            </div>

            {/* <SubmitButton onSubmitData={onSubmitData} xssConfig={xssConfig} /> */}
          </div>
          <Messages ref={message} className="global-message" />
          <AutoSave debounceMs={2000} onSubmitData={() => {
            setTimeout(() => {
              console.log(formProps.errors)
              if (formProps.dirty && formProps.isValid && isAutoSave) {
                xssFilter(formProps.values, xssConfig);
                onSubmitData(formProps.values);
              }
            }, 10)
          }}
          />
          <FormikForm className="needs-validation">
            {props.renderForm(formProps)}
          </FormikForm>

          <div className="footer-panel">
            <SubmitButton onSubmitData={onSubmitData} {...submitButtonConfig} xssConfig={xssConfig} />
          </div>
        </React.Fragment>
      )}
    </Formik>
  );
};

export default WIForm;
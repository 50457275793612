import { useRef, useState } from "react";
import React from "react";
import "./add-goal-form.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { parseStringToInt, showNotification } from "../../../../utils/logic";
import { Dropdown } from "primereact/dropdown";
import { ImportTypeList, TypeLandCode } from "../../../../utils/utils";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { GoalManagerAPI } from "../../../../services";
import WIUploadMediaField from "../../../common/upload/wi-upload-media-field";
import { env } from "../../../../environment";
import _ from "lodash";
import moment from "moment";
import { RichTextEditor } from "../custom-editor-component";
import { formatImageFileURL, getThumbnailFullpath } from "../../../../utils/mediaUtils";
import { Button } from "primereact/button";

const partner_none: any = [
  {
      name: 'NONE',
      uuid: ''
  }
];

const PartnerItem = (props: any) => {
  const { option } = props;
  return (
      <div className="global-page-item">
          <div className="global-page-picture">
              {option.partner_logo ?
              <img 
              src={`${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.partner_logo))}?v=${moment(option.changed).unix()}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src= `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option.partner_logo)}`;
              }}
              alt={option.name} /> : ''}
          </div>
          <div className="page-details">
              <div className="global-page-name">{option.name}</div>
              <div className="global-page-position">{option.code}</div>
          </div>
      </div>
  );
}

const AddGoalFormComponent = (props: any) => {
  const { onHide, fetchCallBack, toast, goalData, regions, campaigns, partners } = props;
  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledPartner, setIsDisabledPartner] = useState(goalData?.goal_type === TypeLandCode.campaign);

  const onAddGoal = async () => {
    try {
        // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setIsLoading(true);
        let valuesData = {
          ...values,
          donation_goal: +values.donation_goal
        };
        delete valuesData.area_selected;

        if(goalData) {
          let updateRes = await GoalManagerAPI.updateGoal(goalData.id, valuesData);
          if (updateRes?.status === 200 && updateRes?.data) {
            showNotification(
              "success",
              "Update goal successfully.",
              toast
            );
            onHide();
  
            fetchCallBack(updateRes.data);
            return;
          }
        } else {
          let createRes = await GoalManagerAPI.generateGoal(valuesData);
          if (createRes?.status === 200 && createRes?.data) {
            showNotification(
              "success",
              "Add a new goal successfully.",
              toast
            );
            onHide();
  
            fetchCallBack(createRes.data);
            return;
          }
        }
      }
      setIsLoading(false);
    } catch (error: any) {
        if(error && error.response.data.errors === 'Duplicate Goal Code') {
          showNotification("error", error.response.data.errors, toast);
        } else {
          showNotification("error", "Check goal failed.", toast);
        }
        setIsLoading(false);
    }
  };

  const initialFormValues = () => {
    let initialGoal = {
      goal_type: TypeLandCode.region,
      source_id: '',
      area_selected: null,
      name: "",
      partner_id: "",
      code: "",
      donation_goal: 0,
      description: "",
      cover_photo: ""
    };

    if(goalData) {
      initialGoal.goal_type = goalData.goal_type || TypeLandCode.region;
      initialGoal.source_id = goalData.source_id ? goalData.source_id : "";
      initialGoal.area_selected = null;
      initialGoal.name = goalData.name ? goalData.name : "";
      initialGoal.partner_id = goalData.partner_id ? goalData.partner_id : "";
      initialGoal.code = goalData.code ? goalData.code : "";
      initialGoal.donation_goal = goalData.donation_goal || 0;
      initialGoal.description = goalData.description ? goalData.description : "";
      initialGoal.cover_photo = goalData.cover_photo ? goalData.cover_photo : "";
    }

    return initialGoal;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().nullable().required("This field is required."),
    source_id: Yup.string().nullable().required("This field is required."),
    code: Yup.string().nullable().required("This field is required."),
    cover_photo: Yup.string().nullable().required("This field is required."),
    description: Yup.string().nullable().required("This field is required."),
    donation_goal: Yup.number().nullable().moreThan(0, "This field must be greater than 0.").required("This field is required.").test('is-valid-amount', 'Goal must be less than available size.', 
    function (value: any) {
       // @ts-ignore: Object is possibly 'null'.
      const available_size = parseInt(formikRef?.current?.values?.area_selected?.available_size) ? parseInt(formikRef?.current?.values?.area_selected?.available_size) : goalData?.available_size;
      return value <= available_size;
    }),
  });

  const formatOptionTemplate = (option: any, available_size: any) => {
    return (
      <div>
        {option.name.de} - Available size [
        {parseInt(available_size)?.toLocaleString("de-DE")} m<sup>2</sup>]{" "}
      </div>
    );
  };

  const selectedOptionTemplate = (
    option: any,
    values: any,
    available_size: any,
    props: any
  ) => {
    if (available_size < values.custom_area) {
      values.donation_type === "land"
        ? (values.land_id = "")
        : (values.campaign_id = "");
      option = null;
    }

    return option ? (
      formatOptionTemplate(option, available_size)
    ) : (
      <span>{props.placeholder}</span>
    );
  };

  const getPartnerList = (partners: any[]) => {
    return partners ? partner_none.concat(_.sortBy(partners, (item) => item.name?.toLowerCase())) : [];
  }

  const formatString = (inputString: string) => {
    const formattedString = inputString.replace(/[^a-zA-Z0-9]/g, '');
    return formattedString.toLowerCase();
  }

  return (
    <>
      <div className="add-goal-form">
        <div className="add-goal-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        {/* <label className="label">Name <span className="required-label"> *</span></label> */}
                        <RichTextEditor
                            className={`${touched.name && errors.name
                                ? "has-error"
                                : ""
                              }`}
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            // maxLength={100}
                            isMultipleLine={true}
                            isRequired={true}
                            header="Name"
                          />
                        {/* <div className={`${touched.name && errors.name ? 'error' : ''}`}>
                            {touched.name && errors.name}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          Type
                          <span className="required-label"> *</span>
                        </label>
                        <SelectButton
                          id="goal_type"
                          name="goal_type"
                          value={values.goal_type}
                          className="wi-selectbutton"
                          options={ImportTypeList}
                          onChange={(item: any) => {
                            if (item.value) {
                              setFieldValue("goal_type", item.value, true);
                              setIsDisabledPartner(item.value === TypeLandCode.campaign);
                              setFieldValue('source_id', null, true);
                            }
                          }}
                          optionLabel="name"
                          optionValue="code"
                          disabled={goalData?.total_area > 0}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          Partner
                        </label>
                          <Dropdown
                            id="partner_id"
                            name="partner_id"
                            className="p-dropdown-custom-search"
                            value={values.partner_id}
                            options={getPartnerList(partners)}
                            scrollHeight={'500px'}
                            filter
                            filterBy={['name', 'code'].join(",")}
                            onChange={(item: any) => {
                              setFieldValue('partner_id', item.value, false);
                            }}
                            itemTemplate={(option) => (<PartnerItem option={option}></PartnerItem>)}
                            optionLabel="name"
                            optionValue="uuid"
                            appendTo="self"
                            placeholder="Choose a partner"
                            disabled={isDisabledPartner || goalData?.total_area > 0}
                        />
                        <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Shows only <b className="helper-bold">active</b> partners
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">
                          {values.goal_type === TypeLandCode.region
                            ? "Region"
                            : "Campaign"}
                          <span className="required-label"> *</span>
                        </label>
                        <Dropdown
                          name="source_id"
                          id="source_id"
                          className="p-dropdown-custom-search"
                          value={values.source_id}
                          onBlur={handleBlur}
                          options={values.goal_type === TypeLandCode.region ? regions : campaigns || []}
                          onChange={(item: any) => {
                            const area_item_selected = values.goal_type === TypeLandCode.region
                              ? regions.find(
                                  (region: any) => region.uuid === item.value
                                )
                              : campaigns.find(
                                  (campaign: any) =>
                                    campaign.uuid === item.value
                                );
                            setFieldValue("source_id", item.value, true);
                            setFieldValue(
                              "area_selected",
                              values.goal_type === TypeLandCode.region
                                ? area_item_selected
                                : area_item_selected.land,
                              true
                            );
                            if(values.goal_type === TypeLandCode.campaign) {
                              setFieldValue("partner_id", area_item_selected.partner_id, true);
                            }
                          }}
                          optionLabel={`name.de`}
                          optionDisabled={(option) => {
                              const available_size = values.goal_type === TypeLandCode.region ?
                                option?.available_size : option?.land?.available_size;
                              return available_size < 0
                            }
                          }
                          itemTemplate={(option) => {
                              const available_size = values.goal_type === TypeLandCode.region ?
                                option?.available_size : option?.land?.available_size;
                              return formatOptionTemplate(
                                option,
                                available_size
                              );
                            }
                          }
                          valueTemplate={(option, props) => {
                              const available_size = values.goal_type === TypeLandCode.region ?
                                  option?.available_size : option?.land?.available_size;
                              return selectedOptionTemplate(
                                option,
                                values,
                                available_size,
                                props
                              );
                            }
                          }
                          optionValue={"uuid"}
                          appendTo="self"
                          placeholder={`Choose ${
                            values.goal_type === TypeLandCode.region
                              ? "a region"
                              : "a campaign"
                          }`}
                          disabled={goalData?.total_area > 0}
                          filter
                          filterBy="name,name.de,code,identifier"
                        />
                        <div
                          className={`${
                            touched.source_id && errors.source_id ? "error" : ""
                          }`}
                        >
                          {`${
                            touched.source_id && errors.source_id
                              ? errors.source_id
                              : ""
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Code <span className="required-label"> *</span></label>
                        <InputText
                          value={values.code}
                          name="code"
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue('code', formatString(value), false);
                          }}
                          onBlur={handleBlur}
                          maxLength={20}
                          disabled={goalData}
                        />
                        <div className={`${touched.code && errors.code ? 'error' : ''}`}>
                            {touched.code && errors.code}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="element-form">
                        <label className="label">Goal (m<sup>2</sup>) <span className="required-label"> *</span></label>
                        <InputNumber
                          value={values.donation_goal}
                          name="donation_goal"
                          onBlur={(e) => {
                              const value = e.target.value ? parseStringToInt(e.target.value) : 0;
                              setFieldValue('donation_goal', value, true);
                              setTimeout(() => {
                                  handleBlur(e);
                              }, 200);
                          }}
                          locale="de-DE" 
                          placeholder="0"
                      />
                        <div className={`${touched.donation_goal && errors.donation_goal ? 'error' : ''}`}>
                            {touched.donation_goal && errors.donation_goal}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        {/* <label className="label">Description <span className="required-label"> *</span></label> */}
                        <RichTextEditor
                            className={`${touched.description && errors.description
                                ? "has-error"
                                : ""
                              }`}
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            // maxLength={400}
                            isMultipleLine={true}
                            isRequired={true}
                            header="Description"
                          />
                        {/* <div className={`${touched.description && errors.description ? 'error' : ''}`}>
                            {touched.description && errors.description}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Picture <span className="required-label"> *</span></label>
                        <WIUploadMediaField
                          type="file"
                          hideLabel={true}
                          classImage="image-cert"
                          className="goal_logo"
                          name="cover_photo"
                          label="Picture"
                          classField="logo-form"
                          require={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-4 mb-2">
                    <Button
                      className="wi-primary-button wi-button-medium"
                      type="submit"
                      label={goalData ? 'Update' : 'Add'}
                      icon={"pi pi-angle-right"}
                      iconPos={"right"}
                      onClick={() => onAddGoal()}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddGoalFormComponent;

import _ from "lodash";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { WIListBox } from "../../../common";

const FilterField = (props: any) => {
  const ref = useRef(null);
  const [items, setItems] = useState<any[]>([]);
  const [selectedValues, setSelectedValues] = useState<any[]>();
  const { name, options, label, visible, optionLabel, optionValue, selectedItems, isDirty, setDirty, className, pannelClassName, onChange } = props;

  useEffect(() => {
    const items = options.map((item: any, index: number) => {
      return { ...item, key: index };
    });
    setItems(items);
  }, [options]);

  useEffect(() => {
    const selected = selectedItems || [];
    setSelectedValues(selected);
    setDirty(false);
  }, [selectedItems, isDirty]);

  const onPanelToggle = (panel: any, event: any) => {
    panel.current.toggle(event);
  };

  const onHandlerChange = (event: any) => {
    onChange({
      field: name,
      value: event.value,
    });
  };

  const generateHeadline = (): string => {
    if (selectedValues && selectedValues.length > 1) {
      return `${label} | ${selectedValues.length} items`;
    } else if (selectedValues && selectedValues.length > 0) {
      const selectedItem = items.find(p => selectedValues.includes(_.get(p, optionValue)));
      return `${label} | ${_.get(selectedItem, optionLabel)}`;
    }
    return label;
  };

  return ( visible ?
    <>
      <Button
        type="button"
        icon="pi pi-plus-circle"
        label={generateHeadline()}
        className={`${className} ${selectedValues?.length && selectedValues?.length  > 0 ? 'filter-active' : ''}` }
        aria-haspopup
        aria-controls="overlay_panel"
        onClick={(e) => onPanelToggle(ref, e)}
      />
      <OverlayPanel
        id="overlay_panel"
        ref={ref}
        showCloseIcon={false}
        appendTo={document.body}
        className={`overlay_panel_wir ${pannelClassName}`}
      >
        <WIListBox
          selectedValues={selectedValues}
          items={items}
          onHandlerChange={onHandlerChange}
          optionLabel={optionLabel}
          optionValue={optionValue}
          style={{ maxHeight: "180px" }}
        />
      </OverlayPanel>
    </>
  :<></>);
};

export default FilterField;

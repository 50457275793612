import { AxiosClient } from './httpClient'

interface SearchCodeParams {
  pageNo: number;
  range: number;
  where?: any;
  order?: any;
}

interface BatchActionParams {
  is_all: boolean;
  selected_ids: string[];
}

export default class CouponDetailAPI {
 
  static getCouponDetail(uuid: string) {
    return AxiosClient.get(`/coupon-batches/${uuid}`);
  }

  static getGeoByBatchId(uuid: any) {
    return AxiosClient.get(`/coupon-batches/geo/${uuid}`);
  }

  static queryBatchCodes(uuid: string, params: SearchCodeParams) {
    return AxiosClient.post(`/coupon-batches/${uuid}/codes`, params);
  }

  static generateReceipt(uuid: string, data: any) {
    return AxiosClient.post(`/coupon-batches/${uuid}/download-receipt`, data);
  }

  static generateCertificate(uuid: string, data: any) {
    return AxiosClient.post(`/coupon-batches/${uuid}/download-certificate`, data);
  }

  static updateBatch(uuid: string, data: any) {
    return AxiosClient.post(`/coupon-batches/${uuid}/update-batch`, data);
  }

  static activeBatchCodes(uuid: string, params: BatchActionParams) {
    return AxiosClient.post(`/coupon-batches/${uuid}/active-codes`, params);
  }

  static deactiveBatchCodes(uuid: string, params: BatchActionParams) {
    return AxiosClient.post(`/coupon-batches/${uuid}/deactive-codes`, params);
  }

  static updateAllStatus(uuid: string, value: any) {
    return AxiosClient.put(`/coupon-batches/${uuid}/update-all-status`, value);
  }

  static updateStatus(uuid: string, value: any) {
    return AxiosClient.put(`/coupon-batches/${uuid}/update-status`, value);
  }

  static importUngivenCodesCSV(csvData: any) {
    return AxiosClient.post(`/codes/import-ungiven-codes-csv`, csvData);
  }

  static importReferenceCodes(csvData: any) {
    return AxiosClient.post(`/codes/import-reference-codes-csv`, csvData);
  }
}

import { ReactComponent as FloatLeftSvg } from '../../assets/images/menu/float-left.svg';
import { ReactComponent as DashSvg } from '../../assets/images/menu/layout-dashboard.svg';
import { ReactComponent as PhotoHeartSvg } from '../../assets/images/menu/photo-heart.svg';
import { ReactComponent as SpeakerPhoneSvg } from '../../assets/images/menu/speakerphone.svg';
import { ReactComponent as MoreSvg } from '../../assets/images/menu/dots-more.svg';
import { ReactComponent as HeartHandsSvg } from '../../assets/images/menu/heart-handshake.svg';
import { ReactComponent as TreesSvg } from '../../assets/images/menu/trees.svg';
import { ReactComponent as UserCogSvg } from '../../assets/images/menu/user-cog.svg';
import { ReactComponent as UserHeartSvg } from '../../assets/images/menu/user-heart.svg';
import { ReactComponent as WorldHertSvg } from '../../assets/images/menu/world-heart.svg';
import { ReactComponent as ClockHeartSvg } from '../../assets/images/menu/clock-heart.svg';
import german from '../../assets/images/flags/germany.svg';
import english from '../../assets/images/flags/english.svg';
import { ReactComponent as TargetSvg } from '../../assets/images/menu/target-arrow.svg';
import { ReactComponent as LanguageSvg } from '../../assets/images/menu/language.svg';
import { ReactComponent as DonationExampleSvg } from '../../assets/images/menu/user-heart.svg';
import { ReactComponent as GlobaSvg } from '../../assets/images/menu/brand-instagram.svg';
import { ReactComponent as ReceiptSvg } from '../../assets/images/menu/receipt.svg';
import { ReactComponent as AreasSvg } from '../../assets/images/icons/map-2.svg';
import { ReactComponent as CodesSvg } from '../../assets/images/icons/qrcode.svg';
import { ReactComponent as HeaderSvg } from '../../assets/images/menu/heading.svg';
import { ReactComponent as FooterSvg } from '../../assets/images/menu/arrow-down.svg';
import { ReactComponent as FAQSvg } from '../../assets/images/menu/question-mark.svg';
import { ReactComponent as PeopleSvg } from '../../assets/images/menu/users-group.svg';
import { ReactComponent as QuotesSvg } from '../../assets/images/menu/quote.svg';
import { ReactComponent as DiscountSvg } from '../../assets/images/menu/discount.svg';
import { ReactComponent as MatchFundingSvg } from '../../assets/images/menu/match-funding.svg';

import React from 'react';

export const WI_TENANT_ID = '2b041d43-444d-11ef-939d-560004d269f8';

export const buildMenuItems = (permissions: any) => [
    {
        label: 'txt_dashboard',
        icon: <DashSvg></DashSvg>,
        url: '/dashboard',
        className: '',
        hidden: !permissions.canViewDashboard
    },
    {
        label: 'txt_donation',
        icon: <TreesSvg></TreesSvg>,
        url: '/donations',
        className: '',
        hidden: !permissions.canViewDonations
    },
    {
        label: 'txt_donor',
        icon: <UserHeartSvg></UserHeartSvg>,
        url: '/donors',
        className: '',
        hidden: !permissions.canViewDonors
    },
    {
        label: 'txt_subscription',
        icon: <ClockHeartSvg></ClockHeartSvg>,
        url: '/subscriptions',
        className: '',
        hidden: !permissions.canViewSubscriptions
    },
    {
        label: 'txt_region',
        icon: <WorldHertSvg></WorldHertSvg>,
        url: '/regions',
        className: '',
        hidden: !permissions.canViewRegions
    },
    {
        label: 'txt_areas',
        icon: <AreasSvg></AreasSvg>,
        url: '/areas',
        className: '',
        hidden: !permissions.canViewAreas
    },
    {
        label: 'txt_campaign',
        icon: <SpeakerPhoneSvg></SpeakerPhoneSvg>,
        url: '/campaigns',
        className: '',
        hidden: !permissions.canViewCampaigns
    },
    {
        label: 'txt_partners',
        icon: <HeartHandsSvg></HeartHandsSvg>,
        url: '/partners',
        className: '',
        hidden: !permissions.canViewPartners
    },
    {
        label: 'txt_cms',
        icon: <FloatLeftSvg></FloatLeftSvg>,
        url: '/cms-pages',
        className: '',
        hidden: !permissions.canViewCMSPages
    },
    {
        label: 'txt_media',
        icon: <PhotoHeartSvg></PhotoHeartSvg>,
        url: '/media',
        className: '',
        hidden: !permissions.canViewMedia
    },
    {
        label: 'txt_user',
        icon: <UserCogSvg></UserCogSvg>,
        url: '/users',
        className: '',
        hidden: !permissions.canViewUsers
    },
    {
        label: 'txt_more',
        icon: <MoreSvg></MoreSvg>,
        url: '/utilities',
        className: '',
        items: [
            {
                label: 'txt_codes',
                icon: <CodesSvg></CodesSvg>,
                url: '/codes',
                className: '',
                hidden: !permissions.canViewCodes
            },
            {
                label: 'txt_coupons',
                icon: <DiscountSvg></DiscountSvg>,
                url: '/coupons',
                className: '',
                hidden: !permissions.canViewCoupons
            },
            {
                label: 'txt_match_fundings',
                icon: <MatchFundingSvg></MatchFundingSvg>,
                url: '/match-funding',
                className: '',
                hidden: !permissions.canViewMatchFundings
            },
            {
                label: 'txt_target',
                icon: <TargetSvg></TargetSvg>,
                url: '/utilities/targets',
                className: '',
                hidden: !permissions.canViewGoals
            },
            // {
            //     label: 'txt_translations',
            //     icon: <LanguageSvg></LanguageSvg>,
            //     url: '/utilities/translations',
            //     className: ''
            // },
            {
                label: 'txt_donation_example',
                icon: <DonationExampleSvg></DonationExampleSvg>,
                url: '/global-contents/donation-examples',
                className: '',
                hidden: !permissions.canViewGlobalContents
            },
            {
                label: 'txt_header',
                icon: <HeaderSvg></HeaderSvg>,
                url: '/global-contents/cms-headers',
                className: '',
                hidden: !permissions.canViewGlobalContents
            },
            {
                label: 'txt_footer',
                icon: <FooterSvg></FooterSvg>,
                url: '/global-contents/cms-footers',
                className: '',
                hidden: !permissions.canViewGlobalContents
            },
            {
                label: 'txt_global_content',
                icon: <GlobaSvg></GlobaSvg>,
                url: '/global-contents/cms-global-blocks',
                className: '',
                hidden: !permissions.canViewGlobalBlocks
            },
            {
                label: 'txt_FAQs',
                icon: <FAQSvg></FAQSvg>,
                url: '/global-contents/faqs',
                className: '',
                hidden: !permissions.canViewGlobalContents
            },
            {
                label: 'txt_people',
                icon: <PeopleSvg></PeopleSvg>,
                url: '/global-contents/people',
                className: '',
                hidden: !permissions.canViewGlobalContents
            },
            {
                label: 'txt_quotes',
                icon: <QuotesSvg></QuotesSvg>,
                url: '/global-contents/cms-quotes',
                className: '',
                hidden: !permissions.canViewGlobalContents
            },
            {
                label: 'txt_manual_receipts',
                icon: <ReceiptSvg></ReceiptSvg>,
                url: '/utilities/manual-receipts',
                className: '',
                hidden: !permissions.canViewUtilities
            }
        ]
    },
    // {
    //     label: 'txt_logout', 
    //     icon: logout,
    //     url: '/codes',
    //     className: 'log-out'
    // }
];

export const LANGUAGE_CODE = {
    ENGLISH: 'en',
    GERMAN: 'de'
}

export const LANGUAGE_LIST = [
    {
        label: 'language.txt_english',
        code: LANGUAGE_CODE.ENGLISH,
        icon: english,
        text: 'english'
    },
    {
        label: 'language.txt_german',
        code: LANGUAGE_CODE.GERMAN,
        icon: german,
        text: 'german'
    }
]

export const USER_PARTIES = {
    WILDERNESS_INTERNATIONAL: 'Wilderness International',
    PARTNER: 'Partner'
}


export const TENANT_TYPES = [
    {
        label: 'roles.txt_admin',
        code: USER_PARTIES.WILDERNESS_INTERNATIONAL
    },
    {
        label: 'roles.txt_partner',
        code: USER_PARTIES.PARTNER
    }
]

export const STATUS_CODE = {
    ACTIVE: 'active',
    NEW: 'new',
    INACTIVE: 'inactive',
    ARCHIVED: 'archived',
    COMPLETED: 'completed',
}

export const SUBSCRIPTION_STATUS_CODE = {
    ACTIVE: 'active',
    PENDING: 'pending',
    FINISHED: 'finished'
}

export const CMS_STATUS_CODE = {
    PUBLISHED: 'published',
    DRAFT: 'draft',
}

export const PARTNER_STATUS_LIST = [
    {
        label: 'status.txt_active',
        code: STATUS_CODE.ACTIVE,
        color: '#34C759',
        background_color: 'rgba(52, 199, 89, 0.10)'
    },
    {
        label: 'status.txt_new',
        code: STATUS_CODE.NEW,
        color: '#007AFF',
        background_color: 'rgba(0, 122, 255, 0.10)'
    },
    {
        label: 'status.txt_inactive',
        code: STATUS_CODE.INACTIVE,
        color: '#FF9500',
        background_color: 'rgba(255, 149, 0, 0.10)'
    },
    {
        label: 'status.txt_archived',
        code: STATUS_CODE.ARCHIVED,
        color: '#3A3A3C',
        background_color: 'rgba(58, 58, 60, 0.10)'
    }
];

export const STATUS_LIST = [
    {
        label: 'status.txt_active',
        code: STATUS_CODE.ACTIVE,
        color: '#34C759',
        background_color: 'rgba(52, 199, 89, 0.10)'
    },
    {
        label: 'status.txt_new',
        code: STATUS_CODE.NEW,
        color: '#007AFF',
        background_color: 'rgba(0, 122, 255, 0.10)'
    },
    {
        label: 'status.txt_inactive',
        code: STATUS_CODE.INACTIVE,
        color: '#FF9500',
        background_color: 'rgba(255, 149, 0, 0.10)'
    },
    {
        label: 'status.txt_archived',
        code: STATUS_CODE.ARCHIVED,
        color: '#3A3A3C',
        background_color: 'rgba(58, 58, 60, 0.10)'
    }
];

export const CMS_STATUS_LIST = [
    {
        label: 'status.txt_published',
        code: CMS_STATUS_CODE.PUBLISHED,
        color: '#34C759',
        background_color: 'rgba(52, 199, 89, 0.10)'
    },
    {
        label: 'status.txt_draft',
        code: CMS_STATUS_CODE.DRAFT,
        color: '#FF9500',
        background_color: 'rgba(255, 149, 0, 0.10)'
    }
];


export const DONATION_STATUS_LIST: any = [
    {
        code: 'completed',
        label: 'status.txt_succeeded',
        name: { de: 'Succeeded', en: 'Succeeded' },
        color: '#34C759',
        background_color: 'rgba(52, 199, 89, 0.10)'
    },
    {
        code: 'draft',
        label: 'status.txt_pending',
        name: { de: 'Pending', en: 'Pending' },
        color: '#30B0C7',
        background_color: 'rgba(48, 176, 199, 0.10)'
    },
    {
        code: 'reserved',
        label: 'status.txt_reserved',
        name: { de: 'Reserved', en: 'Reserved' },
        color: '#007AFF',
        background_color: 'rgba(0, 122, 255, 0.10)'
    },
    {
        code: 'failed',
        label: 'status.txt_failed',
        name: { de: 'Failed', en: 'Failed' },
        color: '#FF3B30',
        background_color: 'rgba(255, 59, 48, 0.1)'
    },
    {
        code: 'cancelled',
        label: 'status.txt_cancelled',
        name: { de: 'Cancelled', en: 'Cancelled' },
         color: '#FF9500',
        background_color: 'rgba(255, 149, 0, 0.10)'
    },
    {
        code: 'refunded',
        label: 'status.txt_refunded',
        name: { de: 'Refunded', en: 'Refunded' },
        color: '#3A3A3C',
        background_color: 'rgba(58, 58, 60, 0.10)'
    }
]

export const STATUS_LIST_2 = [
    {
        label: 'status.txt_active',
        code: STATUS_CODE.ACTIVE,
        color: '#34C759',
        background_color: 'rgba(52, 199, 89, 0.10)'
    },
    {
        label: 'status.txt_new',
        code: STATUS_CODE.NEW,
        color: '#007AFF',
        background_color: 'rgba(0, 122, 255, 0.10)'
    },
    {
        label: 'status.txt_inactive',
        code: STATUS_CODE.INACTIVE,
        color: '#FF9500',
        background_color: 'rgba(255, 149, 0, 0.10)'
    },
    {
        label: 'status.txt_completed',
        code: STATUS_CODE.COMPLETED,
        color: '#30B0C7',
        background_color: 'rgba(48, 176, 199, 0.10)'
    }
];

export const SUBSCRIPTION_STATUS_LIST = [
    {
        label: 'status.txt_active',
        code: SUBSCRIPTION_STATUS_CODE.ACTIVE,
        color: '#34C759',
        background_color: 'rgba(52, 199, 89, 0.10)'
    },
    {
        label: 'status.txt_pending',
        code: SUBSCRIPTION_STATUS_CODE.PENDING,
        color: '#007AFF',
        background_color: 'rgba(0, 122, 255, 0.10)'
    },
    {
        label: 'status.txt_finished',
        code: SUBSCRIPTION_STATUS_CODE.FINISHED,
        color: '#861135',
        background_color: 'rgba(134, 17, 53, 0.10)'
    }
]

export const fetchFromObject = (obj: any, prop: any): any => {
    if (typeof obj === "undefined") return false;
    const index = prop.indexOf(".");
    if (index > -1) {
        return fetchFromObject(
            obj[prop.substring(0, index)],
            prop.substr(index + 1)
        );
    }
    return obj[prop];
};

export const PARTNER_TYPES = {
    SUPPORTER: 'naturschutz-supporter',
    PARTNER: 'naturschutz-partner',
    FUNDRAISER: 'naturschutz-fundraiser',
};

export const PARTNER_TYPE_LIST = [
    {
        label: 'partner_type.txt_naturschutz_partner',
        code: PARTNER_TYPES.PARTNER,
        className: 'wi-tag-cyan'
    },
    {
        label: 'partner_type.txt_naturschutz_supporter',
        code: PARTNER_TYPES.SUPPORTER,
        className: 'wi-tag-cyan'
    },
    {
        label: 'partner_type.txt_naturschutz_fundraiser',
        code: PARTNER_TYPES.FUNDRAISER,
        className: 'wi-tag-cyan'
    }
];

export const CAMPAIGN_TYPES = {
    STANDARD: 'standard'
};

export const CAMPAIGN_TYPE_LIST = [
    {
        label: 'campaign_type.txt_standard',
        code: CAMPAIGN_TYPES.STANDARD
    }
];

export const PARTNER_CATEGORIES = {
    DONOR: 'spenden',
    COOPERATION: 'kooperationspartner',
    SCIENTIFIC: 'wissenschaftliche_partner',
    EDUCATIONAL: 'bildungspartner',
    MATERIAL: 'material',
};

export const PARTNER_CATEGORY_LIST = [
    {
        label: 'partner_category.txt_spenden',
        code: PARTNER_CATEGORIES.DONOR,
        className: 'wi-tag-teal'
    },
    {
        label: 'partner_category.txt_kooperationspartner',
        code: PARTNER_CATEGORIES.COOPERATION,
        className: 'wi-tag-teal'
    },
    {
        label: 'partner_category.txt_wissenschaftliche_partner',
        code: PARTNER_CATEGORIES.SCIENTIFIC,
        className: 'wi-tag-teal'
    },
    {
        label: 'partner_category.txt_bildungspartner',
        code: PARTNER_CATEGORIES.EDUCATIONAL,
        className: 'wi-tag-teal'
    },
    {
        label: 'partner_category.txt_material',
        code: PARTNER_CATEGORIES.MATERIAL,
        className: 'wi-tag-teal'
    }
];

export const AVARTAR_COLORS = [
    '#FF3B30',
    '#FF9500',
    '#FFCC00',
    '#34C759',
    '#00C7BE',
    '#30B0C7',
    '#32ADE6',
    '#007AFF',
    '#5856D6',
    '#AF52DE',
    '#FF2D55',
    '#A2845E'
]

export const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * AVARTAR_COLORS.length);
    return AVARTAR_COLORS[randomIndex];
}

export const TYPE_LOADING = {
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error'
}

export const PERMISSIONS = {
    USER_READ: 'USER_R',
    USER_WRITE: 'USER_W',

    PARTNER_READ: 'PARTNER_R',
    PARTNER_WRITE: 'PARTNER_W',

    CAMPAIGN_READ: 'CAMPAIGN_R',
    CAMPAIGN_WRITE: 'CAMPAIGN_W',
    CAMPAIGN_ADVANCED: 'CAMPAIGN_A',

    REGION_READ: 'REGION_R',
    REGION_WRITE: 'REGION_W',

    AREA_READ: 'AREA_R',
    AREA_WRITE: 'AREA_W',

    DONATION_READ: 'DONATION_R',
    DONATION_WRITE: 'DONATION_W',
    DONATION_ADVANCED: 'DONATION_A',

    DONOR_READ: 'DONOR_R',
    DONOR_WRITE: 'DONOR_W',

    SUBSCRIPTION_READ: 'SUBSCRIPTION_R',
    SUBSCRIPTION_WRITE: 'SUBSCRIPTION_W',

    CODE_READ: 'CODE_R',
    CODE_WRITE: 'CODE_W',

    CMS_PAGE_READ: 'CMS_PAGE_R',
    CMS_PAGE_WRITE: 'CMS_PAGE_W',

    GLOBAL_CONTENT_READ: 'GLOBAL_CONTENT_R',
    GLOBAL_CONTENT_WRITE: 'GLOBAL_CONTENT_W',

    GLOBAL_BLOCK_READ: 'GLOBAL_BLOCK_R',
    GLOBAL_BLOCK_WRITE: 'GLOBAL_BLOCK_W',

    GOAL_READ: 'GOAL_R',
    GOAL_WRITE: 'GOAL_W',

    COUPON_READ: 'COUPON_R',
    COUPON_WRITE: 'COUPON_W',

    WI_RUN_READ: 'WI_RUN_R',
    WI_RUN_WRITE: 'WI_RUN_W',

    MEDIA_READ: 'MEDIA_R',
    MEDIA_WRITE: 'MEDIA_W',

    STATISTIC_READ: 'STATISTIC_R'
}

export const PERMISSIONS_V2 = {
    STATISTIC_VIEW: 'STATISTIC_R',

    USER_VIEW: 'USER_V',
    USER_CREATE: 'USER_C',
    USER_UPDATE: 'USER_U',

    MEDIA_VIEW: 'MEDIA_V',
    MEDIA_CREATE: 'MEDIA_C',
    MEDIA_UPDATE: 'MEDIA_U',

    REGION_VIEW: 'REGION_V',
    REGION_CREATE: 'REGION_C',
    REGION_UPDATE: 'REGION_U',

    AREA_VIEW: 'AREA_V',
    AREA_CREATE: 'AREA_C',
    AREA_UPDATE: 'AREA_U',

    CAMPAIGN_VIEW: 'CAMPAIGN_V',
    CAMPAIGN_CREATE: 'CAMPAIGN_C',
    CAMPAIGN_UPDATE: 'CAMPAIGN_U',

    CAMPAIGN_CREATE_TENANT: 'CAMPAIGN_C_TENANT',
    CAMPAIGN_UPDATE_TENANT: 'CAMPAIGN_U_TENANT',

    PARTNER_VIEW: 'PARTNER_V',
    PARTNER_CREATE: 'PARTNER_C',
    PARTNER_UPDATE: 'PARTNER_U',

    CODE_VIEW: 'CODE_V',
    CODE_CREATE: 'CODE_C',
    CODE_UPDATE: 'CODE_U',

    GOAL_VIEW: 'GOAL_V',
    GOAL_CREATE: 'GOAL_C',
    GOAL_UPDATE: 'GOAL_U',

    COUPON_VIEW: 'COUPON_V',
    COUPON_CREATE: 'COUPON_C',
    COUPON_UPDATE: 'COUPON_U',
    COUPON_CREATE_TENANT: 'COUPON_C_TENANT',
    COUPON_UPDATE_TENANT: 'COUPON_U_TENANT',

    MATCH_FUNDING_VIEW: 'MATCH_FUNDING_V',
    MATCH_FUNDING_CREATE: 'MATCH_FUNDING_C',
    MATCH_FUNDING_UPDATE: 'MATCH_FUNDING_U',
    MATCH_FUNDING_CREATE_TENANT: 'MATCH_FUNDING_C_TENANT',
    MATCH_FUNDING_UPDATE_TENANT: 'MATCH_FUNDING_U_TENANT',

    CMS_PAGE_VIEW: 'CMS_PAGE_V',
    CMS_PAGE_CREATE: 'CMS_PAGE_C',
    CMS_PAGE_UPDATE: 'CMS_PAGE_U',

    CMS_PAGE_CREATE_TENANT: 'CMS_PAGE_C_TENANT',
    CMS_PAGE_UPDATE_TENANT: 'CMS_PAGE_U_TENANT',

    DONOR_VIEW: 'DONOR_V',
    DONOR_CREATE: 'DONOR_C',
    DONOR_UPDATE: 'DONOR_U',

    SUBSCRIPTION_VIEW: 'SUBSCRIPTION_V',
    SUBSCRIPTION_CREATE: 'SUBSCRIPTION_C',
    SUBSCRIPTION_UPDATE: 'SUBSCRIPTION_U',

    DONATION_VIEW: 'DONATION_V',
    DONATION_CREATE: 'DONATION_C',
    DONATION_UPDATE: 'DONATION_U',
    DONATION_IMPORT: 'DONATION_IMPORT',
    DONATION_X: 'DONATION_X',

    UTILITY_VIEW: 'UTILITY_V',
    UTILITY_CREATE: 'UTILITY_C',
    UTILITY_UPDATE: 'UTILITY_U',

    GLOBAL_CONTENT_VIEW: 'GLOBAL_CONTENT_V',
    GLOBAL_CONTENT_CREATE: 'GLOBAL_CONTENT_C',
    GLOBAL_CONTENT_UPDATE: 'GLOBAL_CONTENT_U',

    GLOBAL_BLOCK_VIEW: 'GLOBAL_BLOCK_V',
    GLOBAL_BLOCK_CREATE: 'GLOBAL_BLOCK_C',
    GLOBAL_BLOCK_UPDATE: 'GLOBAL_BLOCK_U',
}

export const PERMISSION_GROUPS = [
    {
        group: 'STATISTIC',
        permissions: [PERMISSIONS.STATISTIC_READ]
    },
    {
        group: 'USERS',
        permissions: [PERMISSIONS.USER_READ, PERMISSIONS.USER_WRITE]
    },
    {
        group: 'DONATIONS',
        permissions: [PERMISSIONS.DONATION_READ, PERMISSIONS.DONATION_WRITE]
    },
    {
        group: 'DONATIONS_ADVANCED',
        permissions: [PERMISSIONS.DONATION_ADVANCED]
    },
    {
        group: 'DONORS',
        permissions: [PERMISSIONS.DONOR_READ, PERMISSIONS.DONOR_WRITE]
    },
    {
        group: 'SUBSCRIPTIONS',
        permissions: [PERMISSIONS.SUBSCRIPTION_READ, PERMISSIONS.SUBSCRIPTION_WRITE]
    },
    {
        group: 'PARTNER',
        permissions: [PERMISSIONS.PARTNER_READ, PERMISSIONS.PARTNER_READ]
    },
    {
        group: 'AREAS',
        permissions: [PERMISSIONS.AREA_READ, PERMISSIONS.AREA_READ]
    },
    {
        group: 'REGIONS',
        permissions: [PERMISSIONS.REGION_READ, PERMISSIONS.REGION_READ]
    },
    {
        group: 'CAMPAIGNS',
        permissions: [PERMISSIONS.CAMPAIGN_READ, PERMISSIONS.CAMPAIGN_READ]
    },
    {
        group: 'CAMPAIGNS_ADVANCED',
        permissions: [PERMISSIONS.CAMPAIGN_ADVANCED]
    },
    {
        group: 'CODES',
        permissions: [PERMISSIONS.CODE_READ, PERMISSIONS.CODE_READ]
    },
    {
        group: 'CMS_PAGES',
        permissions: [PERMISSIONS.CMS_PAGE_READ, PERMISSIONS.CMS_PAGE_READ]
    },
    {
        group: 'MEDIA',
        permissions: [PERMISSIONS.MEDIA_READ, PERMISSIONS.MEDIA_READ]
    },
    {
        group: 'GLOBAL_CONTENT',
        permissions: [PERMISSIONS.GLOBAL_CONTENT_READ, PERMISSIONS.GLOBAL_CONTENT_READ]
    },
    {
        group: 'GLOBAL_BLOCKS',
        permissions: [PERMISSIONS.GLOBAL_BLOCK_READ, PERMISSIONS.GLOBAL_BLOCK_READ]
    },
    {
        group: 'GOALS',
        permissions: [PERMISSIONS.GOAL_READ, PERMISSIONS.GOAL_READ]
    },
    {
        group: 'WI_RUNS',
        permissions: [PERMISSIONS.WI_RUN_READ, PERMISSIONS.WI_RUN_READ]
    }
]

export const LIST_TEMPLATE = [
    {
        name: "Standard Template",
        uuid: "40e8db43-0997-4ed4-947a-7930fbf47539"
    },
    {
        name: "Inclusive 1",
        uuid: "c081b3a0-a771-4252-83ba-51f3bc43c61c"
    },
]

export const fieldsCampaignForm: any = {
    general: ['name', 'headline', 'code', 'region_id', 'headline_description', 'landingpage_url'],
    certificate: ['certificate_campaign_description', 'certificate_animal_image', 'certificate_tree_image', 'certificate_drone_image'],
    email: ['email_template_id', 'email_header_picture', 'email_additional_picture'],
    donationExample: ['donation_example_list'],
    thankyou: ['video_url']
}

export const DONATION_RHYTHM_LIST = [
    {
        'code': 0,
        'name': 'rhythms.txt_once'
    },
    {
        'code': 1,
        'name': 'rhythms.txt_monthly'
    },
    {
        'code': 3,
        'name': 'rhythms.txt_quarterly'
    },
    {
        'code': 6,
        'name': 'rhythms.txt_biannually'
    },
    {
        'code': 12,
        'name': 'rhythms.txt_yearly'
    }
];

export const SUBSCRIPTION_RHYTHM_LIST = [
    {
        'code': 1,
        'name': 'rhythms.txt_monthly'
    },
    {
        'code': 12,
        'name': 'rhythms.txt_yearly'
    }
];

export const HOMEPAGE_URL = '/';

export const MATCH_FUNDING_TYPES = {
    FIXED: 'fixed',
    PERCENTAGE_50: 'percentage_50',
    PERCENTAGE_100: 'percentage_100',
    PERCENTAGE_200: 'percentage_200',
}
export const MATCH_FUNDING_TYPE_LIST = [
    {
        name: "match_fundings.txt_fixed",
        code: MATCH_FUNDING_TYPES.FIXED,
    },
    {
        name: "match_fundings.txt_percentage_50",
        code: MATCH_FUNDING_TYPES.PERCENTAGE_50,
    },
    {
        name: "match_fundings.txt_percentage_100",
        code: MATCH_FUNDING_TYPES.PERCENTAGE_100,
    },
    {
        name: "match_fundings.txt_percentage_200",
        code: MATCH_FUNDING_TYPES.PERCENTAGE_200,
    },
];

export const COMMON_STATUS_LIST = [
    { name: 'status.txt_active', code: 'active' },
    { name: 'status.txt_inactive', code: 'inactive' },
];

export const MATCH_FUNDING_STATUS_LIST = [
    ...COMMON_STATUS_LIST
];

export const COUPON_STATUS_LIST = [
    ...COMMON_STATUS_LIST
];

export const COMMON_CURRENCY = [
    {
        name: 'EUR',
        code: 'eur'
    },
    {
        name: 'CHF',
        code: 'chf',
    },
    {
        name: 'CAD',
        code: 'cad'
    }
];
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import React from "react";
import "./runners-details.scss";
import { MenuDot, WIPaginator, WISearchField } from "../common";
import MenuButton from "../common/menu-button/menu-button";
import Layout from "../layout/layout";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import { WildernessRunsManagerAPI } from "../../services";
import { Sidebar } from 'primereact/sidebar';
import { Toast } from "primereact/toast";
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from "moment";
import UpdateRunnerForm from "./components/update-runner-form/update-runner-form";
import { formatDateByFormatBodyTemplate } from "../common/column-template-table/column-template";
import WITagSponsor from "./components/tag-sponsor/tag-sponsor";
import UpdateSponsorForm from "./components/update-sponsor-form/update-sponsor-form";
import { Link } from "react-router-dom";
import ConfirmPasswordDialog from "../common/confirm-password-dialog/confirm-password-dialog";
import { PASSWORD_SENDMAIL } from "../../utils/utils";
import { showNotification } from "../../utils/logic";

const RunnersDetails = (props: any) => {
  const wildernessRunID = window.location.pathname ? window.location.pathname.split("/")[2] : '';
  const TeamID = window.location.pathname ? window.location.pathname.split("/")[4] : '';
  const runnerID = window.location.pathname ? window.location.pathname.split("/")[6] : '';

  const [runnerData, setRunnerData] = useState<any>();
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isShowSidebarSponsor, setIsShowSidebarSponsor] = useState(false);
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sonpors, setSponsors] = useState({
    data: [],
    totalPage: 5,
  });
  const [paginator, setPaginator] = useState({
    page: 0,
    first: 0,
    rows: 10,
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: 'name',
    sortOrder: -1,
  });
  const [valueSearch, setValueSearch] = useState('');
  const [sponsorSelected, setSponsorSelected] = useState<any>();
  const [isShowPasswordDialog, setIsShowPasswordDialog] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState(false);

  const fetchRunnersDetails = async () => {
    setIsLoading(true);
    try {
        const res = await WildernessRunsManagerAPI.getRunnerDetails(wildernessRunID, TeamID, runnerID);
        if (res && res.status === 200) {
            setRunnerData(res.data.record || null);
          }
          setIsLoading(false);
    } catch (error) {
        setIsLoading(false);
    }
  }

  const fetchSponsorsData = async () => {
    setIsLoading(true);
    const valuesFilter = {
      pageNo: paginator.page,
      range: paginator.rows,
      where: {
        search_name: valueSearch
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === 1 ? "DESC" : "ASC"]
    }
    try {
      const resSponsors = await WildernessRunsManagerAPI.getSponsors(wildernessRunID, TeamID, runnerID, valuesFilter);
      if (resSponsors && resSponsors.status === 200) {
        setSponsors({
          data: resSponsors.data.records,
          totalPage: resSponsors.data.total || 0,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setSponsors({
        data: [],
        totalPage: 0,
      });
      setIsLoading(false);
    }
  };

  const sendMailsDonor = async () => {
    if(password === PASSWORD_SENDMAIL) {
        setErrorPassword(false);
        setIsLoading(true);
        try {
          const resSend = await WildernessRunsManagerAPI.sendMailsDonor(wildernessRunID, TeamID, runnerID, {});
          if (resSend && resSend.status === 200) {
            fetchRunnersDetails();
            showNotification("success", "Sent mail successfully", toast);
          }
          setIsLoading(false);
        } catch (error) {
          showNotification("error", "Failed to send mail", toast);
          setIsLoading(false);
          return;
        }
        onHideConfirmDialog();
    } else {
        setErrorPassword(true);
    }
}

  useEffect(() => {
    if (typeof paginator.page === "number") {
      fetchSponsorsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator, sortConfig]);

  useLayoutEffect(() => {
    Promise.all([
      fetchRunnersDetails(),
      fetchSponsorsData()
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsShowSidebarSponsor(sponsorSelected ? true : false);
  }, [sponsorSelected])

  const onBasicPageChange = (event: any) => {
    setPaginator({
      page: event.page,
      first: event.first,
      rows: event.rows,
    });
  };

  const onHideConfirmDialog = () => {
      setPassword("");
      setIsShowPasswordDialog(false);
      setErrorPassword(false);
    }
  const onSearchFunc = () => {
    fetchSponsorsData();
  }
  
  const actionItems: any = [
    { 
      label: 'Edit Runner',
      icon: 'fa-solid fa-pen',
      description: "Edit Runner Details",
      command: () => setIsShowSidebar(true),
    },
    // { 
    //   label: 'Send All Donor Certificates',
    //   icon: 'fa-solid fa-paper-plane',
    //   description: "Send all donor certificates to all donor leaders via emails",
    //   disabled: runnerData?.donors_certificate_sent_at,
    //   command: () => setIsShowPasswordDialog(true),
    // }
  ];
  
  const buildActionMenu = () => {
    return actionItems;
  };

  const formatAmountBodyTemplate = (rowData: any, field: string) => {
    return <div><span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData[field]?.toString())?.toLocaleString("de-DE") : '-'}</span></div>;
  };

  const formatAchievedBodyTemplate = (rowData: any) => {
    if(runnerData) {
      return <div><span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat((rowData?.expected_money_per_units * runnerData?.archived_units)?.toString())?.toLocaleString("de-DE") : '-'}</span></div>;
    }
  };

  const formDate = (value: any) => {
    if (value) {
        const date = moment(new Date(value), 'DD.MM.YYYY HH:mm:ss').utc().add(1, 'hour').format("DD.MM.YYYY HH:mm:ss");
        return date.replace("01:00:00", "00:00:00");
    }
    return '-';
}

  const tableColumns = [
    {
      field: "name",
      header: "Name",
      width: "calc(20% - 50px)",
      align: "left",
    },
    {
      field: "payment_email",
      header: "Email",
      width: "23%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return <span>{rowData?.payment_email?.toLowerCase() || '-'}</span>
      },
    },
    {
      field: "expected_money_per_units",
      header: "EA/Unit (€)",
      width: "10%",
      align: "left",
      bodyTemPlate: (rowData: any) => formatAmountBodyTemplate(rowData, "expected_money_per_units"),
    },
    {
      field: "",
      header: "EA (€)",
      width: "10%",
      align: "left",
      bodyTemPlate: (rowData: any) => formatAchievedBodyTemplate(rowData),
    },
    {
      field: "is_paid",
      header: "Status",
      width: "10%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return <WITagSponsor value={rowData?.is_paid === 1 ? 'Paid' : 'Unpaid' || 'Unpaid'}></WITagSponsor>
      },
      disabledSort: true
    },
    {
      field: "actual_paid_money",
      header: "APA (€)",
      width: "12%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return <div><span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{(rowData && rowData.is_paid === 1) ? parseFloat(rowData.actual_paid_money?.toString())?.toLocaleString("de-DE") : '-'}</span></div>
      },
    },
    {
      field: "paid_date",
      header: "Paid Date",
      width: "15%",
      align: "left",
      bodyTemPlate: formatDateByFormatBodyTemplate("MMM DD, YYYY"), 
    }
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
    {
        label: "Edit Sponsor",
        description: "Edit Sponsor details",
        icon: "fa-solid fa-pen",
        command: () => {
          setSponsorSelected(rowData);
        },
        disabled: (rowData?.is_paid === 1)
    },
    {
        label: "Open Associated Donation",
        description: "Open the associated donation detail when it is already paid",
        icon: "fa-solid fa-arrow-up-right-from-square",
        url: `/donations/${rowData?.donation_id}`,
        disabled: (rowData?.is_paid === 0 || (rowData?.is_paid === 1 && !rowData?.donation_id))
    }];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
}

  return (
    <Layout>
      <Toast ref={toast} />
      {
        isLoading && (
          <div className="loading-component">
            <ProgressSpinner />
          </div>
        )
      }
      <div className="runners-detail-page">
        <div className="runners-detail-container">
          <div className="runners-detail-header">
            <div className="text">
              <p className="name" style={{paddingRight: '1px'}}>
                <Link className="name" style={{paddingRight: '1px'}} to={`/wilderness-runs/${wildernessRunID}`}>{runnerData?.run?.name}</Link>
              </p> 
              <p className="name" style={{fontSize: '16px', padding: '4px 10px'}}> <i className="fa-solid fa-chevron-right"></i></p>
              <p className="name" style={{paddingRight: '1px'}}>
                <Link className="name" style={{paddingRight: '1px'}} to={`/wilderness-runs/${wildernessRunID}/teams/${runnerData?.team?.id}`}>{runnerData?.team?.name}</Link>
              </p> 
              <p className="name" style={{fontSize: '16px', padding: '4px 10px'}}> <i className="fa-solid fa-chevron-right"></i></p>
              <p className="name"> {`${runnerData?.first_name || ''} ${runnerData?.last_name || ''}` || '-'} </p>
            </div>
            <div className="actions">
              <MenuButton label="Actions" items={buildActionMenu()}></MenuButton>
            </div>
          </div>
          <div className="runners-info">
            <div className="runners-info-container">
              <div className="headline">
                <label className="label">Runner Information</label>
              </div>
              <div className="content">
                <div className="row content-row">
                  <div className="col-md-3">
                      <label className="label">Email</label>
                  </div>
                  <div className="col-md-9">
                  <p className="text">{ runnerData?.email || '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                      <label className="label">Achieved </label>
                  </div>
                  <div className="col-md-9">
                      <p className="text">{ runnerData?.archived_units ? `${parseFloat(runnerData?.archived_units?.toString())?.toLocaleString("de-DE")} units - ${((runnerData?.archived_units * runnerData?.run?.unit_length)/1000).toLocaleString("de-DE")} km` : '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                      <label className="label">Sponsors</label>
                  </div>
                  <div className="col-md-9">
                  <p className="text">{ runnerData?.total_sponsors ? parseFloat(runnerData?.total_sponsors?.toString())?.toLocaleString("de-DE") : '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                      <label className="label">Expected amount (EA)</label>
                  </div>
                  <div className="col-md-9">
                      <p className="text">{ runnerData?.expected_money_per_units ? `${parseFloat((runnerData?.expected_money_per_units * runnerData?.archived_units)?.toString())?.toLocaleString("de-DE")} €`: '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                      <label className="label">Actual paid amount (APA)</label>
                  </div>
                  <div className="col-md-9">
                      <p className="text">{ runnerData?.actual_paid_money ? `${ parseFloat(runnerData?.actual_paid_money?.toString())?.toLocaleString("de-DE")} €`: '-'} </p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                      <label className="label">Certificates sent</label>
                  </div>
                  <div className="col-md-9">
                      <p className="text">{runnerData?.certificate_sent_at ? formDate(runnerData?.certificate_sent_at) : '-'}</p>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-md-3">
                      <label className="label">All Donors certificates sent</label>
                  </div>
                  <div className="col-md-9">
                      <p className="text">{runnerData?.donors_certificate_sent_at ? formDate(runnerData?.donors_certificate_sent_at) : '-'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="runners-info">
            <div className="runners-info-container">
              <div className="headline">
                <label className="label">Sponsors</label>
                <div className="runners-header-panel">
                  <WISearchField
                      icon={"pi pi-search"}
                      placeholder="Search for anything"
                      setSearch={(value: any) => setValueSearch(value.global.value)}
                      enterSearch={() => onSearchFunc()}
                      globalValue={valueSearch}
                  />
                  </div>
              </div>
              <div className="runners-table">
                <DataTable 
                    value={sonpors.data}
                    scrollable
                    dataKey="id"
                    scrollHeight="calc(100vh - 166px)"
                    scrollDirection="both"
                    lazy
                    onSort={(e: any) => {
                      setSortConfig({
                        sortField: e.sortField,
                        sortOrder: e.sortOrder,
                      });
                    }}
                    sortField={sortConfig.sortField}
                    sortOrder={sortConfig.sortOrder}
                >
                    {dynamicColumns}
                    <Column
                        frozen
                        alignFrozen="right"
                        style={{ width: "50px" }}
                        body={buildMenu}
                    ></Column>
                </DataTable>
                <WIPaginator
                  first={paginator.first}
                  rows={paginator.rows}
                  totalRecords={sonpors.totalPage}
                  onPageChange={onBasicPageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <Sidebar 
          visible={isShowSidebar} 
          position="right" 
          className="p-sidebar-md add-donor-sibar-right" 
          style={{width:'30em'}}
          onHide={() => setIsShowSidebar(false)}>
            <div className="add-donor-sidebar-content">
              <div className="headline">
                <h6>Update Runner</h6>
              </div>
              <UpdateRunnerForm
                fetchCallBack={(() => fetchRunnersDetails())}
                toast={toast}
                onHide={() => setIsShowSidebar(false)}
                runnerData={runnerData}
                wildernessRunID={wildernessRunID}
                teamsID={TeamID}
              />
            </div>
          </Sidebar>
          <Sidebar 
            visible={isShowSidebarSponsor} 
            position="right" 
            className="p-sidebar-md add-donor-sibar-right" 
            style={{width:'30em'}}
            onHide={() => {
              setSponsorSelected(null);
            }}>
              <div className="add-donor-sidebar-content">
                <div className="headline">
                  <h6>Update Sponsor</h6>
                </div>
                <UpdateSponsorForm
                  fetchCallBack={(() => {
                    fetchSponsorsData();
                    fetchRunnersDetails();
                  })}
                  toast={toast}
                  onHide={() => {
                    setSponsorSelected(null);
                  }}
                  wildernessRunID={wildernessRunID}
                  teamsID={TeamID}
                  sponsorSelected={sponsorSelected}
                  runnerID={runnerID}
                />
              </div>
            </Sidebar>
            <ConfirmPasswordDialog 
                visible={isShowPasswordDialog}
                onHide={() => onHideConfirmDialog()}
                onConfirm={() => sendMailsDonor()}
                password={password}
                onSetPassword={(value: any) => setPassword(value)}
                errorPassword={errorPassword}
            />
      </div>
    </Layout>
  )};

export default RunnersDetails;

import * as React from 'react';
import { useState } from 'react';
import { LANGUAGE_MODE_OPTIONS, SCREEN_MODE_OPTIONS, TabViewOptions } from '../../utils';
import "./builder-header.scss";
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { env } from '../../../../environment';

export const ScreenModePanel = (props: any) => {

    const { onChange } = props;
    const [modes, setModes] = useState([...SCREEN_MODE_OPTIONS]);

    const onSelectMode = (mode: any) => {
        modes.forEach((m: any) => m.isSelected = false);
        mode.isSelected = true;
        setModes([...modes]);
        onChange(mode);
    };

    const formatModeButton = (mode: any, index: number) => {
        return (
            <i
                title={mode.title}
                key={`mode_${index}`}
                className={['mode-item', mode.icon, `${mode.isSelected && 'mode-active'}`].join(' ')}
                onClick={(e: any) => onSelectMode(mode)}
            ></i>
        );
    }

    return (
        <div className="screen-mode">
            {modes.map((m, i) => formatModeButton(m, i))}
        </div>
    )
}

export const LanguageModePanel = (props: any) => {

    const { onChange } = props;
    const [modes, setModes] = useState([...LANGUAGE_MODE_OPTIONS]);

    const onSelectMode = (mode: any) => {
        modes.forEach((m: any) => m.isSelected = false);
        mode.isSelected = true;
        setModes([...modes]);
        onChange(mode);
    };

    const formatModeButton = (mode: any, index: number) => {
        return (
            <span
                title={mode.title}
                key={`lang_${index}`}
                className={`lang-item ${mode.cssClassName} ${mode.isSelected && "mode-active"}`}
                onClick={(e) => onSelectMode(mode)}
            >
                <img src={mode.value} alt="icon-lang" className="icon-lang" />
            </span>
        );
    }

    return <>{modes.map((m, i) => formatModeButton(m, i))}</>
}

interface BuilderSettings {
    zoomLevel: number;
    isFullScreen: boolean;
    layersVisible: boolean;
    activeTabView: number;
    maxZoom: number;
    minZoom: number;
}

interface BuilderHeaderProps {
    onLangModeChange?: any;
    onScreenModeChange?: any;
    onSave?: any;
    onPublish?: any;
    onZoom?: any;
    onBack?: any;
    fullUrl?: string;
    toggleFullScreen?: any;
    setLayersVisible?: any;
    setActiveTabView?: any;
    settings: BuilderSettings;
}

const BuilderHeader = (props: BuilderHeaderProps) => {
    const onZoomChange = (cal: string) => {
        if (props.settings.isFullScreen) {
            return;
        }

        let zoom = props.settings.zoomLevel;
        if (cal === "in") {
            if (zoom >= props.settings.maxZoom) {
                return;
            }
            zoom = Math.min(Number((zoom + 0.1).toFixed(2)), props.settings.maxZoom);

        } else {
            if (zoom <= props.settings.minZoom) {
                return;
            }

            zoom = Math.max(Number((zoom - 0.1).toFixed(2)), props.settings.minZoom);
        }

        if (props.onZoom) {
            props.onZoom(zoom);
        }
    }

    const formatSelectTemplate = (option: any) => {
        return <i title={option.title} className={`${option.icon}`}></i>;
    };

    return (
        <div className="header-wrapper">
            <ScreenModePanel onChange={props.onScreenModeChange} />
            <div className="btn-panel">
                {/* <LanguageModePanel onChange={props.onLangModeChange} /> */}
                <i
                    title="Zoom In"
                    className={`mode-item zoom-btn fa-solid fa-magnifying-glass-plus ${props.settings.zoomLevel >= props.settings.maxZoom || props.settings.isFullScreen ? 'disable-icon' : ''}`}
                    onClick={() => onZoomChange("in")}
                ></i>
                <i
                    title="Zoom Out"
                    className={`mode-item zoom-btn fa-solid fa-magnifying-glass-minus ${props.settings.zoomLevel <= props.settings.minZoom || props.settings.isFullScreen ? 'disable-icon' : ''}`}
                    onClick={() => onZoomChange("out")}
                ></i>
                <i
                    title="Fullscreen"
                    className={`mode-item fa-solid ${props.settings.isFullScreen ? 'fa-compress' : 'fa-expand'}`}
                    onClick={(e) => props.toggleFullScreen(e)}
                ></i>
            </div>
            <div className="bg-panel" style={{ flex: 1 }}>
                {/* <img className="bg-header" src={Natural} alt="logo-footer" /> */}
            </div>
            <div className="btn-panel">
                {/* <i
                    title="Save Page"
                    className={`mode-item fa-solid fa-floppy-disk`}
                    onClick={(e) => props.onSave(e)}
                ></i> */}
                {/* <Button className="wi-btn"
                    icon="pi pi-chevron-right"
                    label="Back to settings"
                    iconPos="right">
                </Button> */}
                <Button className="wi-btn wi-btn-back"
                    icon="pi pi-chevron-left"
                    label="Edit Metadata"
                    iconPos="left"
                    onClick={(e) => props.onBack(e)}>
                </Button>
                <Button className="wi-btn"
                    icon="pi pi-chevron-right"
                    label="Save Draft"
                    iconPos="right"
                    onClick={(e) => props.onSave(e)}>
                </Button>
                <Button className="wi-btn wi-btn-published"
                    icon="pi pi-chevron-right"
                    label="Publish"
                    iconPos="right"
                    onClick={(e) => props.onPublish(e)}>
                </Button>
                <a href={`${env.CMS_URL}${props.fullUrl}` || '#'} target="_blank">
                    <i
                        title="Open Published Page"
                        className={`mode-item fa-solid fa-eye`}
                    ></i>
                </a>
                <i
                    title="Layers"
                    className={`mode-item fa-solid fa-layer-group ${props.settings.layersVisible && 'mode-active'}`}
                    onClick={(e) => props.setLayersVisible(!props.settings.layersVisible)}
                ></i>
            </div>

            <SelectButton
                className="tab-menu"
                value={props.settings.activeTabView}
                options={TabViewOptions}
                onChange={(e) => props.setActiveTabView(e.value)}
                itemTemplate={formatSelectTemplate}
                optionLabel="value"
            />
        </div>
    )
};

export default BuilderHeader;
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import "./download-certificate-dialog.scss";
import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import * as Yup from "yup";
import {
  generateLinkDownLoad,
  showNotification,
} from "../../../../utils/logic";
import CouponDetailAPI from "../../../../services/couponDetail";
import { SelectButton } from "primereact/selectbutton";
import { LanguageCode, LanguageDisplayList, getCO2Factor } from "../../../../utils/utils";

const DownloadCertificateDialogComponent = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    visible,
    onHide,
    partner,
    occasion,
    date,
    id,
    land,
    area,
    quantity,
    name,
    geoCoordinate,
    description,
    certificate_logo,
    currencyUnit
  } = props;

  const toast = useRef(null);
  const formikRef = useRef(null);
  const [languageCode, setLanguageCode] = useState(LanguageCode.DE);

  const CERTIFICATE_DEFAULT_VALUE = {
    name: "",
    occasion: "",
    date: moment().toDate(),
    land: {},
    area: 1,
    amount_kg_co2: 1,
    geoCoordinate: ""
  };

  const initialReceiptFormValues = () => {
    let initialReceipt = CERTIFICATE_DEFAULT_VALUE;
    initialReceipt.name = partner ? partner.name : "";
    initialReceipt.date = date ? moment(date).toDate() : moment().toDate();
    initialReceipt.land = land;
    initialReceipt.area = area * quantity;
    initialReceipt.amount_kg_co2 = getCO2Factor(land, currencyUnit) * (area * quantity);
    initialReceipt.geoCoordinate = geoCoordinate
    return initialReceipt;
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required."),
    date: Yup.string().required("This field is required."),
  });

  const generateCertificate = async (values: any) => {
    if (values) {
      const data = {
        ...values,
        // @ts-ignore: Object is possibly 'null'.
        date: moment
          .utc(moment(new Date(values.date)).format("MM/DD/YYYY HH:mm:ss"))
          .toISOString(),
      };

      data.language_code = languageCode;
      data.description = description;
      data.certificate_logo = certificate_logo;
      try {
        setIsLoading(true);
        let response: any;
        if (id) {
          response = await CouponDetailAPI.generateCertificate(id, data);
          if (response && response.status === 200) {
            const fileName =
              languageCode === LanguageCode.DE
                ? "Urkunde - " + name
                : "Certificate - " + name;
            generateFileDownload(`${fileName}.pdf`, response.data.content);
            showNotification("success", "Generate successfully", toast);
          } else {
            showNotification("error", "Generate failed", toast);
          }
          setIsLoading(false);
          onHide();
        }
      } catch (error) {
        showNotification("error", "Generate failed", toast);
        setIsLoading(false);
      }
    }
  };

  const generateFileDownload = (filename: string, data: any) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const url = URL.createObjectURL(
      new Blob([byteArray], { type: "application/pdf; charset=utf-8" })
    );
    generateLinkDownLoad(filename, url);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Download Certificate`}
        visible={visible}
        style={{ width: "460px" }}
        modal
        className="wi-dialog"
        onHide={onHide}
      >
        <div className="codes-certificate-form">
          <div className="certificate-form-container">
            <Formik
              innerRef={formikRef}
              initialValues={initialReceiptFormValues()}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                //console.log("values >>>", values);
                generateCertificate(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                isValid,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleChange,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row-content">
                    <div className="row">
                      <div className="col-md-12 language-container">
                        <div className="element-form">
                          <label className="label">Choose language</label>
                          <SelectButton
                            className="wi-selectbutton"
                            name="language_option"
                            value={languageCode}
                            options={LanguageDisplayList}
                            onChange={(item: any) => {
                              if (item.value) {
                                setLanguageCode(item.value);
                              }
                            }}
                            optionLabel="name"
                            optionValue="code"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">
                            Certificate Name <span className="asterik">*</span>
                          </label>
                          <InputText
                            value={values.name}
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            maxLength={40}
                          />
                          <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Max 40
                            letters
                          </div>
                          <div
                            className={`${
                              touched.name && errors.name ? "error" : ""
                            }`}
                          >
                            {touched.name && errors.name}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">
                            Certificate date <span className="asterik">*</span>
                          </label>
                          <Calendar
                            id="basic"
                            name="date"
                            panelClassName="expire-calendar"
                            value={values.date}
                            onBlur={handleBlur}
                            onChange={(item) => {
                              setFieldValue("date", item.value, false);
                              console.log(isValid);
                            }}
                            dateFormat="dd.mm.yy"
                          />
                          <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Date on
                            the certificate, it should be the date that partner
                            made the donation.
                          </div>
                          <div
                            className={`${
                              touched.date && errors.date ? "error" : ""
                            }`}
                          >
                            {touched.date && errors.date
                              ? "This field is invalid"
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="element-form">
                          <label className="label">Certificate occasion</label>
                          <InputText
                            value={values.occasion}
                            name="occasion"
                            onChange={handleChange}
                            maxLength={50}
                          />
                          <div className="helper-text">
                            <i className="fa-solid fa-circle-info"></i> Max 50
                            letters
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-4 mb-2">
                      <Button
                        type="submit"
                        label={"Download"}
                        icon="pi pi-download"
                        disabled={!isValid}
                        iconPos="right"
                        loading={isLoading}
                        className="wi-primary-button wi-button-medium"
                      />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DownloadCertificateDialogComponent;

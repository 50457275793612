import { ErrorMessage, useFormikContext } from "formik";
import React from "react";
import { useState, useEffect } from "react";
import { WIFormElement } from "../wi-form-element/wi-form-element";
import "./wi-selectcolor-v2.scss";
import * as _ from "lodash";
import { Dropdown } from "primereact/dropdown";

export function SelectColorV2(props: any) {
  const { name, values, options } = props;
  const [option, setOption] = useState(_.get(values, name));
  const { setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (option) {
      setFieldValue(name, option, false);
    }
  }, [option]);

  const itemTemplate = (option: any) => {
    if (option) {
      return (
        <div className="border-content-item" style={{ marginLeft: 10 }}>
          <div
            className="content-item-color"
            style={{ backgroundColor: option.label }}
          ></div>
          <div style={{ fontSize: 15 }}>{option.label}</div>
        </div>
      );
    }
    return <div className="border-content-item" style={{ marginLeft: 10, height: 25 }}>Default</div>
  };

  const onItemChange = (e: any) => {
    return !e.value ? setOption(option) : setOption(e.value);
  };

  return (
    <WIFormElement {...props}>
      <Dropdown
        id={name}
        name={name}
        onBlur={() => setFieldTouched(name)}
        value={option}
        options={options}
        onChange={onItemChange}
        className="wi-form-selectColor-v2"
        itemTemplate={itemTemplate}
        valueTemplate={itemTemplate}
        appendTo="self"
      />
      <ErrorMessage
        name={name}
        render={(msg: any) => <div style={{ color: "red" }}>{msg}</div>}
      />
    </WIFormElement>
  );
}

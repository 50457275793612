import { useLocation, Navigate, Outlet } from "react-router-dom";
import React from 'react';
import useAuth from "./useAuth";

const AuthPartyPage = ({ children, allowedParties }: any) => {
    const { auth } = useAuth();
    const location = useLocation();

    if (auth) {
        const isPartyAuthorized = allowedParties.find((party: string) => auth?.party?.includes(party));
        if (!!isPartyAuthorized) {
            return children;
        }
    }

    return <Navigate to="/" state={{ from: location }} replace />;
}

export default AuthPartyPage;
import * as React from "react"; 
import "./preview-form.scss";
import PreViewFormUtil from "./preview-form.util";
import WIUploadMediaField from "../upload/wi-upload-media-field"; 

const PreviewForm = (props: any) => {
  const { values, language, imageTypes } = props;
  const [content, setContent] = React.useState<any>(null);

  const getContent = () => {
    const _content = PreViewFormUtil.getContent(language);
    setContent(_content);
  };

  React.useEffect(() => {
    getContent();
  }, [language]);

  return content ? (
    <div className="a4 preview-inclusive1-form">
      <div className="pdf-certificate">
        <div className="pdf-header">
          <div className="pdf-header-section-right">
            <WIUploadMediaField
              type="file"
              name="template_data_banner1"
              label="Certificate Banner"
              required={true}
              classField={"drone-image"}
              classImage={"banner-cert"}
              fieldName={"Banner"}
              hideHintText={true}
              isMiddle={true}
              hideLabel={true}
              imageTypes={imageTypes}
            />
          </div>
        </div>
        <div className="pdf-body">
          <div className="pdf-body-section-center">
            <p className="pdf-title">{content.primary_headline}</p>
            <p className="pdf-title-second">{content.secondary_headline}</p>
            <div className="pdf-donor">
              <p className="pdf-donor-title">{content.donor_title}</p>
              <p className="pdf-donor-fullname">{content.donor_fullname}</p>
              <p className="donation-serial" style={{ margin: "0px" }}>
                {values.certificate_reason_description[language]}
              </p>
              <p className="donation-serial" style={{ margin: "0px" }}>
                {content.donation_serial}
              </p>
              <p className="donation-date" style={{ position: "relative" }}>
                {content.donation_date}
              </p>
            </div>
            <div className="container">
              <div className="row" style={{ height: "25px" }}>
                <div className="col-md-6" style={{ paddingRight: "5px" }}>
                  <p className="geo-coordinate" style={{ float: "right" }}>
                    S12°52'13.6236
                  </p>
                </div>
                <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                  <p className="geo-coordinate" style={{ float: "left" }}>
                    W69°30'47.4012
                  </p>
                </div>
              </div>
            </div>
            <p className="pdf-description">
              {content.geo_coordinate_description}
            </p>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="pdf-donor-area">
                    <p
                      className="pdf-area-amount"
                      dangerouslySetInnerHTML={{ __html: content.area_amount }}
                    ></p>
                    <p className="pdf-area-title">{values.forest_type ? values.forest_type[language] :content.area_title}</p>
                    <p className="pdf-area-title-second">
                    {content.area_description}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pdf-co2">
                    <p
                      className="pdf-co2-amount"
                      dangerouslySetInnerHTML={{ __html: content.co2_amount + " " + content.co2_title  }}
                    ></p>
                    <p className="pdf-co2-title-second">
                      {" "}
                      {content.co2_description}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-content" style={{marginTop: -10}}>
              <div className="col-md-6">
                <div className="pdf-donor">
                  <div
                    style={{
                      width: "154px",
                      height: "82px",
                      marginLeft: 180,
                    }}
                  >
                    <WIUploadMediaField
                      type="file"
                      name="signature"
                      fieldName="Image"
                      classImage={"image-cert"}
                      classField="campaign-logo"
                      hideLabel={true}
                      imageTypes={imageTypes}
                    />
                    <p
                      className="pdf-donor-title-sub"
                      style={{
                        marginTop: "-2px",
                        zIndex: 1,
                      }}
                    >
                      {values.signature_name?.de}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pdf-donor">
                  <div
                    style={{
                      width: "154px",
                      height: "82px",
                      marginLeft: 15,
                    }}
                  >
                    <WIUploadMediaField
                      type="file"
                      name="signature_second"
                      fieldName="Image"
                      classImage={"image-cert"}
                      classField="campaign-logo"
                      hideLabel={true}
                      imageTypes={imageTypes}
                    />
                    <p
                      className="pdf-donor-title-sub"
                      style={{
                        marginTop: "-2px",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      {values.signature_name_second?.de}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pdf-footer">
            <div className="row row-content">
              <div className="col-md-3">
                {/* <LogoTemplate /> */}
                <div className="illustration_section">
                  <div className="qr-code-image">
                    <WIUploadMediaField
                      type="file"
                      name="template_data_illustration_logo"
                      label="Certificate QR Code"
                      fieldName={"Illustration"}
                      classImage={"image-cert"}
                      classField={"left-image"}
                      isNotCenter={true}
                      hideLabel={true}
                      imageTypes={imageTypes}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="wi-logo-section">
                  <div className="qr-code-image">
                    <WIUploadMediaField
                      type="file"
                      name="template_data_wi_logo"
                      label="Certificate Wi Logo"
                      fieldName={"Wi logo"}
                      classImage={"image-cert"}
                      classField={"center-image"}
                      hideLabel={true}
                      imageTypes={imageTypes}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="campaign-logo-section">
                  <div className="qr-code-image">
                    <WIUploadMediaField
                      type="file"
                      name="campaign_logo"
                      label="Certificate Campaign"
                      fieldName={"Campaign logo"}
                      classImage={"image-cert"}
                      classField={"center-image"}
                      hideLabel={true}
                      imageTypes={imageTypes}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="qr-section">
                  <div className="qr-code-image">
                    <WIUploadMediaField
                      type="file"
                      name="template_data_qr_code"
                      label="Certificate QR Code"
                      fieldName={"QR Code"}
                      classImage={"image-cert"}
                      classField={"right-image"}
                      isNotCenter={true}
                      hideLabel={true}
                      imageTypes={imageTypes}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <FooterTemplate /> */}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};
export default PreviewForm;

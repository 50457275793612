import { Field, FieldArray } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useState } from "react";
import WIForm from "./wi-form/wi-form";
import * as _ from "lodash";
import {
  TextField,
  WIFormElement,
  WIImage,
  WITextArea,
  WITextHTML,
  WICalendar,
  RichTextEditor,
  SelectButtonField,
  SelectColorV2,
  TextFieldCustom,
  WIUploadMedia,
  VideoUrlField,
  SearchTextField,
  SelectField,
  SizeDropdown,
  WiTextEditor,
  WiTextEditor1Line,
  MultiSelectSizeDropdown,
  GoalDropdown,
  CodeBatchesDropdown,
  MultiSourceDropdown,
  WIIconDropdown
} from "../commons";
import './wi-arrayfield.scss';
import { WIFile } from "../commons/wi-file/wi-file";
import { Button } from "primereact/button";
import { WIToggle } from "../commons/wi-toggle/wi-toggle";
import { clearHtmlTags } from "../../../utils/xssFilter";
import { Tree } from "primereact/tree";
import { Panel } from 'primereact/panel';
import { v4 as uuidv4 } from "uuid";
import { WIVideo } from "../commons/wi-video/wi-video";
import { WIToggleReserved } from "../commons/wi-toggle/wi-toggle-reserved";

const isVisible = (values: any, elementSchema: any) => {
  return !elementSchema.dependency || _.get(values, elementSchema.dependency?.name) === elementSchema.dependency?.value;
}

export const getFormElement = (
  elementName: string,
  elementSchema: any,
  {
    values,
    parentKey,
    isGlobalContentRef,
    search_content,
    selectFieldOptions,
    isGlobalDisabled
  }: any,
  lang: string = ""
) => {
  const props = {
    name: `${parentKey ? `${parentKey}.` : ""}${elementName}`,
    // Multilang
    // label: `${elementSchema.label}${lang ? ` ${lang.toUpperCase()}` : ""}`,
    label: `${elementSchema.label}`,
    options: elementSchema.options,
    isRequired: elementSchema.isRequired,
    supportMultiLanguages: elementSchema.supportMultiLanguages,
    lang,
    values,
    schema: elementSchema,
    parentKey,
    disabled: isGlobalContentRef || isGlobalDisabled,
    maxLength: elementSchema.maxLength,
    search_content: search_content,
    selectFieldOptions: selectFieldOptions,
  };

  if (!isVisible(values, elementSchema)) {
    return <div></div>;
  }

  if (["textField", "email"].includes(elementSchema.type)) {
    return <TextField {...props} />;
  }

  if (elementSchema.type === "toggleButtons") {
    return <WIToggle {...props} />;
  }

  if (elementSchema.type === "toggleButtonsReserved") {
    return <WIToggleReserved {...props} />;
  }

  if (elementSchema.type === "dropdownColorList") {
    return <SelectColorV2 {...props} />;
  }

  if (elementSchema.type === "array") {
    return <ArrayField {...props} />;
  }

  if (elementSchema.type === "textarea") {
    return <WITextArea {...props} />;
  }
  
  if (elementSchema.type === "textHtml") {
    return <WITextHTML {...props} />;
  }

  if (elementSchema.type === "calendar") {
    return <WICalendar {...props} />;
  }

  if (elementSchema.type === "imageField") {
    return <WIImage {...props} required={props.isRequired} />;
  }

  if (elementSchema.type === "fileField") {
    return <WIFile {...props} />;
  }

  if (elementSchema.type === "videoField") {
    return <WIVideo {...props} />;
  }

  if (elementSchema.type === "richTextEditor") {
    //return <RichTextEditor {...props} />;
    return <WiTextEditor {...props} />;
  }

  if (elementSchema.type === "dropdown") {
    return <WIIconDropdown {...props} />
  }

  if (elementSchema.type === "selectButtons") {
    return <SelectButtonField {...props} />;
  }

  if (["textFieldCustom"].includes(elementSchema.type)) {
    //return <TextFieldCustom {...props} />;
    return <WiTextEditor1Line {...props} />;
  }

  if (elementSchema.type === "object") {
    let form = renderFormElement(elementSchema.children, {
      ...props,
      parentKey: `${props.name}`,
      isGlobalContentRef: props.disabled || _.get(values, props.name)?.is_global_ref,
    });

    if (elementSchema.label) {
      return (
        <Accordion>
          <AccordionTab header={elementSchema.label}>{form}</AccordionTab>
        </Accordion>
      );
    }

    return form;
  }

  if (elementSchema.type === "wiUploadMedia") {
    return <WIUploadMedia {...props} required={props.isRequired} />;
  }

  if (elementSchema.type === "searchTextField") {
    return <SearchTextField {...props} />;
  }

  if (elementSchema.type === "sizeDropdown") {
    return <SizeDropdown {...props} />;
  }

  if (elementSchema.type === "multiSelectSizeDropdown") {
    return <MultiSelectSizeDropdown {...props} />;
  }

  if (elementSchema.type === "goalDropdown") {
    return <GoalDropdown {...props} />;
  }

  if (elementSchema.type === "codeBatchesDropdown") {
    return <CodeBatchesDropdown {...props} />;
  }

  if (elementSchema.type === "recentDonationsDropdown") {
    return <MultiSourceDropdown {...props} />;
  }

  if (elementSchema.type === "videoUrlField") {
    return <VideoUrlField {...props} />;
  }

  if (elementSchema.type === "selectField") {
    return <SelectField {...props} />;
  }
  // return <Field key={props.name} id={props.name} name={props.name} />;
  return <div></div>;
};

export const renderFormElement = (elementSchema: any, formProps: any) => {
  const { parentKey, values } = formProps;

  return (
    elementSchema &&
    Object.keys(elementSchema).map((key: string) => {
      if (elementSchema[key].supportMultiLanguages && elementSchema[key].supportMultiLanguages.length > 0 && elementSchema[key].type !== 'searchTextField') {
        const lang = 'de';
        return (
          // className="form-element-wrapper"
          <div className="row form-row form-multilang" key={`${parentKey}-${key}-${lang}`}> 
            {getFormElement(`${key}.${lang}`, elementSchema[key], formProps, lang)}
          </div>
        )
      } else {
        return (
          <div className="row form-row" key={`${parentKey}-${key}`}>
            {getFormElement(key, elementSchema[key], formProps)}
          </div>
        )
      }
    })
  );
};

export function ArrayField(props: any) {
  const { name, label, placeholder, values, schema, ...rest } = props;
  const { max, min, hidden } = schema;
  _.get(values, name)?.forEach((c: any) => c.id = c.id || uuidv4());
  const [selectedNode, setSelectedNode] = useState<any>();

  const findIndex = (node: any) => _.get(values, name).findIndex((c: any) => c.id === node.id);

  const formatHeader = (header: string) => {
    let str = _.cloneDeep(header);
    clearHtmlTags(str);
    return str;
  }

  const removeItem = (e: any, arrayHelpers: any, i: number) => {
    e.preventDefault();
    e.stopPropagation();
    arrayHelpers.remove(i);
  };

  const onDisabledBtn = (name: string) => {
    if (min || max) {
      return !(_.get(values, name)?.length < max);
    }
    return false;
  }

  const nodeTemplate = (arrayHelpers: any, schema: any) => (node: any, column: any) => {
    const i = findIndex(node);
    return <React.Fragment>
      <Panel  key={`pabel-${name}-${i}`}
      headerTemplate={buildHeaderTemplate(arrayHelpers, schema, node, i)} 
      toggleable collapsed={node.id !== selectedNode} onToggle={(e) => setSelectedNode(!e.value ? node.id : null)}>
        <div className="form-element-item" key={`${name}-${i}`}>
          <div>
            {renderFormElement(schema.children, {
              ...props,
              parentKey: `${name}.${i}`,
              isGlobalContentRef: props.disabled || _.get(values, name)[i].is_global_ref
            })}
          </div>
        </div>
      </Panel>
    </React.Fragment>
  };

  const buildHeaderTemplate = (arrayHelpers: any, schema: any, node: any, i: any) => (options: any) => {
    const valueItems = _.get(values, name);
    const valueItem = valueItems ? _.get(valueItems, i) : null;
    
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const className = `${options.className} justify-content-start`;
    const titleClassName = `${options.titleClassName} pl-1`;
    const header = node && schema.keyField ? formatHeader(_.get(node, schema.keyField)) : '';
    const canDelete = !min || _.get(values, name)?.length > min;
    const disabledRemove = valueItem?.disabledRemove || false;
    const disabledEdit = valueItem?.disabledEdit || false;
    
    return (
      <div className={className} onClick={!disabledEdit ? options.onTogglerClick : () => {}}>
        <button className={options.togglerClassName} onClick={!disabledEdit ? options.onTogglerClick : () => {}}>
          <span className={toggleIcon}></span>
        </button>
        <div className={titleClassName} dangerouslySetInnerHTML={{ __html: header }}></div>
        {
          !disabledRemove ? !props.disabled && (!min || (min || 0) < max) &&
          <i
            className={`item-icon fa-solid fa-trash-can ${!canDelete ? "disable-btn" : ""}`}
            onClick={(e) => removeItem(e, arrayHelpers, findIndex(node))}
          ></i>
        : '' }
      </div>
    )
  }

  const validatedNodes = (arrayHelpers: any) => (value: any) => {
    let dropIndex = value.dropNode ? findIndex(value.dropNode) : value.dropIndex;
    const dragIndex = findIndex(value.dragNode);
    arrayHelpers.move(dragIndex, dropIndex);
  };

  const addDefaultItem = (arrayHelpers: any) => {
    let defaultValues = _.get(values, name);
    let defaultObjValue = defaultValues && defaultValues[0] ? defaultValues[0] : {};
    let newObjValue = {...defaultObjValue}
    newObjValue.id = uuidv4();
    arrayHelpers.push({ ...newObjValue });
  }

  return (
    hidden ? <></> : <FieldArray
      name={name}
      render={(arrayHelpers: any) => (
        <WIFormElement {...props}>
          <Tree
            className="form-tree"
            contentClassName="form-tree-content"
            dragdropScope="wi-array-field-tree"
            value={_.get(values, name)}
            nodeTemplate={nodeTemplate(arrayHelpers, schema)}
            onDragDrop={(event: any) => validatedNodes(arrayHelpers)(event)}
          />
          {!props.disabled && (!max || (min || 0) < max) && !schema.disabledAdd && <Button
            className="btn-submit-list"
            onClick={() => arrayHelpers.push({ id: uuidv4()})}
            disabled={onDisabledBtn(name)}
          >
            <i className="fa-solid fa-plus"></i>
          </Button>}
        </WIFormElement>
      )}
    />
  );
}

export { WIForm };


{/* <Accordion>
{_.get(values, name) &&
  _.get(values, name).map((f: any, i: number) => {
    return (
      <AccordionTab
        key={i}
        headerTemplate={buildHeaderTemplate(
          _.get(values, name)[i] && schema.keyField ? _.get(_.get(values, name)[i], schema.keyField) : '',
          (e: any) => {
            const { min } = schema;
            if (min && _.get(values, name)?.length === min) {
              return;
            } else {
              removeItem(e, arrayHelpers, i)
            }
          }
        )}
      >
        <div className="form-element-item" key={`${name}-${i}`}>
          <div>
            {renderFormElement(schema.children, {
              ...props,
              parentKey: `${name}.${i}`,
              isGlobalContentRef: props.disabled || _.get(values, name)[i].is_global_ref
            })}
          </div>
        </div>
      </AccordionTab>
    );
  })}
</Accordion> */}

import * as React from "react";
import Layout from "../../components_v2/common/layout/layout";
import { useTranslation } from "react-i18next";
import './homepage.scss';
import moment from "moment";
import { useLayoutV2 } from "../../context/LayoutProvider";
import useAuth from "../../context/useAuth";

const Homepage = (props: any) => {
    const { t } = useTranslation('language', { keyPrefix: 'homepage' });
    const { getLanguage } = useLayoutV2();
    const { auth } = useAuth();

    const generateGreetings = () => {
        const currentHour = +moment().format("HH");
        const lang = getLanguage();
        if (currentHour >= 3 && currentHour < 12){
            return (lang === 'de' ? "Guten Morgen" : "Good morning");
        } else if (currentHour >= 12 && currentHour < 15){
            return (lang === 'de' ? "Guten Tag" : "Good afternoon");
        }   else if (currentHour >= 15 && currentHour < 20){
            return (lang === 'de' ? "Guten Abend" : "Good evening");
        } else if (currentHour >= 20 || currentHour < 3){
            return (lang === 'de' ? "Gute Nacht" : "Good night");
        } else {
            return (lang === 'de' ? "Hallo" : "Hello");
        }
      }
    
    return (
        <Layout title={t('txt_title')}>
            <div className="welcome-page">
                <h5 className="date">{moment().locale(getLanguage()).format('dddd, MMMM D')}</h5>
                <h5 className="welcome-text">{generateGreetings()} {getLanguage() === 'de' ? "und willkommen" : "and welcome"}, {auth.first_name}</h5>
                <h5 className="normal-text" dangerouslySetInnerHTML={{__html: t('txt_handbook')}}></h5>
                <h5 className="normal-text">{t('txt_slack_question')}</h5>
                <h5 className="normal-text">Blue Eagles</h5>
            </div>
        </Layout>
    )
};

export default Homepage;
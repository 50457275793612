import React, { useRef } from "react";
import { Dialog } from "primereact/dialog";
import "./editor-dialog.scss";
import "tui-image-editor/dist/tui-image-editor.css";
import { whiteTheme } from "./white-theme";
// @ts-ignore
import ImageEditor from "@toast-ui/react-image-editor";
import _ from "lodash";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { ReactComponent as SaveSvg } from "../../../../../assets/images/icons/save.svg";

const ImageEditorDialogComponent = (props: any) => {
  const { visible, onHide, data, saveChangesPhoto, fileBase64 } = props;
  const editorRef = useRef(null);
  const { t } = useTranslation('language', { keyPrefix: 'media_manager' });
  
  const convertDataUrlToFile = (dataUrl: any, fileName: any) => {
    let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
  }

  const submitImage = async () => {
    // @ts-ignore
    let instance = editorRef.current.getInstance();
    let dataURL = instance.toDataURL();
    if (dataURL) {
      const mediaInfo = _.cloneDeep(data);
      mediaInfo.file = convertDataUrlToFile(dataURL, mediaInfo.name);
      mediaInfo.size = parseFloat(data.size);
      saveChangesPhoto(mediaInfo);

      // clear object
      instance.clearObjects();
      instance.clearUndoStack();
      instance.clearRedoStack();
    }

    onHide();
  };

  return (
    <>
      <Dialog
        maskClassName="top-mask-dialog"
        className="editor-photo-dialog wi-dialog"
        visible={visible}
        header={"Photo Editor"}
        style={{ width: "65vw" }}
        modal
        onHide={onHide}
      >
        <ImageEditor
          key={"imageEditor"}
          ref={editorRef}
          includeUI={{
            loadImage: {
              path: fileBase64,
              name: "SampleImage",
            },
            theme: whiteTheme,
            menu: ["resize", "crop", "flip", "rotate"],
            initMenu: "",
            uiSize: {
              width: "100%",
              height: "600px",
            },
            menuBarPosition: "right",
          }}
          cssMaxHeight={400}
          cssMaxWidth={700}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={false}
        />
        <div className="d-flex justify-content-end mt-24 mb-12">
          <Button
            className="wi-primary-button-v2 h48"
            type={"button"}
            label={t('txt_edit')}
            onClick={submitImage}
            style={{width: '200px'}}>
            <SaveSvg className="icon-svg"></SaveSvg>
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default ImageEditorDialogComponent;

import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Tree } from "primereact/tree";
import { IComponent } from "../../interfaces/IComponent";
import { ITreeView } from "../../interfaces/ITreeView";
import "./content-builder-components-view.scss";
import InputInline from "../../../common/inputInline/input-inline";
import { Tooltip } from 'primereact/tooltip';
import _ from "lodash";

const ContentBuilderComponentView = (props: any) => {
  const { dashboard, id, className, setDashboard, setSelectedComponent, selectedComponent, header, footer } = props;
  const [expandedKeys, setExpandedKeys] = useState({ [id]: true });
  const [selectedKey, setSelectedKey] = useState<string>('');
  const [items, setItems] = useState<ITreeView[]>([]);
  const [showEditLabel, setShowEditLabel] = useState(false);
  const [labelBlock, setLabelBlock] = useState("");
  const [nodeEdit, setNodeEdit] = useState<any>();
  const inputRef = useRef(null);
  
  useEffect(() => {
    setItems([
      {
        label: header || "Header",
        key: 'page-main-header',
        children: []
      },
      {
        label: "Body",
        key: id,
        children: dashboard[0].components.map((component: IComponent, index: number) => {
          return {
            ...component,
            key: component.key,
            label: component.name,
            icon: "fa-solid fa-up-down-left-right",
            // draggable: !component.isBlockDisabled,
            leaf: true
          };
        }),
      },
      {
        label: footer || "Footer",
        key: 'page-main-footer',
        children: []
      }
    ]);

    setSelectedKey(selectedComponent ? selectedComponent : '');
  }, [dashboard]);

  const setNodes = (event: any) => {
    const node = event.value;
    const item = node[0];
    // @ts-ignore: Object is possibly 'null'.
    if (item.children.length !== items[0].children.length || event.dropNode.leaf) {
      return;
    }

    const children = event.dropNode.children;
    const newDashboard = {
      ...dashboard[0],
      components: children
    }
    setItems([
      {
        ...node[0],
        children: children,
      }
    ]);
    setDashboard([newDashboard]);
    setSelectedComponent(`${event.dragNode.key}`);
    setSelectedKey(`${event.dragNode.key}`);
  };

  const setSelectedNode = (data: any) => {
    const { node } = data;
    setSelectedComponent(node.key === id ? '' : `${node.key}`);
    setSelectedKey(node.key === id ? '' : `${node.key}`);
  };

  const handleSubmitChangeLabel = () => {
    if (!labelBlock.trim()) {
        setLabelBlock(nodeEdit.label);
    } else {
      const indexNodeSelected = dashboard[0].components.findIndex((node: any) => node.key === nodeEdit.key);
  
      if (indexNodeSelected > -1) {
        const newComponents = _.cloneDeep(dashboard[0]);
        newComponents.components[indexNodeSelected].name = labelBlock;
        
        const newDashboard = {
          ...dashboard[0],
          components: newComponents.components
        }
        setDashboard([newDashboard]);
      }
    }
  }

  const handleShowEditLabel = (node: any) => {
    setShowEditLabel(true);
    setNodeEdit(node);
    setLabelBlock(node.name)
  }

  const deleteNode = (node: any, options: any, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    
    const parent = options.props.parent;
    const newItems = parent.children.filter((p: any) => p.key !== node.key);
    setItems([
      {
        ...parent,
        children: newItems
      }
    ]);
    const newDashboard = {
      ...dashboard[0],
      components: newItems
    };
    setDashboard([newDashboard]);
    setSelectedComponent('');
    setSelectedKey('');
  };

  const nodeTemplate = (node: any, options: any) => {
    if ([id, 'page-main-header', 'page-main-footer'].includes(node.key)) {
      return <div className="p-treenode-label" >{node.label}</div>
    }
    return (
      showEditLabel && nodeEdit && nodeEdit.key === node.key
      ? 
        <div className="input-edit-label">
          <InputInline
            setShowEditLabel={setShowEditLabel}
            childRef={inputRef}
            showEditLabel={showEditLabel}
            handleSubmitChangeLabel={handleSubmitChangeLabel}
          >
          <input
            ref={inputRef}
            className="edit-inline-block"
            type="text"
            name="block"
            value={labelBlock}
            onChange={e => setLabelBlock(e.target.value)}
          />
          </InputInline>
        </div> 
      :
       <div key={node.key} className="p-treenode-custom">
        <span className={`p-treenode-panel node_${node.key}`} onDoubleClick={() => handleShowEditLabel(node)}>{node.label}</span>
        {!node.isBlockDisabled && <i className="fa-solid fa-trash-can" onClick={(e: any) => deleteNode(node, options, e)}></i>}
        {node.label.length > 25 ? <Tooltip target={`.node_${node.key}`} autoHide={false}> {node.label} </Tooltip> : ""}
      </div>
    )
  };

  return (
    <React.Fragment>
      <Tree
        value={items}
        expandedKeys={expandedKeys}
        className={className}
        selectionMode={'single'}
        selectionKeys={selectedKey}
        dragdropScope="content-builder-component-view"
        onDragDrop={(event: any) => setNodes(event)}
        nodeTemplate={nodeTemplate}
        onSelectionChange={(e: any) => setSelectedKey(e.value)}
        onSelect={(e: any) => setSelectedNode(e)}
      />
    </React.Fragment>
  );
};

export default ContentBuilderComponentView;

import { onInitPageValues, generateComponent, getContentById } from "../components/pages/builder-page.common";
import { clearHtmlTags, convertHtmlToText } from "../utils/xssFilter";
import { COMMON_STATUS } from "./utils";
import moment from "moment";
import _ from "lodash";
import { generateUrl } from "../components/common/useUrlConversion";

export const generatePageData = async (formikValues: any, parentPages: any, cmsPage: any, pageType: any, mappings: any, allBlocks: any, footers: any, headers: any, recordId: any, seo_image: any, isPublished = false) => {
    let cmsContent = await getContentById(cmsPage?.uuid);
    return buildPageData(formikValues, parentPages, cmsContent, pageType, mappings, allBlocks, footers, headers, recordId, seo_image, isPublished);
}

const buildPageSEOConfig = (initialPage: any, description: any, seo_image: any) => {
    let keys = ['og_seo_config', 'twitter_seo_config'];
    let seo_config: any = {};
    keys.forEach((k: any) => {
        if (initialPage[k]) {
            seo_config[k] = {
                seo_image: seo_image,
                seo_title: initialPage.title,
                seo_description: {
                    de: description?.de ? convertHtmlToText(clearHtmlTags(description?.de)) : '',
                    en: description?.en ? convertHtmlToText(clearHtmlTags(description?.en)) : '',
                }
            }
        }
    });

    return JSON.stringify(seo_config);
}

const buildPageMetadata = ({ formikValues, seo_image, parentPages, pageType, footers, headers, recordId, isPublished }: any) => {
    let findParentPage = (str: string) => str && str.trim() ? parentPages.find((p: any) => p.special_type === str) : '';
    let pageId = findParentPage(pageType.special_page_type)?.id;
    let param = {
        defaultPageTypeId: pageType?.uuid,
        footers: footers,
        headers: headers,
        isShowCreate: true
    };

    let recordName = formikValues.name;
    let initialPage: any = onInitPageValues(param);
    initialPage = {
        ...initialPage,
        name: recordName,
        title: { de: recordName, en: recordName },
        language: "both",
        page_id: pageId,
        url: generateUrl(recordName)
    }

    const seo_config = buildPageSEOConfig(initialPage, formikValues.description, seo_image);
    initialPage.seo_configuration = seo_config;
    delete initialPage["og_seo_config"];
    delete initialPage["twitter_seo_config"];

    initialPage.parent_id = recordId;
    initialPage.status = isPublished ? COMMON_STATUS.PUBLISHED : COMMON_STATUS.DRAFT;
    if (isPublished) {
        initialPage['custom_config'] = initialPage['custom_config'] || {};
        initialPage['custom_config']['published_info'] = initialPage['custom_config']['published_info'] || [];
        initialPage['custom_config']['published_info'].push({ time: moment().toISOString() })
    }

    return initialPage;
}

const buildDefaultBlocks = ({mappings, allBlocks}: any) => {
    let components: any = []
    Object.keys(mappings).map((code: any) => {
        let block = allBlocks.find((c: any) => c.code === code);
        if (block) {
            let newBlock = _.cloneDeep(block);
            Object.keys(mappings[code]).map((path: any) => {
                _.update(newBlock.parameters, path, (u) => mappings[code][path]);
            });

            let newComponent = generateComponent(newBlock.name, newBlock);
            components.push(newComponent);
        }
    });

    return components.map((c: any, index: any) => ({
        order: index,
        uuid: c.key,
        cta_block_id: c.blockId,
        is_visible: true,
        is_disabled: true,
        is_block_disabled: true,
        parameters: c.parameters
    }));
}

const correctBlocksOrder = ({defaultBlocks, previousCmsContent}: any) => {
    let content = [...defaultBlocks];
    if (previousCmsContent) {
        previousCmsContent.forEach((element: any) => {
            const component = content.find((component: any) => !!element.is_disabled && component.cta_block_id === element.cta_block_id);
            if (!component) {
                content.push(element);
            } else {
                component.order = element.order;
            }
        });

        content = [...content].sort((a, b) => a.order - b.order);
    }

    return JSON.stringify(content);
}

const buildPageData = (formikValues: any, parentPages: any, cmsContent: any, pageType: any, mappings: any, allBlocks: any, footers: any, headers: any, recordId: any, seo_image: any, isPublished = false) => {
    const initialPage = buildPageMetadata({formikValues, seo_image, parentPages, pageType, footers, headers, recordId, isPublished});
    let defaultBlocks = buildDefaultBlocks({mappings, allBlocks});
    initialPage.content = correctBlocksOrder({defaultBlocks, previousCmsContent: cmsContent});
    return initialPage;
}

export const findCmsPage = (cmsPages: any, record: any) => {
    if (!cmsPages || !record) {
        return null;
    }

    let cmsPage = cmsPages.find((f: any) => f.parent_id === record.uuid);
    return cmsPage;
}
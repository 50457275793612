import { useField } from "formik";
import * as React from "react";
import { useState, useEffect } from "react";
import { env } from "../../../environment";
import "./wi-upload-field.scss";
import { formatImageFileURL } from "../../../utils/mediaUtils";
import InsertMediaDialogComponent from "./wi-upload-media-dialog";
import _ from "lodash";

const LIMIT_FILE_SIZE = 1000000;
const LIMIT_HERO_FILE_SIZE = 10000000;

const WIUploadMediaFileField = (props: any) => {
  const [field, meta, helpers] = useField(props.name);
  const [fieldSize, metaSize, helpersSize] = useField(`${props.name}_size`);
  const [fieldName, metaName, helpersName] = useField(`${props.name}_name`);
  const [fieldExtension, metaExtension, helpersExtension] = useField(
    `${props.name}_extension`
  );
  const { setValue: setSizeValue } = helpersSize;
  const { setValue: setNameValue } = helpersName;
  const { setValue: setExtensionValue } = helpersExtension;
  const { setValue, setTouched } = helpers;
  const { error, touched } = meta;
  const { value } = field;
  const [file, setFile] = useState(null);
  const { value: valueSize } = fieldSize;
  const [fileInfo, setFileInfo] = useState<any>(null);
  const [isShowMediaDialog, setIsShowMediaDialog] = useState(false);

  useEffect(() => {
    if (value) {
      setFileUrl(setFileProps());
    }
  }, [value]);

  const setFileProps = () => {
    if (!!value) {
        setFile(value);
        setFileInfo({ extension: value, size: valueSize });
        return `${env.PUBLIC_UPLOAD_URL}/${value}`
    }
    return null;
  };

  const [fileUrl, setFileUrl] = useState(() => setFileProps());

  const handleFileValue = (selectedFile: any, isSetShowDialog?: boolean) => {
    setTouched(true);
    if (!selectedFile) {
      return;
    }

    // const limitSize = props.overSize ? LIMIT_HERO_FILE_SIZE : LIMIT_FILE_SIZE;
    // if (selectedFile.file_size > limitSize) {
    //   window.alert(
    //     `File size has exceeded it max limit of ${props.overSize ? 10 : 1}MB`
    //   );
    //   return;
    // }
    setFile(selectedFile.fullPath);
    setFileInfo({extension: selectedFile.file_extension, size: Number(selectedFile.size)});
    setValue(encodeURI(`${selectedFile.fullPath}`), true);

    const sizeInMB = Math.max(0.1, +(Number(selectedFile.size) / (1000 * 1000)).toFixed(2));
    const name = _.last(selectedFile.name.split('--'));
    setSizeValue({de: sizeInMB.toLocaleString('de'), en: sizeInMB.toLocaleString('en')}, true);
    setNameValue({de: name, en: name}, true);
    setExtensionValue(selectedFile.file_extension, true);
    setFileUrl(encodeURI(`${env.PUBLIC_UPLOAD_URL}/${`${formatImageFileURL(selectedFile.fullPath)}`}`));
    if (isSetShowDialog) {
      setIsShowMediaDialog(false);
    }
  };

  const handleRemoveFileValue = (event: any) => {
    event.preventDefault();
    setFile(null);
    setFileInfo(null);
    setFileUrl(null);
    setTouched(false);
    setValue("");
    setSizeValue(0);
    setExtensionValue("");
    return;
  };

  const onOpenMediaManager = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowMediaDialog(true);
  };

  return (
    <>
      {!props.hideLabel ? (
        <label className="label">
          {props.label}{" "}
          {props.required && !props.fieldName ? (
            <span className="required-label">*</span>
          ) : (
            ""
          )}
        </label>
      ) : (
        ""
      )}
      {!props.hideLabel ? <label className="label">{file}</label> : ""}

      <label
        className={`label-upload-field img-frame-16-9 ${
          props.classField || ""
        } ${props.className || ""} ${
          error && touched && !file ? "upload-error" : ""
        }`}
      >
        <p style={{ display: fileUrl ? "none" : "block" }}>
          Click to choose{" "}
          {props.fieldName ? ` ${props.fieldName}` : ""}{" "}
          {props.required && props.fieldName ? (
            <span className="required-label">*</span>
          ) : (
            ""
          )}
        </p>
        <p
          style={{
            display: fileInfo ? "block" : "none",
            fontWeight: 700,
            color: "#13735f",
          }}
        >
          {fieldName?.value?.de} <br />
          <span style={{ fontSize: "15px" }}>
            {fieldSize?.value?.de} MB
          </span>
        </p>
         <button
          className="input-upload"
          name={field.name}
          type={props.type}
          onChange={handleFileValue}
          disabled={props.disabled}
          onClick={(e: any) => onOpenMediaManager(e)}
        />
        {fileUrl && !props.disabled && (
          <>
            <span className="remove-icon">
              <i
                className="fa-solid fa-xmark fa-lg"
                onClick={handleRemoveFileValue}
              />
            </span>
          </>
        )}
      </label>
      <InsertMediaDialogComponent
        visible={isShowMediaDialog}
        fileType={props.fileType}
        handleFileValue={(selectedMedia: any) => handleFileValue(selectedMedia, true)}
        setIsShowMediaDialog={setIsShowMediaDialog}
        onHide={() => setIsShowMediaDialog(false)}
      />
    </>
  );
};

export default WIUploadMediaFileField;

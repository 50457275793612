import * as React from "react";
import "./partner-item.scss";
import { formatImageFileURL, getThumbnailFullpath } from "../../../utils/mediaUtils";
import { env } from '../../../environment';
import moment from "moment";
import Logo from '../../../assets/images/icons/logo.svg';

const PartnerItem = (props: any) => {
  const { option } = props;

  const renderPartnerLogo = (option: any) => {
    if (option.partner_logo) {
      return `${env.PUBLIC_UPLOAD_URL}/${getThumbnailFullpath(formatImageFileURL(option.partner_logo))}?v=${moment(option.changed).unix()}`
    } else {
      return Logo;
    }
  }
  return (
      <div className="partner-item">
          <div className="partner-name">
              <div className="name">{option.name}</div>
          </div>
          <div className="partner-picture">
              <img 
              src={renderPartnerLogo(option)}
              onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src= `${env.PUBLIC_UPLOAD_URL}/${formatImageFileURL(option?.partner_logo)}`;
                }} 
              alt={option.name} /> 
          </div>
      </div>
    );
  }

export default PartnerItem;
import * as React from "react"
import './create-receipt-dialog.scss';
import { Dialog } from 'primereact/dialog';
import CreateReceiptFormComponent from "../create-receipt-form/create-receipt-form";

const CreateReceiptDialogComponent = (props: any) => {

    const { visible, onHide, title, receipt, fetchReceiptsData, countries } = props;
    
    return (
        <Dialog 
            className="create-receipt-dialog wi-dialog"
            header={title} 
            visible={visible} 
            style={{width: '800px'}} 
            modal 
            onHide={onHide}
            >
            <CreateReceiptFormComponent 
                receipt={receipt} 
                onHide={onHide} 
                fetchReceiptsData={fetchReceiptsData}
                countries={countries}
            />
        </Dialog>
    )
}

export default CreateReceiptDialogComponent;
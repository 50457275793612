const SEARCH_FIELDS = ["name", "contact_person_name", "contact_person_email"];

export const getObjectWithValues = (where_obj: any) => {
    const tempt_obj: any = {};
    for (const [key, value] of Object.entries(where_obj)) {
        if (key && key !== "dates") {
            if ((Array.isArray(value) || typeof value === "string") && value.length > 0) {
                tempt_obj[key] = value;
            }
        } else {
            if (Array.isArray(value) && value.length === 2) {
                tempt_obj["from_run_date"] = value[0];
                tempt_obj["to_run_date"] = value[1];
            }
        }
    }
    return tempt_obj;
};

// ============================= GLOBAL FUNCTIONALITY =============================
export const getWildernessRunsObjectWithValues = (where_obj: any) => {
    const tempt_obj: any = {};
    for (const [key, value] of Object.entries(where_obj)) {
        if (key && key !== "dates") {
            if ((Array.isArray(value) || typeof value === "string") && value.length > 0) {
                tempt_obj[key] = value;
            }
        } else {
            if (Array.isArray(value) && value.length === 2) {
                tempt_obj["from_run_date"] = value[0];
                tempt_obj["to_run_date"] = value[1];
            }
        }
    }
    return tempt_obj;
};

export const getWildernessRunsObjectSearchValue = (value: string) => {
    const search_result: any[] = [];
    SEARCH_FIELDS.forEach((key: string) => {
        const obj = {
            [key]: value,
        };
        search_result.push(obj);
    });

    return search_result;
};

export const getWildernessRunsPayloadConditions = (filterValues: any, sort_config: any) => {
    const { sortField, sortOrder } = sort_config;
    const where_conditions = getObjectWithValues(filterValues.where);
    const mapper: any = {
        name: [['name']],
        partner_id: [['partner_id']],
        land_id: [['land_id']],
        expected_paid_money: [['expected_paid_money']],
        actual_paid_money: [['actual_paid_money']],
        run_date: [['run_date']]
    };
    const orders = mapper[sortField];
    orders.forEach((r: any) => r.push(sortOrder === -1 ? "DESC" : "ASC"));
    return {
        where: {
            ...where_conditions,
            search_name: filterValues.search
        },
        order: orders[0]
    };
};

export const fetchFromObject = (obj: any, prop: any): any => {
    if (typeof obj === "undefined") return false;
    const index = prop.indexOf(".");
    if (index > -1) {
      return fetchFromObject(
        obj[prop.substring(0, index)],
        prop.substr(index + 1)
      );
    }
    return obj[prop];
  };
import { useRef, useState } from "react";
import React from "react";
import { Button } from "primereact/button";
import { LanguageCode } from "../../../../utils/utils";
import "./generate-certificate-donation-dialog.scss";
import { onDownloadCertificate, onDownloadMultipleCertificate } from "../../../../utils/donationManagerLogic";
import { SelectButton } from 'primereact/selectbutton';
import { useTranslation } from "react-i18next";
import { useLayoutV2 } from "../../../../context/LayoutProvider";
import { LANGUAGE_LIST } from "../../../../components_v2/utils/utils";

const GenerateCertificateDonationDialogComponent = (props: any) => {
  const { donation, yearDonation, selectedDonations, isMultipleDownload, isMailing, onHide } = props;
  const { t } = useTranslation('language', { keyPrefix: 'donation_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: globalTrans } = useTranslation('language');

  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const [languageCode, setLanguageCode] = useState(LanguageCode.DE);

  return (
    <div className="dialog-container">
      <div className="row row-content">
        <div className="col-md-12 element-form language-container">
          <label className="label pb-16">{t('txt_choose_language')}</label>
          <SelectButton
            className="wi-selectbutton"
            name='language_option'
            value={languageCode}
            options={LANGUAGE_LIST.map((l: any) => ({...l, label: globalTrans(l.label)}))}
            onChange={(item: any) => {
              if (item.value) {
                setLanguageCode(item.value);
              }
            }}
            optionLabel="label"
            optionValue="code"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between mt-24 gap-24">
        <Button
          className="wi-danger-button-v2 h48 flex-1"
          label={t('txt_cancel')}
          onClick={() => onHide()}
        ></Button>
        <Button
          type="submit"
          label={t("txt_download")}
          className="wi-primary-button-v2 h48 flex-1"
          onClick={(e) => {
            setLoadingProgress(errorTrans('txt_loading'));
            if (isMultipleDownload) {
              const ids = selectedDonations.map((donation: any) => donation.uuid);
              onDownloadMultipleCertificate(ids, languageCode, setSuccessProgress, setErrorProgress);
            } else {
              const pdfType = isMailing ? 'recurring-certificate' : 'certificate';
              onDownloadCertificate(setSuccessProgress, setErrorProgress, e, donation, languageCode, pdfType, yearDonation);
            }
          }}
        />
      </div>
    </div>
  );
};

export default GenerateCertificateDonationDialogComponent;

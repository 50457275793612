import _ from "lodash";
import { generatePageData } from "../../../utils/commonGeneratePageData";

export const generatePersonPageData = (formikValues: any, parentPages: any, cmsPage: any, personPageType: any, allBlocks: any, footers: any, headers: any, partnerId: any, isPublished = false) => {
    let mappings: any = {
        'person-header': {
            'name': formikValues.name,
            'cover_picture': formikValues.cover_picture,
            'mobile_cover_picture': formikValues.cover_picture,
            'profile_picture': formikValues.profile_picture,
            'position.de': formikValues.position?.de,
            'description.de': formikValues.description?.de,
        },
    };

    return generatePageData(formikValues, parentPages, cmsPage, personPageType, mappings, allBlocks, footers, headers, partnerId, formikValues.cover_picture, isPublished);
}
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import "./wi-sizeDropdown.scss";
import * as _ from "lodash";
import RegionManagerAPI from "../../../../services/regionManager";
import CampaignManagerAPI from "../../../../services/campaignManager";
import { env } from '../../../../environment';

export function SizeDropdown(props: any) {
  const { values, disabled, schema } = props;
  const progressOptions = [
    { name: "Region", value: 0 },
    { name: "Campaign", value: 2},
  ];
  
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [sources, setSources] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [regionSource, setRegionSource] = useState<any>([]);
  const [campaignSource, setCampaignSource] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [progressOptionValue, setProgressOptionValue] = useState<any>();
 
  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    mappingDataSource();
  }, [regionSource, campaignSource]);

  useEffect(() => {
    loadOriginalData();
  }, [regions, campaigns]);

  useEffect(() => {
    updateFieldValue(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (progressOptionValue) {
      const progressOption = getProgressOptionText(progressOptionValue);
      setFieldValue("progress_option_text", progressOption?.name?.toString().toLowerCase());
    }      
  }, [progressOptionValue]);

  const fetchAllData = async () => {
    const [regionData, campaignsData] = await Promise.all([
      fetchRegionsData(),
      fetchCampaignsData(),
    ]);

    setRegionSource(regionData);
    setCampaignSource(campaignsData);
  };

  const loadOriginalData = () => {
    let originalOptionValue = values.progress_option;
    if (originalOptionValue === 1) {
      originalOptionValue = 0; // Set default Region option
    }

    let originalSelectedItem = {};
    const progressOption = getProgressOptionText(originalOptionValue);
    if (progressOption && progressOption?.name === "Region") {
      originalSelectedItem = _.find(regions, { id: values.dropdown_id });
    }
    else {
      originalSelectedItem = _.find(campaigns, { id: values.dropdown_id });
    }

    setProgressOptionValue(originalOptionValue);
    onChangeProgressOption(originalOptionValue, originalSelectedItem, progressOption?.name);
  };

  const fetchRegionsData = async () => {
    try {
      const res = await RegionManagerAPI.getAllRegions();
      if (res && res.status === 200) {
        let regionData = res.data.records || [];
        if (!!regionData) {
          return regionData;
        }
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCampaignsData = async () => {
    try {
      const res = await CampaignManagerAPI.getAllCampaigns();
      if (res && res.status === 200) {
        let campaigns = res.data.records || [];
        if (!!campaigns) {
          return campaigns;
        }
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  const mappingDataSource = () => {
    let regionData = regionSource.map((region: any) => ({
      id: region.uuid,
      name: region.name,
      size: {
        protected_percent: Math.round(calculatePercentProtected(region.size, (region.size - region.protected_size))),
        protected_size: region.protected_size || 0,
        total_size: region.size || 0,
        amount_co2: convertKgToTon(region.protected_kg_co2) || 0,
        no_donations: region.no_donations || 0
      },
      code: region.identifier
    }));
    setRegions(regionData);

    let campaignData = campaignSource.map((campaign: any) => ({
      id: campaign.uuid,
      name: campaign.name,
      size: {
        protected_percent: Math.round((100 * (campaign?.protected_size || 0)) / parseInt(campaign?.land?.size)),
        protected_size: parseInt(campaign.protected_size || 0),
        total_size: parseInt(campaign?.land?.size),
        amount_co2: convertKgToTon(+(campaign?.protected_kg_co2 || parseInt(campaign.protected_size || 0) * 60)),
        no_donations: parseInt(campaign?.no_donations) || 0
      },
      code: campaign?.code,
    }));
    setCampaigns(campaignData);
  };

  const onChangeProgressOption = (progressOptionValue: any, selectedItem: any, progressOptionName: any = "") => {
    setFieldValue("progress_option", progressOptionValue);
    setSelectedValue(selectedItem);

    if (progressOptionName === "Region") {
      setSources(regions);
    }
    else {
      setSources(campaigns);
    }
  };

  const calculatePercentProtected = (totalSize: number, availableSize: number) => {
    let protectedSize = totalSize - availableSize;
    return (100 * protectedSize) / totalSize;
  };

  const convertKgToTon = (kg: any) => {
      return Math.trunc(kg/1000);
  }

  const updateFieldValue = (value: any) => {
    if (value?.size?.protected_percent !== undefined 
      && value?.size?.protected_size !== undefined 
      && value?.id !== undefined 
      && value?.size?.amount_co2 !== undefined 
      && value?.size?.no_donations !== undefined) {

        let fontSize = value?.size?.protected_size.toString().length > 5 
          || value?.size?.amount_co2.toString().length > 5 
          || value?.size?.no_donations.toString().length > 5 ? "3.625rem" : "4.625rem";
        setFieldValue('font_size', fontSize);

        setFieldValue('protected_percent', value?.size?.protected_percent.toString());
        setFieldValue('protected_size', value?.size?.protected_size.toLocaleString());
        setFieldValue('dropdown_id', value?.id);
        setFieldValue('facts.0.fact', value?.size?.no_donations.toLocaleString());
        setFieldValue('facts.1.fact', value?.size?.protected_size.toLocaleString());
        setFieldValue('facts.2.fact', value?.size?.amount_co2.toLocaleString());

        setFieldValue('facts.0.fact.de', value?.size?.no_donations.toLocaleString());
        setFieldValue('facts.1.fact.de', value?.size?.protected_size.toLocaleString());
        setFieldValue('facts.2.fact.de', value?.size?.amount_co2.toLocaleString());
     }

     const progressOption = getProgressOptionText(progressOptionValue);
     let url = '';
     if (value?.code && value?.code !== undefined)
     {
      if (progressOption && progressOption?.name === "Region") {
        url = `${env.DONATION_URL}?region=${value?.code}`;
      }
      else if (progressOption && progressOption?.name === "Campaign") {
        url = `${env.DONATION_URL}?campaign=${value?.code}`;
      }
      
      setFieldValue("selected_code", value?.code) 
    }

    setFieldValue("link.de", url);
    setFieldValue("link.option", 1);
    setFieldValue("data_type", progressOption?.name.toLowerCase());
    setFieldValue('data_src_id', value?.id);
  };

  const customItemTemplate = (item: any) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: `${item?.name?.de} - ${item?.size?.protected_size.toLocaleString()}/${item?.size?.total_size.toLocaleString()} m²`}}>
        </div>
      </div>
    );
  };

  const getProgressOptionText = (progressOptionValue: any) => {
    return progressOptions.find((po) => po.value === progressOptionValue);
  }
  
  return (
    <div className="form-element">
      <div className="form-element-title" style={{ marginBottom: "15px" }}>
        {schema.label}
      </div>
      <div style={{ margin: "-10px" }}>
        <div className="apply-btn" style={{ display: "flex" }}>
          <div className="select-group">
            {sources ? (
              <>
                <div style={{ marginRight: "10px" }}>
                  <SelectButton
                    hidden={disabled}
                    className="wi-form-select-button"
                    optionLabel="name"
                    value={progressOptionValue}
                    options={progressOptions}
                    onChange={(e: any) => {
                      setProgressOptionValue(e.value);
                      const progressOption = getProgressOptionText(e.value);
                      onChangeProgressOption(
                        e.value,
                        selectedValue,
                        progressOption?.name
                      );
                    }}
                    unselectable={false}
                  />
                </div>
                {
                  <Dropdown
                    value={selectedValue}
                    itemTemplate={customItemTemplate}
                    valueTemplate={customItemTemplate}
                    options={sources}
                    dropdownIcon={"pi pi-chevron-down"}
                    onChange={(item: any) => {
                      setSelectedValue(item.value);
                    }}
                    appendTo={"self"}
                    optionLabel="name.de"
                    filter
                    filterBy={schema.sourceConfiguration.searchFields?.join(
                      ","
                    )}
                  />
                }
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
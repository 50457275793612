import { Dialog } from "primereact/dialog";
import { useState, useRef } from "react";
import "./create-component-faq-dialog.scss";
import React from "react";
import { AxiosResponse } from "axios";
import FAQsManagerAPI from "../../../../../services/global-contents/faqsManager";
import { showNotification } from "../../../../../utils/logic";
import { FAQBlock } from "../../utils";
import DynamicForm from "../../../../dynamic-form/dynamic-form";

import { Toast } from "primereact/toast";

const CreateFAQDialogComponent = (props: any) => {
  const { visible, onHide, faq, fetchFAQsData } = props;
  const toast = useRef(null);
  const block = { ...FAQBlock };

  const onSubmitDataForm = async (value: any) => {
    try {
      const resGenerateFAQs = await requestFAQsData(value);
      handleRequestFAQs(resGenerateFAQs);
    } catch (error) {
      showNotification("error", "Failed to generate faq", toast);
    }
  };

  const handleRequestFAQs = (
    resGenerateFAQs: AxiosResponse<any, any>
  ) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateFAQs;
    let method = response.config.method;
    if (
      response &&
      response.status === 200 &&
      (method === "post" || method === "put")
    ) {
      let mesage =
        method === "post"
          ? "Generate faq successfully"
          : "Update faq successfully";
      showNotification("success", mesage, toast);
      onHide();
      fetchFAQsData();
    } else {
      showNotification("error", "Failed to generate faq", toast);
    }
  };

  const requestFAQsData = async (value: any) => {
    // @ts-ignore: Object is possibly 'null'.
    //const formikValues = formikRef?.current.values;

    if (faq) {
      // @ts-ignore: Object is possibly 'null'.
      const faqObj = faq;
      let request = { ...faqObj, ...value };
      return updateFAQ({
        ...request,
      });
    }
    return generateFAQ({
      ...value,
    });
  };

  const generateFAQ = async (parameters: any) => {
    return await FAQsManagerAPI.generateFAQs(parameters);
  };

  const updateFAQ = async (parameters: any) => {
    faq.question = parameters.question;
    faq.answer = parameters.answer;
    faq.category = parameters.category;
    
    return await FAQsManagerAPI.updateFAQs(faq);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="create-faq-dialog wi-dialog"
        header={faq?.uuid ? "Update FAQ" : "New FAQ"}
        visible={visible}
        style={{ width: "600px" }}
        modal
        onHide={onHide}
      >
        <DynamicForm
          formSchema={ block?.configuration || {} }
          data={ faq 
            ? { 
              question: faq.question, 
              answer: faq.answer, 
              category: faq.category 
            }  
            : block?.parameters }
          submitButtonConfig={{title: faq?.uuid ? "Update" : "Create", icon: "pi pi-chevron-right", iconPos: "right"}}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
        ></DynamicForm>
      </Dialog>
    </>
  );
};

export default CreateFAQDialogComponent;

import { Dialog } from "primereact/dialog";
import { useState, useRef } from "react";
import "./donation-example-dialog.scss";
import React from "react";
import { AxiosResponse } from "axios";
import DonationExamplesManagerAPI from "../../../../../services/global-contents/donationExamplesManager";
import { showNotification } from "../../../../../utils/logic";
import { DonationExampleBlock } from "../../utils";
import DynamicForm from "../../../../dynamic-form/dynamic-form";
import { Toast } from "primereact/toast";

const CreateDonationExampleDialogComponent = (props: any) => {
  const { visible, onHide, donationExample, fetchDonationExamplesData, globalContent } = props;
  const toast = useRef(null);
  const block = { ...DonationExampleBlock };

  const onSubmitDataForm = async (value: any) => {
    try {
      const resGenerateDonationExamples = await requestDonationExamplesData(value);
      handleRequestDonationExamples(resGenerateDonationExamples);
    } catch (error) {
      showNotification("error", "Failed to generate Donation Example", toast);
    }
  };

  const handleRequestDonationExamples = (
    resGenerateDonationExamples: AxiosResponse<any, any>
  ) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateDonationExamples;
    let method = response.config.method;
    if (
      response &&
      response.status === 200 &&
      (method === "post" || method === "put")
    ) {
      let mesage =
        method === "post"
          ? "Generate Donation Example successfully"
          : "Update Donation Example successfully";
      showNotification("success", mesage, toast);
      onHide();
      fetchDonationExamplesData();
    } else {
      showNotification("error", "Failed to generate Donation Example", toast);
    }
  };

  const requestDonationExamplesData = async (value: any) => {
    // @ts-ignore: Object is possibly 'null'.
    //const formikValues = formikRef?.current.values;

    if (donationExample) {
      // @ts-ignore: Object is possibly 'null'.
      const donationExampleObj = donationExample;
      let request = { ...donationExampleObj, ...value };
      return updateDonationExample({
        ...request,
      });
    }
    return generateDonationExample({
      ...value,
    });
  };

  const generateDonationExample = async (parameters: any) => {
    return await DonationExamplesManagerAPI.generateDonationExamples(parameters);
  };

  const updateDonationExample = async (parameters: any) => {
    donationExample.example = parameters.example;
    donationExample.description = parameters.description;
    donationExample.url = parameters.url;
    return await DonationExamplesManagerAPI.updateDonationExamples(donationExample);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="create-donation-example-dialog wi-dialog"
        header={donationExample?.uuid ? "Update Example" : "New Example"}
        visible={visible}
        style={{ width: "600px" }}
        modal
        onHide={onHide}
      >
        <DynamicForm
          formSchema={block?.configuration || {}}
          globalContent={globalContent}
          data={donationExample ?  {example: donationExample.example, description: donationExample.description , url: donationExample.url}  :block?.parameters}
          submitButtonConfig={{title: donationExample?.uuid ? "Update" : "Create", icon: "pi pi-chevron-right", iconPos: "right"}}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
        ></DynamicForm>
      </Dialog>
    </>
  );
};

export default CreateDonationExampleDialogComponent;

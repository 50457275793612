import * as React from "react";
import { env } from "../../../environment"; 
import "./preview-form.scss";
import moment from "moment";
import PreViewFormUtil from "./preview-form.util";
import WIUploadMediaField from "../upload/wi-upload-media-field";
import useAuth from "../../../context/useAuth";
import { PERMISSIONS_V2 } from "../../../components_v2/utils/utils";

const PreviewForm = (props: any) => {
  const { values, language, isCampaign, region_logo, imageTypes } = props;
  const [content, setContent] = React.useState<any>(null);
  const { auth } = useAuth();
  const permissions = {
    canCreateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_CREATE),
    canUpdateRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_UPDATE),
    canViewArea: auth?.permissions?.includes(PERMISSIONS_V2.AREA_VIEW)
  };
  
  const getContent = () => {
    const _content = PreViewFormUtil.getContent(language);
    setContent(_content);
  };

  React.useEffect(() => {
    getContent();
  },[language]);

  return content ? (
    <div className="a4">
      <div className="pdf-certificate">
        <div className="pdf-header">
          <div className="pdf-header-section-left">
            <img
              src={`${env.PUBLIC_IMAGE_URL}/logo-wilderness-international-rm.png`}
              alt="wi-logo"
              width="98%"
            />
            {isCampaign ? (
              <WIUploadMediaField
                type="file"
                name="campaign_logo"
                fieldName="Campaign logo"
                classImage={"image-cert"}
                classField="campaign-logo"
                hideLabel={true}
                imageTypes={imageTypes}
              />
            ) : (
              ""
            )}
          </div>
          <div className="pdf-header-section-right">
            <WIUploadMediaField
              type="file"
              name="certificate_drone_image"
              label="Certificate Drone"
              required={true}
              classField={"drone-image"}
              classImage={"drone-cert"}
              fieldName={"Drone"}
              isMiddle={true}
              hideLabel={true}
              imageTypes={imageTypes}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
            />
            <WIUploadMediaField
              type="file"
              name="certificate_animal_image"
              label="Certificate Animal"
              required={true}
              classField={"animal-image"}
              classImage={"image-cert"}
              fieldName={"Animal"}
              hideLabel={true}
              imageTypes={imageTypes}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
            />
            <WIUploadMediaField
              type="file"
              name="certificate_tree_image"
              label="Certificate Tree"
              required={true}
              classField={"tree-image"}
              classImage={"image-cert"}
              fieldName={"Tree"}
              hideLabel={true}
              imageTypes={imageTypes}
              disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
            />
          </div>
        </div>
        <div className="pdf-body">
          <div className="pdf-body-section-left">
            <p className="pdf-title">{content.primary_headline}</p>
            <p className="pdf-title-second">{content.secondary_headline}</p>
            <div className="pdf-donor">
              <p className="pdf-donor-title">{content.donor_title}</p>
              <p className="pdf-donor-fullname">{content.donor_fullname}</p>
              <p className="pdf-donor-reason">
                {isCampaign
                  ? (language === 'de' ? values.certificate_reason_description.de : values.certificate_reason_description.en)
                  : "Rette die Erde"}
              </p>
            </div>
            <div className="pdf-donor-area">
              <p className="pdf-area-amount" dangerouslySetInnerHTML={{__html: content.area_amount}}></p>
              <p className="pdf-area-title">{ values.forest_type ? values.forest_type[language] : content.area_title}</p>
              <p className="pdf-area-title-second">
                {content.area_title_second}
              </p>
            </div>
            <div className="pdf-co2">
              <p className="pdf-co2-amount" dangerouslySetInnerHTML={{__html: content.co2_amount}}></p>
              <p className="pdf-co2-title">
                {content.co2_title}
              </p>
            </div>
          </div>
          <div className="pdf-body-section-right">
            <div className="pdf-logo">
              {isCampaign ? (
                region_logo ? (
                  <img
                    src={`${env.PUBLIC_UPLOAD_URL}/${region_logo}?u=${moment().unix()}`}
                    alt="wi-logo"
                    className="land-logo image-cert"
                  />
                ) : (
                  ""
                )
              ) : (
                <WIUploadMediaField
                  type="file"
                  name="land_logo"
                  required={true}
                  fieldName="Region logo"
                  classImage={"image-cert"}
                  classField="land-logo"
                  hideLabel={true}
                  imageTypes={imageTypes}
                  disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                />
              )}
            </div>
            <p className="pdf-name">
              {values.name[language || 'de']}
            </p>

            <div className="pdf-info">
              <p className="geo-coordinate">S12°52'13.6236</p>
              <p className="geo-coordinate">W69°30'47.4012</p>
              <p className="certificate-description">
                {
                  language === 'de' 
                    ? (isCampaign ? values.certificate_campaign_description.de : values.certificate_description.de)
                    : (isCampaign ? values.certificate_campaign_description.en : values.certificate_description.en)
                }
              </p>

              <div className="qr-section">
                <div className="qr-description">
                  {
                    values.certificate_qr_code && 
                    <p>
                      {`${content.qr_description} `}
                      {
                        values.name[language || 'de']
                      }  {`${content.is} `}
                    </p>
                  }
                </div>
                <div className="qr-code-image">
                  <WIUploadMediaField
                    type="file"
                    name="certificate_qr_code"
                    label="Certificate QR Code"
                    fieldName={"QR Code"}
                    classImage={"image-cert"}
                    classField={"qr-code"}
                    hideLabel={true}
                    imageTypes={imageTypes}
                    disabled={!(permissions.canCreateRegion || permissions.canUpdateRegion)}
                  />
                </div>
              </div>

              <div className="donation-section">
                <div className="donation-section-info">
                  <p className="donation-serial">{content.donation_serial}</p>
                  <p className="donation-date">{content.donation_date}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="pdf-footer">
            <div className="pdf-footer-logo">
              <img
                src={`${env.PUBLIC_IMAGE_URL}/footer-logo.png?u=${moment().unix()}`}
                alt="footer"
              />
            </div>
            <div
              className="pdf-footer-signature"
              style={{ left: "139px", marginBottom: "11px" }}
            >
              <img
                src={`${env.PUBLIC_IMAGE_URL}/kai-signature.png`}
                alt="kaisign"
              />
              <p
                className="sign-person-name"
                style={{ margin: "-5px auto 0 auto" }}
              >
                {content.director_sign_person_name}
              </p>
              <p className="sign-person-detail">{content.director_sign_person_position}</p>
              <p className="sign-person-detail">{content.director_sign_person_company}</p>
              <p className="sign-person-detail">{content.director_sign_person_location}</p>
            </div>
            <div className="pdf-footer-signature" style={{ left: "293px" }}>
              <img
                src={`${env.PUBLIC_IMAGE_URL}/Kirkby_signature_transparent.png`}
                alt="kirkbysign"
              />
              <p
                className="sign-person-name"
                style={{ margin: "4px auto 0 auto" }}
              >
                {content.president_sign_person_name}
              </p>
              <p className="sign-person-detail">{content.president_sign_person_position}</p>
              <p className="sign-person-detail">{content.president_sign_person_company}</p>
              <p className="sign-person-detail">{content.president_sign_person_location}</p>
            </div>
            <div className="pdf-footer-signature" style={{ left: "440px" }}>
              <img
                src={`${env.PUBLIC_IMAGE_URL}/Tobias_signature_transparent.png`}
                alt="tobiassign"
              />
              <p
                className="sign-person-name"
                style={{ margin: "4px auto 0 auto" }}
              >
                {content.board_of_director_sign_person_name}
              </p>
              <p className="sign-person-detail">{content.board_of_director_sign_person_position}</p>
              <p className="sign-person-detail">{content.board_of_director_sign_person_company}</p>
              <p className="sign-person-detail">{content.board_of_director_sign_person_location}</p>
            </div>
            <div className="pdf-footer-signature" style={{ left: "590px" }}>
              <img
                src={`${env.PUBLIC_IMAGE_URL}/Henriette_transparent.png`}
                alt="henriettesign"
              />
              <p
                className="sign-person-name"
                style={{ margin: "4px auto 0 auto" }}
              >
                {content.board_sign_person_name}
              </p>
              <p className="sign-person-detail">{content.board_sign_person_position}</p>
              <p className="sign-person-detail">{content.board_sign_person_company}</p>
              <p className="sign-person-detail">{content.board_sign_person_location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (<div></div>);
};
export default PreviewForm;

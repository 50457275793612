import { useEffect, useState } from "react";
import React from "react";
import "./donor-filter-form.scss";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import CalendarFilterField from "../../../../components_v2/common/calendar-filter-field/calendar-filter-field";

const DonorFilterForm = (props: any) => {
  const { fields, onChange, setSearch, onRemove, filterValues, onSubmit } = props;
  const { t } = useTranslation('language', { keyPrefix: 'donor_manager' });
  const [isDirty, setDirty] = useState<boolean>(false);
  const [values, setValues] = useState<Map<string, string[]>>();

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
  };

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch('');
    await onRemove();
  };

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      filter.set(k, filterValues.where[k]);
    });
    setValues(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  return (
    <>
      <div className="filter-wrapper-v2">
        <div className="filter-form-container">
          <div className="filter-elements mb-16">
            <CalendarFilterField
              name="joined_date"
              label={t('txt_joined_date')}
              // className="wi-filter-option-donor"
              pannelClassName="top-mask-dialog"
              selectedItems={values?.get("joined_date") || []}
              visible={values?.has("joined_date") || true}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange} />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-start gap-24 mt-8">
                <Button
                    className="wi-danger-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t('txt_clear_filter')}`}
                    onClick={() => onRemoveFilters()}
                />
                <Button
                    className="wi-primary-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t('txt_apply')}`}
                    onClick={() => onSubmit(values, fields)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonorFilterForm;

import React from "react";
import { useRef } from "react";
import { Dialog } from "primereact/dialog";
import { AxiosResponse } from "axios";
import { Toast } from "primereact/toast";
import { showNotification } from "../../../../utils/logic";
import { QuoteBlock } from "../utils";
import { QuoteManagerAPI } from "../../../../services";
import DynamicForm from "../../../dynamic-form/dynamic-form";
import "./create-quote-dialog.scss";

const CreateQuoteDialogComponent = (props: any) => {
  const { visible, onHide, quote, fetchQuotesData } = props;
  const toast = useRef(null);
  const block = { ...QuoteBlock };

  const onSubmitDataForm = async (value: any) => {
    try {
      const resGenerateQuote = await requestQuoteData(value);
      handleRequestQuote(resGenerateQuote);
    } catch (error) {
      showNotification("error", "Failed to generate quote", toast);
    }
  };

  const handleRequestQuote = (resGenerateQuote: AxiosResponse<any, any>) => {
    // @ts-ignore: Object is possibly 'null'.
    const response = resGenerateQuote;
    let method = response.config.method;

    if (
      response &&
      response.status === 200 &&
      (method === "post" || method === "put")
    ) {
      let mesage =
        method === "post"
          ? "Generate quote successfully"
          : "Update quote successfully";
      showNotification("success", mesage, toast);
      onHide();
      fetchQuotesData();

    } else {
      showNotification("error", "Failed to generate quote", toast);
    }
  };

  const requestQuoteData = async (value: any) => {
    // @ts-ignore: Object is possibly 'null'.
    if (quote) {
      // @ts-ignore: Object is possibly 'null'.
      const quoteObj = quote;

      let request = { ...quoteObj, ...value };
      return updateQuote({
        ...request,
      });
    }

    return generateQuote({
      ...value,
    });
  };

  const generateQuote = async (parameters: any) => {
    return await QuoteManagerAPI.createQuote(parameters);
  };

  const updateQuote = async (parameters: any) => {
    quote.quote = parameters.quote;
    quote.owner_name = parameters.owner_name;
    quote.owner_position = parameters.owner_position;

    if (quote.owner_picture !== parameters.owner_picture) {
      quote.owner_picture = parameters.owner_picture;
    } else {
      delete quote["owner_picture"];
      delete quote[`${quote["owner_picture"]}_extension`];
    }

    return await QuoteManagerAPI.updateQuote(quote);
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="create-quote-dialog wi-dialog"
        header={quote?.uuid ? "Update Quote" : "New Quote"}
        visible={visible}
        style={{ width: "600px" }}
        modal
        onHide={onHide}
      >
        <DynamicForm
          formSchema={block?.configuration || {}}
          data={
            quote
              ? {
                    quote: quote.quote,
                    owner_name: quote.owner_name,
                    owner_position: quote.owner_position,
                    owner_picture: quote.owner_picture,
                }
              : block?.parameters
          }
          submitButtonConfig={{
            title: quote?.uuid ? "Update" : "Create",
            icon: "pi pi-chevron-right",
            iconPos: "right",
          }}
          onSubmitData={onSubmitDataForm}
          mode="dialog"
        ></DynamicForm>
      </Dialog>
    </>
  );
};

export default CreateQuoteDialogComponent;

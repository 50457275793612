import moment from 'moment';
import { env } from '../../../environment';
import { ReactComponent as StandardSvg } from '../../../assets/images/icons/video.svg';
import { ReactComponent as YoutubeSvg } from '../../../assets/images/icons/youtube.svg';
import { ReactComponent as VimeoSvg } from '../../../assets/images/icons/vimeo.svg';
import { ReactComponent as MediaSvg } from '../../../assets/images/icons/video-plus.svg';
import { ReactComponent as EarthSvg } from '../../../assets/images/icons/world-heart.svg';
import { ReactComponent as MapSvg } from '../../../assets/images/icons/map-2.svg';

import React from 'react';

export const VideoOptions = ['Default', 'Custom'];

export const TypeAreaCode = {
  land: 'land',
  region: 'region',
  campaign: 'campaign'
}

export const LEVEL_AREA = [
  {
    name: 'Region',
    code: TypeAreaCode.region,
    icon: <EarthSvg></EarthSvg>
  },
  {
    name: 'Area',
    code: TypeAreaCode.land,
    icon: <MapSvg></MapSvg>
  }
]

export const VIDEO_TYPE = {
  YOUTUBE: 'yt',
  VIMEO: 'vm',
  MEDIA_LIBRARY: 'ml',
  STANDARD: 'sd'
}

export const VideoOptionTypes = [
  {
    "label": "Standard",
    "value": VIDEO_TYPE.STANDARD,
    "icon": <StandardSvg></StandardSvg>
  },
  {
    "label": "YouTube",
    "value": VIDEO_TYPE.YOUTUBE,
    "icon": <YoutubeSvg></YoutubeSvg>
  },
  {
    "label": "Vimeo",
    "value": VIDEO_TYPE.VIMEO,
    "icon": <VimeoSvg></VimeoSvg>
  },
  {
    "label": "Media library",
    "value": VIDEO_TYPE.MEDIA_LIBRARY,
    "icon": <MediaSvg></MediaSvg>
  },
];

export const docData = `
  <!DOCTYPE html>
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <link rel="stylesheet" href="${env.CTA_LIBRARY_URL}/css/above_the_fold.min.css"/>
    <link rel="stylesheet" href="${env.CTA_LIBRARY_URL}/css/main.min.css"/>
    <link rel="stylesheet" href="${env.CTA_LIBRARY_URL}/cms-custom.css"/>
  </head>
  <body>
    <section class="selected-component">
      <div class="aw_content_video_module_small">
        <div class="aw_container" style="padding-left: 0px; padding-right: 0px">
          <div class="aw_row">
            <div class="col_12" style="max-width: 100%">
              <div class="aw_video_wrapper">
                {param}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <script defer src="${env.CTA_LIBRARY_URL}/js/min/theme.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/popper.min.js"></script>
    <script defer src="${env.CTA_LIBRARY_URL}/bootstrap.min.js"></script>
  </body>
`;

export const defaultVideoUrl: any = {
  de: "https://www.youtube.com/watch?v=nFZ0OgxCnds",
  en: "https://www.youtube.com/watch?v=AHXWWyJETXo",
  de_name: "",
  de_size: "",
  en_name: "",
  en_size: ""
};
export const defaultDataSource = "yt";
export const defaultMessage = {
  de: "Bei Spenden bis 300 € reicht der Kontoauszug als Nachweis aus. Innerhalb von 8 Wochen senden wir dir aber auch per E-Mail einen Link zu deinem Spenderportal, wo du dir deine Spendenquittung herunterladen kannst, wenn du sie benötigst.",
  en: "In Germany, the bank statement is sufficient as proof for donations of up to €300. However, we will send you a link to your donor portal via email within the next 8 weeks, where you can download your receipt if you need it.",
};
export const defaultMessageHeadline = {
  de: "Spendenquittung",
  en: "Donation Receipt",
};

function formatVideoHtml(url: any, type: any) {
  let urlId = "";
  let mediaVideoUrl = url ? `${env.PUBLIC_UPLOAD_URL}/${url}?u=${moment().minute()}` : "";
  if (type === "ml") {
    return `<iframe width="100%" height="485" src="${mediaVideoUrl}" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>`;
  }
  else if (type === "yt") {
    urlId = getVideoIdFromUrlYoutube(url);
  }
  else {
    urlId = getVideoIdFromUrlVimeo(url);
  }

  return `<div class="aw_video_optin_wrap" data-vwidth="" data-vheight="" data-source="${type}" data-video-id="${urlId}" data-video-url="${url}"  data-optin-msg="" data-optin-title=""></div>`;
}

export function replaceVideoUrl(url: string, video_type: string, is_default_video: any) {
  let expectedDocData = docData;
  let formatString = "";

  if (is_default_video) {
    formatString = formatVideoHtml(defaultVideoUrl.de, defaultDataSource);

  }
  else {
    formatString = formatVideoHtml(url, video_type);
  }
  expectedDocData = expectedDocData.replace(
    "{param}",
    formatString
  );

  return expectedDocData;
}

const getVideoIdFromUrlYoutube = (url: string) => {
  if (!url || url === "") {
    return "";
  }
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return (match && match[1]) || "";
};

const getVideoIdFromUrlVimeo = (url: string) => {
  if (!url || url === "") {
    return "";
  }
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/;
  const match = url.match(regex);
  return match ? match[1] : "";
}

import * as React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { useState, useEffect } from "react";
import Layout from "../../components_v2/common/layout/layout";
import moment, { utc } from "moment";
import "./power-bi-report.scss";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed, service } from "powerbi-client";
import PowerBIManager from "../../services/power-bi/powerBIManager";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const PowerBIReport = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const { t } = useTranslation('language', { keyPrefix: 'homepage' });
  const BOOKMARKS = [
    {
      id: 'cc97ce0546c34a4401e0',
      name: 'preset period'
    },
    {
      id: '630e6a9a73113a46115e',
      name: 'custom period'
    }
  ]

  const SLICERS = [
    {
      id: '7af192ef3a2796f77c2b',
      name: 'current period'
    },
    {
      id: '318a2d1c504b016b80c0',
      name: 'previous period'
    }
  ]

  // PowerBI Report object (to be received via callback)
  const [report, setReport] = useState<Report>();
  // Track Report embedding status
  const [isEmbedded, setIsEmbedded] = useState<boolean>(false); // CSS Class to be passed to the embedded component
  const reportClass = "report-container";
  // Pass the basic embed configurations to the embedded component to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set on click of button
  const [sampleReportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: "report",
      embedUrl: undefined,
      tokenType: models.TokenType.Embed,
      accessToken: undefined,
      settings: undefined,
    });

  /**
   * Map of event handlers to be applied to the embedded report
   * Update event handlers for the report by redefining the map using the setEventHandlersMap function
   * Set event handler to null if event needs to be removed
   * More events can be provided from here
   * https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/handle-events#report-events
   */
  const [eventHandlersMap, setEventHandlersMap] = useState<
    Map<
      string,
      (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
    >
  >(
    new Map([
      [
        "loaded",
        () => {
          console.log("Report has loaded");
        },
      ],
      [
        "rendered",
        (visual: any) => {
          setIsFirstLoading(false);
          setIsLoading(false);
          console.log("Report has rendered");
        },
      ],
      [
        "error",
        (event?: service.ICustomEvent<any>) => {
          if (event) {
            console.error(event.detail);
          }
        },
      ],
      ["pageChanged", (event) => console.log("PageChanged has rendered")],
      [
        "bookmarkApplied",
        (event) => {
          var bookmarkName = event?.detail?.bookmarkName;
          if (_.find(BOOKMARKS, (f: any) => f.id === bookmarkName)) {
            setIsLoading(true);
          }

          console.log("bookmarkApplied has raised");
        },
      ],
      [
        "dataSelected",
        (event: any) => {
          var slicerName = event?.detail?.visual?.name;
          if (_.find(SLICERS, (f: any) => f.id === slicerName)) {
            setIsLoading(true);
          }

          console.log("dataSelected has raised");
        },
      ]
    ])
  );

  useEffect(() => {
    if (report) {
      report.setComponentTitle("Embedded Report");
    }
  }, [report]);

  const embedReport = async () => {
    console.log("Call API to get embed report");

    const powerBIReportRes = await PowerBIManager.getPowerBIReport();
    if (!powerBIReportRes?.data) {
      setIsLoading(false);
      return;
    }

    localStorage.setItem(
      "powerbi_report",
      JSON.stringify(powerBIReportRes.data)
    );

    setReportConfigData(powerBIReportRes.data);
    setIsEmbedded(true);
  };

  const setReportConfigData = (powerbi_report_data: any) => {
    const reportConfig = {
      type: "report", // Supported types: report, dashboard, tile, visual, and qna.
      id: powerbi_report_data.report_id,
      embedUrl: powerbi_report_data.embed_url,
      accessToken: powerbi_report_data.token,
      tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
        },
        visualRenderedEvents: false,
        navContentPaneEnabled: false,
        visualSettings: {
          visualHeaders: [
            {
              settings: {
                visible: false,
              },
              /* No selector is listed. The API hides the headers of all the visuals in the report. */
            },
          ],
        },
      },
    };

    setReportConfig(reportConfig);
    setIsEmbedded(true);
  };

  useEffect(() => {
    setIsLoading(true);
    setIsFirstLoading(true);
    const powerbiReportData: any = localStorage.getItem("powerbi_report");
    if (!powerbiReportData) {
      embedReport();
      return;
    }

    const powerbiReportJson = JSON.parse(powerbiReportData);
    const expireTime = powerbiReportJson.expiration;
    const diffTime = utc().diff(expireTime);
    if (expireTime && diffTime < 0) {
      setReportConfigData(powerbiReportJson);
      return;
    }

    embedReport();
  }, []);

  const reportComponent = (
    <PowerBIEmbed
      embedConfig={sampleReportConfig}
      eventHandlers={eventHandlersMap}
      cssClassName={reportClass}
      getEmbeddedComponent={(embedObject: Embed) => {
        console.log(
          `Embedded object of type "${embedObject.embedtype}" received`
        );
        setReport(embedObject as Report);
      }}
    />
  );

  return (
    <Layout title={t('txt_title')}>
      <div className="report-layout">
        {
          isFirstLoading || isLoading ? (
            <div className={"main-loading-component" + (!isFirstLoading ? ' bg-white-10' : '')}>
              <ProgressSpinner />
            </div>
          ) : <></>
        }
        <div className="report-container">
          {isEmbedded ? reportComponent : null}
        </div>
      </div>
    </Layout>
  );
};

export default PowerBIReport;

import { AxiosClient } from './httpClient'

export default class GoalManagerAPI {
  static getGoals(data: any) {
    return AxiosClient.post(`/goals/search`, {...data});
  }

  static generateGoal(data: any) {
    return AxiosClient.post(`/goals`,  data);
  }

  static updateGoal(id: string, data: any) {
    return AxiosClient.put(`/goals/${id}`,  data);
  }

  static getGoalDetails(id: any) {
    return AxiosClient.get(`/goals/${id}`);
  }

}

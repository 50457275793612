import { useRef, useState } from "react";
import React from "react";
import "./update-sponsor-form.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { parseStringToFloat, showNotification } from "../../../../utils/logic";
import { WIButton } from "../../../common";
import { WildernessRunsManagerAPI } from "../../../../services";
import { InputNumber } from "primereact/inputnumber";

const UpdateSponsorForm = (props: any) => {
  const { onHide, fetchCallBack, toast, wildernessRunID, teamsID, sponsorSelected, runnerID} = props;

  const formikRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitFormSponsor = async () => {
    try {
        // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setIsLoading(true);
        let valuesData = {
          ...values,
        };

        let updateRes = await WildernessRunsManagerAPI.updateSponsor(wildernessRunID, teamsID, runnerID, sponsorSelected.id, valuesData);
          if (updateRes?.status === 200 && updateRes?.data) {
            showNotification(
              "success",
              "Update runner successfully.",
              toast
            );
            onHide();
  
            fetchCallBack(updateRes.data);
            return;
          }
      }
    } catch {
        showNotification("error", "Check runner failed.", toast);
        setIsLoading(false);
    }
  };

  const initialFormValues = () => {
    let initialTeam = {
      expected_money_per_units: 0,
    };

    if(sponsorSelected) {
      initialTeam.expected_money_per_units = sponsorSelected.expected_money_per_units || 0;
    }

    return initialTeam;
  };

  const validationSchema = Yup.object().shape({
    expected_money_per_units: Yup.number().nullable().min(0, "This field must be greater than 0."),
  });

  return (
    <>
      <div className="update-runner-form">
        <div className="update-runner-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-12">
                     <div className="donor-selected">
                        <div className="donor-item-selected">
                          <div className="name-item">
                            <span className="icon">
                              <i className="fa-solid fa-user"></i>
                            </span>
                            <span className="name">
                              {sponsorSelected?.name}
                            </span>
                          </div>
                          <div className="email-item">
                            {sponsorSelected?.payment_email}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form">
                        <label className="label">Expected Amount/Unit (€) *</label>
                        <InputNumber
                          value={values.expected_money_per_units}
                          name="expected_money_per_units"
                          onBlur={(e) => {
                              const value = e.target.value ? parseStringToFloat(e.target.value) : 0;
                              setFieldValue('expected_money_per_units', value, false);
                              setTimeout(() => {
                                  handleBlur(e);
                              }, 200);
                          }}
                          min={0}
                          mode="decimal" 
                          locale="de-DE" 
                          placeholder="0.00"
                          minFractionDigits={0}
                          maxFractionDigits={2}
                      />
                      </div>
                    </div>
                  </div>
                  <div className="btn-submit">
                    <WIButton
                      className="update-runner"
                      primary={true}
                      type="submit"
                      label="Update"
                      icon={"pi-angle-right"}
                      onClick={() => onSubmitFormSponsor()}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UpdateSponsorForm;

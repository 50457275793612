import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage, useFormikContext } from "formik";
import { WIFormElement } from "../wi-form-element/wi-form-element";
import * as _ from "lodash";
import { ToolbarsMultiLine, Plugins, ColorsEditor, ContentStyleForMultiLine } from "../../../common/header-editor/header-editor"
import { env } from "../../../../environment";

export function WiTextEditor(props: any) {
  const { name, values, disabled } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const editorRef = useRef<any>(null);
  
  return (
    <WIFormElement {...props}>
      <div className={`editor-wrapper editor-multilines ${disabled && "editor-disabled"}`}>
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          scriptLoading={{ async: true }}
          apiKey={env.EDITOR_API_KEY}
          init={{
            min_height: 200,
            height: 200,
            min_width: 500,
            menubar: false,
            // statusbar: false,
            resize: true,
            plugins: Plugins,
            toolbar: ToolbarsMultiLine,
            color_map: ColorsEditor,
            custom_colors: false,
            toolbar_mode: "sliding",
            branding: false,
            paste_as_text: true,
            content_style: ContentStyleForMultiLine,
            extended_valid_elements:
              "a[class|style|title|href|target|rel|onclick|id|name]",
            newline_behavior: "default",
            paste_data_images: false,
            setup: function (editor) {
              editor.ui.registry.addButton("removeContentButton", {
                icon: "remove",
                tooltip: "Remove content",
                onAction: function (_) {
                  editor.setContent("");
                },
              });
            },
          }}
          id={name}
          textareaName={name}
          onEditorChange={(newValue, editor) => {
            setFieldValue(name, newValue, true);
          }}
          onBlur={() => setFieldTouched(name)}
          value={_.get(values, name)}
          disabled={disabled}
        />
      </div>

      <ErrorMessage
        name={name}
        render={(msg: any) => <div className="error">{msg}</div>}
      />
    </WIFormElement>
  );
}
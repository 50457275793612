import { useRef } from "react";
import React from "react";
import "./invite-user-form.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { LANGUAGE_CODE, LANGUAGE_LIST, USER_PARTIES, TENANT_TYPES, WI_TENANT_ID, getRandomColor } from "../../../../components_v2/utils/utils";
import { UserManagerAPI } from "../../../../services/v2";
import PartnerItem from "../../../../components_v2/common/partner-item/partner-item";
import { useLayoutV2 } from "../../../../context/LayoutProvider";
import { WIFormDropdownV2, WIFormInputTextV2 } from "../../../../components_v2/common/form-fields";
import WIFormMultiSelectV2 from "../../../../components_v2/common/form-fields/wi-form-multiselect";

const InviteUserForm = (props: any) => {
  const { onHide, fetchCallBack, partners, roles } = props;
  const { setLoadingProgress, setSuccessProgress, setErrorProgress }= useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'system_users' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: globalTrans } = useTranslation('language');
  const formikRef = useRef(null);

  const onSubmitInvite = async () => {
    try {
        // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans('txt_sending'));
        const config = {
          language_code: values.language || LANGUAGE_CODE.ENGLISH,
          avartar_color: getRandomColor()
        }

        let valueUser = {
          ...values,
          first_name: values.first_name?.trim(),
          last_name: values.last_name?.trim(),
          display_name: values.display_name?.trim(),
          email: values.email?.trim()?.toLowerCase(),
          tenant_id: values.party === USER_PARTIES.WILDERNESS_INTERNATIONAL ? WI_TENANT_ID : values.partner_id,
          custom_config: JSON.stringify(config)
        };

        delete valueUser.partner_id;
        delete valueUser.language;
        
        let createRes = await UserManagerAPI.createUser(valueUser);
        setSuccessProgress(errorTrans('txt_sent'));
        if (createRes?.status === 200 && createRes?.data) {
          onHide();
          fetchCallBack();
          return;
        }
      }
    } catch (error: any){
      setErrorProgress(errorTrans('txt_email_existed'));
    }
  };

  const commonSelectedTemplate = (option: any) => {
    return (
        <div>{option?.label ? globalTrans(option?.label) : ''} </div>
    );
  };

  const commonSelectedItemsTemplate = (option: any) => {
    const selectedItem = roles.find((r: any) => r.uuid === option);
    if (selectedItem) {
      return (
          <span className="wi-tag-role mr-8">{selectedItem?.label ? globalTrans(selectedItem?.label) : ''} </span>
      );
    }
    return <></>;
  };
  
  const commonItemTemplate = (option: any) => {
    return (
        <div>{globalTrans(option?.label)} </div>
    );
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .transform((currentValue, originalValue) => {
        return !originalValue || originalValue.toString().trim() === "" ? null : currentValue;
      })
      .nullable()
      .required(errorTrans('txt_required')),
    last_name: Yup.string()
      .transform((currentValue, originalValue) => {
        return !originalValue || originalValue.toString().trim() === "" ? null : currentValue;
      })
      .nullable().required(errorTrans('txt_required')),
    email: Yup.string()
      .email(errorTrans('txt_email_format'))
      .required(errorTrans('txt_required')),
    roles: Yup.array().nullable().min(1, errorTrans('txt_required')).required(errorTrans('txt_required')),
    partner_id: Yup.string()
      .nullable()
      .when('party', {
        is: USER_PARTIES.PARTNER,
        then: Yup.string().required(errorTrans('txt_required')),
        otherwise: Yup.string().nullable()
      })
  });

  const initialFormValues = () => {
    let initialUser = {
      first_name: "",
      last_name: "",
      email: "",
      party: USER_PARTIES.WILDERNESS_INTERNATIONAL,
      roles: [],
      display_name: "",
      partner_id: "",
      language: "" || 'en'
    };

    return initialUser;
  };

  const langugeOptionTemplate = (option: any) => {
    return (
      <div className="wi-language-item">
        <p className="wi-language-label m-0">{globalTrans(option?.label)}</p>
        <div className="wi-language-icon ml-12 d-flex">
          <img alt={option?.text} src={option?.icon} loading="lazy" className="m-0" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="invite-form">
        <div className="invite-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              //console.log("values >>>", values);
            }}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row mb-24">
                    <div className="col-md-6">
                      <WIFormInputTextV2
                        name={`first_name`}
                        title={t('txt_first_name')}
                        isRequired={true}
                        maxLength={40}
                      />
                    </div>
                    <div className="col-md-6">
                      <WIFormInputTextV2
                        name={`last_name`}
                        title={t('txt_last_name')}
                        isRequired={true}
                        maxLength={40}
                      />
                    </div>
                  </div>

                  <div className="row mb-24">
                    <div className="col-md-6">
                      <WIFormInputTextV2
                        name={`display_name`}
                        title={t('txt_display_name')}
                        maxLength={16}
                      />
                    </div>
                    <div className="col-md-6">
                      <WIFormDropdownV2
                        name="language"
                        title={t('txt_language')}
                        isRequired={true}
                        options={LANGUAGE_LIST}
                        optionLabel={`label`}
                        optionValue="code"
                        appendTo="self"
                        valueTemplate={commonSelectedTemplate}
                        itemTemplate={langugeOptionTemplate}
                      />
                    </div>
                  </div>

                  <div className="row mb-24">
                    <div className="col-md-12">
                      <WIFormInputTextV2
                        name={`email`}
                        title={t('txt_email')}
                        isRequired={true}
                        maxLength={255}
                      />
                    </div>
                  </div>
                  
                  <div className="row mb-24">
                    <div className="col-md-12">
                      <WIFormDropdownV2
                        name="party"
                        title={t('txt_tenant')}
                        isRequired={true}
                        options={TENANT_TYPES}
                        optionLabel={`label`}
                        optionValue="code"
                        appendTo="self"
                        valueTemplate={commonSelectedTemplate}
                        itemTemplate={commonItemTemplate}
                        onChange={(e: any) => {
                          setFieldValue('roles', [], false);
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-24">
                    <div className="col-md-12">
                      <WIFormMultiSelectV2
                        name="roles"
                        title={t('txt_role')}
                        isRequired={true}
                        options={roles.filter((r: any) => r.is_host_only === (values?.party === USER_PARTIES.WILDERNESS_INTERNATIONAL ? 1 : 0)) || []}
                        optionLabel={`label`}
                        optionValue="uuid"
                        appendTo="self"
                        selectedItemTemplate={commonSelectedItemsTemplate}
                        itemTemplate={commonItemTemplate}
                      />
                    </div>
                  </div>
                  {
                    values?.party === USER_PARTIES.PARTNER && 
                    <div className="row mb-24">
                        <div className="col-md-12">
                          <WIFormDropdownV2
                            name="partner_id"
                            title={t('txt_partner')}
                            isRequired={true}
                            options={partners || []}
                            optionLabel={`name`}
                            optionValue="uuid"
                            appendTo="self"
                            itemTemplate={(option:any) => (<PartnerItem option={option}></PartnerItem>)}
                            filter
                            filterBy="name"
                            filterPlaceholder={t('txt_search')}
                          />
                        </div>
                    </div>
                  }

                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-start gap-24">
                        <Button
                            className="wi-danger-button-v2 h48 flex-1"
                            type="submit"
                            label={`${t('txt_cancel')}`}
                            onClick={() => onHide()}
                        />
                        <Button
                            className="wi-primary-button-v2 h48 flex-1"
                            type="submit"
                            label={`${t('txt_send')}`}
                            onClick={() => onSubmitInvite()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default InviteUserForm;

import * as React from "react";
import { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { fromJS } from "immutable";
import { InputText } from "primereact/inputtext";
import { TabPanel, TabView } from "primereact/tabview";
import Layout from "../layout/layout";
import { ProgressSpinner } from "primereact/progressspinner";
import { ContentBuilderComponent } from "./components/content-builder";
import { SCREEN_MODE_OPTIONS, EmptyPageInfo, S14Blocks, PAGE_CODE } from "./utils";
import { IComponent } from "./interfaces/IComponent";
import { IContent } from "./interfaces/IContent";
import CMsPageManagerAPI from "../../services/cmsPageManager";
import _ from "lodash";
import "./builder-page.scss";
import { Toast } from "primereact/toast";
import DynamicForm from "../dynamic-form/dynamic-form";
import { showNotification } from "../../utils/logic";
import useWindowDimensions from "../common/useWindowDimensions";
import { builderPage, callCreatePageAPI, callUpdatePageAPI, filterParentPages,
  findType, generateBlockCategories, generateComponent, getCMSPageById, loadGlobalContent, searchBlockItems,
  fetchRecords, getCMSPagesByType, updateBreadcrumbs, buildUrls, getInternalURLPages, fetchCMSParentPages, fetchCMSAllPages, getContentById, getLastPublishData, getFirstPublishData,
  parseJSON
}from "./builder-page.common";
import BuilderHeader from "./components/builder-header/builder-header";
import FloatingLayers from "./components/floating-layers/floating-layers";
import { BlockCategories } from "./components/block-categories/block-categories";
import { useBeforeUnload } from "../common/useBeforeUnload";
import WIBlockDialog from "./components/block-modal/block-modal";
import WithPreventNavigation from "../common/preventNavigation";
import moment from "moment";

const INT_LENGTH = 3;
const MIN = 0.3;

const BuilderPage = (props: any) => {
  const { blockPopupVisible, setBlockPopupVisible, shouldBlock, setShouldBlock, setConfirmedNavigation, confirmNavigation } = props;

  let { id } = useParams();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dashboard, setDashboard] = useState<IContent[]>([{ components: [] }]);
  const [activeTabView, setActiveTabView] = useState(0);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [selectedBlock, setSelectedBlock] = useState<any>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [layersVisible, setLayersVisible] = useState(true);
  const [headHtml, setHeadHtml] = useState('');
  const [ctaBlocks, setCTABlocks] = useState<any>([]);
  const [blockCategory, setBlockCategory] = useState<any>();
  const [langCode, setLangCode] = useState('de');
  const [screenMode, setScreenMode] = useState(SCREEN_MODE_OPTIONS.find(c => c.isSelected));
  const toast = useRef(null);
  const [selectedPage, setSelectedPage] = useState<any>();
  const [pageTypes, setPageTypes] = useState<any>([]);
  const [headers, setHeaders] = useState<any>([]);
  const [footers, setFooters] = useState<any>([]);
  const [parentPages, setParentPages] = useState<any>([]);
  const [isShowCreate, setIsShowCreate] = useState(true);
  const [footer, setFooter] = useState<any>(null);
  const [header, setHeader] = useState<any>(null);
  const [fullUrl, setFullUrl] = useState<any>('');
  
  const [globalContent, setGlobalContent] = useState<any>({
    contacts: [],
    downloads: [],
    faqs: [],
    projects: [],
    donation_examples: [],
    project_pages: [],
    search_content: [],
    partners: []
  });
  // const MAX = +(width / 2 > 700 ? (width / 2) / 1260 : 0.47).toFixed(2);
  const MAX = +((width / 2) / 1260).toFixed(2);
  const [zoomlevel, setZoomLevel] = useState(1);

  useBeforeUnload((event: any) => {
    if (shouldBlock) {
      event.preventDefault();
    }
  });

  const getCMSPageInfoById = async (id: any, loading?: boolean) => {
    if (!id) {
      return null;
    }

    const resPageDetail = await CMsPageManagerAPI.getCMSPageDetail(id || '');
    if (resPageDetail && resPageDetail.status === 200) {
      return resPageDetail.data.record;
    }
    return null;
  }

  const getMappingDataByPageType = (allPages: any, parentPages: any, pageCodes: PAGE_CODE[]) => {
    let project_pages = getCMSPagesByType(allPages, parentPages, pageCodes)
                      .map((i: any) => {
                        let og_seo_config = i.seo_configuration ? parseJSON(i.seo_configuration)?.og_seo_config : null;
                        return {
                          uuid: i.uuid,
                          page_name: i.name,
                          name: i.title,
                          description: og_seo_config?.seo_description,
                          picture: og_seo_config?.seo_image,
                          link: i.url,
                          last_published_at: getLastPublishData(i.custom_config),
                          first_published_at: getFirstPublishData(i.custom_config)
                        }
                      });
    return project_pages;
  }

  const fetchData = async () => {
    setIsLoading(true);
    const { footers, headers, ctaBlocks, htmlTemplate, ...globalContent } = await loadGlobalContent();
    let [
      // { pageTypes, footers, headers, ctaBlocks, htmlTemplate, ...globalContent }, 
      allPages, 
      pageInfo, 
      content
    ] = await Promise.all([
      fetchCMSAllPages(),
      // () => [],
      getCMSPageInfoById(id),
      getContentById(id)
    ]);
    const { categories } = generateBlockCategories(ctaBlocks);

    let parentPages = fetchCMSParentPages(allPages);
    setParentPages(parentPages);

    let project_pages = getMappingDataByPageType(allPages, parentPages, [PAGE_CODE.Press, PAGE_CODE.News, PAGE_CODE.Project, PAGE_CODE.Action, PAGE_CODE.Expedition]);
    let press_pages = getMappingDataByPageType(allPages, parentPages, [PAGE_CODE.Press, PAGE_CODE.News]);
    let overview_pages = getMappingDataByPageType(allPages, parentPages, []);
    let internal_urls = getInternalURLPages(allPages);
    let search_content = { internal_urls: internal_urls }
    let newGlobalContent = {...globalContent, project_pages, search_content, press_pages, overview_pages};

    if (id) {
      const arrayDashboard = await getCMSPageById(content, ctaBlocks, newGlobalContent);
      const { headerBlock, footerBlock } = builderPage(pageInfo, headers, footers);
      setFooter(footerBlock);
      setHeader(headerBlock);
      setDashboard(arrayDashboard);
      setTimeout(() => setShouldBlock(false), 100);
    }

    if (pageInfo) {
      setIsShowCreate(false);
    }
    setSelectedPage(pageInfo);
    setGlobalContent(newGlobalContent);
    setFooters(footers);
    setHeaders(headers);
    // setPageTypes(pageTypes);
    setCTABlocks(ctaBlocks);
    setHeadHtml(htmlTemplate);
    setBlockCategory(categories);
    setTimeout(() => setIsLoading(false), 1);
  }

  useEffect(() => {
    if (id && id.length === 36) {
      fetchData();
    } else {
      navigate('/cms-pages/create');
    }
  }, []);

  useEffect(() => {
    setShouldBlock(true);
  }, [dashboard]);

  const getParentURL = (page: any, allPages: any) => {
    let n = 0;
    let page_id = page.page_id;
    let nodes: any[] = [page.url];
    const dict: any = {};
    allPages.forEach((p: any) => { dict[p.id] = p; });

    while (page_id && n < 100) {
      const parentNode = dict[page_id];
      if (parentNode) {
        nodes.unshift(parentNode.url);
        page_id = parentNode.page_id;
      } else {
        page_id = null;
      }
      n++;
    }
    const url = nodes.join('/');
    return url;
  }

  useEffect(() => {
    if (parentPages.length > 0 && selectedPage) {
      setParentPages(filterParentPages(parentPages, selectedPage.uuid));
      updateBreadcrumbs(parentPages, selectedPage, ctaBlocks, dashboard);
      const url = getParentURL(selectedPage, parentPages);
      setFullUrl(url);
    }

  }, [selectedPage])

  useEffect(() => {
    const component = dashboard[0].components?.find((c) => c.key === selectedComponent);

    // Important: Reset Form
    setSelectedBlock(null);

    if (selectedComponent === 'page-main-header') {
      setTimeout(() => setSelectedBlock(header), 10);
    } else if (selectedComponent === 'page-main-footer') {
      setTimeout(() => setSelectedBlock(footer), 10);
    } else if (selectedComponent && component) {
      setTimeout(() => setSelectedBlock(component), 10);
    } else {
      setActiveTabView(0);
    }

    if (parentPages.length > 0 && selectedPage) {
      updateBreadcrumbs(parentPages, selectedPage, ctaBlocks, dashboard);
    }
  }, [selectedComponent]);

  const onBack = () => {
    if (id) {
      navigate(`/cms-pages/${id}`);
    }
  }

  const onUpdatePage = async (e: any = null) => {
    setIsLoading(true);
    const content = dashboard[0].components.map((c: any, index) => ({
      name: c.name,
      order: index,
      uuid: c.key,
      cta_block_id: c.blockId,
      is_visible: true,
      is_disabled: c.isDisabled,
      parameters: c.parameters,
      is_block_disabled: c.isBlockDisabled,
    }));

    if (id) {
      let dataDraft: any = { status: 'draft' };
      if(!header.is_primary) {
        dataDraft = {...dataDraft,  custom_config: { header: { ...header.parameters}  } }
      } 

      const result = await callUpdatePageAPI(id,
        dataDraft,
        () => showNotification("success", "Update content successfully", toast),
        () => showNotification("error", "Failed to generate ", toast)
      );
      const res = await CMsPageManagerAPI.update(id, content);
      setShouldBlock(false);
      setIsLoading(false);
    }
  };

  const saveChanges = async () => {
    setBlockPopupVisible(false);
    await onUpdatePage();
    setConfirmedNavigation(true);
  }

  const onPublish = async (e: any) => {
    setIsLoading(true);
    const content = dashboard[0].components.map((c: any, index) => ({
      name: c.name,
      order: index,
      uuid: c.key,
      cta_block_id: c.blockId,
      is_visible: true,
      is_disabled: c.isDisabled,
      parameters: c.parameters,
      is_block_disabled: c.isBlockDisabled,
    }));

    if (id && selectedPage) {
      const pageData = _.cloneDeep(selectedPage);
      pageData['custom_config'] = pageData['custom_config'] || {};
      pageData['custom_config']['published_info'] = pageData['custom_config']['published_info'] || [];
      pageData['custom_config']['published_info'].push({ time: moment().toISOString() })
      if(!header.is_primary) {
        pageData['custom_config']['header'] = {...header.parameters}
      }
      const res = await CMsPageManagerAPI.update(id, content);
      const result = await callUpdatePageAPI(id,
        { ...pageData, status: 'published'},
        () => showNotification("success", "Update content successfully", toast),
        () => showNotification("error", "Failed to generate ", toast)
      );
      const pageInfo = await getCMSPageInfoById(id);
      setSelectedPage(pageInfo);
      setShouldBlock(false);
    }

    setIsLoading(false);
  }

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, name: string, blockId: string) => {
    event.dataTransfer.setData('id', name);
    event.dataTransfer.setData('blockId', blockId);
    setIsDragging(true);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const onDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(false);
  };

  const onDragDrop = (event: React.DragEvent<HTMLDivElement>, containerId: string) => {
    setIsDragging(false);
    const name = event.dataTransfer.getData('id');
    const blockId = event.dataTransfer.getData('blockId');
    const component = ctaBlocks.find((c: any) => c.uuid === blockId);
    const newComponent: IComponent = generateComponent(name, component);

    const containerArray: string[] = containerId.split("_");
    containerArray.shift(); // ignore first param, it is string prefix

    const componentsPath: Array<number | string> = [];
    containerArray.forEach((id: string, index: number) => {
      componentsPath.push(parseInt(id, INT_LENGTH));
      componentsPath.push(index === 0 ? "components" : "children");
    });

    let componentState = fromJS(dashboard) as any;
    componentState = componentState.setIn(
      componentsPath,
      componentState.getIn(componentsPath).push(newComponent)
    );

    setDashboard(componentState.toJS());
    setSelectedComponent(`${newComponent.key}`);
  };

  const handleClearCanvas = () => {
    if (dashboard[0].components.length > 0) {
      let newDashboard: IContent[] = [];
      const components: any = [];
      newDashboard.push({ components });
      setDashboard(newDashboard);
    }
  }

  const handleSearchBlock = (e: any) => {
    const searchText = e.target.value;
    let isSearchBlock = searchText.length > 0 ? true : false;
    const compare = (str1: string, str2: string) => str1.toLowerCase().includes(str2.toLowerCase());
    const condition = (c: any) => ["name", "type", "code"].some((f) => compare(c[f], searchText));
    const draggableFilter: any = ctaBlocks.filter(condition);
    const searchBlocks = searchBlockItems(draggableFilter, isSearchBlock);

    setBlockCategory(searchBlocks);
  };

  const toggleFullScreen = (e: any) => {
    setZoomLevel(!isFullScreen ? 1 : Math.min(zoomlevel, MAX));
    setIsFullScreen(!isFullScreen);
    setSelectedComponent('');
  }

  const onSubmitDataForm = (value: any) => {
    const selectedCom = dashboard[0].components.find((c: any) => c.key === selectedBlock.key);
    if (selectedCom) {
      selectedCom.parameters = value;
    }

    if(!header.is_primary && selectedComponent === 'page-main-header') {
      setHeader({
        ...header,
        parameters: value
      })
    }
    setDashboard([...dashboard]);
  }

  return (
    <>
      <Layout contentMode="fullscreen-horizontal">
        {isLoading ? (
          <div className="loading-component">
            <ProgressSpinner />
          </div>
        ) : (
          <></>
        )}
        <Toast ref={toast} />
        <div className={`card page-wrapper`}>
          {/* className={isFullScreen ? 'page-wrapper-fullscreen' : ''} */}
          <FloatingLayers
            visible={!isFullScreen && layersVisible}
            dashboard={dashboard}
            setDashboard={setDashboard}
            footer={footer?.name}
            header={header?.name}
            selectedComponent={selectedComponent}
            setSelectedComponent={(id: string) => {
              setSelectedComponent(id);
              setActiveTabView(1);
            }}
          />
          <div className="row page-builder-header">
            <BuilderHeader
              onSave={onUpdatePage}
              onBack={onBack}
              onPublish={onPublish}
              onLangModeChange={(selectedLanguage: any) => setLangCode(selectedLanguage.code)}
              onScreenModeChange={setScreenMode}
              onZoom={setZoomLevel}
              toggleFullScreen={toggleFullScreen}
              setLayersVisible={setLayersVisible}
              setActiveTabView={setActiveTabView}
              fullUrl={fullUrl}
              settings={{
                zoomLevel: zoomlevel,
                layersVisible,
                isFullScreen,
                maxZoom: MAX,
                minZoom: MIN,
                activeTabView
              }}
            />
          </div>
          <div className="row builder-page">
            <div className={`col-12 col-md-6 p-0 m-0 d-flex flex-column align-items-center justify-content-start builder-content ${isFullScreen ? 'builder-content-closed' : ''} ${isDragging ? 'builder-content-on-dragging' : ''}`}>
              {dashboard.map((component: IContent, index: number) => {
                return (
                  <ContentBuilderComponent
                    key={`cb_${index}`}
                    id={`cb_${index}`}
                    cssClass={component.cssClass}
                    mode={screenMode}
                    components={[header, ...component.components, footer]}
                    selectedComponent={selectedComponent}
                    zoom={zoomlevel}
                    lang={langCode}
                    headHtml={headHtml}
                    onDragDrop={onDragDrop}
                    onDragOver={onDragOver}
                  />
                );
              })}
            </div>
            <div className={`col-12 col-md-3 p-0 m-0 builder-components ${activeTabView === null || isFullScreen ? 'builder-components-closed' : ''}`}>
              <TabView
                className="builder-tab-view"
                activeIndex={activeTabView}
                onTabChange={(e) => setActiveTabView(e.index)}
              >
                <TabPanel>
                  <div
                    className="blocks-content"
                    style={{ height: "100%", overflowY: "auto" }}
                  >
                    <div className="wi-search-container sticky-top">
                      <span className="p-input-icon-left w-100">
                        <i className="pi pi-search" />
                        <InputText
                          type="search"
                          onChange={(e) => handleSearchBlock(e)}
                          placeholder="Search block...."
                        />
                      </span>
                    </div>
                    <BlockCategories categories={blockCategory} setBlockCategory={setBlockCategory} onDragStart={onDragStart} onDragEnd={onDragEnd} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="editor-view">
                    <div className="editor-wrapper">
                      {selectedComponent && selectedBlock && selectedBlock?.configuration && <div className="editor">
                        <DynamicForm
                          formSchema={(selectedComponent === 'page-main-header' ? header : 
                            (selectedComponent === 'page-main-footer' ? footer : 
                            ctaBlocks.find((c: any) => c.uuid === selectedBlock?.blockId)))?.configuration || {}}
                          data={selectedBlock?.parameters}
                          disabled={selectedBlock?.isDisabled}
                          onSubmitData={onSubmitDataForm}
                          globalContent={globalContent}
                          isAutoSave={true}
                        >
                        </DynamicForm>
                      </div>}
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>

        <WIBlockDialog
          visible={blockPopupVisible}
          onStay={() => setBlockPopupVisible(false)}
          onLeave={confirmNavigation}
          onSaveChanges={saveChanges}
          message={
            <React.Fragment>
              <div className="mb-3">
                You have modified this item.
                <br/>
                You can save your changes, discard your changes, or cancel to continue editing.
              </div>
            </React.Fragment>
          }
        >
        </WIBlockDialog>
      </Layout>
    </>
  );
};

export default WithPreventNavigation(BuilderPage);

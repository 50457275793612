const SEARCH_FIELDS = [
    "serial",
    "name",
    "code"
  ];

  export const getObjectWithValues = (where_obj: any) => {
    const tempt_obj: any = {};
    for (const [key, value] of Object.entries(where_obj)) {
      if ((Array.isArray(value) || typeof value === "string") && value.length > 0) {
        tempt_obj[key] = value;
      }
    }
    return tempt_obj;
};
  
  export const getPayloadConditions = (where_obj: any, sort_config: any) => {
    const { sortField, sortOrder } = sort_config;
    const where_conditions = getObjectWithValues(where_obj);
    const mapper: any = {
        serial: [["serial"]],
        name: [["name"]],
        code: [["code"]],
        region_id: [["region_id"]]
    };
    const orders = mapper[sortField];
    orders.forEach((r: any) => r.push(sortOrder === -1 ? "DESC" : "ASC"));
    const order = [...orders];
    return {
        where: where_conditions,
        order
    };
};
  
  export const getObjectSearchValue = (value: string) => {
    const search_result: any[] = [];
    SEARCH_FIELDS.forEach((key: string) => {
      const obj = {
        [key]: value,
      };
      search_result.push(obj);
    });
  
    return search_result;
  }; 
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from "primereact/selectbutton";
import "./wi-multi-source-dropdown.scss";
import * as _ from "lodash";
import RegionManagerAPI from "../../../../services/regionManager";
import CampaignManagerAPI from "../../../../services/campaignManager";
import { env } from '../../../../environment';
import { Buffer } from 'buffer';

export function MultiSourceDropdown(props: any) {
  const { values, disabled, schema } = props;
  const progressOptions =
  [
    { name: "Region", value: 0 },
    { name: "Campaign", value: 2 },
  ];

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [sources, setSources] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [regionSource, setRegionSource] = useState<any>([]);
  const [campaignSource, setCampaignSource] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<any>();
  const [progressOptionValue, setProgressOptionValue] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>({
    regions: [],
    campaigns: []
  });
 
  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    mappingDataSource();
  }, [regionSource, campaignSource]);

  useEffect(() => {
    loadOriginalData();
  }, [regions, campaigns]);

  useEffect(() => {
    updateFieldValue(selectedValues);
  }, [selectedValues, values.headline, values.donation_page_picture]);

  useEffect(() => {
    const progressOption = getProgressOptionText(progressOptionValue);
    if (progressOption && progressOption?.name === "Region") {
      setSelectedValues(selectedItem.regions);
    }
    else {
      setSelectedValues(selectedItem.campaigns);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (progressOptionValue) {
      const progressOption = getProgressOptionText(progressOptionValue);
      // setFieldValue("progress_option_text", progressOption?.name?.toString().toLowerCase());
    }      
  }, [progressOptionValue]);

  const fetchAllData = async () => {
    const [regionData, campaignsData] = await Promise.all([
      fetchRegionsData(),
      fetchCampaignsData(),
    ]);

    setRegionSource(regionData);
    setCampaignSource(campaignsData);
  };

  const loadOriginalData = () => {
    let originalOptionValue = values.progress_option;
    if (originalOptionValue === 1) {
      originalOptionValue = 0; // Set default Region option
    }

    let originalSelectedItems: any = [];
    let ids = values.dropdown_id.split(";");
    const progressOption = getProgressOptionText(originalOptionValue);
    if (progressOption && progressOption?.name === "Region") {
      //@ts-ignore
      ids.forEach(id => {
       let originalSelectedItem = _.find(regions, { id: id });
       if (originalSelectedItem) {
        originalSelectedItems.push(originalSelectedItem);
       }
      });
    }
    else {
       //@ts-ignore
       ids.forEach(id => {
        let originalSelectedItem = _.find(campaigns, { id: id });
        if (originalSelectedItem) {
          originalSelectedItems.push(originalSelectedItem);
         }
       });
    }

    setProgressOptionValue(originalOptionValue);
    onChangeProgressOption(originalOptionValue, progressOption?.name);
    onUpdateSelectedValue(originalOptionValue, originalSelectedItems);
  };

  const fetchRegionsData = async () => {
    try {
      const res = await RegionManagerAPI.getAllRegions();
      if (res && res.status === 200) {
        let regionData = res.data.records || [];
        if (!!regionData) {
          return regionData;
        }
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCampaignsData = async () => {
    try {
      const res = await CampaignManagerAPI.getAllCampaigns();
      if (res && res.status === 200) {
        let campaigns = res.data.records || [];
        if (!!campaigns) {
          return campaigns;
        }
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  const mappingDataSource = () => {
    let regionData = regionSource.map((region: any) => ({
      id: region.uuid,
      name: region.name,
      size: {
        protected_percent: Math.round(calculatePercentProtected(region?.size, (region?.size - region?.protected_size))),
        protected_size: region?.protected_size || 0,
        total_size: region?.size || 0,
        amount_co2: convertKgToTon(region?.protected_kg_co2) || 0,
        no_donations: region?.no_donations || 0,
        no_subscriptions: region?.no_subscriptions || 0,
        no_subscriptions_monthly: region?.no_subscriptions_monthly || 0,
        no_subscriptions_yearly: region?.no_subscriptions_yearly || 0,
      },
      code: region?.identifier
    }));
    setRegions(regionData);

    let campaignData = campaignSource.map((campaign: any) => ({
      id: campaign.uuid,
      area_id: campaign.land_id,
      name: campaign.name,
      size: {
        protected_percent: Math.round((100 * (campaign?.protected_size || 0)) / parseInt(campaign?.land?.size)),
        protected_size: parseInt(campaign?.protected_size || 0),
        total_size: parseInt(campaign?.land?.size),
        amount_co2: convertKgToTon(+(campaign?.protected_kg_co2 || parseInt(campaign.protected_size || 0) * 60)),
        no_donations: parseInt(campaign?.no_donations) || 0,
        no_subscriptions: parseInt(campaign?.no_subscriptions) || 0,
        no_subscriptions_monthly: parseInt(campaign?.no_subscriptions_monthly) || 0,
        no_subscriptions_yearly: parseInt(campaign?.no_subscriptions_yearly) || 0,
      },
      code: campaign?.code,
    }));
    setCampaigns(campaignData);
  };

  const onChangeProgressOption = (progressOptionValue: any, progressOptionName: any = "") => {
    setFieldValue("progress_option", progressOptionValue);

    if (progressOptionName === "Region") {
      setSources(regions);
      setSelectedValues(selectedItem.regions);
    }
    else {
      setSources(campaigns);
      setSelectedValues(selectedItem.campaigns);
    }
  };

  const onUpdateSelectedValue = (progressOption: any , value: any) => {
    const option = getProgressOptionText(progressOption);
    if (option && option?.name === "Region") {
      setSelectedItem({regions: value});
    }
    else {
      setSelectedItem({campaigns: value});
    }
  }

  const calculatePercentProtected = (totalSize: number, availableSize: number) => {
    let protectedSize = totalSize - availableSize;
    return (100 * protectedSize) / totalSize;
  };

  const convertKgToTon = (kg: any) => {
      return Math.trunc(kg/1000);
  }

  const updateFieldValue = (value: any) => {
     
    if (value && value?.length > 0) {
        const progressOption = getProgressOptionText(progressOptionValue);
        
        let url = '';
        let firstValueItem = value[0];
        if (firstValueItem?.code && firstValueItem?.code !== undefined){
            if (progressOption && progressOption?.name === "Region") {
                url = `${env.DONATION_URL}?region=${firstValueItem?.code}`;
            }
            else if (progressOption && progressOption?.name === "Campaign") {
                url = `${env.DONATION_URL}?campaign=${firstValueItem?.code}`;
            }
        }

        const ids =  _.map(value, 'id').join(';');
        const type = progressOption?.name.toLowerCase();
        const headline = values?.headline?.de || '';
        const headerImage = values?.donation_page_picture || '';
        const noDonations = _.sumBy(value, 'size.no_donations');
        setFieldValue("donations_page_link.de", `${env.CMS_URL}/spenden?id=${encodeURIComponent(Buffer.from(JSON.stringify({ids, type, headline, noDonations, headerImage}), 'utf8').toString('base64'))}`);
        setFieldValue("link.de", url);
        setFieldValue("link.option", 1);
        setFieldValue("data_type", type);
        setFieldValue('dropdown_id', ids);
        setFieldValue('data_src_id', ids);
    }
  };

  const customItemTemplate = (item: any) => {
    return item ? (
      <div>
        <div dangerouslySetInnerHTML={{ __html: `${item?.name?.de} - ${item?.size?.protected_size?.toLocaleString()}/${item?.size?.total_size?.toLocaleString()} m²`}}>
        </div>
      </div>
    ) : <></>;
  };

  const getProgressOptionText = (progressOptionValue: any) => {
    return progressOptions.find((po) => po.value === progressOptionValue);
  }
  
  return (
    <div className="form-element">
      <div className="form-element-title" style={{ marginBottom: "15px" }}>
        {schema.label}
      </div>
      <div style={{ margin: "-10px" }}>
        <div className="apply-btn" style={{ display: "flex" }}>
          <div className="select-group">
            {sources ? (
              <>
                <div style={{ marginRight: "10px" }}>
                  <SelectButton
                    hidden={disabled}
                    className="wi-form-select-button"
                    optionLabel="name"
                    value={progressOptionValue}
                    options={progressOptions}
                    onChange={(e: any) => {
                      setProgressOptionValue(e.value);
                      const progressOption = getProgressOptionText(e.value);
                      onChangeProgressOption(
                        e.value,
                        progressOption?.name
                      );
                    }}
                    unselectable={false}
                  />
                </div>
                {
                  <MultiSelect
                  className="wi-multi-select-dropdown" 
                  optionLabel="name.de"
                  itemTemplate={customItemTemplate}
                  dropdownIcon={"pi pi-chevron-down"}
                  value={selectedValues}
                  options={sources}
                  maxSelectedLabels={1}
                  selectedItemTemplate={customItemTemplate}
                  appendTo={"self"}
                  onChange={(item: any) => {
                    setSelectedValues(item.value);
                    onUpdateSelectedValue(progressOptionValue, item.value);
                    }}
                  filter
                  filterBy={schema.sourceConfiguration.searchFields?.join(",")}
                  />
                }
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
import React, { useState, useEffect } from "react";
import {Link, useNavigate, useSearchParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import { Sidebar } from "primereact/sidebar";
import "./subscription-component.scss";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useAuth from "../../context/useAuth";
import { env } from "../../environment";
import { SUBSCRIPTION_STATUS } from "../../utils/utils";
import { useLayoutV2 } from "../../context/LayoutProvider";
import { DonorManagerAPI, SubscriptionManagerAPI } from "../../services";
import Layout from "../../components_v2/common/layout/layout";
import MenuDot from "../../components_v2/common/menu-dot/menu-dot";
import WIPaginator from "../../components_v2/common/wi-paginator/wi-paginator";
import WISearchField from "../../components_v2/common/search/wi-search-field";
import WiStatus from "../../components_v2/common/wi-status/wi-status";
import { PERMISSIONS_V2, SUBSCRIPTION_RHYTHM_LIST, SUBSCRIPTION_STATUS_LIST } from "../../components_v2/utils/utils";
import SubscriptionFilterComponent from "./subscription-filter/subscription-filter-component";
import DownloadCertificateDialogComponent from "./download-certificate/download-certificate-dialog";
import { getObjectSearchValue, getObjectWithValues } from "./subscription-component-util";
import { ReactComponent as FilterSvg } from '../../assets/images/icons/filter-cog.svg';
import { formatDateTimeV2, formatDecimalV2 } from "../../utils/logic";

export type SubscriptionFilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[];
    status: string[];
    rhythm: number[];
    collection_date: string[];
    finished_date: string[];
    start_date: string[];
  };
  order: [];
};

const SubscriptionsManager = () => {
  const navigate = useNavigate();
  const { setLoadingProgress, setSuccessProgress, setErrorProgress, getLanguage } = useLayoutV2();
  const { t } = useTranslation('language', { keyPrefix: 'subscription_manager' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { t: globalTrans } = useTranslation('language');
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get("pageNumber") || 1) - 1, 0);
  const noRows = Math.min(Math.max(+(searchParams?.get("noRows") || 50), 50), 1000);
  const sortField = searchParams?.get("sortField") || "serial";
  const sortOrder = +(searchParams?.get("sortOrder") || -1);
  const filterStatus = (searchParams?.get("filterStatus") || SUBSCRIPTION_STATUS.map((c) => c.code).join(",")).split(",");
  const filterRhythm = (searchParams?.get("filterRhythm") || SUBSCRIPTION_RHYTHM_LIST.map((c) => c.code).join(",")).split(",").map(Number);
  const filterStartDate = searchParams?.get("filterStartDate") ? searchParams?.get("filterStartDate")?.split(",") || [] : [];
  const filterCollectionDate = searchParams?.get("filterCollectionDate") ? searchParams?.get("filterCollectionDate")?.split(",") || [] : [];
  const filterFinishedDate = searchParams?.get("filterFinishedDate") ? searchParams?.get("filterFinishedDate")?.split(",") || [] : [];
  const searchField = searchParams?.get("search") || "";
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const [donations, setDonations] = useState({
    data: [] as any,
    totalPage: 0,
  });
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(pageNumber);
  const [first, setFirst] = useState(pageNumber * noRows);
  const [rows, setRows] = useState(noRows);
  const [isShowDownloadCertificateDialog, setIsShowDownloadCertificateDialog] = useState(false);
  const [yearsDonation, setYearsDonation] = useState([])
  const [certificateData, setCertificateData] = useState<any>({
    id : '',
    name: ''
  });
  const [filterValues, setFilterValues] =
    useState<SubscriptionFilterParameters>({
      pageNo: pageNumber,
      range: noRows,
      search: searchField,
      where: {
        or: searchField ? getObjectSearchValue(searchField) : [],
        status: filterStatus,
        rhythm: filterRhythm,
        collection_date: filterCollectionDate,
        finished_date: filterFinishedDate,
        start_date: filterStartDate,
      },
      order: [],
    });
    const [search, setSearch] = useState<any>(filterValues.search);

    const { auth } = useAuth();

    const permissions = {
        canViewDonation: auth?.permissions?.includes(PERMISSIONS_V2.DONATION_VIEW),
    };

  const getRecurringDonation = async (search: any) => {
    setLoading(true);
    delete search.where.collection_date;
    delete search.where.start_date;
    delete search.where.finished_date;

    const res = await SubscriptionManagerAPI.getSubscriptionsBySearch(search);
    if (res && res.status === 200) {
      if (res.data.total > 0) {
        setDonations({
          data: res.data.records,
          totalPage: res.data.total
        });
      } else {
        setDonations({
          data: [],
          totalPage: res.data.length || 0,
        });
      }
      setLoading(false);
    }
  };

  const preprocessSerial = (str: any) => {
    if (!str || _.isNumber(str)) {
      return str;
    } 
    const parts = str.split('-');
    return parts.length > 1 ? parts[1] : parts[0];
  }

  useEffect(() => {
    const where_conditions = getObjectWithValues(filterValues.where);
    var filter = {
      pageNo: (page).toString(),
      range: rows.toString(),
      where: {
        ...where_conditions,
        serial: preprocessSerial(filterValues.search),
        search_name: filterValues.search,
      },
      order: [sortConfig.sortField, sortConfig.sortOrder === -1 ? "DESC": "ASC"],
    }

    const searchParams = { 
      pageNumber: (page).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterStatus: filterValues.where.status?.join(',') || '',
      filterRhythm: filterValues.where.rhythm?.join(',') || '',
      filterStartDate: filterValues.where.start_date?.join(',') || '',
      filterCollectionDate: filterValues.where.collection_date?.join(',') || '',
      filterFinishedDate: filterValues.where.finished_date?.join(',') || '',
    }

    setSearchParams(_.omitBy(searchParams, (p) => !p), { replace: true });
    getRecurringDonation(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  useEffect(() => {
    if (typeof page === "number") {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows
      });

       setSearchParams({ 
        pageNumber: (page).toString(),
        noRows: rows.toString()
      }, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const buildStatusColumnTemplate = (rowData: any, column: any) => {
    const status = _.isString(rowData[column.field]) ? rowData[column.field] : "pending";
    const statusItem = SUBSCRIPTION_STATUS_LIST.find((item: any) => item.code === status);
    return <WiStatus item={statusItem}></WiStatus>
  };

  const formatAmountBodyTemplate = (rowData: any) => {
    return (
      <div>
        <b style={{ fontSize: "13px", color: "black", marginRight: "5px" }}>
          {rowData.status !== "finished" ? formatDecimalV2(rowData.amount, getLanguage()) : "-"}
        </b>{" "}
        <span>{rowData.currency?.toUpperCase()}</span>
      </div>
    );
  };

  const formatCollectionDateBodyTemplate = (rowData: any) => {
    const value = formatDateTimeV2(rowData.collection_date, getLanguage());
    return rowData.status === "finished" ? "-" : value;
  };

  const formatFinishedDateBodyTemplate = (rowData: any) => {
    const value = formatDateTimeV2(rowData.finished_date, getLanguage());
    return rowData.status === "active" ? "-" : value;
  };

  const tableColumns = [
    {
      field: "serial",
      header: t('txt_parent_id'),
      width: "130px", 
      align: "left",
      bodyTemplate: (rowData: any) => {
        return <Link to={`/donations/${rowData.id}`} className="table-ref-link-cell" >{rowData.serial}</Link>;
      }
    },
    {
      field: "payment_first_name",
      header: t('txt_name'),
      width: "150px", 
      align: "left",
      bodyTemplate: (rowData: any) => {
        return (rowData.payment_first_name || '') + " " + (rowData.payment_last_name || '');
      }
    },
    {
      field: "payment_email",
      header: t('txt_email'),
      width: "300px", 
      align: "left",
    },
    {
      field: "created_at",
      header: t('txt_start_date'),
      width: "250px", 
      align: "left",
      bodyTemplate: (rowData: any) => formatDateTimeV2(rowData?.created_at, getLanguage()),
      disabledSort: false,
    },
    {
      field: "collection_date",
      header: t('txt_collection_date'),
      width: "250px", 
      align: "left",
      bodyTemplate: formatCollectionDateBodyTemplate,
      disabledSort: false,
    },
    {
      field: "donation_money",
      header: t('txt_collection_amount'),
      width: "190px", 
      align: "left",
      bodyTemplate: formatAmountBodyTemplate,
    },
    {
      field: "status",
      header: "",
      width: "130px", 
      align: "center",
      bodyTemplate: buildStatusColumnTemplate,
      disabledSort: true,
    },
    {
      field: "rhythm",
      header: t('txt_rhythm'),
      width: "130px",
      align: "left",
      bodyTemplate: (rowData: any) => {
        return globalTrans(SUBSCRIPTION_RHYTHM_LIST.find(c => c.code === rowData.rhythm)?.name || 'rhythms.txt_once');
      }
    },
    {
      field: "finished_date",
      header: t('txt_finished_date'),
      align: "left",
      bodyTemplate: formatFinishedDateBodyTemplate,
      width: "200px",
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemplate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const handleGenerateYearsDonation = (start_date: any, end_date: any) => {
    let current_year = moment(new Date()).format("YYYY").toString();
    let years: any = [];

    end_date = end_date ? end_date : moment(new Date()).format("YYYY-MM-DD").toString()
    for (var m = moment(start_date, 'YYYY-MM-DD'); m.isBefore(end_date); m.add(1, "year")) {
      years.push(m.format("YYYY"));
    }

    if (years.length === 0) {
      years.push(current_year)
    }

    setYearsDonation(years);
  };

  const handleDownloadCertificate = (rowData: any) =>{
    handleGenerateYearsDonation(rowData?.created_at, rowData?.finished_date);
    let data = { id: rowData?.id, name: rowData?.serial}
    setCertificateData(data);
    setIsShowDownloadCertificateDialog(true);
  }

  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const copyLinkDonation = (rowData: any) => {
    navigator.clipboard.writeText(`${window.location.origin}/donations/${rowData.id}`).then(() => {
      setSuccessProgress(errorTrans("txt_copied_parent_success"));
    });
  }

  const onOpenDonorPortal = async (e: any, rowData: any) => {
    if (rowData) {
      setLoadingProgress(errorTrans('txt_loading'));
      const paymentEmail = rowData.payment_email?.toLowerCase();
      const donorData = {
        donorEmail: paymentEmail,
        language: "de",
      };

      try {
        let newTab: any = window.open();
        const response = await DonorManagerAPI.generateDonorPortalURL(donorData);
        if (response?.status === 200 && response?.data) {
          const data = response.data;
          // @ts-ignore: Object is possibly 'null'.
          newTab.location.href = data.link;
          setSuccessProgress(t('txt_link_donor_portal_success'));
        } else {
          throw new Error("txt_link_donor_portal_fail");
        }
      } catch (error: any) {
        setErrorProgress(errorTrans(error?.message || 'txt_link_donor_portal_fail'));
      }  
    }
  };

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: t('txt_open_parent_donation'),
        // description: t('txt_open_parent_donation_details'),
        icon: "wi-open",
        url: `/donations/${rowData.id}`,
        hidden: !permissions.canViewDonation
      },
      {
        label: t('txt_open_subscription'),
        // description: t('txt_open_subscription_details'),
        icon: "wi-open",
        externalUrl: `${env.BASE_STRIPE_URL}/subscriptions/${rowData.subscription_id}`,
      },
      {
        label: t('txt_open_donor_portal'),
        // description: t('txt_open_donor_portal'),
        icon: "wi-open",
        command: (e: any) => onOpenDonorPortal(e, rowData),
      },
      {
        label: t('txt_download_certificate'),
        // description: t('txt_download_certificate_as_pdf'),
        icon: "wi-download",
        command: (e: any) => handleDownloadCertificate(rowData),
      },
      {
        label: t('txt_copy_parent_donation_link'),
        icon: 'wi-copy',
        command:(e: any) => copyLinkDonation(rowData),
        // description: t('txt_copy_parent_donation_link_to_clipboard'),
      }
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  const onFilterChange = (values: any, fields: any) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    fields.forEach((field: any) => {
      if (field !== "start_date" && field !== "finished_date" && field !== "collection_date") {
        const data = values?.get(field);
        where_conditions[field] = data;
      } else {
        const data = values?.get(field);
        if(data && data.length > 0){
          if (data) {
            const dates = data?.map((c: any) => _.isString(c) ? c : moment(c).format("DD/MM/YYYY"));
            where_conditions[field] = dates;
            where_conditions["from_"+field] = dates[0];
            where_conditions["to_"+field] = dates[1];
          }
        }
      }
    });
    
    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order,
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    p.where["status"] = [...SUBSCRIPTION_STATUS.map((c) => c.code)];
    p.where["rhythm"] = [...SUBSCRIPTION_RHYTHM_LIST.map((c) => c.code)];
    p.search = "";
    setFilterValues(p);
  };

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getObjectWithValues(filterValues.where);
    const or_conditions = getObjectSearchValue(searchText);
    where_conditions["or"] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  return (
    <Layout title={t('txt_title')} className="subscription-manager">
        <div className="header">
          <div className="header-content pt-16 pb-16 pl-24 pr-24">
            <div className="search-container">
              <WISearchField
                icon={"pi pi-search"}
                placeholder={t('txt_search')}
                setSearch={(value: any) => setSearch(value.global.value)}
                enterSearch={(value: any) => onSearchFunc(search)}
              />
              <div className="filter-btn">
                <div
                  className="filter-btn-content p-8 ml-16"
                  onClick={() => setIsShowSidebarFilter(true)}
                >
                  <FilterSvg ></FilterSvg>
                </div>
              </div>
              {/* <MenuButton label={t('txt_actions')} items={[...buildActionMenu(null)]}></MenuButton> */}
            </div>
          </div>
        </div>
        <div className="p-table-v2 pt-12 pl-24 pr-24">
          <DataTable
            loading={loading}
            value={donations.data}
            scrollable
            dataKey="code"
            scrollDirection="both"
            lazy
            selection={selectedSubscriptions}
            emptyMessage={t('txt_no_records')}
            onSelectionChange={(e) => {
              setSelectedSubscriptions(e.value);
            }}
            onSort={(e: any) => {
              setSortConfig({
                sortField: e.sortField,
                sortOrder: e.sortOrder,
              });
            }}
            sortField={sortConfig.sortField}
            sortOrder={sortConfig.sortOrder}
          >
            {dynamicColumns}
            <Column
              frozen
              alignFrozen="right"
              className="p-align-right"
              style={{ width: "64px" }}
              body={buildMenu}
            ></Column>
          </DataTable>
          <WIPaginator
            first={first}
            rows={rows}
            totalRecords={donations.totalPage}
            onPageChange={onBasicPageChange}
          />
        </div>
        <Sidebar
          visible={isShowSidebarFilter}
          position="right"
          className="wi-sidebar-v2 p-sidebar-md sidebar-right"
          style={{ width: '400px' }}
          onHide={() => setIsShowSidebarFilter(false)}
        >
          <div className="sidebar-content">
            <div className="headline pt-24 pb-24">
              <h6>{t('txt_filter')}</h6>
            </div>
            <SubscriptionFilterComponent
              fetchCallBack={() => setIsShowSidebarFilter(false)}
              filterValues={filterValues}
              onRemove={onFilterRemove}
              setSearch={setSearch}
              onHide={() => setIsShowSidebarFilter(false)}
              fields={["rhythm","status","collection_date","start_date","finished_date"]}
              onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
            />
          </div>
        </Sidebar>
        <Sidebar
          visible={isShowDownloadCertificateDialog}
          position="right"
          className="wi-sidebar-v2 p-sidebar-md sidebar-right"
          style={{ width: '400px' }}
          onHide={() => setIsShowDownloadCertificateDialog(false)}>
          <div className="sidebar-content">
            <div className="headline pt-24 pb-24">
              <h6>{t('txt_download_certificate')}</h6>
            </div>
            <DownloadCertificateDialogComponent
              onHide={() => setIsShowDownloadCertificateDialog(false)}
              data={certificateData}
              yearsDonation={yearsDonation}
            />
          </div>
        </Sidebar>
    </Layout>
  );
};

export default SubscriptionsManager;

import * as React from "react";
import { useRef } from "react";
import { Menu } from "primereact/menu";
import "./menu-button.scss";
import { Button } from "primereact/button";

const MenuButton = (props: any) => {
  const { items } = props;
  const refMenu = useRef<any>(null);

  const handleClickEvent = (event: any) => {
    refMenu?.current?.toggle(event);
  };

  return (
    <div className="button-action" onClick={handleClickEvent}>
      <Menu
        className="button-action-menu"
        model={items || []}
        popup
        ref={refMenu}
      />
      <Button
        type="button"
        className="code-action-buton"
        icon="fa-solid fa-circle-chevron-down"
        label="Action"
        iconPos="right"
      />
    </div>
  );
};

export default MenuButton;

export const FootersBlock = {
  uuid: "d202f689-23d5-4c2d-841f-430a8f962501",
  name: "Footers",
  code: "footers",
  type: "General Footers Blocks",
  type_title: "General Footers Blocks",
  configuration: {
    name: {
      type: "textField",
      label: "Name",
      isRequired: true,
      supportMultiLanguages: ["de", "en"],
      maxLength: 50,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["Name is required"],
        },
      ],
    },
    is_primary: {
      type: "toggleButtons",
      label: "Primary",
      isRequired: true,
      validationType: "string",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      validations: [
        {
          type: "required",
          params: ["Primary is required"],
        },
      ],
    },
  },
  parameters: {},
};

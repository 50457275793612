import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);

import * as React from "react"
import "./layout.scss"
import { MenuItems } from '../../utils/utils';
import _ from 'lodash';
import { useState } from "react";
import CopyRightFooter from "../common/copy-right/copy-right";
import WIMenubar from "./wi-menubar";

export type LayoutParameters = {
  hideMenu: boolean | false;
  modeMenu: 'horizontal' | 'vertical';
}

const Layout = (props: any) => {
  const modeMenu = props.modeMenu || "horizontal";
  const contentMode = props.contentMode || modeMenu;

  const menuItems =_.cloneDeep(MenuItems);
  menuItems.forEach((m: any) => {
    const isMatched = window.location.pathname === m.url || (m.url !== '/' && window.location.pathname.includes(m.url));
    m.className = isMatched ? `wi-menu-item wi-menu-item-active` : 'wi-menu-item';
  })
  
  const [sidebar, setSidebar] = useState(localStorage.getItem('is_sidebar_expaned') === 'false');

  return (
    <div className="layout">
      <div className="layout-container">
        <div className={`layput-content ${modeMenu === 'horizontal' ? 'd-flex flex-column' : 'd-flex flex-row flex-nowrap'}`}>
          {!props.hideMenu && <div
            className={
              sidebar ? `menu-items active leaf-mode ${modeMenu}` : `menu-items leaf-mode ${modeMenu}`
            }
          >
            <WIMenubar
              sidebar={sidebar}
              setSidebar={(value: any) => setSidebar(value)}
              mode={modeMenu}
              menuItems={menuItems}
              className="navbar-wilderness-dark bg-primary-wilderness"
            />
          </div>
          }
          <div className={props.hideMenu ? 'main-content fullscreen' : (sidebar ? `${props.customLayout ? "custom-main-content" : "main-content"} active ${contentMode}` : (props.customLayout ? `custom-main-content ${contentMode}` : `main-content ${contentMode}`))}>
            <main>{props.children}</main>
						<CopyRightFooter 
              customCopyRight = {props.customCopyRight}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;

import { useField } from "formik";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { env } from "../../../environment";
import "./wi-upload-field-v2.scss";
import InsertMediaDialogComponent from "./wi-upload-media-dialog";
import { Toast } from "primereact/toast";
import { showNotification } from "../../../utils/logic";
import { ReactComponent as ReplaceSvg } from '../../../assets/images/icons/replace.svg';
import { ReactComponent as TrashSvg } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as EditSvg } from '../../../assets/images/icons/edit.svg';
import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";

const LIMIT_FILE_SIZE = 1000000;
const LIMIT_HERO_FILE_SIZE = 10000000;
const LIMIT_IMAGE_TYPES = ["jpg", "jpeg", "png", "svg"];

const WIUploadMediaFieldLogo = (props: any) => {
  const { disabled } = props;
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched } = helpers;
  const { error, touched } = meta;
  const { value } = field;
  const [image, setImage] = useState(null);
  const [fileData, setFileData] = useState<any>();
  const [isShowMediaDialog, setIsShowMediaDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>();
  const { t } = useTranslation('language', { keyPrefix: 'system_user_detail' });
  const menu = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    setImageUrl(setImageProps());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const setImageProps = () => {
    if (!!value) {
      setImageAlternativeText(value);
      setImage(value);
      return `${env.PUBLIC_UPLOAD_URL}/${value}`;
    }

    return null;
  };

  const setImageAlternativeText = async (value: any) => {
    if (!!props.onURLChanged) {
      props.onURLChanged(fileData?.alternativeText['de'], fileData?.title['de']);
    }
  }

  const handleImageValue = (selectedImage: any, isSetShowDialog?: boolean) => {
    setTouched(true);
    const limitImageTypes = props.imageTypes && props.imageTypes.length > 0 ? props.imageTypes : LIMIT_IMAGE_TYPES;
    if (selectedImage) {
      if (
        !limitImageTypes.some((item: string) => item === selectedImage.file_extension.toLowerCase())
      ) {
        const typesImage = props.imageTypes && props.imageTypes.length > 0 ? props.imageTypes.toString() : '.jpeg, .jpg, .png, and .svg';
        showNotification("error", `The system only accepts ${typesImage} file types`, toast);
        return;
      }

      if (!!props.onURLChanged) {
        props.onURLChanged(selectedImage?.alternativeText, selectedImage?.title);
      }

      setFileData(selectedImage);
      setValue(encodeURI(`${selectedImage.fullPath}`), true);
      setImageUrl(encodeURI(`${env.PUBLIC_UPLOAD_URL}/${`${selectedImage.fullPath}`}`));
      if (isSetShowDialog) {
        setIsShowMediaDialog(false);
      }
    }
  };

  const handleRemoveImageValue = (event: any) => {
    // event?.preventDefault();
    setImage(null);
    setImageUrl(null);
    setTouched(false);
    setValue("");
    if (!!props.onURLChanged) {
      props.onURLChanged(null);
    }
    return;
  };

  const onOpenMediaManager = (e: any) => {
    // e.preventDefault();
    // e.stopPropagation();
    setIsShowMediaDialog(true);
  };

  const itemsAction = () => {
    return [
      {
          disabled: disabled,
          label:`${t('txt_change')}`,
          className: 'item-menu',
          icon: <ReplaceSvg className="icon-svg"></ReplaceSvg>,
          command: (e:any) => {onOpenMediaManager(e)}
      },
      {
          disabled: disabled || !imageUrl ,
          label:`${t('txt_delete')}`,
          className: 'item-menu special-item',
          icon: <TrashSvg className="icon-svg"></TrashSvg>,
          command: (e: any) => handleRemoveImageValue(e),
      },
     
    ];
  } 

  return (
    <>
      <div
        className={`label-upload-field-logo ${imageUrl ? 'upload-active' : ''} ${props.classField || ""} ${props.className || ""
          } ${error && touched && !image ? "upload-error" : ""}`}
      > 
          {
            imageUrl ? <img src={imageUrl || ""} className={(props.classImage || '') + " media-preview"} alt="avatar" loading="lazy" /> : <div className="media-preview"></div>
          }

          {
            !disabled && 
            <div 
              className="background-edit-avatar" 
              // @ts-ignore: Object is possibly 'null'.
              onClick={(event) => menu.current.toggle(event)}
            >
              <EditSvg></EditSvg>
            </div>
          }
          <Menu model={itemsAction()} popup ref={menu} id="popup_menu" className="menu-popup-v2 dropdown-panel-bottom-v2"/>
      </div>
      <InsertMediaDialogComponent
        visible={isShowMediaDialog}
        fileType="image"
        handleFileValue={(selectedMedia: any) => handleImageValue(selectedMedia, true)}
        setIsShowMediaDialog={setIsShowMediaDialog}
        onHide={() => setIsShowMediaDialog(false)}
      />
      <Toast ref={toast} />
    </>
  );
};

export default WIUploadMediaFieldLogo;

import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import * as React from "react";
import { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { LandManagerAPI, RegionManagerAPI } from "../../../services";
import Layout from "../../layout/layout";
import CreateLandFormComponent from "./create-land-form/create-land-form";

export const CreateLandManager = () => {
    let { id } = useParams();
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowCreate, setIsShowCreate] = useState<boolean>(true);
    const [selectedLand, setSelectedLand] = useState<any>({});
    const [regions, setRegions] = useState([]);

    const fetchLandData = async (uuid: string) => {
        setIsLoading(true);
        try {
          const res = await LandManagerAPI.getLand(uuid);
          if (res && res.status === 200) {
            const dataLand = res.data.record || {};
            setIsLoading(false);
            setIsShowCreate(false);
            handleRegionName(dataLand);
            setSelectedLand(dataLand);
            return dataLand;
          }
          return [];
        } catch (error) {
            setIsLoading(false);
            setIsShowCreate(true);
            setSelectedLand(null);
        }
    };

    const fetchRegionsData = async () => {
        setIsLoading(true);
        const res = await RegionManagerAPI.getAllRegions();
        if (res && res.status === 200) {
          setRegions(
            res.data.records ? res.data.records.map((f: any) => ({
              id: f.uuid,
              name: f.name.de
            })) : []
          );
          setIsLoading(false);
        }
    };

    const handleRegionName = (land: any) => {
        if (land && regions) {
            const selectedRegion: any = regions.find((r: any) => r.id === land.region_id);
            land.region_name = selectedRegion ? selectedRegion.name : null;
        }
    };

    useEffect(() => {
        (async() => {
          await fetchRegionsData();
          if (id) {
            await fetchLandData(id);
            handleRegionName(selectedLand);
          }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Layout customLayout={true} customCopyRight={true}>
          {isLoading ? (
            <div className="loading-component">
              <ProgressSpinner />
            </div>
          ) : (
            <></>
          )}
          <Toast ref={toast} />
          <div className="d-flex flex-column w-100 text-left">
            <CreateLandFormComponent
                data={{
                    regions,
                    isShowCreate,
                    selectedLand,
                }}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                fetchLandData={fetchLandData}
                toast={toast}
            />
          </div>
        </Layout>
    );
};

export default CreateLandManager;

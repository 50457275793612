import { useFormikContext } from "formik";
import React from "react";
import WIUploadMediaField from "../../../common/upload/wi-upload-media-field";
import { WIFormElement } from "../wi-form-element/wi-form-element";
import "./wi-image.scss";

export function WIImage (props: any) {
  const { name, disabled } = props;
  const { frame, disabledRemove, disabledEdit } = props.schema;
  const frameCssClass = frame ? `img-frame-${frame.replace(':', '-')}` : '';
  const { setFieldValue } = useFormikContext();

  const updateImageAlternativeText = (alt: any, title: any) => {
    let altPath = 'alternative_text';
    let titlePath = 'image_title';
    if (!!props.parentKey) {
      altPath = props.parentKey + '.' + altPath;
      titlePath = props.parentKey + '.' + titlePath;
    }

    setFieldValue(altPath, alt)
    setFieldValue(titlePath, title)
  }

  return (
    <WIFormElement {...props}>
      <WIUploadMediaField
        onURLChanged={updateImageAlternativeText}
        type="file"
        id={name}
        name={name}
        required={true}
        classImage={`image-cert`}
        className={frameCssClass}
        isMiddle={true}
        hideLabel={true}
        overSize={true}
        disabled={disabled || disabledEdit}
        disabledRemove={disabledRemove || disabledEdit}
      />
    </WIFormElement>
  );
};

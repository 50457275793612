import React from "react";
import './wi-form-element.scss';
import "../../../pages/page-manager/flag.scss";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export function WIFormElement(props: any) {
  const { label, children, isRequired, lang } = props;

  return (
    <div  className="form-element">
      {/* // Multilang */}
      {/* {label && !lang && ( */}
      {label && (
        <label className="form-element-title">
          {/* {label} {isRequired && <span style={{ color: "red" }}> *</span>} */}
          {label}
        </label>
      )}
      {children}
    </div>
  );
};
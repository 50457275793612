import { useEffect, useState } from "react";
import React from "react";
import { Button } from "primereact/button";
import FilterField from "../../../../components_v2/common/filter-field/filter-field";
import { useTranslation } from "react-i18next";
import { COMMON_CURRENCY, COUPON_STATUS_LIST } from "../../../../components_v2/utils/utils";

const CouponFilterForm = (props: any) => {
  const { fields, onChange, setSearch, onRemove, campaigns, filterValues, onSubmit } = props;
  const { t } = useTranslation('language', { keyPrefix: 'coupons_manager' });
  const { t: globalTrans } = useTranslation('language');
  
  const [isDirty, setDirty] = useState<boolean>(false);
  const [values, setValues] = useState<Map<string, string[]>>();

  const onFilterChange = async (event: any) => {
    setDirty(true);
    values?.set(event.field, event.value);
  };

  const onRemoveFilters = async () => {
    fields.forEach(async (item: string) => {
      values?.set(item, []);
    });
    setSearch('');
    await onRemove();
  };

  useEffect(() => {
    const filter = new Map<string, string[]>();
    fields.forEach((k: string) => {
      filter.set(k, filterValues.where[k]);
    });
    setValues(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  return (
    <>
      <div className="filter-wrapper-v2">
        <div className="filter-form-container">
          <div className="filter-elements mb-16">
            <FilterField
              name="campaign_id"
              label={t('txt_campaign')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get("campaign_id") || []}
              options={campaigns || []}
              optionLabel={"name.de"}
              optionValue={"uuid"}
              visible={values?.has("campaign_id") || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
              filter
              filterBy="name,name.de,code"
              filterPlaceholder={t('txt_search')}
            />
          </div>
          <div className="filter-elements mb-16">
          <FilterField
              name="coupon_status"
              label={t("txt_status")}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get("coupon_status") || []}
              options={COUPON_STATUS_LIST.map(c => ({...c, name: globalTrans(c.name)}))}
              optionLabel={"name"}
              optionValue={"code"}
              visible={values?.has("coupon_status") || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
            />
          </div>
          <div className="filter-elements mb-16">
            <FilterField
              name="currency_code"
              label={t('txt_currency')}
              className="wi-filter-option-v2"
              pannelClassName="overlaypanel-filter-v2"
              selectedItems={values?.get("currency_code") || []}
              options={COMMON_CURRENCY || []}
              optionLabel={"name"}
              optionValue={"code"}
              visible={values?.has("currency_code") || false}
              isDirty={isDirty}
              setDirty={setDirty}
              onChange={onFilterChange}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-start gap-24 mt-8">
                <Button
                    className="wi-danger-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t('txt_clear_filter')}`}
                    onClick={() => onRemoveFilters()}
                />
                <Button
                    className="wi-primary-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t('txt_apply')}`}
                    onClick={() => onSubmit(values, fields)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponFilterForm;

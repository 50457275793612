import { AxiosClient, cacheAge } from './httpClient'

export default class ImportOldDonationsAPI {
  static importDonors(data: any) {
    return AxiosClient.post(`/import-old-donors`,  data);
  }
  static importDonations(data: any) {
    return AxiosClient.post(`/import-old-donations`,  data);
  }
}

import { AmountItem, BaseType, DefaultExampleList, LanguageItem } from "../../../../utils/commonType";
import * as _ from 'lodash';
import { defaultMessage, defaultMessageHeadline, defaultVideoUrl } from "../../../common/useVideoCustom";

type ItemLandType = {
  name: string;
  code: string;
  status: string;
  size: number | null;
  legal_name: string;
  amount_co2_conversion: AmountItem,
  amount_area_conversion: AmountItem,
  protected_size: number;
  region_id: string;
  land_type: string;
};

export type LandType = ItemLandType;

export const createEmptyLand = (): LandType => {
  return {
    name: "",
    legal_name: "",
    code: "",
    status: "new",
    size: 0,
    amount_co2_conversion: {
      eur: 60,
      cad: 60,
      chf: 60,
    },
    amount_area_conversion: {
      eur: 1,
      cad: 0.677,
      chf: 1,
    },
    protected_size: 0,
    region_id: "",
    land_type: "public",
  };
};

import * as React from "react";
import { useState, useEffect, useRef, Fragment } from "react";
import { Column, ColumnAlignType } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./faqs-manager.scss";
import Layout from "../../layout/layout";
import { LanguageList } from "../../../utils/utils";
import FAQsManagerAPI from "../../../services/global-contents/faqsManager";
import { showNotification } from "../../../utils/logic";
import { Toast } from "primereact/toast";
import {
  MenuDot,
  WIConfirmDialog,
  WIPaginator,
  WISearchField,
} from "../../common";
import CreateFAQDialogComponent from "./components/create-faq-dialog/create-faq-dialog";
import * as _ from "lodash";
import { formatDateBodyTemplate, formatInnerHtmlDataByLanguageBodyTemplate } from "../../common/column-template-table/column-template";
import { Button } from "primereact/button";
import useAuth from "../../../context/useAuth";
import { PERMISSIONS_V2 } from "../../../components_v2/utils/utils";

const FAQsManager = (props: any) => {
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [faqs, setFAQs] = useState<any>({
    data: [],
    totalRecords: 0,
  });
  const [page, setPage] = useState(0);
  const [language] = useState(LanguageList[0]);
  const [faq, setFAQ] = useState<any>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [search, setSearch] = useState<any>(null);
  const { auth } = useAuth();

  const permissions = {
    canCreateFAQ: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_CREATE),
    canUpdateFAQ: auth?.permissions?.includes(PERMISSIONS_V2.GLOBAL_CONTENT_UPDATE),
  };
  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const fetchFAQsData = async (valueSearch?: string) => {
    setIsLoading(true);
    const enableCache = false;
    try {
      const [faqsResult, articlesResult] = await Promise.all([
        FAQsManagerAPI.getAllFAQs(enableCache),
        FAQsManagerAPI.getAllArticles(),
      ]);

      let listFAQs: Array<any> = [];
      if (faqsResult && faqsResult.status === 200) {
        listFAQs = listFAQs.concat(faqsResult.data.records);
      }

      if (articlesResult && articlesResult.status === 200) {
        listFAQs = listFAQs.concat(articlesResult.data?.records);
      }

      let cloneListFAQs = _.orderBy(_.cloneDeep(listFAQs), ["updated_at"], ["desc"]);

      if (valueSearch) {
        try {
          cloneListFAQs = _.filter(cloneListFAQs, (faq) => {
            const question = faq.question[`${language?.code.toLowerCase()}`].toLowerCase();
            const answer = faq.answer[`${language?.code.toLowerCase()}`].toLowerCase();
            const categories = faq.category || "";

            return (
              question.includes(valueSearch.toLowerCase()) ||
              answer.includes(valueSearch.toLowerCase()) ||
              categories.toLowerCase().includes(valueSearch.toLowerCase())
            );
          });
        } catch (error) {
          console.log("filter error ==>> ", error);
        }
      }

      const totalRecords = cloneListFAQs.length;
      cloneListFAQs = _.drop(cloneListFAQs, page * rows).slice(0, rows);

      if (cloneListFAQs) {
        setIsLoading(false);
        setFAQs({
          data: cloneListFAQs.filter(item => item) || [],
          totalRecords: totalRecords || 0,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setFAQs({
        data: [],
        totalRecords: 0,
      });
    }
  };

  const onSearchHistory = () => {
    if (page !== 0) {
      setPage(0);
      setFirst(0);
      return;
    }
    fetchFAQsData(search);
  };

  useEffect(() => {
    fetchFAQsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      fetchFAQsData(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  const onCreateFAQ = (isCreating: boolean, e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setFAQ(null);
    setIsShowCreate(true);
  };

  const onShowDeleteDialog = (isVisible: boolean) => {
    setFAQ(null);
    setIsShowDeleteDialog(isVisible);
  };

  const onDeleteFAQs = async (e: any, rowData: any) => {
    setIsShowDeleteDialog(true);
    setFAQ(rowData);
  };

  const onDeleteConfirm = async () => {
    const response = await FAQsManagerAPI.deleteFAQ(faq.uuid);
    if (response && response.status === 200 && response.data.result.isValid) {
      onShowDeleteDialog(false);
      fetchFAQsData();
      showNotification("success", "Delete faq successfully.", toast);
    } else {
      showNotification("info", "Delete faq failed.", toast);
    }
  };

  const onUpdateFAQs = async (e: any, rowData: any) => {
    setIsLoading(true);
    const res = await FAQsManagerAPI.getFAQ(rowData.uuid);
    if (res && res.status === 200) {
      setFAQ(res.data.record);
      setIsLoading(false);
      setIsShowCreate(true);
    }
  };

  const tableColumns = [
    // { field: "serial", header: "ID", width: "5%", align: "center" },
    {
      field: "category",
      header: "Category",
      width: "10%",
      align: "left",
      bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(),
    },
    {
      field: "question",
      header: "Question",
      width: "15%",
      align: "left",
      bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language),
    },
    {
      field: "answer",
      header: "Answer",
      width: "calc(49% - 50px)",
      align: "left",
      bodyTemPlate: formatInnerHtmlDataByLanguageBodyTemplate(language),
    },
    {
      field: "created_at",
      header: "Created At",
      width: "13%",
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY"),
    },
    {
      field: "updated_at",
      header: "Updated At",
      width: "13%",
      align: "left",
      bodyTemPlate: formatDateBodyTemplate("DD.MM.YYYY"),
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={{ width: col.width }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
          label: "Edit",
          description: "Edit information",
          icon: "fa-solid fa-pen-to-square",
          disabled: rowData.source === "HelpScout",
          hidden: !permissions.canUpdateFAQ,
          command: (e: any) => {
            onUpdateFAQs(e, rowData)
          },
      },
      {
        label: "Delete",
        description: "Delete information",
        icon: "fa-solid fa-trash-can delete-icon",
        dangerous: true,
        hidden: !permissions.canUpdateFAQ,
        disabled: rowData.source === "HelpScout",
        command: (e: any) => {
          onDeleteFAQs(e, rowData);
        },
      }
    ];

    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <div className="faq-manager">
        <div className="manager-container">
          <div className="headline">
            <h3>FAQs</h3>
          </div>
          <div className="certificate">
            <div className="d-flex flex-column flex-md-row justify-content-start justify-content-md-between certificate-action common-horizontal-layout-header">
              <WISearchField
                // className="search-box"
                icon={" pi pi-search"}
                placeholder="Search for anything"
                setSearch={(value: any) => setSearch(value.global.value)}
                enterSearch={onSearchHistory}
              />
              <div className="mt-2 mb-2">
                <Button
                  className="wi-outline-button"
                  type={"button"}
                  label="Create"
                  icon={"pi pi-angle-right"}
                  iconPos="right"
                  hidden={!permissions.canCreateFAQ}
                  onClick={(e: any) => onCreateFAQ(true, e)}
                />
              </div>
            </div>
          </div>
          <div className="table-faq">
            <DataTable
              loading={isLoading}
              value={faqs.data}
              filters={search}
              scrollable
              scrollHeight="calc(100vh - 215px)"
              scrollDirection="both"
            >
              {dynamicColumns}
              <Column
                frozen
                alignFrozen="right"
                style={{ width: "50px" }}
                body={buildMenu}
                // body={(rowData) => buildActionBtnTemplate(rowData, listActionBtns, "", rowData.source !== "HelpScout", rowData.hasLands)}
              ></Column>
            </DataTable>
            <WIPaginator
              first={first}
              rows={rows}
              totalRecords={faqs.totalRecords}
              onPageChange={onBasicPageChange}
            />
          </div>
          <WIConfirmDialog
            visible={isShowDeleteDialog}
            onHide={() => onShowDeleteDialog(false)}
            onConfirm={onDeleteConfirm}
            message={
              <Fragment>
                <span style={{fontSize: '18px', fontWeight: '700'}}>Are you sure you want to delete this FAQ?</span>
                <br/>
                <span style={{fontSize: '13px'}}>
                  All of your data will be deleted <b>permanently</b>.
                  <br/>
                  You <b>cannot undo</b> this action.
                  <br/>
                  <br/>
                  <b>Note</b>: <br/> If this <b>FAQ</b> is using by our website, it can cause <b>unexpected issues</b>.
                  </span>
              </Fragment>
            }
            classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
          />
          <CreateFAQDialogComponent
            visible={isShowCreate}
            fetchFAQsData={fetchFAQsData}
            onHide={() => setIsShowCreate(false)}
            faq={faq}
          />
        </div>
      </div>
    </Layout>
  );
};

export default FAQsManager;

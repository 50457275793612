import * as React from 'react';
import { buildTemplateHTML } from '../../../../utils/templateHTML';
import { replaceParameter } from '../../utils';
import DroppableComponent from '../droppable/droppable-component';
import ContentComponent from './content-component';

const ContentBuilderComponent = (props: any) => {

  const { components, id, selectedComponent, onDragOver, onDragDrop, mode, zoom, lang } = props;
  let { headHtml } = props;
  const attributeHTML: any = {
    style: `
      <style type="text/css">
        .selected-component {
          border: 2px dashed #13735f;
          border-radius: 10px;
        }

        .unselected-component {
          opacity: 0.1;
        }

        html, body {
          scroll-behavior: auto!important;
        }
        
        #page-main-header {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          z-index: 99999981;
        }
      </style>
    `,
    script: `
      <script type="text/javascript">
        window.smoothScroll = function(target) {
          target.scrollIntoView({behavior: "instant", block: "end", inline: "nearest"});
        }

        document.addEventListener("DOMContentLoaded", function(e) {
          var btnCollection = document.getElementsByClassName('aw_btn');
          for (var i = 0; i < btnCollection.length; i++) {
            btnCollection[i].setAttribute("target", "_blank");
          }

          btnCollection = document.getElementsByClassName('aw_btn_menu');
          for (var i = 0; i < btnCollection.length; i++) {
            btnCollection[i].setAttribute("target", "_blank");
          }

          var btnCopyLink = document.getElementById('icon-copy');
          if(btnCopyLink) {
            btnCopyLink.addEventListener('click', function() {
              navigator.clipboard.writeText(window.location.href).then(() => {
                var tooltip = document.getElementById("myTooltip");
                tooltip.innerHTML = "Copied!";
              });
            })
          }

          var btnSocials = document.getElementsByClassName('social-share');
          if(btnSocials) {
            for (var i = 0; i < btnSocials.length; i++) {
              if(btnSocials[i].getAttribute('id') && btnSocials[i].getAttribute('id') === 'btn-whatsapp') {
                var isMobilePlatform = /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
                var hrefWhatsapp = 'https://' + (isMobilePlatform ? 'api' : 'web') + '.whatsapp.com/send?text=';
                btnSocials[i].setAttribute("href", hrefWhatsapp + window.location.href);
              } else {
                var dataHref = btnSocials[i].getAttribute('data-href');
                btnSocials[i].setAttribute("href", dataHref + window.location.href);
              }
            }
          }

        });
      </script>
    `,
  };

  Object.keys(attributeHTML).forEach((f: string) => {
    headHtml = replaceParameter(headHtml, `{{parameters.${f}}}`, attributeHTML[f]);
  })
  
  const html = buildTemplateHTML(components.filter((c: any) => !!c), headHtml, selectedComponent, lang || 'de');

  return (
    <React.Fragment>
      <ContentComponent className={`wrapper ${mode?.cssClassName}`}>
        {
          <iframe style={{ width: '100%', height: `${100 / zoom}%`, transform: `scale(${zoom})`, transformOrigin: 'top' }} srcDoc={html}></iframe>
        }
      </ContentComponent>
      <DroppableComponent
        name={id}
        onDragOver={(ev: any) => onDragOver(ev)}
        onDrop={(ev: any) => onDragDrop(ev, id)}
      />
    </React.Fragment>
  )
}

export default ContentBuilderComponent;
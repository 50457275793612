import * as React from "react";
import * as Yup from 'yup';
import { useState, useRef, useEffect } from "react";
import "./import-page.scss";
import Layout from "../layout/layout";
import { Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import {
  CampaignManagerAPI,
  HistoryImportAPI,
  LandManagerAPI,
  RegionManagerAPI,
} from "../../services";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { parseStringToInt, showNotification } from "../../utils/logic";
import { COMMON_STATUS, CurrencyList, ImportNewTypeList, LandTypes, LanguageCode, TypeLandCode } from "../../utils/utils";
import WICSVUploadField from "../common/upload/wi-upload-csv-field";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import _ from "lodash";
import { formatSourceOptionTemplate } from "../common/column-template-table/column-template";

const ImportPage = () => {
  const LanguageList = [
    {
      name: 'German',
      code: LanguageCode.DE
    },
    {
      name: 'English',
      code: LanguageCode.EN
    }
  ]
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [campaignsGlobal, setCampaignsGlobal] = useState<any[]>([]);
  const [landsGlobal, setLandsGlobal] = useState<any[]>([]);
  const [lands, setLands] = useState<any[]>([]);
  const [totalSize, setTotalSize] = useState<any>(0);
  const formikRef = useRef(null);

  const fetchData = async (values: any) => {
    try {
      if (values.land_id) {
        const selectedLand: any = lands.find((l: any) => l.uuid === values.land_id);
        values.land_code = selectedLand?.code;
      }
      if (values.campaign_id) {
        const selectedCampaign: any = campaigns.find((l: any) => l.uuid === values.campaign_id);
        values.campaign_code = selectedCampaign?.code
      }
      const type = values.donation_type === TypeLandCode.campaign ? TypeLandCode.campaign : TypeLandCode.land;
      const res = await HistoryImportAPI.importDonation({...values, donation_type: type });
      if (res && res.status === 200) {
        setIsLoading(false);
        showNotification("success", "Import successfully", toast);
        navigate(`/import-detail/${res.data.id}`);
      } else {
        setIsLoading(false);
        showNotification("error", "Failed to import", toast);
      }
    } catch (error) {
      setIsLoading(false);
      showNotification("error", "Failed to import", toast);
    }
  };

  const fetchCampaignsData = async () => {
    setIsLoading(true);
    const [regionRes, landsRes, campaignsRes] = await Promise.all([
      RegionManagerAPI.getAllRegions(),
      LandManagerAPI.getAllLands(),
      CampaignManagerAPI.getAllCampaigns(),
    ]);

    let lands: any[] = [];
    let allLands: any[] = [];
    let campaigns: any[] = [];
    let regions: any[] = [];
    // @ts-ignore: Object is possibly 'null'.
    if (landsRes && landsRes.status === 200 && landsRes.data.records?.length > 0) {
      allLands = landsRes.data.records;
      lands = _.orderBy(allLands.filter((l: any) => l.land_type !== LandTypes.CampaignOnly), 'changed', 'desc');
      regions = regionRes?.data?.records;
      lands.forEach((item: any) => {
        item.region = regions?.find((r: any) => r.default_land_id === item.uuid);
        item.master_region = regions?.find((r: any) => r.uuid === item.region_id);
      });
      setLands(lands);
      setLandsGlobal(allLands);
    }
    // @ts-ignore: Object is possibly 'null'.
    if (campaignsRes && campaignsRes.status === 200 && campaignsRes.data.records?.length > 0) {
      campaigns = _.orderBy(campaignsRes.data.records.filter((item: any) => item.status === "active"), 'serial', 'desc');
      campaigns.forEach((item: any) => {
        item.land = allLands?.find((r: any) => r.uuid === item.land_id);
        item.master_region = regions?.find((r: any) => r.uuid === item.land?.region_id);
      });
      
      setCampaigns(campaigns);
      setCampaignsGlobal(campaignsRes.data.records);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCampaignsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = () => {
    return {
      donation_type: TypeLandCode.campaign,
      language: "de",
      currency: "eur",
      land_id: "",
      campaign_id: "",
      dataImport: "",
      totalSize: 0,
      is_send_receipt: true
    }
  };

  const importFormSchema = Yup.object().shape({
    donation_type: Yup.string()
      .required('This field is required.'),
    language: Yup.string()
      .required('This field is required.'),
    currency: Yup.string()
      .required('This field is required.'),
    land_id: Yup.string().when("donation_type", {
      is: (type: string) => type !== TypeLandCode.campaign,
      then: Yup.string().required('This field is required.')
    }),
    campaign_id: Yup.string().when("donation_type", {
      is: (type: string) => type === TypeLandCode.campaign,
      then: Yup.string().required('This field is required.')
    }),
    dataImport: Yup.string()
      .required('This field is required.'),
    totalSize: Yup.number().moreThan(0, 'This field is required.'),
  });

  const formatOptionTemplate = formatSourceOptionTemplate;

  const selectedOptionTemplate = (key: any, option: any, values: any, available_size: any, props: any) => {
    if (available_size < totalSize) {
      key !== TypeLandCode.campaign ? values.land_id = "" : values.campaign_id = "";
      option = null;
    }

    if (!option) {
      return <span>{props.placeholder}</span>;
    }

    if (key === TypeLandCode.region) {
      return (<div>{option?.region?.name?.de || option?.region?.name} - Available size [{(+(available_size || 0))?.toLocaleString("de-DE")} m<sup>2</sup>]</div>);
    } else {
      return (<div>{option?.name?.de || option?.name} - Available size [{(+(available_size || 0))?.toLocaleString("de-DE")} m<sup>2</sup>]</div>);
    }
  }

  return (
    <Layout>
      <div className="import-page">
        <Toast ref={toast} />
        <div className="import-header">
        </div>
        <div className="import-container import-container-extra">
          <div className="impoty-btn">
            <Formik
              innerRef={formikRef}
              initialValues={initialValues()}
              validationSchema={importFormSchema}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);
                fetchData(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-6 col-md-6">
                      <div className="input-item">
                        <label className="label">
                          Total Size Imported (<span className="lowercase-label">m</span><sup>2</sup>) <span className="required-label">*</span>
                        </label>
                        <InputNumber
                          className="inputNumber"
                          name="totalSize"
                          locale="de-DE" 
                          value={values.totalSize}
                          onBlur={(e) => {
                            const value: any = e.target.value ? parseStringToInt(e.target.value) : 0;
                            setFieldValue('totalSize', value, true);
                            setTotalSize(Number(value) ?? 0);
                            setTimeout(() => {
                                handleBlur(e);
                            }, 200);
                        }}
                        />
                        <div
                          className={`${touched.totalSize &&
                              errors.totalSize
                              ? "error"
                              : ""
                            }`}
                        >
                          {`${touched.totalSize && errors.totalSize
                              ? errors.totalSize
                              : ""
                            }`}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-6">
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="input-item">
                        <label className="label">Import For <span className="required-label">*</span></label>
                        <SelectButton
                          className="donation-checkbox"
                          id="is_prepaid"
                          name="is_prepaid"
                          options={ImportNewTypeList}
                          optionLabel="name"
                          optionValue="code"
                          onChange={(item: any) => {
                            if (item.value) {
                              setFieldValue('donation_type', item.value, true);
                            }
                          }}
                          value={values.donation_type}
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="input-item">
                        <label className="label">Language <span className="required-label">*</span></label>
                        <Dropdown
                          className="p-campaign-dropdown"
                          panelClassName="p-campaign-dropdown-panel"
                          value={values.language}
                          options={LanguageList}
                          onChange={(e: any) => {
                            setFieldValue("language", e.value, true);
                          }}
                          optionLabel="name"
                          optionValue="code"
                          appendTo="self"
                        />
                        {errors.language && touched.language ? (<small className="error">{errors.language}</small>) : null}
                      </div>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="input-item">
                        <label className="label">Currency <span className="required-label">*</span></label>
                        <Dropdown
                          className="p-campaign-dropdown"
                          panelClassName="p-campaign-dropdown-panel"
                          value={values.currency}
                          options={CurrencyList}
                          onChange={(e: any) => {
                            setFieldValue("currency", e.value, true);
                          }}
                          optionLabel="name"
                          optionValue="code"
                          appendTo="self"
                        />
                        {errors.currency && touched.currency ? (<small className="error">{errors.currency}</small>) : null}
                      </div>
                    </div>
                    {
                      values.donation_type === TypeLandCode.region ? (
                        <div className="col-12 col-md-12 input-item">
                          <label className="label">
                            Region <span className="required-label">*</span>
                          </label>
                          <Dropdown
                            className="p-campaign-dropdown p-dropdown-custom-search"
                            panelClassName="p-campaign-dropdown-panel"
                            value={values.land_id}
                            options={lands.filter((item: any) => item.region)}
                            onChange={(e: any) => {
                              setFieldValue("land_id", e.value, true);
                            }}
                            optionLabel={`name.${values.language}`}
                            optionDisabled={(option) => option.available_size < totalSize}
                            itemTemplate={(option) => formatOptionTemplate(option, option.available_size, TypeLandCode.region)}
                            valueTemplate={(option, props) => selectedOptionTemplate(TypeLandCode.region, option, values, option?.available_size, props)}
                            optionValue="uuid"
                            showClear
                            appendTo="self"
                            placeholder="Choose a region"
                            filter
                            filterBy="region.name.de,code"
                            filterPlaceholder={'Search'}
                          />
                          {errors.land_id && touched.land_id ? (<small className="error">{errors.land_id}</small>) : null}
                        </div>
                      )
                      : (
                        values.donation_type === TypeLandCode.land ?
                        <div className="col-12 col-md-12 input-item">
                          <label className="label">
                            Area <span className="required-label">*</span>
                          </label>
                          <Dropdown
                            className="p-campaign-dropdown p-dropdown-custom-search"
                            panelClassName="p-campaign-dropdown-panel"
                            value={values.land_id}
                            options={lands.filter((l: any) => l.status === COMMON_STATUS.ACTIVE)}
                            onChange={(e: any) => {
                              setFieldValue("land_id", e.value, true);
                            }}
                            optionLabel={`name.${values.language}`}
                            optionDisabled={(option) => option.available_size < totalSize}
                            itemTemplate={(option) => formatOptionTemplate(option, option.available_size, TypeLandCode.land)}
                            valueTemplate={(option, props) => selectedOptionTemplate(TypeLandCode.land, option, values, option?.available_size, props)}
                            optionValue="uuid"
                            showClear
                            appendTo="self"
                            placeholder="Choose an area"
                            filter
                            filterBy="name,name.de,code"
                            filterPlaceholder={'Search'}
                          />
                          {errors.land_id && touched.land_id ? (<small className="error">{errors.land_id}</small>) : null}
                        </div> :
                        <div className="col-12 col-md-12 input-item">
                          <label className="label">Campaign <span className="required-label">*</span></label>
                          <Dropdown
                            className="p-campaign-dropdown p-dropdown-custom-search"
                            panelClassName="p-campaign-dropdown-panel"
                            value={values.campaign_id}
                            optionDisabled={(option) => option.land?.available_size < totalSize}
                            itemTemplate={(option) => formatOptionTemplate(option, option.land?.available_size, TypeLandCode.campaign)}
                            valueTemplate={(option, props) => selectedOptionTemplate(TypeLandCode.campaign, option, values, option?.land?.available_size, props)}
                            options={campaigns}
                            onChange={(e: any) => {
                              setFieldValue("campaign_id", e.value, true);
                              // @ts-ignore: Object is possibly 'null'.
                              const selected = campaigns.find(c => c.uuid === e.value);
                              if (selected) {
                                // @ts-ignore: Object is possibly 'null'.
                                const land = landsGlobal.find(l => l.uuid === selected.land_id);
                                // @ts-ignore: Object is possibly 'null'.
                                setFieldValue("land_id", land.uuid, true);
                              } else {
                                setCampaigns(campaignsGlobal);
                                setFieldValue("land_id", "", true);
                              }
                            }}
                            optionLabel={`name.${values.language}`}
                            optionValue="uuid"
                            showClear
                            appendTo="self"
                            placeholder="Choose a campaign"
                            filter
                            filterBy="name.de,code"
                            filterPlaceholder={'Search'}
                          />
                        </div>
                      )
                    }

                    <div className="col-12 d-flex align-items-center justify-content-start upload-file">
                      <WICSVUploadField
                        name="dataImport"
                        className="input-upload"
                        labelClassName="label-upload-csv"
                        nameFileClassName="name-file" />
                    </div>
                    <div className="col-12 d-flex flex-column align-items-start justify-content-start note-message">
                      <p>Please make sure that all data are correct. This cannot be undone.</p>
                    </div>
                    <div className="btn-import">
                      <Button
                        loading={isLoading}
                        disabled={!(isValid && dirty)}
                        type="submit"
                        label="Submit"
                        icon="pi pi-chevron-right"
                        iconPos="right"
                        className="btn-submit p-button-rounded">
                      </Button>
                      {/* <Button
                        primary={true}
                        type={'submit'}
                        size={'small'}
                        label="Submit"
                        disabled={!(isValid && dirty)} /> */}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ImportPage;

//react-hooks/exhaustive-deps
import * as React from "react";
import { useState, useRef } from "react";
import "./import-donor-details.scss";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { TreeTable } from "primereact/treetable";
import { Column, ColumnAlignType } from "primereact/column";
import moment from "moment";
import { Button } from 'primereact/button';

const ImportDonorDetail = (props: any) => {
  const { data, onHide } = props;
  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const tableColumns = [
    {
      field: "",
      header: "Name",
      width: "25%",
      bodyTemPlate: (rowData: any) => {
        return `${rowData.data.payment_first_name} ${rowData.data.payment_last_name}`;
      },
      align: "left"
    },
    {
      field: "data.donation_money",
      header: "Amount (€)",
      width: "25%",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        return <div><span style={{ fontSize: '13px', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData?.data?.donation_money?.toString())?.toLocaleString("de-DE") : '-'}</span></div>;
      }
    },
    {
      field: "data.payment_email",
      header: "Email",
      width: "25%",
      align: "left",
    },
    { 
      field: 'data.paid_date', 
      header: 'Paid Date', 
      width: "25%", 
      bodyTemPlate: (rowData: any) => {
        return rowData.data.paid_date ? moment(new Date(rowData.data.paid_date), "MMM DD, YYYY").utc().add(1, 'hour').format("MMM DD, YYYY") : '-';
      },
      align: 'left', 
    }

  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable
      />
    );
  });

  return (
    <div className="import-donor-detail">
      <Toast ref={toast} />
      <div className="detail-container">
        <div className="table-import">
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => {
              setActiveIndex(e.index);
            }}
          >
            <TabPanel header="Success">
              <DataTable
                value={data.validRecords}
                paginator
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={100}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                scrollable
                scrollHeight="calc(100vh - 166px)"
              >
                {dynamicColumns}
              </DataTable>
            </TabPanel>
            <TabPanel header="Failed">
              <TreeTable
                value={data.invalidRecords}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 20, 50]}
              >
                <Column
                  field="name"
                  header="Row"
                  style={{ width: "25%" }}
                  expander
                ></Column>
                <Column
                  field="message"
                  header="Message"
                  style={{ width: "75%" }}
                ></Column>
              </TreeTable>
            </TabPanel>
          </TabView>
        </div>
        <div className="close-table" onClick={onHide}>
          <div className="close-icon">
            <i className="fa-solid fa-angle-left"></i>
          </div>
          <Button 
            aria-label="Cancel" 
            onClick={onHide}
            label="Back"
          />
        </div>
      </div>
    </div>
  );
};

export default ImportDonorDetail;

import * as React from "react";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { WIButton } from "../common";
import './toolkit.scss';
import { Tag } from "primereact/tag";

const ToolkitManager = (props: any) => {
    return (
        <div className="wi-toolkit-manager">
            <h1>Toolkit Manager</h1>
            <div className="d-flex flex-column">
                <h1 className="wi-toolkit-headline">Headline size</h1>
                <h1>H1 size - CSS class .h1</h1>
                <h2>H2 size - CSS class .h2</h2>
                <h3>H3 size - CSS class .h3</h3>
                <h4>H4 size - CSS class .h4</h4>
                <h5>H5 size - CSS class .h5</h5>
                <div>Normal size</div>
            </div>
            <div className="d-flex flex-column">
                <h1 className="wi-toolkit-headline">Text Color</h1>
                <div className="h3">Text Normal Color</div>
                <div className="h3 text-secondary">Text Secondary Color - CSS class .text-secondary</div>
                <div className="h3 text-highlight">Text Normal Color - CSS class .text-highlight</div>
            </div>
            <div className="d-flex flex-column">
                <h1 className="wi-toolkit-headline">Status Tag</h1>
                <div className="d-flex flex-row justify-content-center mb-2">
                    <Tag className="mr-2 wi-status-tag" value={"tag"}></Tag> 
                    <Tag className="mr-2 wi-status-tag tag-status-active" value={"tag"}></Tag> 
                    <Tag className="mr-2 wi-status-tag tag-status-draft" value={"tag"}></Tag> 
                    <Tag className="mr-2 wi-status-tag tag-status-failed" value={"tag"}></Tag> 
                    <Tag className="mr-2 wi-status-tag tag-status-cancelled" value={"tag"}></Tag> 
                    <Tag className="mr-2 wi-status-tag tag-status-refunded" value={"tag"}></Tag> 
                    <Tag className="mr-2 wi-status-tag tag-status-reserved" value={"tag"}></Tag> 
                    <Tag className="mr-2 wi-status-tag tag-status-finished" value={"tag"}></Tag> 
                </div>
            </div>
            <div className="d-flex flex-column">
                <h1 className="wi-toolkit-headline">Button</h1>
                <div className="d-flex flex-row justify-content-center mb-2">
                    <Button className="wi-outline-button mr-2">Btn Outline</Button>
                    <Button className="wi-outline-button wi-button-medium mr-2">Medium</Button>
                    <Button className="wi-outline-button wi-button-medium mr-2" disabled>Disabled</Button>
                </div>
                <div className="d-flex flex-row justify-content-center mb-2">
                    <Button className="wi-primary-button mr-2">Btn Primary</Button>
                    <Button className="wi-primary-button wi-button-medium mr-2">Medium</Button>
                    <Button className="wi-primary-button wi-button-medium mr-2" disabled>Disabled</Button>
                </div>
                <div className="d-flex flex-row justify-content-center mb-2">
                    <Button className="wi-gold-button mr-2">Btn Gold</Button>
                    <Button className="wi-gold-button wi-button-medium mr-2">Medium</Button>
                    <Button className="wi-gold-button wi-button-medium mr-2" disabled>Disabled</Button>
                </div>
                <div className="d-flex flex-row justify-content-center mb-2">
                    <Button className="wi-gray-button mr-2">Btn Gray</Button>
                    <Button className="wi-gray-button wi-button-medium mr-2">Medium</Button>
                    <Button className="wi-gray-button wi-button-medium mr-2" disabled>Disabled</Button>
                </div>
                <div className="d-flex flex-row justify-content-center mb-2">
                    <Button className="wi-red-button mr-2">Btn Red</Button>
                    <Button className="wi-red-button wi-button-medium mr-2">Medium</Button>
                    <Button className="wi-red-button wi-button-medium mr-2" disabled>Disabled</Button>
                </div>
                <div className="d-flex flex-row justify-content-center mb-2">
                    <Link to="#" className="wi-outline-button mr-2">Link</Link>
                    <Link className="wi-outline-button align-self-center mr-2" to="/regions/create" >
                        <div className="wi-btn-label">Icon <i className="fa-solid fa-circle-plus ml-2"></i></div>
                    </Link>
                    <Link to="#" className="wi-outline-button wi-button-medium mr-2">Link</Link>
                </div>
                <div className="d-flex flex-row justify-content-center mb-2">
                    <Button type="submit" className="wi-outline-button mr-2" icon="pi pi-download" iconPos="right" label="Btn Icon"></Button>
                    <Button type="submit" className="wi-primary-button mr-2" icon="pi pi-angle-right" iconPos="right" label="Btn Icon"></Button>
                    <Button type="submit" className="wi-gold-button mr-2" icon="pi pi-angle-right" iconPos="right" label="Btn Icon"></Button>
                </div>
            </div>
        </div>
    );
};

export default ToolkitManager;
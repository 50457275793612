import React, { useEffect, useState, useCallback } from 'react'
 import { useNavigate, useLocation } from 'react-router-dom'
import { useBlocker } from '../../pages/components/block-modal/block-modal';

 export default function WithPreventNavigation(WrappedComponent: any) {
   return function PreventNavigation(props: any) {
    const location = useLocation();
    const [lastLocation, setLastLocation] = useState<any>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const navigate = useNavigate();
    const [blockPopupVisible, setBlockPopupVisible] = useState<any>(false);
    const [shouldBlock, setShouldBlock] = useState<any>(false);

    const confirmNavigation = useCallback(() => {
      setConfirmedNavigation(true);
      setBlockPopupVisible(false);
    }, []);
  
    const handleBlockedNavigation = useCallback(
      (nextLocation: any) => {
        if (
          !confirmedNavigation &&
          nextLocation.location.pathname !== location.pathname
        ) {
          handleLeave(nextLocation);
          setLastLocation(nextLocation);
          return false;
        }
  
        return true;
      },
      [confirmedNavigation]
    )
  
    const handleLeave = (nextLocation: any) => {
      setBlockPopupVisible(true);
    }
  
    useEffect(() => {
      if (confirmedNavigation && lastLocation) {
        navigate(lastLocation.location.pathname);
      }
    }, [confirmedNavigation, lastLocation]);
  
    useBlocker(handleBlockedNavigation, shouldBlock);

     return (
       <WrappedComponent
            blockPopupVisible={blockPopupVisible}
            setBlockPopupVisible={setBlockPopupVisible}
            shouldBlock={shouldBlock}
            setShouldBlock={setShouldBlock}
            setConfirmedNavigation={setConfirmedNavigation}
            confirmNavigation={confirmNavigation}
            {...props}
       />
     )
   }
 }
import * as React from "react"
import { useEffect, useState, Fragment } from "react";
import './receipt-manager.scss';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnAlignType } from 'primereact/column';
import { ReceiptsAPI } from '../../services';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import _ from "lodash";
import { WIConfirmDialog } from "../common";
import { getReceiptObjectSearchValue, getReceiptObjectWithValues, getReceiptPayloadConditions } from "./receipt-manager-util";
import CreateReceiptDialogComponent from "./components/create-receipt-dialog/create-receipt-dialog";
import CountryService from "../../services/country-service";
import { onDownloadPDFReceiptV2 } from "./components/receipt.service";
import Layout from "../../components_v2/common/layout/layout";
import WISearchField from "../../components_v2/common/search/wi-search-field";
import WIPaginator from "../../components_v2/common/wi-paginator/wi-paginator";
import MenuDot from "../../components_v2/common/menu-dot/menu-dot";
import { useLayoutV2 } from "../../context/LayoutProvider";
import { ReactComponent as PlusSvg } from '../../assets/images/icons/add-plus.svg';
import { formatDateTimeV2, formatDateV2 } from "../../utils/logic";
import {
    buildCountryBodyTemplate,
} from "../common/column-template-table/column-template";
import useAuth from "../../context/useAuth";
import { PERMISSIONS_V2 } from "../../components_v2/utils/utils";

export type CouponFilterParameters = {
    pageNo: number;
    range: number;
    search?: string;
    where: {
        or: any[];
    };
    order: [];
};

const ReceiptManager = () => {
    const { getLanguage, setLoadingProgress, setErrorProgress, setSuccessProgress } = useLayoutV2();
    const { t } = useTranslation('language', { keyPrefix: 'manual_receipt_manager' });
    const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });

    const [searchParams, setSearchParams] = useSearchParams();
    const pageNumber = Math.max(+(searchParams?.get('pageNumber') || 1) - 1, 0);
    const noRows = Math.min(Math.max(+(searchParams?.get('noRows') || 50), 50), 1000);
    const sortField = searchParams?.get('sortField') || 'serial';
    const sortOrder = +(searchParams?.get('sortOrder') || -1);
    const searchField = searchParams?.get('search') || '';
    const [countries, setCountries] = useState([]);
    const [isShowCreate, setIsShowCreate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [receipts, setReceipts] = useState({
        data: [],
        totalPage: 0
    });
    const [paginator, setPaginator] = useState({
        page: 0,
        first: 0,
        rows: 50
    })
    const [sortConfig, setSortConfig] = useState<any>({
        sortField: sortField,
        sortOrder: sortOrder
    });
    const [filterValues, setFilterValues] = useState<CouponFilterParameters>({
        pageNo: pageNumber,
        range: noRows,
        search: searchField,
        where: {
            or: searchField ? getReceiptObjectSearchValue(searchField) : [],
        },
        order: []
    });
    const [search, setSearch] = useState<any>(filterValues.search);
    const [receipt, setReceipt] = useState<any>(null);
    const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false);
    const [selectedReceipts, setSelectedReceipts] = useState([]);
    const { auth } = useAuth();

    const permissions = {
        canCreateReceipt: auth?.permissions?.includes(PERMISSIONS_V2.UTILITY_CREATE),
        canUpdateReceipt: auth?.permissions?.includes(PERMISSIONS_V2.UTILITY_UPDATE),
      };

    const onBasicPageChange = (event: any) => {
        setPaginator({
            page: event.page,
            first: event.first,
            rows: event.rows
        });
        setSelectedReceipts([]);
    }

    const fetchReceiptsData = async () => {
        setIsLoading(true);
        const payload = getReceiptPayloadConditions(filterValues.where, sortConfig);
        try {
            const resReceipts = await ReceiptsAPI.getReceipts({
                pageNo: paginator.page,
                range: paginator.rows,
                ...payload
            });

            if (!resReceipts || resReceipts.status !== 200) {
                throw new Error('txt_unknown_load_data_fail');
            }

            setReceipts({
                data: resReceipts.data.records ? resReceipts.data.records : [],
                totalPage: resReceipts.data.total || 0,
            });
        } catch (error: any) {
            setReceipts({ data: [], totalPage: 0 });
            setErrorProgress(errorTrans(error.message));
        } finally {
            setIsLoading(false);
        }
    }

    const fetchCountryData = async () => {
        try {
            const res = await CountryService.getCountries();
            if (res.status === 200 && res.data.data) {
                setCountries(res.data.data);
            } else {
                setCountries([]);
            }
        } catch (error) {
            setCountries([]);
        }
    };

    const onDeleteConfirm = async () => {
        try {
            setLoadingProgress(errorTrans('txt_loading'));

            const ids = selectedReceipts.length > 0 ? selectedReceipts.map((item: any) => item.uuid) : [];
            const response = await ReceiptsAPI.deleteReceipts(ids);
            if (response && response.status === 200 && response.data.result.isvalid) {
                onShowDeleteDialog(false);
                fetchReceiptsData();
                setSelectedReceipts([]);
                setSuccessProgress(t('txt_delete_receipts_success'));
            } else {
                setSelectedReceipts([]);
                onShowDeleteDialog(false);
                throw new Error('txt_delete_receipts_fail');
            }
        } catch (error: any) {
            setErrorProgress(errorTrans(error.message));
        }
    };

    useEffect(() => {
        fetchReceiptsData();
        fetchCountryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof paginator.page === "number") {
            setFilterValues({
                ...filterValues,
                pageNo: paginator.page,
                range: paginator.rows,
            });
            setSearchParams({
                pageNumber: (paginator.page + 1).toString(),
                noRows: paginator.rows.toString(),
            }, { replace: true });
            setSelectedReceipts([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginator]);

    useEffect(() => {
        const searchParams = {
            pageNumber: (paginator.page + 1).toString(),
            noRows: paginator.rows.toString(),
            search: filterValues.search,
            sortField: sortConfig.sortField,
            sortOrder: sortConfig.sortOrder,
        }

        setSearchParams(_.omitBy(searchParams, (p) => !p), { replace: true });
        fetchReceiptsData();
        setSelectedReceipts([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, sortConfig]);


    const onShowCreateDialog = (isVisible: boolean) => {
        setIsShowCreate(isVisible);
    };

    const onShowDeleteDialog = (isVisible: boolean) => {
        setReceipt(null);
        setIsShowDeleteDialog(isVisible);
    };

    const onDeleteReceipt = async (rowData: any) => {
        setIsShowDeleteDialog(true);
        setSelectedReceipts(rowData);
    };

    const onSearchFunc = (searchText: string) => {
        const where_conditions = getReceiptObjectWithValues(filterValues.where);
        const or_conditions = getReceiptObjectSearchValue(searchText);
        where_conditions["or"] = searchText ? or_conditions : null;
        setFilterValues({
            pageNo: filterValues.pageNo,
            range: filterValues.range,
            where: where_conditions,
            search: searchText,
            order: filterValues.order,
        });
        if (paginator.page !== 0) {
            setPaginator({
                ...paginator,
                page: 0,
                first: 0
            });
        }
    };

    const downloadReceipt = async (rowData: any) => {
        try {
            setLoadingProgress(errorTrans('txt_loading'));
            await onDownloadPDFReceiptV2(rowData);
            setSuccessProgress(t('txt_download_receipts_success'));
        } catch (error: any) {
            setErrorProgress(errorTrans(error.message));
        }
    }

    const formatAmountBodyTemplate = (rowData: any, lang = 'de') => {
        return <div><b style={{ fontWeight: '600', color: 'black', marginRight: '5px' }}>{rowData ? parseFloat(rowData.amount?.toString())?.toLocaleString(lang) : '-'}</b> <span>{rowData.currency_code?.toUpperCase()}</span></div>;
    };

    const tableColumns = [
        { field: 'serial', header: `${t('txt_no')}`, width: "5%", align: 'left', disabledSort: false },
        {
            field: 'amount', header: `${t('txt_amount')}`, width: "10%", align: 'right', disabledSort: false,
            bodyTemplate: (rowData: any) => formatAmountBodyTemplate(rowData)
        },
        { field: 'name', header: `${t('txt_name')}`, width: "15%", align: 'left', disabledSort: true },
        { field: 'company_name', header: `${t('txt_organization')}`, width: "10%", align: 'left', disabledSort: true },
        {
            field: 'payment_address', header: `${t('txt_address')}`, width: "20%", minWidth: "240px", align: 'left', disabledSort: true,
        },
        { field: 'postal_code', header: `${t('txt_postal_code')}`, width: "10%", align: 'left', disabledSort: true },
        { field: 'city', header: `${t('txt_city')}`, width: "8%", type: "numeric", align: 'left', disabledSort: true },
        {
            field: 'country', header: `${t('txt_country')}`, width: "10%", align: 'left', disabledSort: true,
            bodyTemplate: buildCountryBodyTemplate(countries)
        },
        {
            field: 'donated_date', header: `${t('txt_donated_date')}`, width: "160px", align: 'left', disabledSort: false,
            bodyTemplate: (rowData: any) => {
                return <div>{formatDateV2(rowData.donated_date, getLanguage())}</div>
            },
        },
        {
            field: 'receipt_date', header: `${t('txt_receipt_date')}`, width: "160px", align: 'left', disabledSort: false,
            bodyTemplate: (rowData: any) => {
                return <div>{formatDateV2(rowData.receipt_date, getLanguage())}</div>
            },
        },
        {
            field: 'created_at', header: `${t('txt_created')}`, width: "160px", align: 'left', disabledSort: false,
            bodyTemplate: (rowData: any) => {
                return <div>{formatDateTimeV2(rowData.created_at, getLanguage())}</div>
            },
        },
    ];

    const dynamicColumns = tableColumns.map((col, i) => {
        return <Column
            key={`${col.field}_${i}`}
            field={col.field}
            header={col.header}
            style={{
                width: col.width,
                minWidth: col.minWidth
            }}
            dataType={col.type}
            body={col.bodyTemplate}
            align={col.align as ColumnAlignType}
            sortable={!col?.disabledSort}
        />;
    });

    const buildMenu = (rowData: any) => {
        const defaultActions = [
            {
                label: t('txt_edit'),
                icon: "wi-edit",
                hidden: !permissions.canUpdateReceipt,
                command: (e: any) => {
                    onShowCreateDialog(true)
                    setReceipt(rowData);
                },
            },
            {
                label: t('txt_download_receipt'),
                icon: "wi-download",
                command: (e: any) => downloadReceipt(rowData)
            },
            {
                label: t('txt_delete'),
                icon: "wi-trash",
                hidden: !permissions.canUpdateReceipt,
                dangerous: true,
                command: (e: any) => onDeleteReceipt([rowData]),
            }
        ];
        return <MenuDot items={[...defaultActions]}></MenuDot>;
    }

    return (
        <Layout title={t('txt_title')} className="manual-receipt-manager">
            <div className="header">
                <div className="header-content pt-16 pb-16 pl-24 pr-24">
                    <div className="search-container">
                        <WISearchField
                            icon={"pi pi-search"}
                            placeholder={t('txt_search')}
                            setSearch={(value: any) => setSearch(value.global.value)}
                            enterSearch={() => onSearchFunc(search)}
                        />
                    </div>
                    <div className="d-flex flex-row gap-16">
                        {
                            selectedReceipts && selectedReceipts.length > 0 ?
                            <Button
                                label={t('txt_delete')}
                                className="wi-danger-button-v2"
                                hidden={!permissions.canUpdateReceipt}
                                onClick={() => onDeleteReceipt(selectedReceipts)}
                            /> : <></>
                        }
                        <Button
                            className="wi-primary-button-v2"
                            label={t('txt_btn_add')}
                            hidden={!permissions.canCreateReceipt}
                            onClick={() => {
                                setReceipt(null);
                                onShowCreateDialog(true)
                            }}>
                            <PlusSvg className="icon-svg" />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="p-table-v2 pt-12 pl-24 pr-24">
                <DataTable dataKey="uuid"
                    loading={isLoading}
                    value={receipts.data}
                    emptyMessage={t('txt_no_records')}
                    responsiveLayout="scroll"
                    scrollable
                    scrollHeight="calc(100vh - 214px)"
                    scrollDirection="both"
                    selectionMode="checkbox"
                    selection={selectedReceipts}
                    onSelectionChange={e => setSelectedReceipts(e.value)}
                    onSort={(e: any) => {
                        setSortConfig({ sortField: e.sortField, sortOrder: e.sortOrder });
                        setSelectedReceipts([]);
                    }}
                    sortField={sortConfig.sortField}
                    sortOrder={sortConfig.sortOrder}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '50px' }}></Column>
                    {dynamicColumns}
                    <Column
                        frozen
                        alignFrozen="right"
                        style={{ width: '64px' }}
                        body={buildMenu}>
                    </Column>
                </DataTable>
                <WIPaginator
                    first={paginator.first}
                    rows={paginator.rows}
                    totalRecords={receipts.totalPage}
                    onPageChange={onBasicPageChange}/>
            </div>
            <WIConfirmDialog
                visible={isShowDeleteDialog}
                onHide={() => onShowDeleteDialog(false)}
                onConfirm={onDeleteConfirm}
                message={
                    <Fragment>
                        <span style={{ fontSize: '18px', fontWeight: '700' }}>Are you sure you want to delete this receipt?</span>
                        <br />
                        <span style={{ fontSize: '13px' }}>
                            All of your data will be deleted <b>permanently</b>.
                            <br />
                            You <b>cannot undo</b> this action.
                        </span>
                    </Fragment>
                }
                classIcon="fa-solid fa-exclamation-triangle mr-3 dialog-icon"
            />
            <CreateReceiptDialogComponent
                title={`${receipt ? 'Update' : 'Generate'} receipt`}
                visible={isShowCreate}
                countries={countries}
                fetchReceiptsData={fetchReceiptsData}
                receipt={receipt}
                onHide={() => {
                    onShowCreateDialog(false);
                    setReceipt(null);
                }}
            />
        </Layout>
    );
}

export default ReceiptManager;

import * as React from "react"
import useDocumentTitle from '../../common/useDocumentTitle';
import { TITLE_APP } from "../../../utils/utils";

const PageComponent = (props: any) => {

 useDocumentTitle(`${TITLE_APP}${props.title}`);

  return (
    <props.component />
  );
}

export default PageComponent;

import { Dialog } from "primereact/dialog";
import { useRef, useState } from "react";
import React from "react";
import { Toast } from "primereact/toast";
import "./download-donor-dialog.scss";
import { WIButton } from "../../../common";

const DownloadDonorDialog = (props: any) => {
  const { visible, onHide, onSubmit, isLoading, labelFileDonor, setLabelFileDonor } = props;
  const [value, setValue] = useState<any>();
  const toast = useRef(null);

  const handleOnChangedValue = (evt: any) => {
    const file = evt.target.files[0];
    if (file && file.type === "text/csv") {
      const label = evt.target.value.split("\\");
      setLabelFileDonor(label[label.length - 1]);
      if (file) {
        const read = new FileReader();
        read.onload = function (e: any) {
          const contents = e.target.result;
          setValue(window.btoa(unescape(encodeURIComponent(contents))));
        };
        read.readAsText(file);
      } else {
        alert("Failed to load file");
      }
    } else {
      alert("Only import files csv");
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={`Import Donors`}
        visible={visible}
        style={{ width: "350px" }}
        modal
        className="wi-dialog"
        onHide={onHide}
      >
        <div className="dialog-donor-container">
          <div className="row">
            <div className="col-md-12 donor-container upload-file">
              <label className="label-upload-csv" htmlFor="upload">
                Choose CSV
              </label>
              <input
                className="input-upload"
                type="file"
                name="upload"
                id="upload"
                accept=".csv"
                onChange={handleOnChangedValue}
              />
            </div>
            {labelFileDonor ? (
              <label className="name-file">{labelFileDonor}</label>
            ) : <div className="description">
                    <a target="_blank" href={`${process.env.PUBLIC_URL}/data/Import_donors.csv`} rel="noreferrer" >Download sample</a>
                </div>}
          </div>
         
          <div className="btn-submit-container">
            <WIButton
              className="add-wir"
              primary={true}
              label="Submit"
              icon={"pi-angle-right"}
              onClick={() => {
                if(value) {
                  onSubmit(value);
                  setValue('');
                  setLabelFileDonor(null);
                }
              }}
              disable={isLoading}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DownloadDonorDialog;

import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from "primereact/selectbutton";
import "./wi-multi-select-szie-dropdown.scss";
import * as _ from "lodash";
import RegionManagerAPI from "../../../../services/regionManager";
import CampaignManagerAPI from "../../../../services/campaignManager";
import { env } from '../../../../environment';
import { PartnerManagerAPI } from "../../../../services";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

export function MultiSelectSizeDropdown(props: any) {
  const { values, disabled, schema } = props;
  const progressOptions = [
    { name: "Region", value: 0 },
    { name: "Campaign", value: 2 },
    { name: "Partner", value: 4 },
  ];

  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [sources, setSources] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [partners, setPartners] = useState<any>([]);
  const [regionSource, setRegionSource] = useState<any>([]);
  const [campaignSource, setCampaignSource] = useState<any>([]);
  const [partnerSource, setPartnerSource] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<any>();
  const [selectedProcessbarMode, setSelectedProcessbarMode] = useState();
  const [selectedItem, setSelectedItem] = useState<any>({
    regions: [],
    campaigns: [],
    partners: [],
    partner: {}
  });

  const [progressOptionValue, setProgressOptionValue] = useState<any>();
 
  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    mappingDataSource();
  }, [regionSource, campaignSource]);

  useEffect(() => {
    loadOriginalData();
  }, [regions, campaigns, partners]);

  useEffect(() => {
    updateFieldValue(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    if(selectedValue){
      updateFieldValue([selectedValue])
    }
  }, [selectedValue]);

  useEffect(() => {
    const progressOption = getProgressOptionText(progressOptionValue);
    if (progressOption && progressOption?.name === "Region") {
      setSelectedValues(selectedItem.regions);
    }
    else if(progressOption && progressOption?.name === "Partner"){
      setSelectedValues(selectedItem.partners);
      setSelectedValue(selectedItem.partner);
    }
    else {
      setSelectedValues(selectedItem.campaigns);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (progressOptionValue) {
      const progressOption = getProgressOptionText(progressOptionValue);
      setFieldValue("progress_option_text", progressOption?.name?.toString().toLowerCase());
    }
  }, [progressOptionValue]);

  const fetchAllData = async () => {
    const [regionData, campaignsData, partnersData] = await Promise.all([
      fetchRegionsData(),
      fetchCampaignsData(),
      fetchPartnersData()
    ]);

    setRegionSource(regionData);
    setCampaignSource(campaignsData);
    setPartnerSource(partnersData);
    setSelectedProcessbarMode(values?.progressbar_mode);
  };

  const loadOriginalData = () => {
    let originalOptionValue = values.progress_option;
    if (originalOptionValue === 1) {
      originalOptionValue = 0; // Set default Region option
    }

    let originalSelectedItems: any = [];
    let ids = values.dropdown_id.split(";");
    const progressOption = getProgressOptionText(originalOptionValue);
    if (progressOption && progressOption?.name === "Region") {
      //@ts-ignore
      ids.forEach(id => {
       let originalSelectedItem = _.find(regions, { id: id });
       if (originalSelectedItem) {
        originalSelectedItems.push(originalSelectedItem);
       }
      });
    }
    else if (progressOption && progressOption?.name === "Partner") {
      //@ts-ignore
      ids.forEach(id => {
       let originalSelectedItem = _.find(partners, { id: id });
       if (originalSelectedItem) {
        originalSelectedItems.push(originalSelectedItem);
       }
      });
    }
    else {
       //@ts-ignore
       ids.forEach(id => {
        let originalSelectedItem = _.find(campaigns, { id: id });
        if (originalSelectedItem) {
          originalSelectedItems.push(originalSelectedItem);
         }
       });
    }

    setProgressOptionValue(originalOptionValue);
    onChangeProgressOption(originalOptionValue, progressOption?.name);
    onUpdateSelectedValue(originalOptionValue, originalSelectedItems);
  };

  const fetchRegionsData = async () => {
    try {
      const res = await RegionManagerAPI.getAllRegions();
      if (res && res.status === 200) {
        let regionData = res.data.records || [];
        if (!!regionData) {
          return regionData;
        }
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCampaignsData = async () => {
    try {
      const res = await CampaignManagerAPI.getAllCampaigns();
      if (res && res.status === 200) {
        let campaigns = res.data.records || [];
        if (!!campaigns) {
          return campaigns;
        }
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPartnersData = async () => {
    try {
      const res = await PartnerManagerAPI.getAllPartners();
      if (res && res.status === 200) {
        let partner = res.data.records || [];
        if (!!partner) {
          return partner;
        }
      }
      return [];
    } catch (error) {
      console.log(error);
    }
  };

  const mappingDataSource = () => {
    let regionData = regionSource.map((region: any) => ({
      id: region.uuid,
      name: region.name,
      code: region?.identifier
    }));
    setRegions(regionData);

    let campaignData = campaignSource.map((campaign: any) => ({
      id: campaign.uuid,
      area_id: campaign.land_id,
      name: campaign.name,
      code: campaign?.code,
      partner_id: campaign?.partner_id
    }));
    setCampaigns(campaignData);


    mappingDataSourceForPartners();
  };

  const mappingDataSourceForPartners = () => {
    let partnerData = partnerSource.map((partner: any) => ({
      id: partner.uuid,
      area_id: partner.land_id,
      name: partner.name,
      code: partner?.code,
    }));

    setPartners(partnerData);
  };


  const onChangeProgressOption = (progressOptionValue: any, progressOptionName: any = "") => {
    setFieldValue("progress_option", progressOptionValue);
    setFieldValue("data_type", progressOptionName.toLowerCase());

    if (progressOptionName === "Region") {
      setFieldValue('data_goal', "");
      setSources(regions);
      setSelectedValues(selectedItem.regions);
    }
    else if (progressOptionName === "Partner") {
      setSources(partners);
      setSelectedValues(selectedItem.partners);
    }
    else {
      setSources(campaigns);
      setSelectedValues(selectedItem.campaigns);
    }
  };

  const onUpdateSelectedValue = (progressOption: any , value: any) => {
    const option = getProgressOptionText(progressOption);
    if (option && option?.name === "Region") {
      setSelectedItem({regions: value});
    }
    else if(option && option?.name === "Partner"){
      setSelectedItem({partners: value, partner: value[0]});
    }
    else {
      setSelectedItem({campaigns: value});
    }
  }

  const updateFieldValue = (value: any) => {
     
    if (value && value?.length > 0) {
        const progressOption = getProgressOptionText(progressOptionValue);

        setFieldValue('dropdown_id', _.map(value, 'id').join(';'));
       
        // @ts-ignore
        let selectedItem = value.find(v => v?.size?.protected_size.toString().length > 5 
        || v?.size?.protected_size.toString().length > 5
        || v?.size?.no_donations?.toString().length > 5);
        let fontSize = selectedItem ? "3.625rem" : "4.625rem";
        setFieldValue('font_size', fontSize);
        
        let firstValueItem = value[0];
        if (firstValueItem?.code && firstValueItem?.code !== undefined){
          if (progressOption && progressOption?.name === "Region") {
            let regionUrl = `${env.DONATION_URL}?region=${firstValueItem?.code}`;
            setFieldValue("link", regionUrl);
          }
          else if (progressOption && progressOption?.name === "Campaign") {
            let campaignUrl = `${env.DONATION_URL}?campaign=${firstValueItem?.code}`;
            setFieldValue("link", campaignUrl);
          }
          setFieldValue("selected_code", firstValueItem?.code);
        }
        setFieldValue("data_type", progressOption?.name.toLowerCase());
        setFieldValue('data_src_id', _.map(value, 'id').join(';'));
    }
  };

  const customItemTemplate = (item: any) => {
    if (item) {
      return (
        <div>
          <div dangerouslySetInnerHTML={{ __html: `${item?.name?.de ?? item?.name }`}}>
          </div>
        </div>
      );
    }
    return(<></>);
  };

  const getProgressOptionText = (progressOptionValue: any) => {
    return progressOptions.find((po) => po.value === progressOptionValue);
  }
  
  return (
    <div className="form-element">
      <div className="form-element-title" style={{ marginBottom: "15px" }}>
        {schema.label}
      </div>
      <div style={{ margin: "-10px" }}>
        <div className="apply-btn" style={{ display: "flex" }}>
          <div className="select-group">
            {sources ? (
              <>
                <div style={{ marginRight: "10px" }}>
                  <SelectButton
                    hidden={disabled}
                    className="wi-form-select-button"
                    optionLabel="name"
                    value={progressOptionValue}
                    options={progressOptions}
                    onChange={(e: any) => {
                      setProgressOptionValue(e.value);
                      const progressOption = getProgressOptionText(e.value);
                      onChangeProgressOption(
                        e.value,
                        progressOption?.name
                      );
                    }}
                    unselectable={false}
                  />
                </div>
                {
                  progressOptionValue === 4 ? 
                  <Dropdown
                    value={selectedValue}
                    itemTemplate={customItemTemplate}
                    valueTemplate={customItemTemplate}
                    options={sources}
                    dropdownIcon={"pi pi-chevron-down"}
                    onChange={(item: any) => {
                      setSelectedValue(item.value);
                      onUpdateSelectedValue(progressOptionValue, [item.value]);
                    }}
                    appendTo={"self"}
                    optionLabel="name"
                    filter
                    filterBy={"name"}
                    filterPlaceholder={'Search'}
                  />
                  :
                  <MultiSelect
                    className="wi-multi-select-dropdown" 
                    optionLabel="name.de"
                    selectedItemTemplate={customItemTemplate}
                    itemTemplate={customItemTemplate}
                    dropdownIcon={"pi pi-chevron-down"}
                    value={selectedValues}
                    maxSelectedLabels={1}
                    options={sources}
                    onChange={(item: any) => {
                      setSelectedValue("");
                      setSelectedValues(item.value);
                      onUpdateSelectedValue(progressOptionValue, item.value);
                      }}
                    appendTo={"self"}
                    filter
                    filterBy={schema.sourceConfiguration.searchFields?.join(",")}
                    filterPlaceholder={'Search'}
                  />
                }
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {
          progressOptionValue !== 0 ?
          <>
            <div className="form-element-title" style={{ margin: "10px" }}>
            Show Progressbar
            </div>
            <div style={{ marginLeft: "10px" }}>
            <InputSwitch
              className="wi-form-toggle-button"
              checked={selectedProcessbarMode} 
              trueValue={false}
              falseValue={true}
              onChange={(e: any) =>{
                if(progressOptionValue !== 0){
                  setFieldValue('progressbar_mode', e.value);
                }
                setSelectedProcessbarMode(e.value);
              }}
            />
            </div>
          </>
          :
          <></>
        }
         {
            progressOptionValue !== 0 && !selectedProcessbarMode ? 
            <>
              <div className="form-element-title" style={{ margin: "10px" }}>
                Goal
              </div>
              <div style={{ marginLeft: "10px" }}>
                <InputNumber
                  format={false}
                  value={values?.data_goal}
                  style={{width: "100%", paddingRight: "10px"}}
                  onChange={(e: any) =>{
                    setFieldValue('data_goal', e.value?.toString());
                  }}
                />
              </div>
            </>
            : <></>
        }
      </div>
    </div>
  );
}
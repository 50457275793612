import * as React from "react";
import { useState, useRef } from "react";
import { FieldArray, Formik, useFormik, useFormikContext } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import "./header-item-editor.scss";
import { Dialog } from "primereact/dialog";
import _ from "lodash";
import { WIButton } from "../../../../common";
import HeaderSubItemList from "./header-subitem-list/header-subitem-list";
import { WiTextEditor, WiTextEditor1Line } from "../../../../dynamic-form/commons";
import SearchURLGlobal from "../../../../common/searchURLGlobal/searchURLGlobal";
import WIUploadMediaField from "../../../../common/upload/wi-upload-media-field";

const CMS_HEADER_ICONS = [
  { label: 'News', value: '&#xe93a;' },
  { label: 'Globe', value: '&#xe940;' },
  { label: 'Campaign', value: '&#xe941;' },
  { label: 'About Us', value: '&#xe93c;' },
  { label: 'Info', value: '&#xe939;' },
  { label: 'Project', value: '&#xe93b;' },
  { label: 'German Flag', value: '&#xe93e;' },
  { label: 'Canadian Flag', value: '&#xe93f;' },
  { label: 'Peruvian Flag', value: '&#xe93d;' },
]

const HeaderItemEditor = (props: any) => {
  const { data, visible, setVisible, setData, globalContent } = props;
  const formikRef = useRef(null);

  const onInitValues = (data: any) => {
    return data;
  }

  const renderIcons = (option: any) => {
    return option ? <div className="header-item-label"><span className="aw_icon_dropdown"><i className="aw_icon" dangerouslySetInnerHTML={{ __html: option.value }}></i></span> {option.label}</div> : '';
  }

  const saveData = (values: any) => {
    onHide();
    setData(values);
  }

  const onHide = () => {
    setVisible(false);
  }

  return <>
    {
      <Dialog
        header="Editor"
        visible={visible || false}
        onHide={onHide}
        style={{ width: "800px", maxHeight: "70vh" }}
      >
        <div className={`header-item-editor-container`}>
          <Formik
            enableReinitialize={true}
            innerRef={formikRef}
            initialValues={onInitValues(data)}
            // validationSchema={validationLandSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log('[Header] Save changes...');
              saveData(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              dirty,
              isValid,
            }) => (
              <div>
                <div className="col-12">
                  <div className="row row-content">
                    {
                      data.type === 'list-sub-items' ? <>
                        <div className="col-md-6">
                          <div className="element-form">
                            <label className="label">
                              Icon {" "}
                              {/* <span className="required-label">*</span> */}
                            </label>
                            <Dropdown
                              className={`header-item-icon ${errors.icon ? "has-error" : ""}`}
                              name="icon"
                              value={values.icon}
                              options={CMS_HEADER_ICONS}
                              itemTemplate={renderIcons}
                              valueTemplate={renderIcons}
                              onChange={handleChange}
                              optionLabel="label"
                              optionValue="value"
                              showClear
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="element-form">
                            <label className="label">
                              Title {" "}
                              {/* <span className="required-label">*</span> */}
                            </label>
                            <WiTextEditor1Line
                              name="label"
                              values={values}
                            />
                          </div>
                        </div>
                      </> : <></>
                    }
                    {
                      data.type !== 'page' ? <>
                        <div className="col-md-12">
                          <div className="element-form">
                            <label className="label">
                              Items {" "}
                              {/* <span className="required-label">*</span> */}
                            </label>
                          </div>
                          <HeaderSubItemList globalContent={globalContent} type={data.type} />
                        </div>
                      </> : <></>
                    }
                    {
                      data.type === 'page' ? <>
                        <div className="col-md-12">
                          <div className="element-form">
                            <label className="label">
                              Title {" "}
                              {/* <span className="required-label">*</span> */}
                            </label>
                            <WiTextEditor1Line
                              name="label"
                              values={values}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="element-form">
                            <label className="label">
                              Description {" "}
                              {/* <span className="required-label">*</span> */}
                            </label>
                            <WiTextEditor
                              name={`description`}
                              values={values}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="element-form">
                            <SearchURLGlobal
                              label={"Link"}
                              search_content={globalContent}
                              values={values.link}
                              searchFields={["name"]}
                              // isRequired={true}
                              onChangeValue={(valueURL: any) => {
                                setFieldValue(`link`, valueURL, true);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="element-form">
                            <WIUploadMediaField
                              type="file"
                              hideLabelFileName={true}
                              classImage="image-cert"
                              className="img-frame-16-9"
                              name="image"
                              label="Picture"
                              // required={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="element-form">
                            <label className="label">
                              Link Name {" "}
                              {/* <span className="required-label">*</span> */}
                            </label>
                            <WiTextEditor1Line
                              name="link_name"
                              values={values}
                            />
                          </div>
                        </div>
                      </> : <></>
                    }
                  </div>
                </div>
                <div className="header-action-footer">
                  <WIButton
                    type="submit"
                    className="btn-save-header-item"
                    primary={true}
                    label="Apply"
                    onClick={handleSubmit}>
                  </WIButton>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Dialog>
    }
  </>;
};

export default HeaderItemEditor;

import _ from "lodash";
import * as Yup from "yup";
import { CMsPageManagerAPI, PartnerManagerAPI, QuoteManagerAPI } from "../../services";
import ContactManagerAPI from "../../services/global-contents/contactManager";
import FAQsManagerAPI from "../../services/global-contents/faqsManager";
import { joinURL } from "../../utils/urlUtils";
import { createEmptyPage, createEmptySEOConfig, PageType } from "./components/cms-page-info/pageType";
import { PAGE_LANGUAGE, PAGE_STATUS, PAGE_CODE } from "./utils";
import { v4 as uuidv4 } from "uuid";
import { IComponent } from "./interfaces/IComponent";
import moment from "moment";

const getDefaultFooter = (footers: any) => {
  if (footers) {
    let item = footers.find((f: any) => f.is_primary);
    return item ? item.uuid : footers[0]?.uuid;
  }
  return "";
};

const getDefaultHeader = (headers: any) => {
  if (headers) {
    let item = headers.find((f: any) => f.is_primary);
    return item ? item.uuid : headers[0]?.uuid;
  }
  return "";
};

const getPageLanguage = (value: string) => {
  if (value === "both") {
    return PAGE_LANGUAGE.map((l: any) => l.code);
  }
  let item = PAGE_LANGUAGE.find(
    (f) => f.code.toLocaleLowerCase() === value.toLocaleLowerCase()
  );
  return [item ? item.code : PAGE_LANGUAGE[0].code];
};

const getPageStatus = (code: string) => {
  let item = PAGE_STATUS.find(
    (f) => f.code.toLocaleLowerCase() === code.toLocaleLowerCase()
  );
  return item ? item.code : PAGE_STATUS[0].code;
};

const getSeoImageName = (data: any) => {
  if (data.seo_image) {
    const pieces = data.seo_image.split(";base64,");
    if (pieces && pieces.length === 2) {
      const ext = pieces[0].replace("data:image/", "")
      return `cms_page_${data.name.replace(/\s/g, "_").toLocaleLowerCase()}_${data.type_id}_seo_image.${ext}`;
    }
  }
  return "";
};

export const validationPageSchema = Yup.object().shape({
  name: Yup.string()
    .required("Page name is required")
    .max(100, "Page name max length is 100 letters"),
  title: Yup.object().shape({
    de: Yup.string()
      .required("Page title is required")
      .max(100, "Page title max length is 100 letters"),
    en: Yup.string()
      .required("Page title is required")
      .max(100, "Page title max length is 100 letters"),
  }),
  type_id: Yup.string().required("Page type is required"),
  page_id: Yup.string().required("Parent Page is required"),
  header_id: Yup.string().required("Header is required"),
  footer_id: Yup.string().required("Footer is required"),
  language: Yup.array().min(1, "Languages is required"),
  status: Yup.string().required("Status is required"),
});

const formatDate = (str: any, format = 'DD.MM.YYYY') =>  str ? moment(str).format(format) : '';
export const getLastPublishData = (custom_config: any, format = 'DD.MM.YYYY') => {
  return custom_config && custom_config.published_info && custom_config.published_info.length > 0 ?
    formatDate(_.last(custom_config?.published_info as any[])?.time, format) : '';
}

export const getFirstPublishData = (custom_config: any, format = 'DD.MM.YYYY') => {
  return custom_config && custom_config.published_info && custom_config.published_info.length > 0 ?
    formatDate(_.first(custom_config?.published_info as any[])?.time, format) : '';
}

export const onInitPageValues = (data: any): PageType => {
  if (!data.isShowCreate && data.selectedPage) {
    let page = data.selectedPage;
    const seo_config = page.seo_configuration
      ? parseJSON(page.seo_configuration)
      : page.seo_configuration;

    const og_seo_config = seo_config && seo_config.og_seo_config ? seo_config.og_seo_config : createEmptySEOConfig();
    const twitter_seo_config = seo_config && seo_config.twitter_seo_config ? seo_config.twitter_seo_config : createEmptySEOConfig();

    return {
      name: page.name,
      title: page.title,
      special_type: page.special_type,
      type_id: page.type_id || findType(data.pageTypes),
      header_id: page.header_id || getDefaultHeader(data.headers),
      page_id: page.page_id || "",
      footer_id: page.footer_id || getDefaultFooter(data.footers),
      language: getPageLanguage(page.language),
      url: page.url || '',
      status: getPageStatus(page.status),
      og_seo_config: og_seo_config,
      twitter_seo_config: twitter_seo_config,
      custom_config: page.custom_config,
      addtional_css_libs:  page.custom_config?.addtional_css_libs || '',
      addtional_js_libs:  page.custom_config?.addtional_js_libs || '',
      allow_search:  page.custom_config?.hide_search ? 0 : 1,
      // use_header: page.use_header,
      // use_footer: page.use_footer,
      // button_header: page.custom_config?.header?.button_header,
      // button_header_url: page.custom_config?.header?.button_header_url,
      last_published_at: getLastPublishData(page.custom_config, 'DD.MM.YYYY HH:mm:ss'),
      first_published_at: getFirstPublishData(page.custom_config, 'DD.MM.YYYY HH:mm:ss')
    };
  }

  const newPage = {
    ...createEmptyPage(),
    type_id: data.defaultPageTypeId || findType(data.pageTypes)?.uuid,
    header_id: getDefaultHeader(data.headers),
    footer_id: getDefaultFooter(data.footers),
  };
  return {
    ...newPage,
  };
};

export const getSeoConfiguration = (data: any) => {
  if (data) {
    const image_name = getSeoImageName(data);

    const seo_config = {
      primary: [
        {
          name: "title",
          content: data.seo_title,
        },
        {
          name: "description",
          content: data.seo_description,
        },
      ],
      og: [
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:url",
          content: data.url,
        },
        {
          property: "og:title",
          content: data.seo_title,
        },
        {
          property: "og:description",
          content: data.seo_description,
        },
        {
          property: "og:image",
          content: image_name,
        },
      ],
      twitter: [
        {
          property: "twitter:card",
          content: data.name,
        },
        {
          property: "twitter:url",
          content: data.url,
        },
        {
          property: "twitter:title",
          content: data.seo_title,
        },
        {
          property: "twitter:description",
          content: data.seo_description,
        },
        {
          property: "twitter:image",
          content: image_name,
        },
      ],
    };

    return seo_config;
  }

  return "";
};

const getNewSeoImageName = (data: any, field: string) => {
  if (data[field].seo_image) {
    const pieces = data[field].seo_image.split(";base64,");
    if (pieces && pieces.length === 2) {
      const ext = pieces[0].replace("data:image/", "");
      return `cms_page_${uuidv4()}_${field}_image.${ext}`;
    } else {
      return data[field].seo_image;
    }
  }
  return "";
};

export const getNewSeoConfiguration = (data: any) => {
  const keys = ['og_seo_config', 'twitter_seo_config'];
  const config: any = {};
  keys.forEach((k: string) => {
    if (data[k]) {
      config[k] = {
        seo_image: getNewSeoImageName(data, k),
        seo_title: data[k].seo_title,
        seo_description: data[k].seo_description
      }
      data[`${k}_image`] = data[k].seo_image;
      data[`${k}_image_name`] = config[k].seo_image;
    }
  });

  return config;
}

export const processPageInfo = (values: any, selectedPage: any, isPublished = false) => {
  let pageData = Object(_.cloneDeep(values));

  pageData.status = isPublished ? 'published' : 'draft';
  if (!pageData.language || pageData.language.length === 2) {
    pageData.language = "both";
  } else if (pageData.language.length === 1) {
    pageData.language = pageData.language[0];
  }

  const seo_config = getNewSeoConfiguration(pageData);
  pageData.seo_configuration = JSON.stringify(seo_config);
  delete pageData["og_seo_config"];
  delete pageData["twitter_seo_config"];

  if (selectedPage) {
    if (!pageData["og_seo_config_image"]?.includes(';base64')) {
      delete pageData["og_seo_config_image"];
      delete pageData["og_seo_config_image_name"];
    }
    if (!pageData["twitter_seo_config_image"]?.includes(';base64')) {
      delete pageData["twitter_seo_config_image"];
      delete pageData["twitter_seo_config_image_name"];
    }
  }

  pageData['custom_config'] = pageData['custom_config'] || {};
  pageData['custom_config']['hide_search'] = pageData.allow_search !== 1; 
  pageData['custom_config']['addtional_js_libs'] = pageData.addtional_js_libs; 
  pageData['custom_config']['addtional_css_libs'] = pageData.addtional_css_libs; 
  if (isPublished) {
    pageData['custom_config'] = pageData['custom_config'] || {};
    pageData['custom_config']['published_info'] = pageData['custom_config']['published_info'] || [];
    pageData['custom_config']['published_info'].push({ time: moment().toISOString() })
  }

  return pageData;
}

export const fetchRecords = async (action: any) => {
  try {
    const res = await action();
    if (res && res.status === 200) {
      return res.data.records || [];
    }
    return [];
  } catch (error) {
    console.log("Fetch error", error);
    return [];
  }
};

const fetchContactsData = async () => fetchRecords(ContactManagerAPI.getAll);
const fetchAllFAQsData = async () => fetchRecords(FAQsManagerAPI.getAllFAQs);
const fetchAllArticles = async () => fetchRecords(FAQsManagerAPI.getAllArticles);
const fetchQuoteData = async () => fetchRecords(QuoteManagerAPI.getAll);
const fetchPartners = async () => fetchRecords(PartnerManagerAPI.getAllPartners);
export const fetchCMSHeaders = async () => fetchRecords(CMsPageManagerAPI.getAllCMSHeaders);
export const fetchCMSFooters = async () => fetchRecords(CMsPageManagerAPI.getAllCMSFooters);
const fetchCMSDonationExamples = async () => fetchRecords(CMsPageManagerAPI.getAllCMSDonationExamples);
export const fetchAllBlocks = async () => fetchRecords(CMsPageManagerAPI.getCTABlocks);
const getHeadHtml = async () => {
  try {
    const res = await CMsPageManagerAPI.getHeadHtml();
    if (res && res.status === 200) {
      return res.data.htmlTemplate;
    }
    return '';
  } catch (error) {
    console.log("Fetch error", error);
    return '';
  }
};

export const fetchCMSAllPages = async () => fetchRecords(CMsPageManagerAPI.getAllPages);

export const fetchPageTypes = async () => {
  let allPageTypes = await fetchRecords(CMsPageManagerAPI.getAllPageType);
  let pageTypes = allPageTypes.map((item: any) => {
    return {
      ...item,
      name: isJSON(item.name) ? JSON.parse(item.name) : generateObjWithLanguages(item.name)
    }
  })
  
  return pageTypes;
}

export const getPageTypeByCode = (pageTypes: any[], code: PAGE_CODE) => {
  if (code && pageTypes) {
    return pageTypes.find((t: any) => t.code.toLowerCase() === code);
  }

  return null;
}

export const loadGlobalContent = async () => {
  let [contacts, faqs, quotes, partners, footers, headers, ctaBlocks, htmlTemplate, articles] = await Promise.all([
    fetchContactsData(),
    // fetchDownloadData(),
    fetchAllFAQsData(),
    // fetchProjectsData(),
    fetchQuoteData(),
    fetchPartners(),
    // fetchPageTypes(),
    fetchCMSFooters(),
    fetchCMSHeaders(),
    // fetchCMSDonationExamples(),
    fetchAllBlocks(),
    getHeadHtml(),
    fetchAllArticles(),
  ]);

  quotes.forEach((q: any) => q.owner_name = q.owner_name || '');
  
  if (articles) {
    faqs = faqs.concat(articles);
  }
  
  return {
    contacts,
    // downloads,
    faqs,
    // projects,
    quotes,
    partners,
    // pageTypes,
    footers,
    headers,
    // donation_examples,
    ctaBlocks,
    htmlTemplate
  };
}

export const loadPageMasterData = async () => {
  const [pageTypes, footers, headers, htmlTemplate] = await Promise.all([
    fetchPageTypes(),
    fetchCMSFooters(),
    fetchCMSHeaders(),
    getHeadHtml()
  ]);

  return {
    pageTypes,
    footers,
    headers,
    htmlTemplate
  };
}

export const syncBlockWithGlobalContent = (block: any, globalItems: any, updatedPaths: any) => {
  const globalConfig = block.configuration.globalConfiguration;
  const items = globalItems[globalConfig.type] || [];
  const fieldName = globalConfig.fieldName;
  let parameters = fieldName && block.parameters[fieldName] ? block.parameters[fieldName] : block.parameters;
  if (_.isArray(parameters)) {
    parameters = parameters.map((p, index) => {
      const selectedItem = items.find((c: any) => c.uuid === p.global_content_id);
      if(selectedItem) {
        updatedPaths.push(`${fieldName}.${index}`);
      }
      return selectedItem ? _.defaultsDeep(selectedItem, p) : p;
    });

    if (fieldName) {
      block.parameters[fieldName] = parameters;
    } else {
      block.parameters = parameters;
    }
  } else {
    const selectedItem = items.find((c: any) => c.uuid === parameters.global_content_id);
    const newData = selectedItem ? _.defaultsDeep(selectedItem, parameters) : parameters;
    if (fieldName) {
      updatedPaths.push(fieldName);
      block.parameters[fieldName] = newData;
    } else {
      block.parameters = newData;
    }
  }

  return block;
}

export const syncBlockWithGlobalContentForObject = (block: any, globalItems: any, excludedPaths: any) => {
  let sourceConfiguration_key = 'sourceConfiguration';
  let sourceConfigurationObjs: any = [];
  findAllObjectsByKey(block.configuration, sourceConfiguration_key, sourceConfigurationObjs);
  if(!sourceConfigurationObjs) {
    return;
  }

  let paramObjs: any = [];
  findAllObjectsByKey(block.parameters, 'global_content_id', paramObjs);
  if(!paramObjs) {
    return;
  }
  
  sourceConfigurationObjs.forEach((configItem: any) => {
    let sourceConfiguration = configItem.data[sourceConfiguration_key]
    let search_content = globalItems.search_content[sourceConfiguration.type];
    if(!search_content) {
      return;
    }

    paramObjs.forEach((item: any) => {
      let paramObj = item.data;
      let parentPath = item.paths.join('.');
      if(excludedPaths.includes(parentPath)) {
        return;
      }
      
      let globalContent = search_content.find((f: any) => f.id === paramObj.global_content_id);
      if(globalContent) {
        let newData = globalContent ? _.defaultsDeep(globalContent, paramObj) : paramObj;
        _.update(block.parameters, parentPath, u => newData);
      }
    });
  });
}

const findAllObjectsByKey = (obj: any, key: any, result: any, parentPaths: any = []) => {
  if(!obj) {
    return;
  }
  
  if (_.isArray(obj)) {
    obj.forEach((element: any, index: any) => {
      // parentPaths.push(index);
      findAllObjectsByKey(element, key, result, [...parentPaths, index]);
    });
    return;
  }

  if (obj.hasOwnProperty(key)) {
    result.push(
      {
        data: obj,
        paths: _.cloneDeep(parentPaths)
      })
  }

  for (let k of Object.keys(obj)) {
    if (typeof obj[k] === "object") {
      // parentPaths.push(k);
      findAllObjectsByKey(obj[k], key, result, [...parentPaths, k]);
    }
  }

  parentPaths.pop();
}

export const CTAGroupTypes = ['Page Header', 'General Blocks', 'Statistic', 'Basic CTA', 'Donation CTA', 'Text', 'Picture', 'Video'];
export const generateBlockCategories = (inputBlocks: any[]) => {
  const searchBlocks = searchBlockItems(inputBlocks, false);
  let groups: any = {};

  Object.keys(searchBlocks).forEach(key => groups[key] = { typeTitle: key });

  return { groups, categories: searchBlocks };
};

export const searchBlockItems = (inputBlocks: any[], isSearchBlock: boolean) => {
  const searchBlocks: any = {};
  let sortBy = _.sortBy(inputBlocks, (item: any) => {
    return CTAGroupTypes.indexOf(item.type)
  });

  const groupCategories = _.groupBy(sortBy, "type");
  for (const key in groupCategories) {

    if (key !== 'Special') {
      searchBlocks[key] = {
        values: groupCategories[key],
        isOpen: isSearchBlock,
      };
    }
  }

  return searchBlocks;
};

export const fetchCMSParentPages = (allPages: any) => {
  let pageParent: any = [];
  if (allPages && allPages.length > 0) {
    let isPublished = (f: any) => f.status.toLocaleLowerCase() === PAGE_STATUS[1].code;
    pageParent = allPages.filter((f: any) => isPublished(f) || f.type === 'master')
      .map((i: any) => { return { id: i.uuid, name: i.name, page_id: i.page_id, url: i.url, title: i.title, special_type: i.special_type } });
  }
  pageParent.unshift({ id: '', name: "None" });
  let buildPageParent = buildUrls(pageParent);
  return buildPageParent;
};

export const getContentById = async (id: any) => {
  if (id) {
    const result = await CMsPageManagerAPI.getCMSPageById(id);
    if (result && result.status === 200) {
      const record = result?.data?.pageContent;
      return record && record.content && record.content.length > 0 ? record.content : [];
    }
  }

  return [];
}

export const getCMSPageById = async (content: any[], ctaBlocks: any, globalItems: any) => {
  try {
    let arrayDashboard = [];
    let filterContent: any = [];

    if (content.length > 0 && ctaBlocks.length > 0) {
      content.forEach((f: any) => {
        const selectedBlock = ctaBlocks.find((r: any) => r.uuid === f.cta_block_id)
        if (selectedBlock) {
          let block = _.cloneDeep(selectedBlock);
          block["key"] = f["uuid"];
          block["name"] = f["name"] || block["name"];
          block["blockId"] = f["cta_block_id"];
          block["isDisabled"] = f["is_disabled"];
          block["parameters"] = f["parameters"];
          block["isBlockDisabled"] = f["is_block_disabled"];

          let updatedPaths: any = [];
          if (block.parameters && block.configuration && block.configuration.globalConfiguration) {
            block = syncBlockWithGlobalContent(block, globalItems, updatedPaths);
          }

          syncBlockWithGlobalContentForObject(block, globalItems, updatedPaths);
          filterContent.push(block);
        }
      });

      arrayDashboard.push({ "components": filterContent });

      return arrayDashboard;
    }
    return [{ components: [] }];
  } catch (error) {
    console.log(error);
    return [{ components: [] }];
  }
}

export const callCreateAndPublishPageAPI = async (data: any, onSuccess: any, onFail: any) => {
  try {    
    const res = await CMsPageManagerAPI.createAndPublishPage(data);
    if (res && res.status === 200) {
      onSuccess(res.data.result);
    } else {
      onFail();
    }
  } catch (error) {
    console.log(error);
    onFail();
  }
}

export const callCreatePageAPI = async (data: any, onSuccess: any, onFail: any) => {
  try {
    const res = await CMsPageManagerAPI.createPage(data);
    if (res && res.status === 200) {
      onSuccess(res.data.result);
    } else {
      onFail();
    }
  } catch (error) {
    console.log(error);
    onFail();
  }
}

export const callUpdatePageAPI = async (uuid: string, data: any, onSuccess: any, onFail: any) => {
  try {
    const res = await CMsPageManagerAPI.updatePage(uuid, data);

    if (res && res.status === 200) {
      onSuccess(res.data.result);
      // showNotification("success", "Update Page successfully", toast);
      // return;
    } else {
      onFail();
    }
  } catch (error) {
    console.log(error);
    onFail();
  }
};

export const callDeletePageAPI = async (uuid: string, onSuccess: any, onFail: any) => {
  try {
    const res = await CMsPageManagerAPI.deletePage(uuid);

    if (res && res.status === 200) {
      onSuccess(res.data.result);
    } else {
      onFail();
    }
  } catch (error) {
    console.log(error);
    onFail();
  }
};

export const getCMSPagesByType = (allPages: any, parentPages: any, page_codes: PAGE_CODE[]) => {
  let projectPages: any = [];
  if (allPages && allPages.length > 0) {
    let isPublished = (f: any) => f.status.toLocaleLowerCase() === PAGE_STATUS[1].code;
    let isProjectType = (f: any) => page_codes.length === 0 || page_codes.includes(f.type.toLocaleLowerCase());
    let isValidSEOConfig = (f: any) => !!f.seo_configuration;
    projectPages = allPages
      .filter((f: any) => isPublished(f) && isProjectType(f) && isValidSEOConfig(f))
      .map((i: any) => {
          let parentURL = getParentURL(i.page_id, parentPages);
          let url = createURL(parentURL, i.url);
          return {...i, url: url}
      });
  }
  return projectPages;
};

export const getInternalURLPages = (allPages: any) => {
  let parentPages: any = [];
  if (allPages && allPages.length > 0) {
    parentPages = allPages.map((i: any) => { return { id: i.uuid, name: i.name, page_id: i.page_id, url: i.url, title: i.title } });
  }

  parentPages = buildUrls(parentPages);
  let internalPages: any = [];
  if (parentPages && parentPages.length > 0) {
    internalPages = parentPages
      .filter((f: any) => !!f.id)
      .map((i: any) => {
        let name = _.last(i.nodes?.map((m: any) => m.name));
        let urls = i.nodes?.map((n: any) => n.url).filter((u: string) => !!u);
        let urlObj = _.reduce(PAGE_LANGUAGE.map((l: any) => l.code), function (pre: any, cur: any) {
          pre[cur] = joinURL('/', ...urls);
          return pre;
        }, {});
        let result = {
          id: i.id,
          name: name,
          url: urlObj,
          nodes: i.nodes,
        }
       _.assign(result, urlObj);
        return result;
      });
  }
  return _.sortBy(internalPages, 'name');
};

export const filterParentPages = (parentPages: any, uuid: any) => {
  if (!parentPages || !uuid) {
    return;
  }

  let childrenPageIds = findChildrenOfParentPage(parentPages, uuid);
  let filterParentPages = _.filter(parentPages, f => f.id !== uuid && !childrenPageIds.includes(f.id));
  return filterParentPages;
}

export const findChildrenOfParentPage = (parentPages: any, id: any) => {
  let isValid: boolean = true;
  let ids: any = [id];
  do {
    let pages = _.filter(parentPages, f => f.page_id === _.last(ids));
    if (!pages || pages.length <= 0 || _.difference(pages.map(f => f.id), ids).length === 0) {
      isValid = false;
    }
    else {
      ids = _.union(ids, pages.map(f => f.id));
    }
  } while (isValid);
  return ids;
}

export const getMasterURL = (page_id: string, master_pages: any[]) => {
  if (!page_id) {
    return '/';
  }

  let selected = master_pages?.find((p: any) => p.uuid === page_id);
  let prefix = selected?.url;
  return prefix ? '/' + prefix + '/' : '/';
}

export const getParentURL = (page_id: string, pages: any[]) => {
  if (!page_id) {
    return '/';
  }

  let selected = pages.find((p: any) => p.id === page_id);
  let prefix = selected?.nodes?.map((n: any) => n.url).filter((u: string) => !!u).join('/');

  return prefix ? '/' + prefix + '/' : '/';
}

export const createURL = (parentUrl: any, url: any) => {
  let reducedLanguages = _.reduce(PAGE_LANGUAGE.map((l: any) => l.code), function (pre: any, cur: any) {
    pre[cur] = joinURL('/', parentUrl, url);
    return pre;
  }, {});
  return reducedLanguages;
}

export const updateBreadcrumbs = async (parentPages: any, selectedPage: any, ctaBlocks: any, dashboard: any) => {
  let breadcrumbs: any = [];
  if (selectedPage.page_id) {
    let isExisted: boolean = true;
    let pageIds = [selectedPage.page_id];
    do {
      let page = parentPages.find((f: any) => f.id !== '' && f.id === _.last(pageIds));
      if (!page || pageIds.includes(page.page_id)) {
        isExisted = false;
      }
      else {
        pageIds.push(page.page_id);
        breadcrumbs.unshift({
          name: page.title,
          href: page.url
        })
      }
    } while (isExisted);
  }

  let href = '/';
  breadcrumbs.forEach((item: any) => {
    href = joinURL(href, item.href);
    let reducedLanguages = _.reduce(PAGE_LANGUAGE.map((l: any) => l.code), function (pre: any, cur: any) {
      pre[cur] = href
      return pre;
    }, {});
    item.href = reducedLanguages;
  });

  let blockCodes = ['content-header', 'image-content-header'];
  let activeBlocks;
  if (dashboard && dashboard.length > 0) {
    activeBlocks = dashboard[0].components.filter((f: any) => blockCodes.includes(f.code));
  }

  let blocks = ctaBlocks.filter((f: any) => blockCodes.includes(f.code))
  if (blocks || activeBlocks) {
    blocks.concat(activeBlocks).forEach((block: any) => {
      block.parameters.breadcrumbs = breadcrumbs;
      block.parameters.breadcrumb_last = selectedPage.title;
    });
  }
}

export const findType = (pageTypes: any[], name = 'General') => {
  if (name && pageTypes) {
    return pageTypes.find((t: any) => t.name.de === name);
  }

  return null;
}

export const generateComponent = (name: string, component?: IComponent): any => {
  if (component) {
    const { uuid: blockId, code, type, template, parameters, configuration } = component;
    return { name, key: uuidv4(), type, code, blockId, template, parameters, configuration };
  }

  return { name, key: uuidv4() };
};

export const builderPage = (pageInfo: any, headers: any[], footers: any[]) => {
  const footer = (footers || []).find((f: any) => f.uuid === pageInfo.footer_id);
  const header = (headers || []).find((f: any) => f.uuid === pageInfo.header_id);
  const footerBlock: any = {};
  const headerBlock: any = {};

  ['configuration', 'parameters'].forEach(f => {
    footerBlock[f] = JSON.parse(footer[f]);
    headerBlock[f] = JSON.parse(header[f]);
  });

  footerBlock['template'] = footer['template'];
  footerBlock['key'] = 'page-main-footer';
  footerBlock['blockId'] = footer['uuid'];
  footerBlock['name'] = footer.name.de;
  footerBlock.parameters.is_global_ref = true;

  headerBlock['template'] = header['template'];
  headerBlock['key'] = 'page-main-header';
  headerBlock['blockId'] = header['uuid'];
  headerBlock['name'] = header.name.de;
  headerBlock['is_primary'] = header.is_primary;
  headerBlock.parameters.is_global_ref = header.is_primary;
  
  if(header && !header.is_primary) {
    headerBlock.parameters = { ...headerBlock.parameters, ...pageInfo.custom_config.header, menuItems: headerBlock.parameters.menuItems }
  }
  
  return { headerBlock, footerBlock };
}

export const buildUrls = (pages: any[]) => {
  const dict: any = {};
  pages.forEach((p: any) => { dict[p.id] = p; });

  return pages.map((f: any) => {
    if (!f.id) {
      return { id: '', name: 'None' };
    }

    let page_id = f.page_id;
    let nodes: any[] = [f];
    let n = 0;

    while (page_id && n < 100) {
      const parentNode = dict[page_id];
      if (parentNode) {
        nodes.unshift(parentNode);
        page_id = parentNode.page_id;
      } else {
        page_id = null;
      }
      n++;
    }

    return { special_type: f.special_type, id: f.id, page_id: f.page_id, url: f.url, title: f.title, name: nodes.map((n: any) => n.title?.de).join('  >  '), nodes };
  });
}

const generateObjWithLanguages = (value: any) => {
  return _.reduce(PAGE_LANGUAGE.map((l: any) => l.code), function (pre: any, cur: any) {
    pre[cur] = value;
    return pre;
  }, {});
}

export const isJSON = (text: any) => {
  if (typeof text !== "string") {
      return false;
  }
  try {
      JSON.parse(text);
      return true;
  } catch (error) {
      return false;
  }
}

export const stringifyJSON = (data: any) => {
  try {
    return JSON.stringify(data);
  } catch (error) {
    return JSON.stringify([]);
  }
}

export const parseJSON = (data: any) => {
  try {
    return _.isString(data) ? JSON.parse(data) : data;
  } catch (error) {
    return data;
  }
}

export const emptySpecialDataOfSource = (data: any) => {
  data.source_id = data.uuid;
  data.status = 'draft';
  data.special_type = '';
  data.parent_id = '';
  data.last_published_at = '';
  data.first_published_at = '';
  data.updated_at = null;
  data.content_id = null;
  data.created_at = null;
  data.uuid = null;
  data.serial = null;
  data.name = 'Copy of ' + (data.name || 'Untitled');
  data.title = {
    de: 'Copy of ' + (data.title?.de || 'Untitled'),
    en: 'Copy of ' + (data.title?.en || 'Untitled'),
  };

  if (data.custom_config && data.custom_config.published_info) {
    data.custom_config.published_info = [];
  }

  return data;
}

export const removeDisabledFromBlocks = (content: any[]) => {
  if (!content) {
    return [];
  }

  (content || []).forEach((c: any) => {
    c.is_block_disabled = false;
    c.is_disabled = false;
  });

  return content || [];
}
import { AxiosClient, cacheAge } from './httpClient'

export default class HistoryImportAPI {
  static getDetailImport(id: string) {
    return AxiosClient.get(`/donations/import/${id}`);
  }

  static importGeoCoordinate(data: any) {
    return AxiosClient.post(`/donations/import-geocoordinate`, data);
  }

  static getDetailGeoImport(id: string) {
    return AxiosClient.get(`/donations/import-geo/${id}`);
  }

  static importDonation(values: any) {
    const data = {
      _batch: {
        data: values.dataImport
      },
      donation_type: values.donation_type,
      payment_language_code: values.language,
      payment_currency_code: values.currency,
      land_id: values.land_id,
      land_code: values.land_code,
      campaign_id: values.campaign_id,
      campaign_code: values.campaign_code,
      is_send_receipt: values.is_send_receipt
    };

    return AxiosClient.post(`/donations/import-csv`, data);
  }

  static importRecurringDonations(values: any) {
    const data = {
      _batch: {
        data: values.dataImport
      },
      donation_type: values.donation_type,
      payment_language_code: values.language,
      payment_currency_code: values.currency,
      land_id: values.land_id,
      land_code: values.land_code,
      campaign_id: values.campaign_id,
      campaign_code: values.campaign_code,
      is_send_receipt: values.is_send_receipt
    };

    return AxiosClient.post(`/donations/import-recurring-csv`, data);
  }

  static sendEMails(id: any, data: any) {
    return AxiosClient.post(`/donations/import/send-mail`, { id, passed_records: data });
  }
  
  static sendGeoMails(id: any, data: any) {
    return AxiosClient.post(`/donations/import-geo/${id}/send-mail`, { id, passed_records: data });
  }

  static getHistoryImport(data: any) {
    return AxiosClient.get(`/donations/import/pagination`, {...data, ...cacheAge});
  }

  static getGeoImports(data: any) {
    return AxiosClient.get(`/donations/import-geo/pagination`, {...data, ...cacheAge});
  }
}

import { AxiosClient, cacheAge } from './httpClient'

export default class WildernessRunsManagerAPI {
  static getWildernessRuns(data: any) {
    return AxiosClient.post(`/wi-runs/search`,  data);
  }

  static generateWildernessRun(data: any) {
    return AxiosClient.post(`/wi-runs`,  data);
  }

  static updateWildernessRun(id: string, data: any) {
    return AxiosClient.put(`/wi-runs/${id}`,  data);
  }

  static getWildernessRunDetails(id: any) {
    return AxiosClient.get(`/wi-runs/${id}`);
  }

  static downloadWIRCertificate(uuid: any) {
    return AxiosClient.get(`/wi-runs/${uuid}/certificate`);
  }

  static sendWIRCertificate(uuid: any, lang: any = 'de') {
    return AxiosClient.post(`/wi-runs/${uuid}/certificate/send-mail?lang=${lang}`, {});
  }

  static getTeams(id: string,data: any) {
    return AxiosClient.post(`/wi-runs/${id}/teams/search`,  data);
  }

  static getTeamDetails(id: string, teamID: string) {
    return AxiosClient.get(`/wi-runs/${id}/teams/${teamID}`);
  }

  static updateTeam(id: string, teamID: string, data: any) {
    return AxiosClient.put(`/wi-runs/${id}/teams/${teamID}`,  data);
  }

  static getRunners(id: string, teamID: string, data: any) {
    return AxiosClient.post(`/wi-runs/${id}/teams/${teamID}/runners/search`,  data);
  }

  static getRunnerDetails(id: string, teamID: string, runnerID: string) {
    return AxiosClient.get(`/wi-runs/${id}/teams/${teamID}/runners/${runnerID}`);
  }

  static updateRunner(id: string, teamID: string, runnerID: string, data: any) {
    return AxiosClient.put(`/wi-runs/${id}/teams/${teamID}/runners/${runnerID}`,  data);
  }

  static getSponsors(id: string, teamID: string, runnerID: string, data: any) {
    return AxiosClient.post(`/wi-runs/${id}/teams/${teamID}/runners/${runnerID}/sponsors/search`,  data);
  }

  static updateSponsor(id: string, teamID: string, runnerID: string, sponsorID: string, data: any) {
    return AxiosClient.put(`/wi-runs/${id}/teams/${teamID}/runners/${runnerID}/sponsors/${sponsorID}`,  data);
  }

  static importSponsors(id: string, data: any) {
    return AxiosClient.post(`/wi-runs/${id}/import-sponsors`,  { data });
  }

  static importTeamsRunners(id: string, data: any) {
    return AxiosClient.post(`/wi-runs/${id}/import-runners-teams`,  { data });
  }

  static sendMailsDonor(id: string, teamID: string, runnerID: string, data: any) {
    return AxiosClient.post(`/wi-runs/${id}/teams/${teamID}/runners/${runnerID}/certificate/send-mail`,  { data });
  }

  static downloadTeamCertificate(id: string, teamID: string) {
    return AxiosClient.get(`/wi-runs/${id}/teams/${teamID}/certificate`);
  }

  static downloadRunnerCertificate(uuid: any, teamId: any, runnerId: any) {
    return AxiosClient.get(`/wi-runs/${uuid}/teams/${teamId}/runners/${runnerId}/certificate`);
  }

  static downloadAllTeamCertificate(uuid: any) {
    return AxiosClient.get(`/wi-runs/${uuid}/teams/batch/certificates`);
  }

  static downloadAllRunnerCertificate(uuid: any, teamId: any) {
    return AxiosClient.get(`/wi-runs/${uuid}/teams/${teamId}/runners/batch/certificates`);
  }

  static sendDonorCertificates(id: string) {
    return AxiosClient.post(`/wi-runs/${id}/sponsors/certificate/send-mail`,  {});
  }

  static sendAllTeamCertificates(id: string) {
    return AxiosClient.post(`/wi-runs/${id}/teams/certificate/send-mail`,  {});
  }

  static sendAllRunnerCertificates(id: string, teamId: string) {
    return AxiosClient.post(`/wi-runs/${id}/teams/${teamId}/runners/certificate/send-mail`,  {});
  }
}

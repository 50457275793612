import * as React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button } from "primereact/button";
import { useRef } from "react";
import {  parseJSON } from "../../../../utils/logic";
import { DonationManagerAPI } from "../../../../services";
import CheckboxSvg from "../../../common/CheckboxSvg";
import AnimateHeight from "react-animate-height";
import './update-donation-form.scss';
import { WIFormInputTextV2 } from "../../../../components_v2/common/form-fields";
import { useTranslation } from "react-i18next";
import { useLayoutV2 } from "../../../../context/LayoutProvider";

const UpdateDonationFormComponent = (props: any) => {
  const {
    turnOffProgress,
    setLoadingProgress,
    setErrorProgress,
    setSuccessProgress,
  } = useLayoutV2();
  const { t } = useTranslation("language", { keyPrefix: "donation_manager" });
  const { t: errorTrans } = useTranslation("language", { keyPrefix: "errors" });
  
  const { donation, onHide, fetchDonationsData } = props;
  const formikRef = useRef(null);
  const lang = donation.payment_language_code;
  const defaultReason = donation.campaign && donation.campaign.certificate_reason_description ? donation.campaign.certificate_reason_description[lang] : null;
  const initialRegionsValues = () => {
    return {
      payment_first_name: donation && donation.payment_first_name !== null ? donation.payment_first_name : '',
      payment_last_name: donation && donation.payment_last_name !== null ? donation.payment_last_name : '',
      payment_company_name: donation && donation.payment_company_name !== null ? donation.payment_company_name : '',
      payment_email: donation && donation.payment_email !== null ? donation.payment_email : '',
      personalize_certificate_reason: donation && donation.personalize_certificate_reason !== null ? parseJSON(donation.personalize_certificate_reason) : defaultReason,
      personalize_certificate_display_name: donation && donation.personalize_certificate_display_name !== null ?  donation.personalize_certificate_display_name : '',
      personalize_certificate: donation.personalize_certificate || false,
      as_company: donation.as_company || false,
    };
  };

  const donationFormSchema = Yup.object().shape({
    as_company: Yup.boolean().notRequired(),
    personalize_certificate: Yup.boolean().notRequired(),
    payment_first_name: Yup.string(),
    payment_last_name: Yup.string(),
    payment_email: Yup.string().email("Email is invalid format").required("Email is required"),
    payment_company_name: Yup.string().when(["as_company"], { is: (as_company: any) => as_company, then: Yup.string().required("Company name is required")}),
    personalize_certificate_display_name: Yup.string().nullable().max(40, 'Max 40 letters'),
    personalize_certificate_reason: Yup.string().nullable().max(80, 'Max 80 letters')
  });

  const onUpdateDonation = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (!formikRef || !formikRef?.current?.isValid) {
        return;
      }

      setLoadingProgress(errorTrans('txt_loading'));
      // @ts-ignore: Object is possibly 'null'.
      const data = formikRef?.current.values;
      data.payment_company_name = data.as_company ? data.payment_company_name : "";
      data.personalize_certificate_reason = data.personalize_certificate ? data.personalize_certificate_reason : "";
      data.personalize_certificate_display_name = data.personalize_certificate ? data.personalize_certificate_display_name : "";

      const response = await DonationManagerAPI.updateDonation(donation.uuid, data);
      if (response && response.status === 200) {
        setSuccessProgress(t('txt_save_donation_success'));
        onHide();
        fetchDonationsData();
      } else {
        throw new Error('txt_save_donation_failed');
      }
    } catch (error: any) {
      setErrorProgress(t(error.message || 'txt_save_donation_failed'));
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={initialRegionsValues()}
      validationSchema={donationFormSchema}
      onSubmit={(values, { setSubmitting }) => {
        // console.log("values >>>", values);
      }}
    >
      {({
        values,
        errors,
        touched,
        dirty,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="row-content">
            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2
                  title={t("txt_certificate_name")}
                  name="personalize_certificate_display_name"
                  isRequired={false}
                  maxLength={40}
                />
              </div>
            </div>
            <div className="row mb-24">
              <div className="col-md-12">
                <WIFormInputTextV2
                  title={t("txt_certificate_occasion")}
                  name="personalize_certificate_reason"
                  isRequired={false}
                  maxLength={80}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between gap-24">
                  <Button
                    className="wi-danger-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t("txt_cancel")}`}
                    onClick={() => onHide()}
                  />
                  <Button
                    className="wi-primary-button-v2 h48 flex-1"
                    type="submit"
                    label={`${t("txt_save")}`}
                    onClick={onUpdateDonation}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default UpdateDonationFormComponent;

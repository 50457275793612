import * as React from 'react';
import "./droppable-component.scss";

const DroppableComponent = (props: any) => {
  const { name, onDragOver, onDrop, children } = props;

  return (
    <div
      className='droppable-component'
      onDragOver={(ev: React.DragEvent<HTMLDivElement>) => onDragOver(ev)}
      onDrop={(ev: React.DragEvent<HTMLDivElement>) => onDrop(ev, name)}
      data-id={name}
    >
      {/* Drag blocks & drop here */}
      <i className='pi pi-plus-circle' />
      {children}
    </div>
  )
};

export default DroppableComponent;

import { Button } from "primereact/button";
import * as React from "react";
import './action-form-partner.scss';
import { ReactComponent as SaveSvg } from "../../../../assets/images/icons/save.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackSvg } from '../../../../assets/images/icons/arrow-narrow-left.svg';
import WiStatus from "../../../../components_v2/common/wi-status/wi-status";
import { STATUS_LIST } from "../../../../components_v2/utils/utils";
import { useFormikContext } from "formik";
import MenuDot from "../../../../components_v2/common/menu-dot/menu-dot";

const ActionFormComponent = (props: any) => {
  const { isEdit, actionBack, onSubmit, menuItems } = props;
  const { t } = useTranslation('language', { keyPrefix: 'partner_details' });
  const { values } = useFormikContext<any>();

  return (
    <div className="action-form-partner pt-12 pr-24 pb-12 pl-24">
      <div className="d-flex flex-row">
        <WiStatus item={STATUS_LIST.find(s => s.code === values.status)}></WiStatus>
      </div>
      <div className="d-flex flex-row gap-16">
        {!isEdit ? <MenuDot items={menuItems} /> : <></>}
        <Button
          className="wi-danger-button-v2"
          label={t('txt_discard')}
          onClick={(e: any) => actionBack()}
          hidden={!isEdit}>
          <BackSvg className="icon-svg" />
        </Button>
        <Button
          className="wi-primary-button-v2"
          label={t('txt_save')}
          onClick={(e: any) => onSubmit()}
          hidden={!isEdit}>
          <SaveSvg className="icon-svg" />
        </Button>
      </div>
    </div>
  );
};

export default ActionFormComponent;

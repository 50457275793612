import { useFormikContext } from "formik";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { WIFormElement } from "../wi-form-element/wi-form-element";
import * as _ from "lodash";
import './wi-calendar.scss';
import moment from 'moment';

export function WICalendar(props: any){
  const { name, dateFormat, values, disabled } = props;
  const { handleChange, handleBlur, setFieldValue } = useFormikContext();
  
  useEffect(() => {
    let date: any =  _.get(values, name) ? new Date(moment(_.get(props.values, props.name), "DD.MM.YYYY").format("MM/DD/YYYY")) : new Date(moment().format("MM/DD/YYYY"));
    _.update(props.values, props.name, u => moment(date).format('DD.MM.YYYY'));
  }, []);

  const onHandleChange = (e: any) => {
    if (e.value === undefined) {
      return;
    }
    const formatDate =  e.value ? moment(e.value).format("DD.MM.YYYY") : null;
    setFieldValue(name, formatDate);
  }
  
  return (
    <WIFormElement {...props}>
      <Calendar
        id={name}
        name={name}
        value={ _.get(values, name) ? new Date(moment(_.get(props.values, props.name), "DD.MM.YYYY").format("MM/DD/YYYY")) : undefined }
        onChange={onHandleChange}
        onBlur={handleBlur}
        dateFormat={dateFormat || 'dd.mm.yy'}
        onTodayButtonClick={onHandleChange}
        onClearButtonClick={onHandleChange}
        showButtonBar
        readOnlyInput
        disabled={disabled}
      />
    </WIFormElement>
  );
};

import * as React from "react";
import "./wi-tag.scss";
import { Tag } from "primereact/tag";
import { useEffect, useState } from "react";
import { DONATION_STATUS } from '../../../utils/utils';

const WITag = (props: any) => {
  const {  value } = props;
  const [valueTag, setValueTag] = useState({
    icon: '',
    value: '',
    status: '',
    color: '',
    backgroundColor: ''
  })

  useEffect(() => {
    if(value) {
      switch(value) {
        case DONATION_STATUS.SUCCEEDED: setValueTag({
          icon: 'pi pi-check',
          value: "Succeeded",
          status: 'success',
          color: '#ffffff',
          backgroundColor: '#94C24A'
        });
        break;
        case DONATION_STATUS.PENDING: setValueTag({
          icon: 'pi pi-user',
          value: "Pending",
          status: 'info',
          color: '#ffffff',
          backgroundColor: '#8ACBC1'
        });
        break;
        case DONATION_STATUS.CANCELLED: setValueTag({
          icon: 'pi pi-times',
          value: "Cancelled",
          status: 'Danger',
          color: '#ffffff',
          backgroundColor: '#ababab'
        });
        break;
        case DONATION_STATUS.FAILED: setValueTag({
          icon: 'pi pi-times',
          value: "Failed",
          status: 'Danger',
          color: '#ffffff',
          backgroundColor: '#E52322'
        });
        break;
        case DONATION_STATUS.REFUNDED: setValueTag({
          icon: 'pi pi-exclamation-triangle',
          value: "Refunded",
          status: 'warning',
          color: '#ffffff',
          backgroundColor: '#333333'
        });
        break;
        case DONATION_STATUS.RESERVED: setValueTag({
          icon: 'pi pi-info-circle',
          value: "Reserved",
          status: 'info',
          color: '#ffffff',
          backgroundColor: '#477EC0'
        });
        break;
        default: setValueTag({
          icon: 'pi pi-info-circle',
          value: "Empty",
          status: 'info',
          color: '#ffffff',
          backgroundColor: '#eceef1'
        });
        break;
      }
    }
  }, [value]);

  return (
    <>
    {
        valueTag.value ? 
        <div className="tag-custom">
          <Tag className="mr-2" style={{ color: valueTag.color, backgroundColor: valueTag.backgroundColor }} severity={valueTag.status} value={valueTag.value}></Tag>
        </div>
        : ''
    }
    </>
  );
};

export default WITag;
